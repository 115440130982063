import CustomerSurveysListView from "../../../pages/Views/srv/CustomerSurveys/CustomerSurveysListView";
import CustomerSurveysEditView from "../../../pages/Views/srv/CustomerSurveys/CustomerSurveysEditView";
import CustomerSurveysAddView from "../../../pages/Views/srv/CustomerSurveys/CustomerSurveysAddView";
import CustomerSurveysDeleteView from "../../../pages/Views/srv/CustomerSurveys/CustomerSurveysDeleteView";
import { FAStack } from "../../../Utility/FontAwesome";

const CustomerSurveysRoute = {
  role: ['admin'],
  type: "collapse",
  name: "Customer Surveys",
  sidenav: true,
  order: 2,
  key: "admin-customerSurveys",
  hideCollapse: true,
  route: "/customer_surveys/list",
  icon: (
    <>
      <FAStack main="square-poll-vertical" sub={false} />
      &nbsp;
    </>
  ),
  collapse: [
    {
      name: "Customer Survey Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "customerSurveys-list",
      route: "/customer_surveys/list",
      icon: <FAStack main="user" sub="plus-circle" />,
      component: <CustomerSurveysListView />,
    },
    {
      hide: true,
      name: "Edit Customer Survey",
      sidenav: true,
      key: "customerSurveys-edit",
      route: "/customer_surveys/edit/:customerSurveyId",
      component: <CustomerSurveysEditView />,
    },
    {
      hide: true,
      name: "Delete Customer Survey",
      sidenav: true,
      key: "customerSurveys-delete",
      route: "/customer_surveys/delete/:customerSurveyId",
      component: <CustomerSurveysDeleteView />,
    },
    {
      name: "Add New Customer Survey",
      sidenav: true,
      key: "customerSurveys-add",
      route: "/customer_surveys/add",
      component: <CustomerSurveysAddView />,
    },
  ],
};

export default CustomerSurveysRoute;
