import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Alert, TableFooter } from "@mui/material";
import { CostFormat } from "../../../../../Reusable/Misc";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InstallerLineItem } from "./ProductLineItems/InstallerLineItem";
import { ProductLineItem } from "./ProductLineItems/ProductLineItem";
import { styled } from "@mui/material/styles";
import { productImage } from "../../../../../Reusable/QuoteUtilities";
import TextElement from "../../../../../Reusable/Forms/Elements/TextElement";
import Grid from "@mui/material/Grid";
import SuiTypography from "../../../../../components/SuiTypography";
import SuiInput from "../../../../../components/SuiInput";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`&.${tableRowClasses.head} th`]: {
    color: "#fff !important",
    fontSize: 14,
  },
}));

export const ProductLineItems = (props) => {
  const {
    state,
    status,
    setting,
    handleChange,
    handleDepositChange,
    handleBlur,
    depositPctField,
    removeLineItem,
  } = props;

  var lineItemCounter = 0;
  var lineItemCount = 0;
  var defaultPct;

  var quoteStatus;
  var rowCounter = 5;

  if (state.viewData.installRequest.quot !== null) {
    defaultPct = state.viewData.installRequest.quot.depositPct;

    if (defaultPct === null) {
      defaultPct = setting("QUOTE_DEPOSIT_PCT");
    }
  } else {
    defaultPct = setting("QUOTE_DEPOSIT_PCT");
  }

  if (status === "QUOTE_ACCEPTED_DEPOSIT_PAID") {
    quoteStatus = "DEPOSIT_PAID";
    rowCounter++;
  } else {
    quoteStatus = "NOT_PAID";
  }

  const renderInstallerLineItem = (lineItem, counter, i) => {
    const count = ++lineItemCounter;

    return (
      <InstallerLineItem
        productImage={productImage}
        quoteStatus={quoteStatus}
        lineItem={lineItem}
        count={count}
        i={i}
        removeLineItem={removeLineItem}
      />
    );
  };

  const handleLocalChange = (ev) => {
    handleChange(ev);
  };

  var amountPaid = 0.0;

  if (
    state.viewData.installRequest.quot !== null &&
    typeof state.viewData.installRequest.quot.paymentsList !== "undefined"
  ) {
    state.viewData.installRequest.quot.paymentsList.map((payment) => {
      amountPaid = amountPaid + 2;
    });
  }

  const renderLineItem = (lineItem, i) => {
    const count = i + 1;

    lineItemCounter = count;

    return (
      <ProductLineItem
        productImage={productImage}
        quoteStatus={quoteStatus}
        lineItem={lineItem}
        count={count}
      />
    );
  };

  const calcDeposit = (deposit, paid) => {
    let dueAmount = deposit;

    if (setting("QUOTE_DEPOSIT_LIMIT_ENABLE") === "YES") {
      if (deposit > 0) {
        if (deposit < setting("QUOTE_DEPOSIT_MINIMUM")) {
          dueAmount = parseFloat(setting("QUOTE_DEPOSIT_MINIMUM")).toFixed(2);
        } else if (deposit > setting("QUOTE_DEPOSIT_MAXIMUM")) {
          dueAmount = parseFloat(setting("QUOTE_DEPOSIT_MAXIMUM")).toFixed(2);
        } else {
          dueAmount = parseFloat(deposit).toFixed(2);
        }
      }
    } else {
      dueAmount = parseFloat(deposit).toFixed(2);
    }

    if (paid) {
      dueAmount = dueAmount - paid;
      if (dueAmount < 0) {
        dueAmount = 0;
      }
    }
    return dueAmount;
  };

  const totalPaid = () => {
    let amountPaid = 0;
    if (state.viewData.installRequest.quot !== null) {
      if (state.viewData.installRequest.quot.paymentsList.length > 0) {
        state.viewData.installRequest.quot.paymentsList.map((payment) => {
          amountPaid += payment.amount;
        });
      }
    }
    return amountPaid;
  };

  const totalDue = (total, paid) => {
    const amtPaid = totalPaid();
    let totalDue = 0;

    if (state.viewData.installRequest.quot !== null) {
      totalDue = state.viewData.installRequest.quot.total;
    }
    return totalDue - amtPaid;
  };

  const blockChangeIfComplete = (ev) => {
    if (state.viewData.installRequest.irr.status === 'QUOTE_ACCEPTED_BY_CUSTOMER' ||
        state.viewData.installRequest.irq.status === 'INVOICE_PAID') {
      return false;
    }

    return this.handleChange(ev);
  }

  return (
    <>
      <h4 className="mt2">Items Ordered</h4>
      <hr />
      <br />

      <Table className="table-striped data-list-table">
        <TableHead color={"primary"} sx={{ display: "table-header-group !important" }}>
          <StyledTableRow>
            <TableCell align="right">#</TableCell>
            <TableCell></TableCell>
            <TableCell align={"left"}>Product/Service</TableCell>
            <TableCell align={"left"}>Quantity</TableCell>
            <TableCell align={"right"}>Price</TableCell>
            <TableCell align={"right"}>Status</TableCell>
            <TableCell align={"right"}>Optional</TableCell>
            <TableCell align={"right"}>Total Due</TableCell>
            <TableCell align={"center"}>Action</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody className={"table-bb-thin"}>
          {Array.isArray(state.viewData.installRequest.ord.orderLineItemsList) &&
            state.viewData.installRequest.ord.orderLineItemsList.map((lineItem, i) => {
              lineItemCount++;
              return renderLineItem(lineItem, i);
            })}
          {state.data.quoteLineItems.map((installerLineItem, i) => {
            return renderInstallerLineItem(installerLineItem, lineItemCount, i);
          })}
        </TableBody>
        <TableFooter className={"alt-stripe"}>
          <TableRow className="quote-total-price-footer">
            <td rowSpan={rowCounter} colSpan={6}>
              <h3>Deposit Percentage to Collect</h3>
              <p className={"text-muted"}>
                Up front, the customer will be prompted to pay a deposit - which is a percentage
                that you set here.
              </p>

              <Grid container>
                <Grid xs={3} item>
                  <TextElement
                    max={3}
                    name={"depositPct"}
                    defaultValue={defaultPct}
                    disabled={
                      state.viewData.installRequest.irr.status === "QUOTE_ACCEPTED_BY_CUSTOMER" ||
                      state.viewData.installRequest.irq.status === "INVOICE_PAID"
                    }
                    field={depositPctField}
                    onChange={blockChangeIfComplete}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} item>
                  {setting("QUOTE_DEPOSIT_LIMIT_ENABLE") === "YES" ? (
                    <Alert
                      color={"info"}
                      icon={<FontAwesomeIcon icon={["fas", "exclamation-circle"]} />}
                    >
                      <SuiTypography fontSize={14}>
                        If you do not meet the minimum deposit of $
                        {setting("QUOTE_DEPOSIT_MINIMUM")}, the deposit will be set to $
                        {setting("QUOTE_DEPOSIT_MINIMUM")}. If the deposit exceeds $
                        {setting("QUOTE_DEPOSIT_MAXIMUM")}, then the deposit will be capped at $
                        {setting("QUOTE_DEPOSIT_MAXIMUM")}.
                      </SuiTypography>
                    </Alert>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </td>
            <td
              style={{ borderLeft: "solid 4px #004990", backgroundColor: "#fff" }}
              align={"right"}
            >
              Discount :
            </td>
            <td colSpan={2} align={"right"} style={{ borderLeft: "2px solid #d9e5f2" }}>
              ${parseFloat(0).toFixed(2)}
            </td>
          </TableRow>
          <TableRow className="quote-total-price-footer">
            <td style={{ borderLeft: "solid 4px #004990" }} align={"right"}>
              Required Items :
            </td>
            <td colSpan={2} align={"right"} style={{ borderLeft: "2px solid #d9e5f2" }}>
              ${parseFloat(state.requiredCostOfQuote).toFixed(2)}
            </td>
          </TableRow>
          <TableRow className="quote-total-price-footer">
            <td style={{ borderLeft: "solid 4px #004990" }} align={"right"}>
              Optional Items :
            </td>
            <td colSpan={2} align={"right"} style={{ borderLeft: "2px solid #d9e5f2" }}>
              ${parseFloat(state.optionalCostOfQuote).toFixed(2)}
            </td>
          </TableRow>
          <TableRow className="quote-total-price-footer">
            <td style={{ borderLeft: "solid 4px #004990" }} align={"right"}>
              Taxes :
            </td>
            <td colSpan={2} align={"right"} style={{ borderLeft: "2px solid #d9e5f2" }}>
              ${parseFloat(0).toFixed(2)}
            </td>
          </TableRow>
          <TableRow className="quote-total-price-footer">
            <td
              style={{ borderLeft: "solid 4px #004990", borderBottom: "1px solid #333" }}
              align={"right"}
            >
              Subtotal :
            </td>
            <td style={{ borderBottom: "1px solid #333", borderLeft: "2px solid #d9e5f2"  }} colSpan={2} align={"right"}>
              <CostFormat cost={state.totalCostOfQuote} />
            </td>
          </TableRow>
          {status === "QUOTE_ACCEPTED_DEPOSIT_PAID" && (
            <TableRow className="quote-total-price-footer">
              <td
                style={{ borderLeft: "solid 4px #004990", borderBottom: "1px solid #333" }}
                align={"right"}
              >
                PAID :
              </td>
              <td style={{ borderBottom: "1px solid #333" }} colSpan={2} align={"right"}>
                <CostFormat cost={totalPaid()} />
              </td>
            </TableRow>
          )}
          <TableRow className="quote-total-price-footer">
            <td colSpan={6} />
            <td
              style={{ borderLeft: "solid 4px #004990", borderBottom: "2px solid #333" }}
              align={"right"}
            >
              Deposit Due <sup>†</sup> :
            </td>
            <td style={{ borderBottom: "2px solid #333" }} colSpan={2} align={"right"}>
              ({state.data.depositPct.value || setting("QUOTE_DEPOSIT_PCT")}%) $
              {calcDeposit(state.depositOfQuote, totalPaid())}
            </td>
          </TableRow>
          <TableRow className="quote-total-price-footer">
            <td colSpan={6} />
            <td
              style={{ borderLeft: "solid 4px #004990", borderBottom: "2px solid #333" }}
              align={"right"}
            >
              TOTAL Due <sup>‡</sup> :
            </td>
            <td style={{ borderBottom: "2px solid #333" }} colSpan={2} align={"right"}>
              ${totalDue()}
            </td>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};
