import React from "react";
import ZevForm from "../ZevForm";
import TextElement from "../../../Reusable/Forms/Elements/TextElement";
import withRouter from "../../../withRouter";

class ForgotPasswordForm extends ZevForm {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const o = this.init(this, {
      isCodeSent: false,
      loaderStyle: "auth",
      service: {
        endpoint: "/account/password/forgot",
        entity: "ForgotPassword",
      },
      labels: {
        submit: "Continue",
        submitting: "Sending reset link",
      },
      data: {
        email: {
          value: "",
          immutable: true,
          valid: false,
          touched: false,
          placeholder: "Email Address",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
      },
    });

    this.setState(o, () => {
      this.mounted();
    });
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <TextElement
              type="email"
              name="email"
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.email}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(ForgotPasswordForm);
