import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shop from "../../examples/Icons/Shop";
import DefaultContent from "../../ZevModules/Dashboard/DefaultContent";
import UsersRoute from "./core/UsersRoute";
// import AuditsRoute from "./core/AuditsRoute";
import ProductsRoute from "./core/ProductsRoute";
import CustomersRoute from "./cst/CustomersRoute";
import VehiclesRoute from "./cst/VehiclesRoute";
import MakesRoute from "./cst/MakesRoute";
import ModelsRoute from "./cst/ModelsRoute";
import SitesRoute from "./cst/SitesRoute";
import DealersRoute from "./dlr/DealersRoute";
import ContractorsRoute from "./ins/ContractorsRoute";
import InstallersRoute from "./ins/InstallersRoute";
import InstallRequestsRoute from "./irq/InstallRequestsRoute";
import InstallationQuestionsRoute from "./irq/InstallationQuestionsRoute";
// import TeamsRoute from "./irq/TeamsRoute";
import JobsRoute from "./irq/JobsRoute";
import OrdersRoute from "./ord/OrdersRoute";
import CustomerSurveysRoute from "./srv/CustomerSurveysRoute";
import CustomerSurveysQuestionsRoute from "./srv/CustomerSurveysQuestionsRoute";
import StoresRoute from "./core/StoresRoute";
import CompleteAccountSetupView from "../../pages/Account/CompleteAccountSetupView";
import SettingsRoute from "./core/SettingsRoute";
import OnboardingSuccessView from "../../pages/Account/OnboardingSuccess";
import PaymentsOnboardingView from "../../pages/Account/PaymentsOnboarding";

const adminRoutes = [
  {
    type: "collapse",
    noCollapse: true,
    name: "Dashboard Home",
    order: 1,
    key: "dashboard-home",
    sidenav: true,
    icon: <Shop size="12px" />,
    component: <DefaultContent />,
    route: "/",
  },
  {
    type: "collapse",
    noCollapse: true,

    name: "SuccessfulOnboarding",
    order: 1,
    hide: true,
    key: "successful-onboarding",
    sidenav: true,
    icon: <Shop size="12px" />,
    component: <OnboardingSuccessView />,
    route: "/onboarding_success",
  },
  {
    type: "collapse",
    noCollapse: true,

    name: "Payments Onboarding",
    order: 1,
    hide: true,
    key: "payments-onboarding",
    sidenav: true,
    icon: <Shop size="12px" />,
    component: <PaymentsOnboardingView />,
    route: "/payments_onboarding",
  },
  {
    order: 1.5,
    type: "title",
    name: "top divider",
    title: "",
    key: "admin-settings-pages-td15",
  },


  {
    role: ['admin'],
    type: "collapse",
    name: "System",
    order: 2,
    key: "system-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "gear"]} />,
    collapse: [
      // AuditsRoute,
      SettingsRoute,
      UsersRoute,
      ProductsRoute,
      StoresRoute,
    ],
  },

  {
    role: ['admin' ],
    type: "collapse",
    name: "Installers",
    order: 3,
    key: "installers-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "user-helmet-safety"]} />,
    collapse: [ContractorsRoute, InstallersRoute],
  },

  {
    role: ['admin'],
    type: "collapse",
    name: "Order data",
    order: 4,
    key: "order-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "bags-shopping"]} />,
    collapse: [CustomersRoute, VehiclesRoute, MakesRoute, ModelsRoute, SitesRoute, OrdersRoute],
  },

  {
    type: "divider",
    name: "top divider",
    key: "divider-3-45",
    order: 4.5,
  },

  {
    role: ['admin'],
    type: "collapse",
    name: "Front-end Data",
    order: 5,
    key: "front-end-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "message-question"]} />,
    collapse: [InstallationQuestionsRoute, CustomerSurveysQuestionsRoute],
  },

  {
    type: "collapse",
    name: "Job Workflow",
    order: 6,
    key: "workflow-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "clipboard-list"]} />,
    collapse: [
      InstallRequestsRoute,
      // QuotesRoute,
      JobsRoute,
      CustomerSurveysRoute,
    ],
  },

  {
    type: "collapse",
    role: ['admin'],
    name: "Third Party",
    order: 7,
    key: "third-party-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "user-tag"]} />,
    collapse: [DealersRoute],
  },

  {
    type: "collapse",
    name: "Complete Account Setup",
    order: 7.2,
    hide: true,
    route: "/complete_setup",
    key: "continue-account-setup-admin-data",
    icon: <FontAwesomeIcon icon={["fad", "user-tag"]} />,
    component: <CompleteAccountSetupView />,
  },

  {
    type: "divider",
    name: "top divider",
    key: "divider-3-8",
    order: 8,
  },
];

export default adminRoutes;
