import axios from "axios";

import { getBearerToken, isLoggedIn } from "../Auth/Check";
import { bakeCookie, deleteCookie } from "../../Utility/Cookie";
import Response from "../Responses/Response";
import Auth from "../Responses/Auth";
import Exception from "../Responses/Exception";
import RateLimit from "../Responses/RateLimit";
import Toastr from "../Toastr";
import Session from "../Responses/Session";
import Account from "../Responses/Account";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

class HttpClient {
  static bind(axios: axios) {
    axios.interceptors.request.use(function (config) {
      config.headers["Content-Type"] = "application/json";
      config.headers["Accept"] = "application/json";
      if (isLoggedIn()) {
        config.headers.Authorization = "Bearer " + getBearerToken();
      }

      return config;
    });
    axios.interceptors.response.use(
      function (response) {
        const cookie_auth = "authTokenCK";
        if (typeof response.headers !== "undefined") {
          if (typeof response.headers["content-type"] !== "undefined") {
            if (response.headers["content-type"].indexOf("text/html") > -1) {
              setTimeout(() => {
                // createSAMLForm(response.data);
              }, 500);
            }
          }

          if (typeof response.headers["set-authorization"] !== "undefined") {
            localStorage.removeItem("zev_cache");
            bakeCookie(cookie_auth, response.headers["set-authorization"]);
          }
        } else {
        }

        let responseObject;

        if (response.data.newAccount === true) {
          // window.location = "/complete_setup";
        }

        if (response.data.instance === "AUTH") {
          responseObject = new Auth(response);
        } else if (response.data.instance === "ACCOUNT") {
          responseObject = new Account(response);
        } else if (response.data.instance === "RATE_LIMIT") {
          responseObject = new RateLimit(response);
          Toastr.error("Slow Down", "Rate limited!");
        } else if (response.data.instance === "SESSION") {
          responseObject = new Session(response);
        } else {
          responseObject = new Response(response);
        }

        if (responseObject.hasToast()) {
          const toastSubject = responseObject.getToastSubject();
          const toastMessage = responseObject.getToastMessage();

          switch (responseObject.getToastStatus()) {
            case "ERROR":
              Toastr.error(toastSubject, toastMessage);
              break;
            case "INFO":
              Toastr.info(toastSubject, toastMessage);
              break;
            case "SUCCESS":
              Toastr.success(toastSubject, toastMessage);
              break;
            case "WARNING":
              Toastr.warning(toastSubject, toastSubject);
              break;
          }
        }

        return responseObject;
      },
      function (error) {
        const cookie_auth = "authTokenCK";
        if (error) {
          let responseObject;

          if (error.response.status === 401) {
            deleteCookie(cookie_auth);
            localStorage.removeItem("zev_cache");
            if (error.config.url !== "/zev.hub/api/auth/login") {
              window.location.href = "/auth/login"; // reload();
            }
          }

          if (error.response.status === 403) {
            window.location.href = "/zev.hub/auth/status";
          }

          if (error.response.status === 429) {
            const errorText =
              "Maximum of " +
              error.response.data.data.max_requests +
              " requests in a " +
              error.response.data.data.time_period +
              " second period exceeded.";
            responseObject = new RateLimit(error.response);
            Toastr.error("Rate limit", errorText);
          } else if (error.message.indexOf("JWT") > -1) {
            deleteCookie("JSESSIONID");
            window.location.href = "/zev.hub/auth/status";
          } else if (error.toString().indexOf("status code 500") > -1) {
            Toastr.error("Server Error", "Please try again");
          } else {
            responseObject = new Exception(error.response);
          }
          return responseObject;
        }
      }
    );
  }

  static getUploadClient() {
    return axios.create({
      baseURL: "https://localhost:8080",
      headers: {
        "Content-type": "application/json",
      },
    });
  }
}

export default HttpClient;
