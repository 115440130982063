import { setDotProp } from "./Misc";

const Revalidate = function () {
  this.field = null;

  this.setField = function (field) {
    this.field = field;
  };

  this.getField = function () {
    return this.field;
  };
};

const ValidationRules = {
  required: function (el, set) {
    let length = 0;

    if (typeof el.value !== "undefined" && el.value !== null && el.value !== "") {
      return true;
    }

    return set.msg;
  },
  email: function (el, set) {
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let re = new RegExp(pattern);
    if (re.test(el.value)) {
      return true;
    }
    return set.msg;
  },
  between: function (el, set) {
    if (el.value >= set.lower && el.value <= set.upper) {
      return true;
    }

    return set.msg;
  },
  integerOnly: function (el, set) {
    let pattern = /^([0-9.]+)$/;
    let re = new RegExp(pattern);
    if (re.test(el.value)) {
      if (ValidationRules.numericDecimal(el, set)) {
        if (Math.ceil(parseInt(el.value)) === parseInt(el.value)) {
          return true;
        }
        return set.msg;
      }
    }
    return set.msg;
  },
  allInList: function (el, set) {
    let isValid = true;

    for (let k in el.value) {
      if (set.opt.indexOf(el.value[k].id) === -1) {
        isValid = false;
      }
    }

    if (isValid) {
      return true;
    }

    return set.msg;
  },
  gtd: function (el, set) {
    if (typeof set.opt === "function") {
      if (set.opt() === "") {
        return true;
      }
    }

    console.log(el);

    const baseDt = new Date(el.value);
    const compDt = new Date(set.opt());

    if (baseDt.getTime() > compDt.getTime()) {
      return true;
    }

    return set.msg;
  },
  ltd: function (el, set) {
    if (typeof set.opt === "function") {
      if (set.opt() === "") {
        return true;
      }
    }

    const baseDt = new Date(el.value);
    const compDt = new Date(set.opt());

    if (baseDt.getTime() < compDt.getTime()) {
      return true;
    }

    return set.msg;
  },
  numericDecimal: function (el, set) {
    let pattern = /^([0-9.]+)$/;
    let re = new RegExp(pattern);
    if (re.test(el.value)) {
      return true;
    }
    return set.msg;
  },
  numeric: function (el, set) {
    let pattern = /^\d+$/;
    let re = new RegExp(pattern);
    if (re.test(el.value)) {
      return true;
    }
    return set.msg;
  },
  is: function (el, set) {
    let val = "";
    if (typeof set.opt === "function") {
      val = set.opt();
    } else {
      val = set.opt;
    }

    if (el.value === val) {
      return true;
    }
    return set.msg;
  },
  isChecked: function (el, set) {
    if (el.checked === set.opt) {
      return true;
    }
    return set.msg;
  },
  minLength: function (el, set) {
    if (el.value.length >= set.opt) {
      return true;
    }
    return set.msg;
  },
  maxLength: function (el, set) {
    if (el.value.length <= set.opt) {
      return true;
    }
    return set.msg;
  },
  in: function (el, set) {
    if (set.opt.indexOf(el.value.toString()) === -1) {
      return set.msg;
    }
    return true;
  },
};

export const validate = (el, rules, loop) => {
  if (typeof el.value === "undefined" && typeof el.selectvalue === "object") {
    el.value = el.selectvalue.value;
  }

  if (rules === false) {
    rules = ValidationRules;
  }

  const RV = new Revalidate();
  let valid = true;
  let msgs = [];

  for (let rule in rules) {
    if (loop !== true && typeof rules[rule].rv !== "undefined") {
      RV.setField(rules[rule].rv);
    }

    let response = ValidationRules[rule](el, rules[rule]);
    if (response !== true) {
      valid = false;
      msgs.push(response);
    }
  }

  return {
    revalidate: RV.getField(),
    valid: valid,
    errors: msgs,
  };
};

export const validateWizardStep = (state, props) => {
  let isValid = validateAll(state, props);

  return isValid;
};

export const validateForm = (scope, props) => {
  let isValid = validateAll(scope, props);
  return isValid;
};

export const validateField = (scope, field, ehCb) => {
  let handler = ehCb || function () {};
  let state = scope.state;
  let isValid = true;
  let errors = [];

  const result = validate(field, field.vr);

  if (result.valid === false) {
    const obj = {
      el: field,
      err: result.errors,
    };

    // errors.push(obj);

    result.errors.map((error) => {
      // state.data[field.name].errors[0] = error;
    });

    setDotProp(field.name + ".errors", state.data, result.errors);

    scope.setState(state, () => {
      scope.forceUpdate();
    });

    isValid = false;
  }

  if (!isValid) {
    handler(errors);
  }

  return isValid;
};

export const validateAll = (scope, props) => {
  const state = scope.state;
  let isValid = true;
  let errors = [];

  for (let v in state.data) {
    let el = state.data[v];
    let rules = state.data[v].vr;

    if (typeof el.noderef !== "undefined" && el.noderef.current !== null) {
      el.noderef.current.focus();
      el.noderef.current.blur();
    }

    const result = validate(el, rules);

    if (result.valid === false) {
      const obj = {
        el: v,
        err: result.errors,
      };

      errors.push(obj);

      result.errors.map((error) => {
        state.data[v].errors[0] = error;
      });

      scope.setState(state, () => {
        scope.forceUpdate();
      });

      isValid = false;
    }
  }

  if (!isValid) {
    props.errorCallback(errors);
  }

  return isValid;
};

export default validate;
