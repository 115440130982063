import React from "react";
import ZevList from "../../../Forms/ZevList";
import { caseWords, SiteLinkButton } from "../../../../Reusable/Misc";
import { shipmentStatus } from "../../../../Reusable/Widgets";
import withRouter from "../../../../withRouter";
import moment from "moment";

class OrdersList extends ZevList {
  constructor(props) {
    super(props);
    this.props = props;
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "orders",
        endpoint: "/orders",
      },
      sortFields: [
        { f: "orderNumber", l: "Order Number" },
        { f: "customers.userId.lastName", l: "Customer" },
        { f: "installRequestsList.siteId.city", l: "City/State" },
        { f: "paymentStatus", l: "Payment Status" },
        { f: "ff_status", l: "Fulfillment" },
        { f: "created", l: "Order Date", cls: "text-right" },
        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record) {
    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small text-color-light">{record.updated_by}</span>
        </div>
      </>
    );

    const actions = (order) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View order"
            path={"/orders/view/" + order.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit order"
            path={"/orders/edit/" + order.id}
            btnIcon="edit"
            btnStyle="p"
          />
        </>
      );
    };

    const viewOrder = (order) => {
      return (
        <>
          <a href="#">{order}</a>
        </>
      );
    };

    return {
      orderNumber: viewOrder(record.order_number),
      "customer.user.lastName": caseWords(record.customer),
      "installRequestsList.siteId.city": record.city + ", " + record.state,
      paymentStatus: caseWords(record.payment_status.replace("_", " ")),
      ff_status: shipmentStatus(record.ff_status),
      created: moment.utc(record.created).local().format("M/D/Y h:mm A"),
      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(OrdersList);
