import React from "react";
import SuiBadge from "../components/SuiBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActiveStatus = (props) => {
  let content = (
    <>
      <FontAwesomeIcon size="2x" icon={["fad", "times-circle"]} />
      Inactive
    </>
  );

  if (props.active) {
    content = (
      <>
        <FontAwesomeIcon size="2x" icon={["fad", "check-circle"]} />
        &nbsp; Active
      </>
    );

    return <SuiBadge color="success" variant="gradient" badgeContent={content} size="md" />;
  }

  return <SuiBadge color="error" variant="gradient" badgeContent={content} size="md" />;
};

export default ActiveStatus;
