import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";
import TextAreaElement from "../../../../Reusable/Forms/Elements/TextAreaElement";

class StoresAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "store",
          endpoint: "/stores/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let store = "";

    if (this.props.recordId) {
      store = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { store: data };
        }.bind(this)
      );
    } else {
      store = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([store]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "store",
        loaderStyle: "store",
        service: {
          endpoint: "/stores/save",
          redirectTarget: "/dashboard",
          entity: "Store",
        },
        labels: {
          submit: "Save Store",
          submitting: "Saving Store",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          name: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Name",
            label: "Store Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              minLength: { opt: 3, msg: "Min length of 3 required" },
              maxLength: { opt: 40, msg: "Max length of 40 required" },
            },
          },
          description: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Description",
            label: "Store Description",
            errors: [],
            vr: {},
          },
          address1: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Address1",
            label: "Store Address1",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          address2: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Address2",
            label: "Store Address2",
            errors: [],
            vr: {},
          },
          apiKey: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Api Key",
            label: "Store Api Key",
            errors: [],
            vr: {},
          },
          apiSecret: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Api Secret",
            label: "Store Api Secret",
            errors: [],
            vr: {},
          },
          apiToken: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Api Token",
            label: "Store Api Token",
            errors: [],
            vr: {},
          },
          city: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store City",
            label: "Store City",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          domain: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Domain",
            label: "Store Domain",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          isActive: {
            type: "checkbox",
            value: 0,
            placeholder: "Is Active",
            label: "Is Active",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            errors: [],
            vr: {},
          },
          state: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store State",
            label: "Store State",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          storeType: {
            value: "SHOPIFY",
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "Store Store Type",
            label: "Store Store Type",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          /*     userId: {
                                     value: '',
                                     isRequired: false,
                                     immutable: false,
                                     valid: false,
                                     touched: false,
                                     placeholder: 'Store User Id',
                                     label: 'Store User Id',
                                     errors: [],
                                     vr: {}
                                 },*/
          zip: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store Zip",
            label: "Store Zip",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.store, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="name"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    defaultValue={this.state.data.name.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <TextAreaElement
                      sz="1"
                      label="Description"
                      field={this.state.data.description}
                      name="description"
                      setFieldData={this.setFieldData}
                      placeholder={this.state.data.description.placeholder}
                      touched={this.state.data.description.touched}
                      valid={this.state.data.description.valid}
                      defaultValue={this.state.data.description.value}
                      errors={this.state.data.description.errors}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="address1"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address1}
                    defaultValue={this.state.data.address1.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="address2"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address2}
                    defaultValue={this.state.data.address2.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="apiKey"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.apiKey}
                    defaultValue={this.state.data.apiKey.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="apiSecret"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.apiSecret}
                    defaultValue={this.state.data.apiSecret.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="apiToken"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.apiToken}
                    defaultValue={this.state.data.apiToken.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="city"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.city}
                    defaultValue={this.state.data.city.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="domain"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.domain}
                    defaultValue={this.state.data.domain.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={12}>
                  <CheckboxElement
                    name="isActive"
                    onChange={this.handleChange}
                    field={this.state.data.isActive}
                  />
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    type="text"
                    name="state"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.state}
                    defaultValue={this.state.data.state.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item>
                    <Grid item className="pr1" xs={6}>
                      <TextElement
                        type="text"
                        name="zip"
                        focus={true}
                        size="large"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        field={this.state.data.zip}
                        defaultValue={this.state.data.zip.value}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>&nbsp;</Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(StoresAddEditForm);
