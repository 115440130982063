import React from "react";
import { validateSession } from "./Auth/Check";
import withRouter from "../withRouter";
import { deleteCookie } from "../Utility/Cookie";
import Session from "./Responses/Session";

class Heartbeat extends React.Component {
  timers = {};

  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.tick();
  }

  tick() {
    clearTimeout(this.timers.heartbeat);
    this.timers.heartbeat = setTimeout(() => {
      this.tock();
    }, 15000);
  }

  async tock() {
    console.log("TOCK!");
    const session = await validateSession();

    if (session instanceof Session) {
      if (session.getIsExpired()) {
        deleteCookie("authTokenCK");
        deleteCookie("JSESSIONID");
        localStorage.removeItem("zev_cache");
        this.props.navigate("/auth/login");
      } else if (session.getIsValid()) {
        this.tick();
      } else if (session.getIsInvalid()) {
        deleteCookie("authTokenCK");
        deleteCookie("JSESSIONID");
        localStorage.removeItem("zev_cache");
        this.props.navigate("/auth/login");
      }
    } else {
    }
  }

  render() {
    return <></>;
  }
}

export default withRouter(Heartbeat);
