import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import { DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { createGlobalStyle } from "styled-components";
import BadgePill from "../../Reusable/BadgePill";
import moment from "moment/moment";

const DatePickerWrapperStyles = createGlobalStyle`
    .dp-full-width {
        width: 100%;
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RescheduleDate = (props) => {
  const { jobId, currentInstallDate, quoteId, ops, isOpen, onSuccess } = props;

  const [dateSelected, setDateSelected] = useState(true);
  const [installDate, setInstallDate] = useState(new Date(currentInstallDate));
  const [confirm, setConfirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const handleClose = (e, r) => {
    if (r && r === "backdropClick") {
      return;
    }

    props.handleClose();
  };

  const scheduleJob = async () => {
    setIsSaving(true);

    const results = await ops
      .singleSave(
        {
          quoteId: quoteId,
          jobId: jobId,
          installIso: moment.utc(installDate).local().toISOString(),
          installDate: moment.utc(installDate).local().format("YYYY-MM-DD"),
          installTime: moment.utc(installDate).local().format("h:mm a"),
        },
        {
          endpoint: "jobs/scheduleJob",
        }
      )
      .then((results) => results)
      .catch((results) => results);

    if (results.getIsSuccess() === true) {
      setIsSaved(true);
      props.onSuccess();
    }
  };

  return (
    <div>
      <Dialog
        className="overflow-modal w666"
        open={isOpen}
        onClose={handleClose}
        keepMounted
        disableEscapeKeyDown
        // TransitionComponent={Transition}
      >
        {confirm === true ? (
          <DialogTitle>{isSaved === true ? <>Saved</> : <>Confirm Reschedule</>}</DialogTitle>
        ) : (
          <DialogTitle>Reschedule Job</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>
            {confirm === true ? (
              <>
                {isSaved === true ? (
                  <>Appointment successfully saved.</>
                ) : (
                  <p>Please confirm that the chosen date and time is accurate.</p>
                )}
              </>
            ) : (
              <p>
                Please select the date and time for this job, and it will be attached to this Job
                order. <br />
                <br />{" "}
                <span className={"color-primary"}>
                  Once the Job has been rescheduled, the customer will be notified of the change.
                </span>
              </p>
            )}
          </DialogContentText>
          {confirm === true ? (
            <>
              {isSaved === true ? (
                <></>
              ) : (
                <>
                  <Grid container className={"mt6"}>
                    <Grid item>
                      <p>Install Date: {moment.utc(installDate).local().format("MMMM D, yyyy")}</p>
                      <p>Time: {moment.utc(installDate).local().format("h:mm a")}</p>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <Grid container className={"mt6"} alignItems={"center"}>
                <Grid item xs={8}>
                  <DatePicker
                    selected={installDate}
                    inline
                    wrapperClassName={"dp-full-width"}
                    onChange={(date) => {
                      setDateSelected(true);
                      setInstallDate(date);
                    }}
                    showTimeSelect
                    minDate={new Date()}
                    timeFormat="h:mm a"
                    timeIntervals={30}
                    timeCaption={"Time"}
                    dateFormat={"M/D/yyyy h:mm a"}
                  />
                </Grid>
                <Grid item xs={4}>
                  Selected Date:
                  <br />
                  <BadgePill>
                    {!dateSelected ? (
                      "Not Selected"
                    ) : (
                      <>
                        {moment.utc(installDate).local().format("MMMM D, yyyy")}
                        <br />
                        <small>@ {moment.utc(installDate).local().format("h:mm a")}</small>
                      </>
                    )}
                  </BadgePill>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {confirm === true ? (
            <>
              {isSaved === true ? (
                <>
                  <Button
                    color="success"
                    onClick={props.handleClose}
                    className={"nav-header-button"}
                  >
                    <FontAwesomeIcon
                      icon={icon({ style: "duotone", name: "check-circle" })}
                      fixedWidth
                    />
                    &nbsp; Saved - Close
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isSaving}
                    color="dark"
                    onClick={() => {
                      setConfirm(false);
                    }}
                    className={"nav-header-button"}
                  >
                    <FontAwesomeIcon
                      icon={icon({ style: "duotone", name: "arrow-left" })}
                      fixedWidth
                    />
                    &nbsp; Go Back
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    disabled={isSaving}
                    onClick={scheduleJob}
                    color="primary"
                    className={"nav-header-button"}
                  >
                    {isSaving === true ? (
                      <>
                        <FontAwesomeIcon
                          icon={icon({ style: "duotone", name: "spinner" })}
                          spin
                          fixedWidth
                        />
                        &nbsp;Saving...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={icon({ style: "duotone", name: "floppy-disk" })}
                          fixedWidth
                        />
                        &nbsp;Confirm and Save
                      </>
                    )}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    disabled={isSaving}
                    color="light"
                    onClick={props.handleClose}
                    className={"nav-header-button"}
                  >
                    <FontAwesomeIcon
                      icon={icon({ style: "duotone", name: "times-circle" })}
                      fixedWidth
                    />
                    &nbsp; Cancel
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setConfirm(true);
                }}
                color="primary"
                className={"nav-header-button"}
                disabled={!dateSelected}
              >
                <FontAwesomeIcon
                  icon={icon({ style: "duotone", name: "check-circle" })}
                  fixedWidth
                />
                &nbsp; Save
              </Button>
              &nbsp; &nbsp;
              <Button color="light" onClick={props.handleClose} className={"nav-header-button"}>
                <FontAwesomeIcon
                  icon={icon({ style: "duotone", name: "times-circle" })}
                  fixedWidth
                />
                &nbsp; Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RescheduleDate;
