import { toast } from "react-toastify";

const toastrProps = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ToastMessage = ({ closeToast, subject, text }) => (
  <div className="toastr-widget">
    <h4>{subject}</h4>
    <p>{text}</p>
  </div>
);

const Toastr = {
  success: function (subject, message) {
    toast.success(<ToastMessage subject={subject} text={message} />, toastrProps);
  },
  error: function (subject, message) {
    toast.error(<ToastMessage subject={subject} text={message} />, toastrProps);
  },
  info: function (subject, message) {
    toast.info(<ToastMessage subject={subject} text={message} />, toastrProps);
  },
  warning: function (subject, message) {
    toast.warning(<ToastMessage subject={subject} text={message} />, toastrProps);
  },
};

export default Toastr;
