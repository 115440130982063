import React from "react";
import SuiInput from "../../../components/SuiInput";
import FieldValidationErrors from "../FieldValidationErrors";
import SuiBox from "../../../components/SuiBox";
import { HiddenInput } from "../../FormElements";
import LabelElement from "./LabelElement";
import SuiTypography from "../../../components/SuiTypography";

class TextElement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.maxLength = props.max || null;
    this.bottomMargin = typeof props.bottomMargin !== "undefined" ? props.bottomMargin : 2;
  }

  componentDidMount() {
    /*this.setState(
          {
            ready: true,
            handleBlur: this.props.onBlur,
            handleChange: this.props.onChange,
            value: this.props.field.value,
            type: this.props.type,
            label: this.props.label,
            name: this.props.name,
            placeholder: this.props.field.placeholder,
            size: this.props.size
          }
        );*/
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {}

  render() {
    if (this.props.type === "hidden") {
      return <HiddenInput name={this.props.name} value={this.props.value} />;
    }

    return (
      <>
        <SuiBox className="target-fields-wrapper" mb={this.bottomMargin}>
          <LabelElement field={this.props.field} inline={this.props.inline}>
            {this.props.readonly ? (
              <>
                <br />
                <SuiTypography
                  component={"span"}
                  variant="button"
                  color="dark"
                  fontWeight="regular"
                  textGradient
                >
                  {this.props.field.value}
                </SuiTypography>
              </>
            ) : (
              <>
                <SuiInput
                  fullWidth={true}
                  type={this.props.type}
                  name={this.props.name}
                  size={this.props.size}
                  onChange={this.props.onChange}
                  autoFocus={this.props.focus === true}
                  placeholder={this.props.field.placeholder}
                  disabled={this.props.disabled}
                  value={
                    this.props.displayValue || this.props.field.value || this.props.defaultValue
                  }
                  onFocus={this.props.onFocus}
                  onBlur={this.props.onBlur}
                  error={this.props.field.errors.length > 0}
                />
              </>
            )}
          </LabelElement>
          <div>
            <FieldValidationErrors fieldErrors={this.props.field.errors} />
          </div>
        </SuiBox>
      </>
    );
  }
}

export default TextElement;
