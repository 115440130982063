import jwt from "jwt-decode";
import { ACL_DEFINITIONS } from "../Constants";
import _ from "lodash";
import { heartbeat } from "../Misc";
import { readCookie } from "../../Utility/Cookie";
import Session from "../Responses/Session";
import Operations from "../../Reusable/Controller/Operations";

import AxiosHandler from "../Controller/AxiosHandler";

export async function validateSession(): Session {
  const ops = new Operations(AxiosHandler);
  const cs = await ops.checkSession();
  return cs;
}

export async function asValidateSession() {
  let r = await validateSession().then((res) => res);

  if (r instanceof Session) {
    if (r.getIsExpired()) {
      return false;
    } else if (r.getIsValid()) {
      return true;
    }
  }

  return false;
}

export const authCheck = () => {
  const token = readCookie("authTokenCK");

  if (Array.isArray(token) && token.length === 0) {
    return false;
  } else if (token == null) {
    return false;
  }

  return true;
};

export const getBearerToken = () => {
  const token = readCookie("authTokenCK");
  return token;
};

export const checkAuth = () => {
  return heartbeat();
};

export const isLoggedIn = () => {
  const hasToken = authCheck();

  if (!hasToken) {
    return false;
  }

  return true;
};

export const getPermissions = () => {
  const token = getBearerToken();
  const dToken = jwt(token);
  const tokenObject = JSON.parse(dToken.sub);
  const uid = tokenObject.username;
  const tmpCache = JSON.parse(localStorage.getItem("zev_cache"));

  const ONE_MIN = 1 * 60 * 1000;
  const FIVE_MIN = 5 * 60 * 1000;

  const getPerms = () => {
    const perms = tokenObject.perms;
    let myPerms = [];

    for (let k in perms) {
      if (perms[k] === 1) {
        myPerms.push(ACL_DEFINITIONS[k]);
      }
    }

    return myPerms;
  };

  if (tmpCache === null) {
    const tmpCache = {
      [uid]: {
        created: new Date(),
        perms: getPerms(),
      },
    };

    localStorage.setItem("zev_cache", JSON.stringify(tmpCache));
    return tmpCache[uid].perms;
  } else {
    if (typeof tmpCache[uid] === "undefined") {
      const tmpCache = {
        [uid]: {
          created: new Date(),
          perms: getPerms(),
        },
      };

      localStorage.removeItem("zev_cache");
      localStorage.setItem("zev_cache", JSON.stringify(tmpCache));

      return tmpCache[uid].perms;
    } else {
      const currDate = new Date();
      const createdDate = new Date(tmpCache[uid].created);
      const dateDifference = currDate - createdDate;

      if (dateDifference > FIVE_MIN) {
        localStorage.removeItem("zev_cache");

        const tmpCache = {
          [uid]: {
            created: new Date(),
            perms: getPerms(),
          },
        };

        localStorage.setItem("zev_cache", JSON.stringify(tmpCache));
        return tmpCache[uid].perms;
      } else {
        // current data - return it

        const perms = tmpCache[uid].perms;
        return perms;
      }
    }
  }
};

export const isResourceAllowed = (properties) => {
  let requestedResourcePermissions = [];

  if (typeof properties.perms !== "undefined") {
    if (!Array.isArray(properties.perms)) {
      requestedResourcePermissions = new Array(properties.perms);
    } else {
      requestedResourcePermissions = properties.perms;
    }
  } else {
    return true;
  }

  const perms = getPermissions();

  if (requestedResourcePermissions.length === 0) {
    return true;
  }

  if (isLoggedIn() === true) {
    const ints = _.intersection(perms, requestedResourcePermissions);

    return ints.length > 0;
  } else {
  }
};
