import React from "react";
import { mapData } from "../../Reusable/Misc";
import { CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ZevForm from "../Forms/ZevForm";
import Operations from "../../Reusable/Controller/Operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withRouter from "../../withRouter";
import CheckboxElement from "../../Reusable/Forms/Elements/CheckboxElement";
import TextElement from "../../Reusable/Forms/Elements/TextElement";
import SuiButton from "../../components/SuiButton";
import SelectElement from "../../Reusable/Forms/Elements/SelectElement";
import states from "../../libs/states";

class CompleteAccountSetup extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: true,
        service: {
          entity: "completeSetup",
          endpoint: "/complete_setup/finish",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  noop(el) {
    if (el.target.tagName === "LABEL") {
      return false;
    }
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    const obj = new Promise(function (resolve, reject) {
      resolve();
    });

    this.promise = await Promise.all([obj]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(
        this,
        {
          overrideSubmitButtons: true,
          totalCostOfQuote: 0,
          recordId: this.props.recordId,
          submitIcon: (
            <FontAwesomeIcon
              icon={["fad", "floppy-disk-circle-arrow-right"]}
              fixedWidth={true}
              className={"mr2"}
            />
          ),
          flow: "completeAccountSetup",
          loaderStyle: "completeSetup",

          service: {
            endpoint: "/completeSetup/save",
            redirectTarget: "/",
            entity: "CompleteSetup",
          },
          openModal: {},
          labels: {
            submit: "Save Account Information",
            submitting: "Saving Account Information",
            submitted: "Saved",
            failed: "Save Failed",
          },
          viewData: viewData,
          data: {
            companyName: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Name",
              label: "Company Name",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
              },
              options: [],
            },
            userId: {
              value: "1",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Name",
              label: "Company Name",
              errors: [],
              vr: {},
            },
            companyAddress1: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Address 1",
              label: "Company Address 1",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
              },
              options: [],
            },
            companyAddress2: {
              value: "",
              isRequired: false,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Address 2",
              label: "Company Address 2",
              errors: [],
              vr: {},
              options: [],
            },
            companyCity: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company City",
              label: "Company City",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
              },
              options: [],
            },
            companyState: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company State",
              label: "Company State",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
              },
              options: [],
            },
            companyZip: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company ZIP",
              label: "Company ZIP",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
              },
              options: [],
            },
            companyWebsite: {
              value: "",
              isRequired: false,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Website",
              label: "Company Website",
              errors: [],
              vr: {},
              options: [],
            },
            companyPhone: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Company Phone",
              label: "Company Phone",
              errors: [],
              vr: {
                required: { opt: true, msg: "This field is required" },
                numeric: { opt: true, msg: "This field is required" }
              },
              options: [],
            },
            accountTypeReferer: {
              value: "",
              isRequired: false,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Dealer/Shopify Store",
              label: "Dealer/Shopify Store",
              errors: [],
              vr: {},
              options: [],
            },
            accountTypeInstaller: {
              value: true,
              isRequired: false,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Installer",
              label: "Installer",
              errors: [],
              vr: {},
              options: [],
            },
          },
        },
        true
      );

      let ns = mapData(o.viewData.installRequest, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  form() {
    return (
      <>
        <Card variant={"outlined"}>
          {/*<CardContentHeader></CardContentHeader>*/}
          <CardContent>
            <br />
            <h3 className={"mt2 mb2"}>Additional Information Requested</h3>
            <hr />
            <br />

            <Grid container>
              <Grid item sm={8}>
                <p>Enter your Company Details</p>
                <TextElement
                  type="text"
                  name="companyName"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyName}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={8}>
                <p>Company Address</p>
              </Grid>
              <Grid item sm={6}>
                <TextElement
                  type="text"
                  name="companyAddress1"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyAddress1}
                />
              </Grid>
              <Grid item sm={6}>
                <TextElement
                  type="text"
                  name="companyAddress2"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyAddress2}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={6}>
                <TextElement
                  type="text"
                  name="companyCity"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyCity}
                />
              </Grid>

              <Grid item sm={2}>
                <SelectElement
                  type="text"
                  name="companyState"
                  options={states}
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyState}
                />
              </Grid>
              <Grid item sm={4}>
                <TextElement
                  type="text"
                  name="companyZip"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyZip}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item sm={6}>
                <TextElement
                  type="text"
                  name="companyPhone"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyPhone}
                />
              </Grid>

              <Grid item sm={6}>
                <TextElement
                  type="text"
                  name="companyWebsite"
                  onBlur={this.handleBlur}
                  onChange={this.handleChange}
                  field={this.state.data.companyWebsite}
                />
              </Grid>
            </Grid>

            {/*<CheckboxElement type="checkbox" name="accountTypeReferer" onBlur={this.handleBlur} onChange={this.handleChange} field={this.state.data.accountTypeReferer}></CheckboxElement>*/}
            <div style={{ visibility: "hidden" }}>
              <p>Please select your account type</p>
              <CheckboxElement
                disabled={true}
                type="checkbox"
                name="accountTypeInstaller"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                field={this.state.data.accountTypeInstaller}
              ></CheckboxElement>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }

  customSubmit() {
    return (
      <>
        <Grid container className="mt5">
          <Grid item md={10} style={{ textAlign: "right " }}>
            <SuiButton
              type="submit"
              size={"large"}
              color="primary"
              variant="gradient"
              className={"mt6"}
              disabled={this.state.status.isSubmitting}
            >
              <FontAwesomeIcon icon={["fad", "do-not-enter"]} />
              &nbsp;&nbsp; Save and Continue
            </SuiButton>
          </Grid>
        </Grid>
      </>
    );
  }

  render() {
    return <>{this.state.initialized === true ? this.wrapper() : <></>}</>;
  }
}

export default withRouter(CompleteAccountSetup);
