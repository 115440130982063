class Controller {
  constructor(form, stateCallback) {
    this.form = form;
    this.stateCallback = stateCallback;
  }

  isSubmitting() {
    this.remoteState.status.isSubmitting = true;
    this.remoteState.status.isSubmitDisabled = true;
    this.stateCallback(this.remoteState);
  }

  async submit(state) {
    this.remoteState = state;
    this.isSubmitting();
    let auth;

    if (this.form.isPrivate()) {
      auth = await window.globals.operations._submit(this.form, this.form.data());
    } else {
      auth = await window.globals.operations.submit(this.form, this.form.data());
    }
    return auth;
  }

  async save(state) {}

  persist() {}
}

export default Controller;
