import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";
import { GhostComplete } from "../../../../ZevModules/GhostComplete";

class UsersAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "user",
          endpoint: "/users/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let user = "";

    if (this.props.recordId) {
      user = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { user: data };
        }.bind(this)
      );
    } else {
      user = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([
      user,
      // contractors
    ]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "user",
        loaderStyle: "user",
        service: {
          endpoint: "/users/save",
          redirectTarget: "/dashboard",
          entity: "User",
        },
        labels: {
          submit: "Save User",
          submitting: "Saving User",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          email: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Email",
            label: "User Email",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          firstName: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User First Name",
            label: "User First Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          isDeleted: {
            type: "checkbox",
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Is Deleted",
            label: "User Is Deleted",
            errors: [],
            vr: {},
            defaultValue: 0,
          },
          isLocked: {
            type: "checkbox",
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Is Locked",
            label: "User Is Locked",
            errors: [],
            vr: {},
          },
          isLoginEnabled: {
            type: "checkbox",
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Is Login Enabled",
            label: "User Is Login Enabled",
            errors: [],
            vr: {},
          },
          isSystemAdmin: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Is System Admin",
            label: "User Is System Admin",
            errors: [],
            vr: {},
          },
          lastName: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "User Last Name",
            label: "User Last Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          }
        },
      });

      let ns = mapData(o.viewData.user, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  asFill(v, field) {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="email"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.email}
                    value={this.state.data.email.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm={12}>
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item className="pr1" xs={3}>
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="firstName"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.firstName}
                      value={this.state.data.firstName.value}
                    />
                  </Grid>
                  <Grid item className="pr1" xs={3}>
                    <GhostComplete
                      filterLabel={"Find Contractor"}
                      setValue={this.asFill}
                      field={"lastName"}
                      path={"/contractors/search"}
                    >
                      <TextElement
                        readonly={this.state.viewOnly}
                        type="text"
                        name="lastName"
                        focus={true}
                        size="large"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        field={this.state.data.lastName}
                        value={this.state.data.lastName.value}
                      />
                    </GhostComplete>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item sm={12}>
                    &nbsp;
                  </Grid>

                  <Grid item className="pr1" xs={3}>
                    <CheckboxElement
                      name="isLocked"
                      readonly={this.state.viewOnly}
                      field={this.state.data.isLocked}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item className="pr1" xs={3}>
                    <CheckboxElement
                      name="isLoginEnabled"
                      readonly={this.state.viewOnly}
                      field={this.state.data.isLoginEnabled}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item className="pr1" xs={3}>
                    <CheckboxElement
                      name="isSystemAdmin"
                      readonly={this.state.viewOnly}
                      field={this.state.data.isSystemAdmin}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item className="pr1" xs={3}>
                    <CheckboxElement
                      name="isDeleted"
                      readonly={this.state.viewOnly}
                      field={this.state.data.isDeleted}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <>loading message</>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(UsersAddEditForm);
