import React from "react";
import { mapData } from "../../Reusable/Misc";
import Grid from "@mui/material/Grid";
import ZevForm from "../Forms/ZevForm";
import Operations from "../../Reusable/Controller/Operations";
import withRouter from "../../withRouter";
import Loader from "../../Reusable/Loader";

class OnboardingSuccessView extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      validated: false,
      entity: 'stripe',
      isLoading: true,
      isLoaded: false,
      viewData: {
        stripe: {
          data: {
            data: {
              stripe_valid: null
            }
          }
        }
      }
    };

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */
    const params = {
      endpoint: "completeSetup/verifyStripe",
    };

    let stripe = this.ops.singlePost({}, params).then(
      function (data) {
        return { stripe: data };
      }.bind(this)
    );

    this.promise = await Promise.all([stripe]).then((values) => {
      let viewData = {};

      console.log(values);


      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      console.log(viewData);

      const o = this.init(this, {
        isLoading: false,
        isLoaded: true,
        viewData: viewData,
        overrideSubmitButtons: true,
      });

      console.log(o);

      let ns = mapData(o.viewData.stripe, o);

      this.setState(ns);
    });



  }

  render() {
    return (
      <>
        <Grid container style={{ width: "100%" }}>
          <Grid item md={10}>
            <Loader
              isLoaded={this.state.isLoaded}
              loadingMessage={"Verifying your account with Stripe.com "}
            >
              {this.state.viewData?.stripe?.data?.data?.stripe_valid === "VALID" ? (
                <>Your account has been successfully validated</>
              ) : (
                <>There was a problem validating your account</>
              )}
            </Loader>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(OnboardingSuccessView);
