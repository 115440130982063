import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InstallationQuestionsListView from "../../../pages/Views/irq/InstallationQuestions/InstallationQuestionsListView";
import InstallationQuestionsEditView from "../../../pages/Views/irq/InstallationQuestions/InstallationQuestionsEditView";
import InstallationQuestionsAddView from "../../../pages/Views/irq/InstallationQuestions/InstallationQuestionsAddView";
import InstallationQuestionsDeleteView from "../../../pages/Views/irq/InstallationQuestions/InstallationQuestionsDeleteView";
import { FAStack } from "../../../Utility/FontAwesome";

const InstallationQuestionsRoute = {
  type: "collapse",
  name: "Installation Questions",
  sidenav: true,
  order: 2,
  key: "admin-installationQuestions",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Installation Question Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "installationQuestions-list",
      route: "/installation_questions/list",
      icon: <FAStack main="user" sub="plus-circle" />,
      component: <InstallationQuestionsListView />,
    },
    {
      hide: true,
      name: "Edit Installation Question",
      sidenav: true,
      key: "installationQuestions-edit",
      route: "/installation_questions/edit/:installationQuestionId",
      component: <InstallationQuestionsEditView />,
    },
    {
      hide: true,
      name: "Delete Installation Question",
      sidenav: true,
      key: "installationQuestions-delete",
      route: "/installation_questions/delete/:installationQuestionId",
      component: <InstallationQuestionsDeleteView />,
    },
    {
      name: "Add New Installation Question",
      sidenav: true,
      key: "installationQuestions-add",
      route: "/installation_questions/add",
      component: <InstallationQuestionsAddView />,
    },
  ],
};

export default InstallationQuestionsRoute;
