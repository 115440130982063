"use strict";

import React from "react";
import PropTypes from "prop-types";

const PageView = (props) => {
  let { itemClass, linkClass } = props;
  const {
    page,
    selected,
    activeClassName,
    activeLinkClass,
    getEventListener,
    pageSelectedHandler,
    href,
    extraAriaContext,
  } = props;

  let ariaLabel =
    props.ariaLabel || "Page " + page + (extraAriaContext ? " " + extraAriaContext : "");
  let ariaCurrent = null;

  if (selected) {
    ariaCurrent = "page";

    ariaLabel = props.ariaLabel || "Page " + page + " is your current page";

    if (typeof itemClass !== "undefined") {
      itemClass = itemClass + " " + activeClassName;
    } else {
      itemClass = activeClassName;
    }

    if (typeof linkClass !== "undefined") {
      if (typeof activeLinkClass !== "undefined") {
        linkClass = linkClass + " " + activeLinkClass;
      }
    } else {
      linkClass = activeLinkClass;
    }
  }

  return (
    <li className={itemClass}>
      <a
        role="button"
        className={linkClass}
        href={href}
        tabIndex="0"
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        onKeyPress={pageSelectedHandler}
        {...getEventListener(pageSelectedHandler)}
      >
        {page}
      </a>
    </li>
  );
};

PageView.propTypes = {
  pageSelectedHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  itemClass: PropTypes.string,
  linkClass: PropTypes.string,
  activeClassName: PropTypes.string,
  activeLinkClass: PropTypes.string,
  extraAriaContext: PropTypes.string,
  href: PropTypes.string,
  ariaLabel: PropTypes.string,
  page: PropTypes.number.isRequired,
  getEventListener: PropTypes.func.isRequired,
};

export default PageView;
