import SuiBadge from "../components/SuiBadge";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const shipmentStatus = (status) => {
  let content = <></>;
  let color = "";

  switch (status) {
    case "SHIPPED":
      content = (
        <>
          <FontAwesomeIcon icon={["fad", "truck"]} className="mr2" /> Shipped
        </>
      );
      color = "success";
      break;
    case "NOT_SHIPPED":
      content = (
        <>
          <FontAwesomeIcon icon={["fad", "truck"]} className="mr2" /> Not Shipped
        </>
      );
      color = "error";
      break;
  }

  return (
    <>
      <SuiBadge
        className="widget-badge"
        color={color}
        variant="gradient"
        badgeContent={content}
        size="lg"
      />
    </>
  );
};
