import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsListView from "../../../pages/Views/core/Products/ProductsListView";
import ProductsEditView from "../../../pages/Views/core/Products/ProductsEditView";
import ProductsAddView from "../../../pages/Views/core/Products/ProductsAddView";
import ProductsDeleteView from "../../../pages/Views/core/Products/ProductsDeleteView";

const ProductsRoute = {
  type: "collapse",
  name: "Products",
  sidenav: true,
  order: 2,
  key: "admin-products",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Product Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "products-list",
      route: "/products/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <ProductsListView />,
    },
    {
      hide: true,
      name: "Edit Product",
      sidenav: true,
      key: "products-edit",
      route: "/products/edit/:productId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <ProductsEditView />,
    },
    {
      hide: true,
      name: "View Product",
      sidenav: true,
      key: "products-view",
      route: "/products/view/:productId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <ProductsEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Delete Product",
      sidenav: true,
      key: "products-delete",
      route: "/products/delete/:productId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <ProductsDeleteView />,
    },
    {
      name: "Add New Product",
      sidenav: true,
      key: "products-add",
      route: "/products/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <ProductsAddView />,
    },
  ],
};

export default ProductsRoute;
