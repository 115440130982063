// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "Global/DashboardNavbar";
import Footer from "ZevModules/Footers/main";

// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Data
import DefaultContent from "./DefaultContent";

function Dashboard(props) {
  const { values } = breakpoints;

  const defaultContent = <DefaultContent />;

  const { title, subtitle, children } = props;

  const header = title || "Dashboard";
  const subheader = subtitle || "";

  const content = children || defaultContent;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3} className="bottom-page-padding-fix">
        <Grid container>
          <Grid item xs={12}>
            <SuiBox mb={3} p={1}>
              <SuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                {header}
              </SuiTypography>
              <SuiTypography
                variant={window.innerWidth < values.sm ? "h6" : "h5"}
                textTransform="capitalize"
                color={"secondary"}
                fontWeight="regular"
              >
                {subheader}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid container style={{ fontSize: '.88em'}}>
            <Grid item xs={1} />
            <Grid item xs={10} >
              <Grid container>{content}</Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
