import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";

class CustomerSurveysAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "customerSurvey",
          endpoint: "/customerSurveys/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let customerSurvey = "";

    if (this.props.recordId) {
      customerSurvey = this.ops
        .getRecord(this, this.props.recordId, null, null, this.props.entity)
        .then(
          function (data) {
            return { customerSurvey: data };
          }.bind(this)
        );
    } else {
      customerSurvey = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([customerSurvey]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "customerSurvey",
        loaderStyle: "customerSurvey",
        service: {
          endpoint: "/customerSurveys/save",
          redirectTarget: "/dashboard",
          entity: "CustomerSurvey",
        },
        labels: {
          submit: "Save CustomerSurvey",
          submitting: "Saving CustomerSurvey",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          isDeleted: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Customer Survey Is Deleted",
            label: "Customer Survey Is Deleted",
            errors: [],
            vr: {},
          },
          jobId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Customer Survey Job Id",
            label: "Customer Survey Job Id",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.customerSurvey, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="pr1" xs={12}>
                  <CheckboxElement
                    readonly={this.state.viewOnly}
                    field={this.state.data.isDeleted}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <SelectInput
                      size="1"
                      label="Job Id"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      name="jobId"
                      value={this.state.data.jobId.selectvalue}
                      touched={this.state.data.jobId.touched}
                      valid={this.state.data.jobId.valid}
                      errors={this.state.data.jobId.errors}
                      placeholder="Select..."
                      options={this.props.jobId}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(CustomerSurveysAddEditForm);
