import React from "react";

class LabelElementUncontrolled extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.field = props.field;
  }

  render() {
    let textLabel,
      checkboxLabel = <></>;

    textLabel = this.props.label;

    let errorClass = "";

    if (Object.keys(this.props.errors).length > 0) {
      errorClass = "label-error";
    }

    if (this.props.aboveLabel === true) {
      return (
        <>
          <label className={"form-label"}>
            {checkboxLabel} <br />
            {this.props.children}
          </label>
          <div></div>
        </>
      );
    }

    var inline;

    if (this.props.isRequired && this.props.inline) {
      inline = "label-inline-required";
    }

    return (
      <>
        <label className={"form-label " + inline + " " + errorClass}>
          {textLabel}
          <>
            {this.props.inline && (
              <span className="form-required-inline" title="This field is required">
                {" "}
                *{" "}
              </span>
            )}
            {!this.props.inline && (
              <span className="form-required" title="This field is required">
                {" "}
                *{" "}
              </span>
            )}
          </>
          <>{this.props.children}</>
        </label>
      </>
    );
  }
}

export default LabelElementUncontrolled;
