import React, {useEffect} from "react";
import { deleteCookie } from "../../Utility/Cookie";
import withRouter from "../../withRouter";
import Toastr from "../../Reusable/Toastr";
import Grid from "@mui/material/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const Logout = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(
        () => { navigate("/auth/login", { replace: true }); },
        1200
    );
  }, []);


    deleteCookie("JSESSIONID");
    deleteCookie("authTokenCK");

    Toastr.info("Authentication", "You have logged out");

    return <>
      <Grid container height={"100vh"} alignItems={"center"} justifyContent={"center"}>
        <Grid item height={"100%"} display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignContent={"center"} className={"text-color-primary"}>
          <Grid container>
            <Grid item display={"flex"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"28px"}>
                <FontAwesomeIcon icon={icon({style:"duotone", name:"spinner-third"})} spin={true} size={"2xl"} />
              </Typography>
              &nbsp;
              &nbsp;
              &nbsp;
              <Typography color={"darkslateblue"} fontSize={"22px"}>
                You are being logged out - please wait
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>;

}

export default Logout;
