import React from "react";
import withRouter from "../../../../withRouter";
import Grid from "@mui/material/Grid";
import ProductsAddEditForm from "../../../Forms/AddEdit/core/ProductsAddEditForm";

class ProductsEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: props.params.productId,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <ProductsAddEditForm
                viewOnlyMode={this.props.viewOnlyMode}
                recordId={this.state.recordId}
                entity={"products"}
                pageScope={this}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(ProductsEditView);
