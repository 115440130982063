import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModelsListView from "../../../pages/Views/cst/Models/ModelsListView";
import ModelsEditView from "../../../pages/Views/cst/Models/ModelsEditView";
import ModelsAddView from "../../../pages/Views/cst/Models/ModelsAddView";
import ModelsDeleteView from "../../../pages/Views/cst/Models/ModelsDeleteView";

const ModelsRoute = {
  type: "collapse",
  name: "Models",
  sidenav: true,
  order: 2,
  key: "admin-models",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Model Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "models-list",
      route: "/models/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <ModelsListView />,
    },
    {
      hide: true,
      name: "Edit Model",
      sidenav: true,
      key: "models-edit",
      route: "/models/edit/:modelId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <ModelsEditView />,
    },
    {
      hide: true,
      name: "Delete Model",
      sidenav: true,
      key: "models-delete",
      route: "/models/delete/:modelId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <ModelsDeleteView />,
    },
    {
      name: "Add New Model",
      sidenav: true,
      key: "models-add",
      route: "/models/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <ModelsAddView />,
    },
  ],
};

export default ModelsRoute;
