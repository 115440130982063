import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";

class VehiclesAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "vehicle",
          endpoint: "/vehicles/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let vehicle = "";

    if (this.props.recordId) {
      vehicle = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { vehicle: data };
        }.bind(this)
      );
    } else {
      vehicle = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([vehicle]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "vehicle",
        loaderStyle: "vehicle",
        service: {
          endpoint: "/vehicles/save",
          redirectTarget: "/dashboard",
          entity: "Vehicle",
        },
        labels: {
          submit: "Save Vehicle",
          submitting: "Saving Vehicle",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          customerId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Vehicle Customer Id",
            label: "Vehicle Customer Id",
            errors: [],
            vr: {},
          },
          isDeleted: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Vehicle Is Deleted",
            label: "Vehicle Is Deleted",
            errors: [],
            vr: {},
          },
          modelId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Vehicle Model Id",
            label: "Vehicle Model Id",
            errors: [],
            vr: {},
          },
          year: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Vehicle Year",
            label: "Vehicle Year",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.vehicle, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container>
                  <Grid item>
                    <SelectInput
                      size="1"
                      label="Customer Id"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      name="customerId"
                      value={this.state.data.customerId.selectvalue}
                      touched={this.state.data.customerId.touched}
                      valid={this.state.data.customerId.valid}
                      errors={this.state.data.customerId.errors}
                      placeholder="Select..."
                      options={this.props.customerId}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={12}>
                  <CheckboxElement
                    readonly={this.state.viewOnly}
                    field={this.state.data.isDeleted}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <SelectInput
                      size="1"
                      label="Model Id"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      name="modelId"
                      value={this.state.data.modelId.selectvalue}
                      touched={this.state.data.modelId.touched}
                      valid={this.state.data.modelId.valid}
                      errors={this.state.data.modelId.errors}
                      placeholder="Select..."
                      options={this.props.modelId}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(VehiclesAddEditForm);
