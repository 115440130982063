import React from "react";
import {useLocation} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";


const ExternalRedirect = (props) => {

    const location = useLocation();

    setTimeout(
        function() {
            window.location.href = location.state.redirectUrl;
        }, 5000
    );


    return (
        <Grid justifyContent={"center"} alignItems={"center"} justifyItems={"center"} bgcolor={"#fafafa"} container height={"100vh"} width={"100%"}>
            <Grid item>
                <Dialog open={true} PaperProps={{style: {overflow: "hidden"}}}>
                    <DialogTitle className={"bg-primary"} style={{}}>Leaving ZEV Society</DialogTitle>
                    <DialogContent className={"p4"} sx={{height: "200px", width: "450px"}}>
                        {location.state.redirectMessage}<br/><br/>
                        You will be redirected in 5 seconds
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid>
    );


}


export default ExternalRedirect;
