import {react} from "react";




const AccessDenied = (props) => {



    return (
        <>
            access denied
        </>
    );


}

export default AccessDenied;
