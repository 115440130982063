import Http from "./Controller/HttpClient";
import { getBearerToken } from "./Auth/Check";

class Upload {
  constructor(options) {
    this.section = options.section;
    this.assessmentId = options.assessmentId;
  }

  download(fileId, assessmentId) {
    let formData = new FormData();

    formData.append("assessmentId", assessmentId);

    return Http.get("/vwasa/api/assessments/attachments/download/" + fileId, {
      headers: {
        Authorization: "Bearer " + getBearerToken(),
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
    });
  }

  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("assessment_id", this.assessmentId);
    formData.append("section", this.section);

    return Http.post("/vwasa/api/assessments/attachments/attach", formData, {
      headers: {
        Authorization: "Bearer " + getBearerToken(),
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  delete(fileId) {
    let formData = new FormData();

    formData.append("file_id", fileId);
    formData.append("assessment_id", this.assessmentId);
    formData.append("section", this.section);

    return Http.post("/vwasa/api/assessments/attachments/delete", formData, {
      headers: {
        Authorization: "Bearer " + getBearerToken(),
      },
    });
  }

  getFiles() {
    let formData = new FormData();
    formData.append("assessment_id", this.assessmentId);
    formData.append("section", this.section);

    return Http.post("/vwasa/api/assessments/attachments/list", formData, {
      headers: {
        Authorization: "Bearer " + getBearerToken(),
      },
    });
  }
}

export default Upload;
