import React from "react";
import { AcceptQuote } from "./ActionButtons/AcceptQuote";
import { RejectQuote } from "./ActionButtons/RejectQuote";
import Grid from "@mui/material/Grid";
import { Cancel } from "./ActionButtons/Cancel";

export const QuoteActionButtons = (props) => {
  const { state, accept, reject, cancel } = props;

  return (
    <>
      <Grid
        container
        align={"flex-end"}
        display={"flex"}
        className={"mt6"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Grid item align={"flex-end"} display={"flex"}>
          <AcceptQuote state={state} accept={accept} />
          &nbsp;&nbsp;
          <RejectQuote state={state} reject={reject} />
          &nbsp;&nbsp;
          <Cancel cancel={cancel} />
        </Grid>
      </Grid>
    </>
  );
};
