/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Custom styles for the SidenavItem
import {item, itemArrow, itemContent} from "ZevModules/Sidenav/styles/sidenavItem";

// Soft UI Dashboard PRO React contexts
import {useSoftUIController} from "context";
import {ListItemButton} from "@mui/material";

function SidenavItem({ name, active, nested, children, open, icon, ...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav } = controller;

  let nodeActive = "";

  if (active) {
    nodeActive = "current-node";
  }

  return (
    <>
      <ListItemButton
        className={nodeActive}
        disableRipple={false}
        disableGutters={true}
        {...rest}
        component="li"
        style={{ paddingLeft: "25px", listStyleType: "none" }}
        sx={item}
      >
        <SuiBox sx={(theme) => itemContent(theme, { active, miniSidenav, name, nested })}>
          <SuiBox sx={{ padding: 0, margin: 0 }}>{icon}</SuiBox>
          <ListItemText primary={name} />
          {children && (
            <Icon component="i" sx={(theme) => itemArrow(theme, { open, miniSidenav })}>
              expand_less
            </Icon>
          )}
        </SuiBox>
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavItem
SidenavItem.defaultProps = {
  active: false,
  nested: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default SidenavItem;
