import React from "react";

class BuildInfo extends React.Component {
  constructor() {
    super();
  }

  render() {
    return <>03/05/2024 09:04:22 EST - ZEVSOCUI - Build #308</>;
  }
}

export default BuildInfo;
