import React from "react";
import SuiBox from "../../../../components/SuiBox";
import OrdersList from "../../../Lists/ord/Orders/OrdersList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class OrdersListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Order"
            path={"/orders/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <OrdersList />
      </>
    );
  }
}

export default OrdersListView;
