import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";
import Table from "@mui/material/Table";
import { html, isDescendantOfClass } from "./Misc";
import { capitalCase } from "change-case";
import hash from "object-hash";

// this will render an instance of asc/desc arrows for each field it is added to in the table listing display
// state is to preserve the correct sort and direction - this may be able to be moved to a stateless component
// and let the parent/calling component manage it.

class Sorter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.fields = props.fields;
    this.row = props.displayFunc;
    this.className = props.className || "text-left";
    this.renderFunc = props.render;
    this.special = props.special;

    this.state = {
      label: props.label,
      currentSort: props.sort.field,
      currentDir: props.sort.dir,
      currentPage: props.activePage,
      currentIconStyle: "a",
    };

    this.iconStyles = {
      a: {
        default: <FontAwesomeIcon icon={["fas", "sort"]} style={{ color: "#999" }} />,
        up: (
          <FontAwesomeIcon
            icon={["fad", "sort-up"]}
            style={{ "--fa-primary-color": "rgb(0,200,0)" }}
          />
        ),
        down: (
          <FontAwesomeIcon
            icon={["fad", "sort-down"]}
            style={{ "--fa-primary-color": "rgb(0,0,200)" }}
          />
        ),
      },
      b: {
        default: <FontAwesomeIcon icon={["fad", "sort-alt"]} />,
        up: <FontAwesomeIcon icon={["fad", "sort-size-up"]} />,
        down: <FontAwesomeIcon icon={["fad", "sort-size-down"]} />,
      },
      c: {
        default: <FontAwesomeIcon icon={["fad", "sort-circle"]} />,
        up: <FontAwesomeIcon icon={["fad", "sort-circle-up"]} />,
        down: <FontAwesomeIcon icon={["fad", "sort-circle-down"]} />,
      },
      d: {
        default: <FontAwesomeIcon icon={["fad", "sort-alt"]} />,
        up: <FontAwesomeIcon icon={["fad", "sort-amount-up"]} />,
        down: <FontAwesomeIcon icon={["fad", "sort-amount-down"]} />,
      },
    };

    this.renderRow = this.renderRow.bind(this);
  }

  getIcon(field) {
    if (this.isActiveFilter(field)) {
      if (this.state.currentDir.toUpperCase() === "ASC") {
        return this.iconStyles[this.state.currentIconStyle].down;
      } else {
        return this.iconStyles[this.state.currentIconStyle].up;
      }
    } else {
      return this.iconStyles[this.state.currentIconStyle].default;
    }
  }

  isLoading() {
    return <></>;
    return (
      <tr className="is-loading">
        <td colSpan={this.fields.length}>
          <Loader isLoaded={this.props.loaded} msg="Loading, please wait" />
        </td>
      </tr>
    );
  }

  handleClick(field, ev) {
    let sortDir;

    if (!this.isActiveFilter(field)) {
      sortDir = "ASC";
    } else {
      sortDir = this.state.currentDir.toUpperCase() === "DESC" ? "ASC" : "DESC";
    }

    this.setState({
      currentSort: field,
      currentDir: sortDir,
    });

    this.renderFunc(field, sortDir);
    ev.preventDefault();
  }

  isActiveFilter(field) {
    if (typeof field === "string" && typeof this.state.currentSort === "string") {
      if (field === this.state.currentSort) {
        return true;
      }
      return false;
    }

    if (Array.isArray(field) && Array.isArray(this.state.currentSort)) {
      for (let i in field) {
        if (field[i] !== this.state.currentSort[i]) {
          return false;
        }
      }

      return true;
    }
  }

  renderSorter(obj) {
    const field = obj.f;

    return (
      <>
        <span className="table-header-sort-wrapper" onClick={this.handleClick.bind(this, field)}>
          <span className="table-header-sort-label">{obj.l}</span>
          <span className="table-header-sort-icon">{this.getIcon(field)}</span>
        </span>
      </>
    );
  }

  dirClass(field) {
    if (this.isActiveFilter(field)) {
      return "active-filter-" + this.state.currentDir.toLowerCase();
    }

    return "";
  }

  isActiveClass(field) {
    if (this.isActiveFilter(field)) {
      return "table-header-sort-active";
    }
    return "";
  }

  header(i, obj, lo) {
    const labelOnly = lo || false;
    return (
      <th
        key={"HeaderKey" + i}
        className={
          "table-header-sort " +
          this.isActiveClass(obj.f) +
          " " +
          obj.cls +
          " " +
          this.dirClass(obj.f)
        }
      >
        {labelOnly ? obj.l : this.renderSorter(obj)}
      </th>
    );
  }

  // "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJwZXJtc1wiOnt9LFwiZW1haWxcIjpcImJhcnJ5LmNoYXBtYW5AZ21haWwuY29tXCJ9IiwiZXhwIjoxNjc2MjczOTExLCJpYXQiOjE2NzYyNTU5MTF9.gO2qJbcoRZ-uU6ldWSaQiAYD3lU_0OAFesSDGGWiFiA3kjBPUuxKOhrDnZNVZC6jOR6eUFElfppbRYLPfvDqIw"
  justify(col) {
    let justify = "";
    switch (col) {
      case "created_by":
      case "createdBy":
      case "created":
      case "modified":
      case "updated_by":
      case "updatedBy":
      case "actions":
        justify = "text-right";
        break;
      default:
        justify = "text-left";
    }

    return justify;
  }

  getFieldExtClass(i) {
    const field = this.getField(i);
    if (typeof field.cls !== "undefined") {
      return field.cls;
    }
  }

  renderCell(col, value, i) {
    const justification = this.justify(col);
    const activeClass = this.isActiveFilter(col)
      ? "active-filter-" + this.state.currentDir.toLowerCase()
      : "";

    return (
      <td
        className={justification + ` ${this.getFieldExtClass(i)} ` + " p1 " + activeClass}
        key={"Fld" + col + "_"}
      >
        {value}
      </td>
    );
  }

  renderColumn(obj, idx) {
    if (obj.f === false) {
      return this.header(idx, obj, true);
    }

    return this.header(idx, obj, false);
  }

  handleRowClick(id, ev) {
    if (isDescendantOfClass(ev.target, "list-button-action")) {
      return;
    }

    if (this.props.selectRow === true) {
      this.props.selectRowDefaultAction(id);
    }
  }

  renderHeader() {
    return <>{this.fields.map((i, obj) => this.renderColumn(i, obj))}</>;
  }

  getHeaderCount() {
    return this.fields.length;
  }

  noResults() {
    return (
      <tr className="no-records">
        <td colSpan={this.fields.length}>
          <h3 align="center">No results to display</h3>
        </td>
      </tr>
    );
  }

  renderRow(record, altBorder) {
    const rec = this.row(record);
    const row = [];
    let style = {};

    let i = 0;

    for (let k in rec) {
      let value = rec[k];

      if (Array.isArray(value)) {
        let splStr = "";
        let nKey = "";

        if (k.indexOf(".") > -1) {
          splStr = k.split(".");
          nKey = splStr[1];
        } else {
          nKey = splStr;
        }

        if (value.length === 1) {
          value = capitalCase(value[0][nKey]);
        } else if (value.length > 1) {
          let nv = "<ul>";
          value.map((v) => {
            nv += "<li>" + capitalCase(v[nKey]) + "</li>";
          });
          nv += "</ul>";
          value = html(nv);
        } else {
          value = "";
        }
      }

      row.push(this.renderCell(k, value, i++));
    }

    if (altBorder === true) {
      style = {
        borderTop: "2px solid #ccc",
      };
    }

    let rowClass = "";

    if (this.props.selectRow === true) {
      rowClass = "row-clickable";
    }

    return (
      <tr
        className={rowClass}
        onClick={this.handleRowClick.bind(this, record.id)}
        key={hash(record)}
        style={style}
      >
        {row.map((cell) => {
          return cell;
        })}
      </tr>
    );
  }

  getField(i) {
    return this.fields[i];
  }

  render() {
    let rId = "";

    let recordsLoading = false;
    let extraLoadingClass = "";

    if (this.props.loading === true) {
      recordsLoading = true;
      extraLoadingClass = "loading-table-records";
    } else {
      extraLoadingClass = "hide-loading-message";
    }

    return (
      <>
        <Table className="table-striped data-list-table">
          <thead className="text-primary">
            <tr>{this.renderHeader()}</tr>
          </thead>
          <tbody className="table-body">
            <>
              <tr className={"loading-records-active " + extraLoadingClass}>
                <td
                  colSpan={this.getHeaderCount()}
                  className={"table-loading-message " + extraLoadingClass}
                >
                  <Loader isLoaded={this.props.loaded} />
                </td>
              </tr>
            </>
            {this.props.loaded === true
              ? this.props.records.length > 0
                ? this.props.records.map((record) => {
                    let altBorder = false;

                    if (this.special === "audit") {
                      if (rId === "") {
                        rId = record.hash;
                      } else {
                        if (rId !== record.hash) {
                          // new row
                          altBorder = true;
                          rId = record.hash;
                        }
                      }
                    }

                    return this.renderRow(record, altBorder);
                  })
                : this.noResults()
              : this.isLoading()}
          </tbody>
        </Table>
      </>
    );
  }
}

export default Sorter;
