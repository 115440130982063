import React from "react";
import { DisplayErrorMessage } from "../../Reusable/DisplayErrorMessage";
import FormHandler, { blurHandler, changeHandler } from "../../Reusable/FormHandler";
import { HiddenInput, SubmitCancelButtonInputNew } from "../../Reusable/FormElements";
import Controller from "../../Reusable/Controller";
import FormSecurity from "../../Reusable/Security/FormSecurity";
import Exception from "../../Reusable/Responses/Exception";
import Auth from "../../Reusable/Responses/Auth";
import Response from "../../Reusable/Responses/Response";
import { StateManager } from "../../Reusable/Core";
import RateLimit from "../../Reusable/Responses/RateLimit";
import AuthHandler from "./Handlers/AuthHandler";
import ExceptionHandler from "./Handlers/ExceptionHandler";
import ResponseHandler from "./Handlers/ResponseHandler";
import Account from "../../Reusable/Responses/Account";
import SelfServiceAccountHandler from "./Handlers/SelfServiceAccountHandler";
import Confirmation from "../../components/Popup/Confirmation";
import { html } from "../../Reusable/Misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateForm } from "../../Reusable/Validation";
import InfoMessage from "../../Reusable/InfoMessage";
import Loader from "../../Reusable/Loader";
import _ from "lodash";

class ZevForm extends React.Component {
  response = null;

  constructor(props) {
    super(props);

    this.children = props.children;
    this.pgScope = props.pageScope;

    this.state = {
      viewOnly: false,
      settings: {},
      auth: false,
      private: false,
      overrideHandlers: false,
      formErrors: [],
      confirmation: {
        isError: false,
        isOpen: false,
        title: null,
        content: null,
      },
      redirect: null,
      flow: null,
      initialized: false,
      loaderStyle: "default",
      service: {
        recordId: null,
        redirectTarget: null,
        endpoint: null,
        apiPrefix: null,
        entity: null,
      },
      validation: {
        isValidated: false,
        rules: {},
      },
      viewData: {},
      _data: {},
      data: {},
      labels: {
        submit: "Save",
        submitting: "Saving",
        submitted: "Saved",
        failed: "Save Failed",
        submitIcon: <FontAwesomeIcon icon={["fad", "floppy-disk"]} className="mr2" />,
        cancelIcon: <FontAwesomeIcon icon={["fad", "times-circle"]} className="mr2" />,
      },
      lastStatus: null,
      status: {
        isException: false,
        doPause: false,
        isSubmitDisabled: false,
        isSubmitSuccessful: false,
        isDismissed: false,
        doRedirect: false,
        isSubmitFailure: null,
        isCurrentSubmitFailure: null,
        isValidationError: null,
        isSubmitting: false,
        isLoaded: true,
      },
      security: {
        token: null,
        hash: null,
      },
    };

    this.Controller = new Controller(this, this.stateCallback);
    this.handleState = this.handleState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callback = this.callback.bind(this);
    this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
    this.setState = this.setState.bind(this);
    this.wrapper = this.wrapper.bind(this);
    this.form = this.form.bind(this);
    this.formheader = this.formheader.bind(this);
    this.formfooter = this.formfooter.bind(this);
    this.handleResponse = this.handleResponse.bind(this);

    this.nestedData = this.nestedData.bind(this);
    this.setting = this.setting.bind(this);
  }

  setSettings(settings) {
    const newState = {
      settings: settings,
    };

    StateManager.meld(this, this.state, newState, () => {
      this.forceUpdate();
    });
  }

  currentSubmitIsFailure(isFailure) {
    const newState = {
      status: {
        isCurrentSubmitFailure: isFailure,
      },
    };

    StateManager.meld(this, this.state, newState, () => {
      this.forceUpdate();
    });
  }

  lastStatus(status) {
    const newState = {
      lastStatus: status,
    };

    StateManager.meld(this, this.state, newState);
  }

  handleResponse(response, success, failed) {

    console.log(response);

    this.currentSubmitIsFailure(!response.getIsSuccess());

    if (response.getHasRedirect()) {
      // external redirect
      if (response.getRedirect().indexOf('http') === 0) {
        window.location.replace(response.getRedirect());
      }
    }

    this.lastStatus(response.getStatus());

    if (response.getIsSuccess() && success) {
      success();
    } else if (!response.getIsSuccess() && failed) {
      failed();
    }

    if (this.props.responseHandler && typeof this.props.responseHandler === "function") {
      this.props.responseHandler(response);
    } else {
      const status = response.getStatus();

      let callback = () => {};

      if (response.getHasRedirect()) {
        const SM = new StateManager(this);
        SM.merge("redirect", response.getRedirect());
        SM.commit();
      }

      if (response instanceof Auth) {
        const $scope = this;
        const handler = new AuthHandler(response, $scope, this.pgScope);
        const handled = handler.handle(this.state);

        handled.then((response) => {
          if (response instanceof Auth) {
            if (response.getIsLoginSuccessful()) {
              return this.props.navigate(this.state.service.redirectTarget, { replace: true });
            }
          }
        });
      } else if (response instanceof Account) {
        const $scope = this;
        const handler = new SelfServiceAccountHandler(response, this, this.pgScope);
        const handled = handler.handle(this.state);

        handled
          .then((hldRsp) => {
            const confirmation = {
              isError: false,
              isOpen: true,
              title: response.getSubject(),
              content: response.getMessage(),
            };

            if (hldRsp.data.status === "SUCCESS") {
              const SM = new StateManager($scope);
              SM.merge("confirmation", confirmation);
              SM.commit();
            }
          })
          .catch((hldRsp) => {
            const confirmation = {
              isError: true,
              isOpen: true,
              title: response.getSubject(),
              content: html(response.getMessage()),
            };

            if (hldRsp.data.status === "SUCCESS") {
              const SM = new StateManager($scope);
              SM.merge("confirmation", confirmation);
              SM.commit();
            }
          });
      } else if (response instanceof Response) {
        const $scope = this;
        const handler = new ResponseHandler(response, this, this.pgScope);
        const handled = handler.handle(this.state);

        handled
          .then((hldRsp) => {
            const confirmation = {
              isError: false,
              isOpen: true,
              title: response.getSubject(),
              content: response.getMessage(),
            };

            if (hldRsp.data.status === "SUCCESS") {
              const SM = new StateManager($scope);
              SM.merge("confirmation", confirmation);
              SM.commit();
            } else {
              this.displayErrors(response.getErrors(), response.getValidationErrors());
            }
          })
          .catch((hldRsp) => {
            const confirmation = {
              isOpen: true,
              title: response.getSubject(),
              content: html(response.getMessage()),
            };

            if (hldRsp.data.status === "SUCCESS") {
              const SM = new StateManager($scope);
              SM.merge("confirmation", confirmation);
              SM.commit();
            } else {
              this.displayErrors(response.getErrors(), response.getValidationErrors());
            }
          });
      } else if (response instanceof RateLimit) {
        this.handler = new ResponseHandler(response, this, this.pgScope);
        this.handler.markFailed();
        this.handler.reset();
        this.displayErrors(response.getErrors());
      } else if (response instanceof Exception) {
        const $scope = this;

        this.handler = new ExceptionHandler(response, $scope, this.pgScope);

        const handled = this.handler.handle(this.state);

        handled.then((hldRsp) => {
          const status = response.getStatus();

          if (status === "ERR_VALIDATION") {
            this.displayErrors(response.getErrors(), response.getValidationErrors());
          } else {
            const confirmation = {
              isError: true,
              isOpen: true,
              title: response.getSubject(),
              content: html(response.getMessage()),
            };

            const SM = new StateManager($scope);
            SM.merge("confirmation", confirmation);
            SM.commit();
          }
        });
      }
    }
  }

  displayErrors(errors, validationErrors) {
    const stateObj = {};

    stateObj.data = {};

    Object.keys(validationErrors).forEach((key) => {
      const vErrorsByField = validationErrors[key];
      stateObj.data[key] = {};
      stateObj.data[key].errors = vErrorsByField;
    });
    StateManager.meld(this, this.state, stateObj);
  }

  hasFieldErrors(state, field) {
    if (state.length > 0) {
      return true;
    }
    return false;
  }

  json() {
    const data = this.data();
    if (this.getRecordId()) {
      data["recordId"] = this.getRecordId();
    }

    return JSON.stringify(data);
  }

  nestedData(data, hasDeep) {

    let hd = hasDeep || false;

    if (!hd && typeof data.value !== "undefined") {
      return data.value;
    }

    const isObject = (dt) => {
      return typeof dt === "object" && !!Object.keys(dt).length;
    };

    const hasChildObjects = (dt) => {
      let hasChildObjects = false;

      if (isObject(dt)) {
        Object.keys(dt).map((k) => {
          if (k !== "vr" && k !== "errors" && isObject(dt[k]) && !Array.isArray(dt[k])) {
            hasChildObjects = true;
          }
        });
      }

      return hasChildObjects;
    };

    var responseData = {};

    if (isObject(data)) {
      if (Array.isArray(data)) {
        return data.map((datum) => {
          return this.nestedData(datum);
        });
      } else {
        Object.keys(data).map((dKey) => {
          const datum = data[dKey];

          if (datum.hasOwnProperty("value") && !hasChildObjects(datum)) {
            responseData[dKey] = datum.value;
          } else if (hasChildObjects(datum)) {
            responseData[dKey] = this.nestedData(datum, hd);
          }
        });
      }
    }

    return responseData;
  }

  data(full, hasDeep) {
    const data = {};

    if (full) {
      return this.state.data;
    }

    for (let item in this.state.data) {
      data[item] = this.nestedData(this.state.data[item], hasDeep); //.value;
    }

    // immutable values
    for (let item in this.state._data) {
      data[item] = this.state._data[item];
    }

    return data;
  }

  endpoint() {
    return this.state.service.endpoint;
  }

  api() {
    return this.state.service.apiPrefix;
  }

  entity() {
    return this.state.service.entity;
  }

  init(scope, state, isInitialized) {
    let initialized = true;

    if (isInitialized === false) {
      initialized = false;
    }

    const obj = _.merge({}, this.state, state, { initialized: initialized });
    return obj;
  }

  callback() {}

  handleState() {
    let newState = this.state;
    newState.data[name].value = value;
    newState.data[name].touched = true;
    newState.data[name].errors = [];
    this.setState(newState);
  }

  handleBlur(evt, el) {
    if (this.state.overrideHandlers === true) {
      return;
    }

    const b = blurHandler(evt, this, el);
    this.setState(
      {
        data: b.data,
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  handleChange(evt) {



    if (this.state.overrideHandlers === true) {
      return;
    }
    const c = changeHandler(evt, this.handleState, this);
    this.setState({
      data: c.data,
    });
  }

  setting(setting) {
    if (typeof this.state.settings[setting] !== "undefined") {
      return this.state.settings[setting];
    }
    return "{N/A}";
  }

  getRecordId() {
    if (this.state.recordId !== null) {
      return this.state.recordId;
    } else {
      return false;
    }
  }

  isPrivate() {
    return this.state.private === true;
  }

  isPublic() {
    return this.state.private === false;
  }

  async handleSubmit(evt, success, failed) {
    if (this.state.overrideHandlers === true) {
      return;
    }

    evt.preventDefault();

    const vp = {
      errorCallback: () => {},
    };

    if (!validateForm(this, vp)) {
      return false;
    }

    this.response = await this.Controller.submit(this.state);

    this.handleResponse(this.response, success, failed);
  }

  stateCallback = (stateChanges) => {
    const state = this.state;
    const nState = _.merge({}, state, stateChanges);
    this.setState(nState);
  };

  async mounted() {
    // return;
    const security = new FormSecurity(this);

    const response = await security.secure();
    if (response) {
      const hashes = response.payload();

      this.setState(
        {
          security: {
            token: hashes.fields,
            hash: hashes.values,
          },
        },
        () => {}
      );
    }
  }

  formheader() {
    return (
      <>
        <FormHandler
          scope={this}
          style={this.state.loaderStyle}
          isOverlay={this.state.status.isSubmitting || this.state.status.doPause} // (this.state.status.isSubmitSuccessful && !this.state.status.isDismissed) || this.state.status.isCurrentSubmitFailure
          isSuccess={this.state.status.isSubmitSuccessful}
          doPause={this.state.status.doPause}
          isDismissed={this.state.status.isDismissed}
          isLastSubmitFailed={this.state.status.isCurrentSubmitFailure}
          isFailed={this.state.status.isSubmitFailure}
          isLoaded={this.state.status.isLoaded}
          isSubmitting={this.state.status.isSubmitting}
          message={
            this.state.status.isSubmitting
              ? this.state.labels.submitting
              : this.state.status.isSubmitSuccessful
              ? this.state.labels.submitted
              : this.state.status.isFailed
              ? this.state.labels.failed
              : "Please wait..."
          }
        />
        <InfoMessage message={this.response} />
        <HiddenInput value={this.state.security.token} name="secureToken" />
      </>
    );
  }

  formfooter() {
    const loadingIcon = (
      <FontAwesomeIcon
        icon={["fad", "spinner-third"]}
        spin={true}
        fixedWidth={true}
        className={"mr2"}
      />
    );

    if (this.state.overrideSubmitButtons === true) {
      return (
        <>
          <HiddenInput value={this.state.security.hash} name="fieldsHash" />
          {this.customSubmit()}
        </>
      );
    }
    return (
      <>
        <HiddenInput value={this.state.security.hash} name="fieldsHash" />
        <SubmitCancelButtonInputNew
          icon={
            this.state.status.isSubmitting === true ? loadingIcon : this.state.labels.submitIcon
          }
          cancelIcon={this.state.labels.cancelIcon}
          sz={1}
          flow={this.state.flow}
          disabled={this.state.status.isSubmitDisabled}
          label={
            this.state.status.isSubmitting === true
              ? this.state.labels.submitting
              : this.state.labels.submit
          }
        />
      </>
    );
  }

  customSubmit() {}

  form() {}

  handleCloseConfirmation() {
    const newState = {
      confirmation: {
        isOpen: false,
      },
    };

    StateManager.meld(this, this.state, newState, () => {
      if (this.state.redirect) {
        this.props.navigate(this.state.redirect, { replace: true });
      }
    });
  }

  wrapper() {
    return (
      <>
        <DisplayErrorMessage
          response={this.response}
          scope={this}
          isSubmitFailure={this.state.status.isCurrentSubmitFailure}
        />
        <Confirmation
          isError={this.state.confirmation.isError}
          onClose={this.handleCloseConfirmation}
          title={this.state.confirmation.title}
          content={this.state.confirmation.content}
          open={this.state.confirmation.isOpen}
        />
        <form
          noValidate="noValidate"
          style={{ width: "100%" }}
          className="form-wide"
          onSubmit={this.handleSubmit}
        >
          {this.formheader()}
          {this.state.initialized ? (
            <>
              {this.form(this.state.viewOnly)}
              {this.formfooter()}
            </>
          ) : (
            <>
              <Loader isLoaded={this.state.initialized} />
            </>
          )}
        </form>
      </>
    );
  }
}

export default ZevForm;
