import { AcceptSendQuote } from "./QuoteActionButtons/AcceptSendQuote";
import { SaveQuoteDraft } from "./QuoteActionButtons/SaveQuoteDraft";
import { CancelQuoteChanges } from "./QuoteActionButtons/Cancel";
import React from "react";

export const QuoteActionButtons = (props) => {
  const { state, save, send, cancel, sendDisabled } = props;

  return (
    <>
      <AcceptSendQuote sendQuoteDisabled={sendDisabled} sendHandler={send} state={state} />
      &nbsp;&nbsp;
      {(state.viewData.installRequest.quot === null ||
        (state.viewData.installRequest.quot != null &&
          state.viewData.installRequest.quot.lastSendDate === null)) && (
        <SaveQuoteDraft saveHandler={save} state={state} />
      )}
      &nbsp;&nbsp;
      <CancelQuoteChanges requestStatus={state.viewData.installRequest.irr.status} cancelAction={cancel} />
    </>
  );
};
