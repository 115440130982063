import React from "react";
import SuiBox from "../../../../components/SuiBox";
import SettingsList from "../../../Lists/core/Settings/SettingsList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class SettingsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Setting"
            path={"/settings/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <SettingsList />
      </>
    );
  }
}

export default SettingsListView;
