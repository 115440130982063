/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "ZevModules/Sidenav/SidenavCollapse";
import SidenavList from "ZevModules/Sidenav/SidenavList";
import SidenavItem from "ZevModules/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "ZevModules/Sidenav/SidenavRoot";
import sidenavLogoLabel from "ZevModules/Sidenav/styles/sidenav";

// Soft UI Dashboard PRO React context
import {setMiniSidenav, useSoftUIController} from "context";
import _ from "lodash";
import {readCookie} from "../../Utility/Cookie";

const hash = require("object-hash");

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const currentRole = readCookie("role");

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, hide, route, key, href, icon }) => {
      if (hide) {
        return;
      }

      return (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem
            className={"nested-sidenav-item"}
            icon={icon}
            name={name}
            active={route === pathname}
            nested
          />
        </NavLink>
      );
    });

    /*

        // removed

        href ? (
                <Link
                  key={key}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                    <SidenavItem name={name} nested />
                </Link>
              ) : (

         */

    return template;
  };

  const mergeRoutes = (...routes) => {
    let mergedtmp = {};
    let merged = [];

    const mapper = (input) => {
      const objs = [];
      input.map((route) => {
        if (Array.isArray(route)) {
          route.map((subRoute) => {
            objs.push(subRoute);
          });
        } else {
          objs.push(route);
        }
      });

      return objs;
    };

    let parsedRoutes = mapper(routes);

    parsedRoutes = _.sortBy(parsedRoutes, "order");

    return parsedRoutes;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, icon, role, hideCollapse, collapse, hide, route, href, key }) => {
      let returnValue;
      let openClass = "closed-nav";

      hideCollapse = hideCollapse || false;


        if (role) {
            if (role.indexOf(currentRole) === -1) {
                return;
            }
        }



        if (openNestedCollapse === name) {
        openClass = "open-nav";
      }

      if (hideCollapse) {
        returnValue = (
          <NavLink to={route} key={hash(["link", key, href])} sx={{ textDecoration: "none" }}>
            <SidenavItem etch={"vicious"} name={name} icon={icon} active={key === itemName} />
          </NavLink>
        );
      } else if (collapse) {
        returnValue = (
          <SidenavItem
            key={hash([key, "sidenav-collapse", href])}
            name={name}
            className={"nested-sidenav " + openClass}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        if (!hide) {
          returnValue = href ? (
            <Link
              href={href}
              key={hash({ key, href })}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavItem name={name} active={key === itemName} />
            </Link>
          ) : (
            <NavLink to={route} key={hash(["link", key, href])} sx={{ textDecoration: "none" }}>
              <SidenavItem etch={"vicious"} name={name} active={key === itemName} />
            </NavLink>
          );
        }
      }

      if (!hide) {
        return <SidenavList key={hash([key, "no-hide"])}>{returnValue}</SidenavList>;
      } else {
        return <span key={hash(Math.random())}></span>;
      }
    });

  const renderRoutes = (routes) => {
    return routes.map(
      ({ type, name, icon, title, role, collapse, noCollapse, key, hide, href, route }) => {
        let returnValue;

        if (role) {
            if (role.indexOf(currentRole) === -1) {
                return;
            }
        }


        if (hide === true) {
          return;
        }

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={hash([type, name, title, key, route])}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  key={key}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            returnValue = (
              <NavLink to={route} key={hash([type, name, title, key, route])}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          } else if (noCollapse) {
            returnValue = (
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                key={key}
                active={key === collapseName}
              ></SidenavCollapse>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={hash([type, name, title, key, route])}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <SuiTypography
              key={hash([type, name, title, key, route])}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </SuiTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <li key={hash([type, route, name, title, key])}>
              <Divider key={hash([type, name, title, key, route])} />
            </li>
          );
        }

        return returnValue;
      }
    );
  };

  let openClass = "sidenav-closed";

  if (openCollapse) {
    openClass = "sidenav-open";
  }

  return (
    <SidenavRoot
      className={"ZevSociety-SideNavRoot " + openClass}
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SuiBox pt={3} pb={1} px={4} textAlign="center">
        <SuiBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SuiTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
          <SuiBox
            alignItems="center"
            justifyContent="center"
            display="flex"
            style={{ width: "100%" }}
          >
            {brand && <SuiBox component="img" src={brand} alt="Logo" width="1.6rem" mr={1} />}

            <SuiTypography component="h4" variant="button" fontWeight="medium">
              <SuiBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                {brandName}
              </SuiBox>
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <List className="zev-nav-list" sx={{ marginLeft: "0", width: "100%" }}>
        {renderRoutes(mergeRoutes(routes))}
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
