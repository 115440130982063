import hash from "object-hash";
import TableCell from "@mui/material/TableCell";
import { rounder } from "../../../../../../Reusable/Misc";
import TableRow from "@mui/material/TableRow";
import React from "react";

export const ProductLineItem = (props) => {
  const { productImage, lineItem, count } = props;

  return (
    <>
      <TableRow
        className="fix-top-of-row rendered-line-item"
        style={{ verticalAlign: "top" }}
        key={hash(lineItem)}
      >
        <TableCell align={"right"}>{count}.</TableCell>
        <TableCell align={"center"}>{productImage(lineItem)}</TableCell>
        <TableCell>
          <span className={"strong"}>{lineItem.productId.name}</span>
          <br />
          <small className={"color-charcoal"}>{lineItem.productId.vendor}</small>
        </TableCell>
        <TableCell>{lineItem.quantity}</TableCell>
        <TableCell align={"right"}>${rounder(lineItem.productId.total)}</TableCell>
        <TableCell align={"right"}>PAID</TableCell>
        <TableCell align={"right"}>N/A</TableCell>
        <TableCell align={"right"}>
          {/*${(Math.round(((lineItem.productId.total * lineItem.quantity) * 100)) / 100).toFixed(2)}*/}
          $0.00
        </TableCell>
        <TableCell align={"center"}></TableCell>
      </TableRow>
    </>
  );
};
