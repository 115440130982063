import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContractorsListView from "../../../pages/Views/ins/Contractors/ContractorsListView";
import ContractorsEditView from "../../../pages/Views/ins/Contractors/ContractorsEditView";
import ContractorsAddView from "../../../pages/Views/ins/Contractors/ContractorsAddView";
import ContractorsDeleteView from "../../../pages/Views/ins/Contractors/ContractorsDeleteView";

const ContractorsRoute = {
  role: ['admin'],
  type: "collapse",
  name: "Contractors",
  sidenav: true,
  order: 2,
  key: "admin-contractors",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Contractor Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "contractors-list",
      route: "/contractors/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <ContractorsListView />,
    },
    {
      hide: true,
      name: "View Contractor",
      sidenav: true,
      key: "contractors-view",
      route: "/contractors/view/:contractorId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <ContractorsEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Edit Contractor",
      sidenav: true,
      key: "contractors-edit",
      route: "/contractors/edit/:contractorId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <ContractorsEditView />,
    },
    {
      hide: true,
      name: "Delete Contractor",
      sidenav: true,
      key: "contractors-delete",
      route: "/contractors/delete/:contractorId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <ContractorsDeleteView />,
    },
    {
      name: "Add New Contractor",
      sidenav: true,
      key: "contractors-add",
      route: "/contractors/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <ContractorsAddView />,
    },
  ],
};

export default ContractorsRoute;
