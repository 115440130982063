import React from "react";
import SuiBox from "../../../../components/SuiBox";
import VehiclesList from "../../../Lists/cst/Vehicles/VehiclesList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class VehiclesListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Vehicle"
            path={"/vehicles/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <VehiclesList />
      </>
    );
  }
}

export default VehiclesListView;
