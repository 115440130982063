import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CompleteAccountSetup from "./CompleteAccountSetup";

class CompleteAccountSetupView extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <>
        <Grid container style={{ width: "100%" }}>
          <Grid item md={10}>
            <CompleteAccountSetup pageScope={this} />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(CompleteAccountSetupView);
