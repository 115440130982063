import { CardContentHeader, caseWords } from "../../../../../../Reusable/Misc";
import { CardContent } from "@mui/material";
import SuiButton from "../../../../../../components/SuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiTypography from "../../../../../../components/SuiTypography";
import Card from "@mui/material/Card";
import React from "react";

export const SBCustInfo = (props) => {
  const { state } = props;

  return (
    <>
      <Card className={"mt9"} variant="outlined">
        <CardContentHeader>Customer Information</CardContentHeader>
        <CardContent>
          <br />
          <h3 className="mt2 mb2">
            {caseWords(
              state.viewData.installRequest.user.firstName +
                " " +
                state.viewData.installRequest.user.lastName
            )}
     {/*       <SuiButton
              style={{ float: "right" }}
              color={"primary"}
              variant={"gradient"}
              size={"small"}
            >
              <FontAwesomeIcon icon={["fas", "envelope"]} />
              &nbsp; Contact
            </SuiButton>*/}
          </h3>
          <hr style={{ borderColor: "#ddd" }} />
          <br />

          <blockquote>
            {state.viewData.installRequest.site.address}
            <br />
            {state.viewData.installRequest.site.address2 &&
              state.viewData.installRequest.site.address2 + "<br/>"}
            {state.viewData.installRequest.site.city}, {state.viewData.installRequest.site.state}{" "}
            {state.viewData.installRequest.site.zip}
          </blockquote>

          <SuiTypography color={"primary"}>
            {state.viewData.installRequest.cust.phone}
          </SuiTypography>

          <br />
        </CardContent>
      </Card>
    </>
  );
};
