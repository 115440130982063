import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContentHeader, caseWords, mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";
import withRouter from "../../../../withRouter";
import SuiTypography from "../../../../components/SuiTypography";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";

class OrdersAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "order",
          endpoint: "/orders/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let order;

    if (this.props.recordId) {
      order = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { order: data };
        }.bind(this)
      );
    } else {
      order = new Promise(function (resolve) {
        resolve();
      });
    }

    this.promise = await Promise.all([order]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "order",
        loaderStyle: "order",
        service: {
          endpoint: "/orders/save",
          redirectTarget: "/dashboard",
          entity: "Order",
        },
        labels: {
          submit: "Save Order",
          submitting: "Saving Order",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          ip: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Order Ip",
            label: "Order Ip",
            errors: [],
            vr: {},
          },
          installAddress1: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Order Ip",
            label: "Order Ip",
            errors: [],
            vr: {},
          },
          storeId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Store ID",
            label: "Store ID",
            errors: [],
            vr: {},
          },
          fulfillmentStatus: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Fulfillment Status",
            label: "Fulfillment Status",
            errors: [],
            vr: {},
          },
          trackingNumber: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Tracking Number",
            label: "Tracking Number",
            errors: [],
            vr: {},
          },
          isDeleted: {
            type: "checkbox",
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Is Deleted",
            label: "Product Is Deleted",
            errors: [],
            vr: {},
            defaultValue: 0,
          },
          shopifyId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Order Shopify Id",
            label: "Order Shopify Id",
            errors: [],
            vr: {},
          },
          orderNumber: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Order Number",
            label: "Order Number",
            errors: [],
            vr: {},
          },
          paymentStatus: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Payment Statusr",
            label: "Payment Status",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.order, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid container>
              <Grid item md={8}>
                <Card variant={"outlined"}>
                  <CardContentHeader>Fulfilment Status</CardContentHeader>
                  <CardContent>
                    <br />
                    <SuiTypography variant="button" color="info">
                      Status{" "}
                    </SuiTypography>
                    {this.state.data.fulfillmentStatus.value}
                    <br />
                    <SuiTypography variant="button" color="info">
                      From{" "}
                    </SuiTypography>
                    {this.state.viewData.order.storeId.state}
                    <br />
                    <SuiTypography variant="button" color="info">
                      Tracking{" "}
                    </SuiTypography>
                    {this.state.viewData.order.trackingNumber}
                    <br />
                    <SuiTypography variant="button" color="info">
                      Product{" "}
                    </SuiTypography>
                    {this.state.viewData.order.orderLineItemsList[0].productId.name}
                    <br />
                    <SuiTypography variant="button" color="info">
                      Install Status{" "}
                    </SuiTypography>
                    {this.state.viewData.order.installRequestsList[0].status}

                    <br />
                    <hr />
                    <br />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={3}>
                <Card variant="outlined">
                  <CardContentHeader>Customer</CardContentHeader>
                  <br />
                  <CardContent>
                    <SuiTypography variant="button" color="info">
                      Install Address{" "}
                    </SuiTypography>
                    {this.state.viewData.order.installRequestsList[0].installAddress2}
                    <br />
                    {this.state.viewData.order.installRequestsList[0].installAddress1}
                    <br />
                    {caseWords(this.state.viewData.order.installRequestsList[0].installCity)},&nbsp;
                    {this.state.viewData.order.installRequestsList[0].installState}&nbsp;
                    {this.state.viewData.order.installRequestsList[0].installZip}
                  </CardContent>

                  <CardContent>
                    <br />
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Insight</CardContentHeader>
                  <CardContent>
                    <br />
                    <h3 className="mt2 mb2"></h3>
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Some Detail</CardContentHeader>
                  <CardContent></CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(OrdersAddEditForm);
