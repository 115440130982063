import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InstallersListView from "../../../pages/Views/ins/Installers/InstallersListView";
import InstallersEditView from "../../../pages/Views/ins/Installers/InstallersEditView";
import InstallersAddView from "../../../pages/Views/ins/Installers/InstallersAddView";
import InstallersDeleteView from "../../../pages/Views/ins/Installers/InstallersDeleteView";

const InstallersRoute = {
  type: "collapse",
  name: "Installers",
  sidenav: true,
  order: 2,
  key: "admin-installers",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Installer Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "installers-list",
      route: "/installers/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <InstallersListView />,
    },
    {
      hide: true,
      name: "Edit Installer",
      sidenav: true,
      key: "installers-edit",
      route: "/installers/edit/:installerId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <InstallersEditView />,
    },
    {
      hide: true,
      name: "Delete Installer",
      sidenav: true,
      key: "installers-delete",
      route: "/installers/delete/:installerId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <InstallersDeleteView />,
    },
    {
      name: "Add New Installer",
      sidenav: true,
      key: "installers-add",
      route: "/installers/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <InstallersAddView />,
    },
  ],
};

export default InstallersRoute;
