import React from "react";
import JobsList from "../../../Lists/irq/Jobs/JobsList";

class JobsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/*<SuiBox sx={{mb: 3}}>
                    <SiteLinkButton
                        perms={[]}
                        label="Add New Job"
                        path={"/jobs/add"}
                        btnIcon="newuser"
                        btnStyle="p"
                    />
                </SuiBox>*/}
        <JobsList />
      </>
    );
  }
}

export default JobsListView;
