import React from "react";
import Quill from "quill";
import ReactQuill from "react-quill";
import LabelElement from "./LabelElement";
import FieldValidationErrors from "../FieldValidationErrors";
import SuiBox from "../../../components/SuiBox";

export class TextAreaElement extends React.Component {
  label = "";
  sz = "";

  constructor(props) {
    super(props);
    this.state = { focus: false, editorHtml: false };
    this.props = props;
  }

  handleChange = (html, e) => {
    this.setState({ editorHtml: html });
    this.props.onChange({ value: html }, { name: this.props.name });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
    this.props.onBlur({ value: this.state.editorHtml }, { name: this.props.name });
  };

  getClass() {
    if (this.state.focus === true) {
      return "is-focused";
    } else {
      return "not-focused";
    }
  }

  hiddenTextAreaChange() {}

  lineBreakMatcher() {
    const Delta = Quill.import("delta");
    let newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
  }

  render() {
    var editorClass = this.getClass();
    let classes = "";

    return (
      <>
        <SuiBox className="target-fields-wrapper" mb={2}>
          <LabelElement field={this.props.field}>
            <ReactQuill
              id={this.props.field.name}
              theme="snow"
              placeholder={this.props.field.placeholder}
              className={editorClass}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
              }}
              value={this.state.editorHtml || this.props.field.value}
              onChange={this.handleChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
          </LabelElement>
          <div>
            <FieldValidationErrors fieldErrors={this.props.field.errors} />
          </div>
        </SuiBox>
      </>
    );
  }
}

export default TextAreaElement;
