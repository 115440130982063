import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Authentication layout components
import AuthLayout from "../../layouts/authentication/components/AuthLayout";

// Image
import logo from "assets/img/logos/trans.png";
import Grid from "@mui/material/Grid";
import ResetPasswordForm from "../Forms/Auth/ResetPasswordForm";
import withRouter from "../../withRouter";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const renderedForm = (
      <ResetPasswordForm pageScope={this} reset_token={this.props.params.reset_token} />
    );

    return (
      <AuthLayout
        title="Password Assistance"
        description="Please select a new password"
        illustration={{
          image: logo,
          title: "",
          description: "",
        }}
      >
        <SuiBox component="" role="">
          <>{renderedForm}</>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={12}>
              <SuiBox mt={0} textAlign="center">
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  <SuiTypography
                    component={Link}
                    to="/auth/login"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Go Back
                  </SuiTypography>
                </SuiTypography>
                &nbsp; &nbsp; &nbsp;
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  Don&apos;t have an account?{" "}
                  <SuiTypography
                    component={Link}
                    to="/authentication/sign-up/illustration"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </AuthLayout>
    );
  }
}

export default withRouter(ResetPassword);
