import React from "react";
import Auth from "./Responses/Auth";
import RateLimit from "./Responses/RateLimit";
import Account from "./Responses/Account";
import Exception from "./Responses/Exception";
import Response from "./Responses/Response";
import Grid from "@mui/material/Grid";

export class DisplayErrorMessage extends React.Component {
  constructor(props) {
    super();
    this.props = props;
  }

  renderErrors(errors) {
    const keys = Object.keys(errors);
    return keys.map((key) => {
      const error = errors[key];
      return <li key={"error-message-item-" + key}>{error}</li>;
    });
  }

  render() {
    let html = "";
    let Error = "";

    if (this.props.response !== null) {
      
      console.log(this.props);
      
      if (this.props.response.getSuppressNotice()) {
        return <Grid className="error-handler" xs={12}></Grid>;
      }

      if (this.props.response instanceof Auth) {
        if (_.indexOf(["NOT_PERMITTED"], this.props.scope.state.lastStatus) >= 0) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">{this.props.response.getSubject()}</h4>
                  <h5 className="error-subheader">{this.props.response.getMessage()}</h5>
                </div>
              </div>
            </>
          );
        } else if (
          _.indexOf(["ERR_VALIDATION", "INVALID_CREDENTIALS"], this.props.scope.state.lastStatus) >=
          0
        ) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">Invalid username and/or password</h4>
                  <h5 className="error-subheader">Please check your credentials and try again.</h5>
                </div>
              </div>
            </>
          );
        }
      } else if (this.props.response instanceof Account) {
        if (_.indexOf(["ERR_VALIDATION"], this.props.scope.state.lastStatus) >= 0) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">One or more fields failed validation</h4>
                  <h5 className="error-subheader">
                    Please ensure all forms are completed correctly.
                  </h5>
                </div>
              </div>
            </>
          );
        }
      } else if (this.props.response instanceof RateLimit) {
        if (_.indexOf(["FAILURE"], this.props.scope.state.lastStatus) >= 0) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">Rate Limit exceeded</h4>
                  <h5 className="error-subheader">Please wait a few seconds and try again</h5>
                </div>
              </div>
            </>
          );
        }
      } else if (this.props.response instanceof Response) {
        if (_.indexOf(["ERR_VALIDATION"], this.props.scope.state.lastStatus) >= 0) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">Validation Error</h4>
                  <h5 className="error-subheader">
                    Please ensure all fields are completed correctly.
                  </h5>
                </div>
              </div>
            </>
          );
        } else {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">Submission Error</h4>
                  <h5 className="error-subheader">
                    {this.renderErrors(this.props.response.getErrors())}
                  </h5>
                </div>
              </div>
            </>
          );
        }
      } else if (this.props.response instanceof Exception) {
        if (_.indexOf(["FAILURE"], this.props.scope.state.lastStatus) >= 0) {
          Error = (
            <>
              <div className="validation-errors">
                <div className="inner-wrap">
                  <h4 className="error-header">{this.props.response.getSubject()}</h4>
                  <h5 className="error-subheader">{this.props.response.getMessage()}</h5>
                  <br />
                  <h5 className="error-details">Details</h5>
                  <ul className="rendered-form-errors">
                    {this.renderErrors(this.props.response.data.errors)}
                  </ul>
                </div>
              </div>
            </>
          );
        }
      }
    } else if (this.props.scope.state.status.isValidationError) {
      Error = (
        <>
          <div className="validation-errors">
            <div className="inner-wrap">
              <h4 className="error-header">Validation Errors</h4>
              <h5 className="error-subheader">
                Errors were encountered when submitting this form, please try again.
              </h5>
            </div>
          </div>
        </>
      );
    } else if (this.props.scope.state.status.isOtherError) {
      Error = (
        <>
          <div className="validation-errors">
            <div className="inner-wrap">
              <h3>Application Error(s)</h3>
              <p>An unknown error occurred while submitting your request</p>
              <hr />
              <h5>Error Details</h5>
              <p>{this.props.data.errors}</p>
            </div>
          </div>
        </>
      );
    }

    if (this.props.isSubmitFailure) {
      html = (
        <>
          <Grid className="row mt-4 mb-4" xs={12}>
            <div className="col-sm-1" />
            <div className="col-sm-7">{Error}</div>
          </Grid>
        </>
      );
    }

    return (
      <Grid className="error-handler" xs={12}>
        {html}
      </Grid>
    );
  }
}
