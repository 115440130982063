import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import SuiBox from "../../../components/SuiBox";
import MiniStatisticsCard from "../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "../../../examples/Tables/SalesTable";
import ReportsBarChart from "../../../examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "../../../examples/Charts/LineCharts/GradientLineChart";
import Icon from "@mui/material/Icon";
import SuiTypography from "../../../components/SuiTypography";
import gradientLineChartData from "../../../layouts/dashboards/default/data/gradientLineChartData";
import typography from "../../../assets/theme/base/typography";
import reportsBarChartData from "../../../layouts/dashboards/default/data/reportsBarChartData";
import Operations from "../../../Reusable/Controller/Operations";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import RevenueData from "../../../layouts/dashboards/default/data/gradientLineChartData";

const DefaultContent = (props) => {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const [activeJobs, setActiveJobs] = useState(0);
  const [approvedQuotes, setApprovedQuotes] = useState(0);
  const [closedJobs, setClosedJobs] = useState(0);
  const [closedLast7, setClosedLast7] = useState(0);
  const [pendingCustomer, setPendingCustomer] = useState(0);
  const [pendingContractor, setPendingContractor] = useState(0);
  const [unclaimed, setUnclaimed] = useState(0);
  const [newRequests, setNewRequests] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [appFees, setAppFees] = useState(0);

  const [rd, setRd] = useState([]);

  const ops = new Operations();

  useEffect(() => {
    let response = ops.updateDashboard()
        .then(
            response => {
              const data = response.getData();

              setNewRequests(data.newRequests);
              setActiveJobs(data.activeJobs);
              setApprovedQuotes(data.approvedQuotes);
              setClosedJobs(data.closedJobs);
              setClosedLast7(data.closedLast7);
              setPendingCustomer(data.pendingCustomer);
              setPendingContractor(data.pendingContractor);
              setUnclaimed(data.unclaimed);
              setRd(RevenueData(data.revenue));
              setTotalRevenue(data.totalRevenue);
              setAppFees(data.totalAppFees);

              console.log(RevenueData(data.revenue));
            }
        )
  }, []);

  return (
    <>
      <Grid item xs={11}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} xl={2}>
            <SuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "New Requests", fontWeight: "bold" }}
                count={newRequests}
                // percentage={{ color: "primary", text: "+55%" }}
                icon={{icon: <FontAwesomeIcon icon={icon({style: "duotone", name: "cube"})} color={"#fff"} />, color: "warning"}}
              />
            </SuiBox>
          </Grid>
            <Grid item xs={12} sm={12} md={6} xl={2}>
            <SuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Approved Quotes", fontWeight: "bold" }}
                count={approvedQuotes}
                // percentage={{ color: "primary", text: "+55%" }}
                icon={{icon: <FontAwesomeIcon icon={icon({style: "duotone", name: "check-circle"})} color={"#fff"} />, color: "info"}}
              />
            </SuiBox>
          </Grid>
            <Grid item xs={12} sm={12} md={6} xl={2}>
            <SuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Open Jobs", fontWeight: "bold" }}
                count={activeJobs}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{icon: <FontAwesomeIcon icon={icon({style: "duotone", name: "box-open"})} color={"#fff"} />, color: "success"}}
              />
            </SuiBox>
            <SuiBox mb={3}></SuiBox>
          </Grid>
            <Grid item xs={12} sm={12} md={6} xl={2}>
            <SuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Pending Customer", fontWeight: "bold" }}
                count={pendingCustomer}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{icon: <FontAwesomeIcon icon={icon({style: "duotone", name: "question-circle"})} color={"#fff"} />, color: "secondary"}}
              />
            </SuiBox>
            <SuiBox mb={3}></SuiBox>
          </Grid>
            <Grid item xs={12} sm={12} md={6} xl={2}>
            <SuiBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Closed Jobs", fontWeight: "bold" }}
                count={closedJobs}
                // percentage={{ color: "error", text: "-2%" }}
                icon={{icon: <FontAwesomeIcon icon={icon({style: "duotone", name: "hard-hat"})} color={"#fff"} />, color: "error"}}
              />
            </SuiBox>
            <SuiBox mb={3}></SuiBox>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>

        <Grid item xs={11} lg={11}>
          <GradientLineChart
            title="Sales Overview"
            description={
              <SuiBox display="flex" alignItems="center">
                <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                  <FontAwesomeIcon icon={icon({style:"duotone", name:"dollar-square"})} />
                </SuiBox>
                <SuiTypography variant="button" color="text" fontWeight="medium">
                  {totalRevenue}  {" "}
                </SuiTypography>
&nbsp;
&nbsp;
&nbsp;
                  <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <FontAwesomeIcon icon={icon({style:"duotone", name:"dollar-square"})} />
                  </SuiBox>
                  <SuiTypography variant="button" color="text" fontWeight="regular">
                  {appFees}
                </SuiTypography>
              </SuiBox>
            }
            chart={rd}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultContent;
