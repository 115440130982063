import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContentHeader, mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";
import Card from "@mui/material/Card";
import SuiTypography from "../../../../components/SuiTypography";
import { CardContent } from "@mui/material";

class ProductsAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "product",
          endpoint: "/products/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let product;

    if (this.props.recordId) {
      product = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { product: data };
        }.bind(this)
      );
    } else {
      product = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([product]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }
      // viewData["user"]=
      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "product",
        loaderStyle: "product",
        service: {
          endpoint: "/products/save",
          redirectTarget: "/dashboard",
          entity: "Product",
        },
        labels: {
          submit: "Save Product",
          submitting: "Saving Product",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          name: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Name",
            label: "Product Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              minLength: { opt: 3, msg: "Min length of 3 required" },
              maxLength: { opt: 80, msg: "Max length of 80 required" },
            },
          },
          amperage: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Amperage",
            label: "Product Amperage",
            errors: [],
            vr: {},
          },
          isDeleted: {
            type: "checkbox",
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Is Deleted",
            label: "Product Is Deleted",
            errors: [],
            vr: {},
          },
          power: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Power",
            label: "Product Power",
            errors: [],
            vr: {},
          },
          shopifyProductId: {
            value: "",
            isRequired: false,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "Product Shopify Product Id",
            label: "Product Shopify Product Id",
            errors: [],
            vr: {},
          },
          total: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Total",
            label: "Product Total",
            errors: [],
            vr: {},
          },
          vendor: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product Vendor",
            label: "Product Vendor",
            errors: [],
            vr: {},
          },
          sku: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Product SKU",
            label: "Product SKU",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.product, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid container>
              <Grid item md={8}>
                <Card variant={"outlined"}>
                  <CardContentHeader>Product Details</CardContentHeader>
                  {/*<h3 className="mt2">Order Details</h3>*/}
                  <br />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="firstName"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    value={this.state.data.name.value}
                  />
                  <hr />
                  <SuiTypography>Description</SuiTypography>
                  <SuiTypography> This is a description of the ev charger</SuiTypography>
                  <br />
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Media</CardContentHeader>
                  <br />
                  <Grid container>
                    <Grid item md={2}>
                      <img
                        height={200}
                        src={this.state.viewData.product.productImagesList[0].s3src}
                        alt="product-image"
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={3}>
                <Card variant="outlined">
                  <CardContentHeader>Product Status</CardContentHeader>
                  <CardContent>
                    <CheckboxElement
                      readonly={this.state.viewOnly}
                      field={this.state.data.isDeleted}
                    />
                    <SuiTypography>Stores</SuiTypography>
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Insight</CardContentHeader>
                  <CardContent>
                    <br />
                    <h3 className="mt2 mb2"></h3>
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Product Detail</CardContentHeader>
                  <CardContent>
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="vendor"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.vendor}
                      value={this.state.data.vendor.value}
                    />
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="shopify id"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.shopifyProductId}
                      value={this.state.data.shopifyProductId.value}
                    />
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="total"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.total}
                      value={this.state.data.total}
                    />
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="sku"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.sku}
                      value={this.state.data.sku.value}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(ProductsAddEditForm);
