import R from "./R.js";

class Exception extends R {
  constructor(response) {
    super(response);
  }

  getStatus() {
    return this.data.status;
  }

  getEmail() {
    return this.data.email;
  }

  getAccessToken() {
    return this.data.accessToken;
  }

  hasErrors() {
    return this.data.hasErrors === true;
  }

  hasValidationErrors() {
    return this.data.hasValidationErrors === true;
  }

  getErrors() {
    return this.data.errors;
  }

  getValidationErrors() {
    return this.data.validationErrors;
  }

  getMessage() {
    return this.data.message;
  }
}

export default Exception;
