import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../../../components/SuiButton";
import React from "react";
import {Alert} from "@mui/material";

export const RejectRequest = (props) => {
  const { rejectRequestDisabled, state, rejectHandler } = props;

    if ( state.viewData.installRequest.irr.status === 'QUOTE_ACCEPTED_BY_CUSTOMER') {
        return (
            <Alert variant={"filled"} color={"warning"} icon={false}>
                <span>
                    <FontAwesomeIcon icon={["fad", "question-circle"]}  />
                    &nbsp;&nbsp;
                </span>
                <span>Quote already accepted by customer, can no longer reject</span>
            </Alert>
        )
    }

  return (
    <SuiButton
      color="error"
      disabled={rejectRequestDisabled || state.status.isRequestRejecting === true}
      variant="contained"
      className={
        state.status.isRequestRejectFailed
          ? "glow-error-border"
          : (state.status.isRequestRejected ? "glow-success-border" : "") + " "
      }
      onClick={rejectHandler}
    >
      {state.status.isRequestRejecting ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.requestRejecting}</span>
        </>
      ) : !state.status.isRequestRejecting && state.status.isRequestRejected ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "check-circle"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.requestRejected}</span>
        </>
      ) : !state.status.isRequestRejecting && state.status.isRequestRejectFailed ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "times-circle"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.requestRejectFailed}</span>
        </>
      ) : (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "xmark-to-slot"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.requestReject}</span>
        </>
      )}
    </SuiButton>
  );
};
