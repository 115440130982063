import React from "react";
import FieldValidationErrors from "../FieldValidationErrors";
import SuiBox from "../../../components/SuiBox";
import { HiddenInput } from "../../FormElements";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {InputLabel} from "@mui/material";
import LabelElement from "./LabelElement";

class SelectElement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    /*this.setState(
          {
            ready: true,
            handleBlur: this.props.onBlur,
            handleChange: this.props.onChange,
            value: this.props.field.value,
            type: this.props.type,
            label: this.props.label,
            name: this.props.name,
            placeholder: this.props.field.placeholder,
            size: this.props.size
          }
        );*/
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {}

  render() {
    if (this.props.type === "hidden") {
      return <HiddenInput name={this.props.name} value={this.props.value} />;
    }

    return (
      <>
        <SuiBox className="target-fields-wrapper" mb={2}>

          <LabelElement field={this.props.field} inline={false}>


          <select
              className={"form-select"}
            name={this.props.name}
            size={this.props.size}
            onChange={this.props.onChange}
            placeholder={this.props.field.placeholder}
            // defaultValue={this.props.field.value}
            onBlur={this.props.onBlur}

            error={this.props.field.errors.length > 0}
          >
            {this.props.options.map((option) => {
              return <option value={option.value}>{option.label}</option>;
            })}
          </select>

          <FieldValidationErrors fieldErrors={this.props.field.errors} />
          </LabelElement>
        </SuiBox>
      </>
    );
  }
}

export default SelectElement;
