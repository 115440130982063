import React, { useEffect } from "react";
import ZevList from "../../../Forms/ZevList";
import { html } from "../../../../Reusable/Misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../components/SuiButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import Select from "react-select";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";

function ChangeSetting(props) {
  const [open, setOpen] = React.useState(false);
  const { cv, triggerReload, settingId, operations } = props;
  var currentValue;
  if (cv === "N/A") {
    currentValue = "";
  } else {
    currentValue = cv;
  }
  const [choices, setChoices] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [setting, setSetting] = React.useState(currentValue);
  const [spinning, setSpinning] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [value, setValue] = React.useState({});

  const [isFieldError, setIsFieldError] = React.useState(false);

  const getValue = (val, choices) => {
    var jData = JSON.parse(choices);

    Object.keys(jData).map((choice) => {
      if (choice === val) {
        return {
          value: val,
          label: jData[val],
        };
      }
    });
  };

  const changer = (v) => {
    setValue(v.target.value);
    setSetting(v.target.value);
  };

  useEffect(() => {});

  const onFormSubmit = (e) => {
    e.preventDefault();
    saveSettingHandler();
  };

  const changerOpts = (v) => {
    setSetting(v.value);
    setValue(v);
  };

  const changerNumber = (v, f) => {
    const rx = /^([\.\d]+)$/;

    if (rx.test(v.target.value)) {
      setValue(v.target.value);
      setSetting(v.target.value);
    } else {
      setIsFieldError(true);
      setTimeout(() => {
        setIsFieldError(false);
      }, 300);
    }
  };

  const saveSettingHandler = (ev) => {
    setSpinning(true);
    putSetting(settingId, setting);
  };

  const putSetting = (recordId) => {
    setDisabled(true);
    setSpinning(true);

    operations
      .singlePost(
        {
          id: recordId,
          setting: setting,
        },
        {
          endpoint: "settings/save",
        }
      )
      .then((response: Response) => {
        setSpinning(false);
        setDisabled(false);
        setLoading(false);
        setOpen(false);

        triggerReload();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSetting = (recordId) => {
    operations
      .singlePost(
        {
          id: recordId,
        },
        {
          endpoint: "settings/get",
        }
      )
      .then((response: Response) => {
        setLoading(false);
        const rd = response.getData();
        setData(rd);

        if (rd.type === "CHOICE") {
          setChoices(JSON.parse(rd.choices));
          setValue(getValue(rd.value, rd.choices));
        } else {
          setValue(rd.value);
        }

        setOpen(true);
      });
  };

  const StatusIcon = () => {
    if (loading) {
      return <FontAwesomeIcon icon={["fas", "gear"]} spin={true} className={"mr2"} />;
    }

    return <FontAwesomeIcon icon={["fas", "gear"]} className={"mr2"} />;
  };

  const RenderChoices = () => {
    const jData = JSON.parse(data.choices);
    const options = [];
    var defaultValue = {};

    Object.keys(jData).map((choice) => {
      const choiceValue = choice;
      const choiceLabel = jData[choice];
      const newopt = {
        value: choiceValue,
        label: choiceLabel,
      };

      if (currentValue === choiceValue) {
        defaultValue = newopt;
      }

      options.push(newopt);
    });

    return (
      <FormControl sx={{ m: 1, minWidth: 220 }} size={"small"} style={{ width: "90%" }}>
        <Select
          autoFocus={true}
          key={"text-key-choice-select-" + settingId}
          onChange={changerOpts}
          value={value}
          defaultValue={defaultValue}
          options={options}
        />
      </FormControl>
    );
  };

  const RenderShortText = () => {
    return (
      <>
        <FormControl sx={{ m: 1, minWidth: 220 }} size={"small"} style={{ width: "90%" }}>
          <TextField
            autoFocus={true}
            key={"text-key-short-text-" + settingId}
            value={value}
            defaultValue={currentValue}
            id={"setting-short-text"}
            variant={"outlined"}
            onChange={changer}
            hiddenLabel
          />
        </FormControl>
      </>
    );
  };
  const RenderLongText = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 220 }} size={"small"} style={{ width: "90%" }}>
        <TextField
          autoFocus={true}
          key={"text-key-long-text-" + settingId}
          defaultValue={currentValue}
          id={"setting-short-text"}
          variant={"outlined"}
          onChange={changer}
          hiddenLabel
        />
      </FormControl>
    );
  };
  const RenderNumber = () => {
    const classError = isFieldError ? "field-error-animated-glow" : "";

    return (
      <FormControl sx={{ m: 1, minWidth: 220 }} size={"small"} style={{ width: "90%" }}>
        <Grid container>
          <Grid
            item
            xs={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            className={"pr3"}
          >
            <em>Numbers Only</em>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus={true}
              key={"text-key-number-" + settingId}
              defaultValue={currentValue}
              id={"setting-short-text"}
              fullWidth
              variant={"outlined"}
              onChange={changerNumber}
              className={classError}
              hiddenLabel
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  };

  return (
    <div style={{ overflow: "visible" }}>
      <SuiButton
        color={"primary"}
        variant={"gradient"}
        size={"medium"}
        onClick={() => {
          setLoading(true);
          getSetting(props.settingId);
        }}
      >
        <StatusIcon />
        Modify
      </SuiButton>

      <Dialog
        PaperProps={{ style: { overflow: "visible" } }}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        scroll={"body"}
      >
        <Box style={{ overflow: "visible" }}>
          <form onSubmit={onFormSubmit}>
            <DialogTitle>{data.name}</DialogTitle>
            <DialogContent sx={{ height: "auto", width: "480px" }} style={{ overflow: "visible" }}>
              <DialogContentText style={{ overflow: "visible" }}>
                <>
                  {data.description}
                  <br />
                  {data.type === "CHOICE" ? (
                    <>
                      <RenderChoices currentValue={currentValue} />
                    </>
                  ) : data.type === "SHORT_TEXT" ? (
                    RenderShortText()
                  ) : data.type === "LONG_TEXT" ? (
                    <RenderLongText currentValue={currentValue} />
                  ) : data.type === "NUMBER" ? (
                    RenderNumber()
                  ) : (
                    <>{data.description}</>
                  )}
                </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <SuiButton
                disabled={disabled}
                color={"primary"}
                variant={"gradient"}
                onClick={saveSettingHandler}
              >
                <FontAwesomeIcon
                  icon={["fas", "gear"]}
                  spin={spinning}
                  fixedWidth={true}
                  className={"mr2"}
                />
                Save
              </SuiButton>
              <SuiButton color={"light"} variant={"gradient"} onClick={handleClose}>
                <FontAwesomeIcon icon={["fas", "times-circle"]} className={"mr2"} />
                Cancel
              </SuiButton>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </div>
  );
}

class SettingsList extends ZevList {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "settings",
        endpoint: "/settings",
      },
      selectRowEnable: false,
      sortFields: [
        { f: "id", l: "ID" },
        { f: "name", l: "Setting Name" },
        { f: "value", l: "Value" },
        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  setting(record) {
    switch (record.type) {
      case "NUMBER":
      case "SHORT_TEXT":
      case "LONG_TEXT":
        return record.value;
        break;
      case "CHOICE":
        var choices = JSON.parse(record.choices);
        return choices[record.value] || record.value;
        break;
      default:
    }
  }

  renderRow(record) {
    const contractor = <></>;
    const name = record.name;

    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updated_by}</span>
        </div>
      </>
    );

    const actions = (setting) => {
      return (
        <>
          <ChangeSetting
            settingId={record.id}
            cv={record.value}
            operations={this.ops}
            triggerReload={this.componentDidMount}
          />
        </>
      );
    };

    return {
      id: record.id,
      name: html(
        `<strong>${record.name}</strong><br/><small class='text-muted'>${record.description}</small>`
      ),
      value: this.setting(record),
      modified: modified,
      actions: actions(record),
    };
  }
}

export default SettingsList;
