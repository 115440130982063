import React from "react";
import withRouter from "../../../../withRouter";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardContent, CardHeader } from "@mui/material";
import ModelsDeleteForm from "../../../Forms/Delete/cst/ModelsDeleteForm";

class ModelsDeleteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: props.params.modelId,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardContent>
                  <ModelsDeleteForm
                    recordId={this.state.recordId}
                    entity={"models"}
                    pageScope={this}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(ModelsDeleteView);
