import React from "react";
import ZevForm from "../ZevForm";
import withRouter from "../../../withRouter";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Operations from "../../../Reusable/Controller/Operations";
import { hasOwnDeepProperty, mapData } from "../../../Reusable/Misc";
import { CustomerQuote } from "./Modules/CustomerQuote";
import { StateManager } from "../../../Reusable/Core";
import Toastr from "../../../Reusable/Toastr";

class QuoteReviewForm extends ZevForm {
  constructor(props) {
    super(props);

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: false,
        service: {
          recordId: props.recordId,
          entity: "quote",
          endpoint: "checkout/get_quote",
          apiPrefix: "/api/pub/v1/",
        },
        labels: {},
        status: {
          isDraftSaving: false,
          isDraftFailed: false,
          isDraftSaved: false,
          isQuoteSending: false,
          isQuoteFailed: false,
          isQuoteSent: false,
        },
      },
      false
    );

    this.ops = new Operations();

    this.localChangeHandler = this.localChangeHandler.bind(this);
    this.acceptQuote = this.acceptQuote.bind(this);
    this.rejectQuote = this.rejectQuote.bind(this);
    this.cancelPageRequest = this.cancelPageRequest.bind(this);
  }

  cancelPageRequest() {
    const navigate = this.props.navigate;

    Toastr.info(
      "Request Cancelled",
      "You have not taken action on this quote yet. Click the link in your email to come back to this page at any time."
    );

    navigate("/auth/login");
  }

  lineItemTemplate(lineItem, override) {
    // const isSelected = parseInt(override.selectedLineItem);
    // const isSelected = (override === false) ? ((lineItem.isOptional == 0) ? "1" : "0") : override.selectedLineItem;
    // const isSelected = (override === false) ? (lineItem.isOptional == 0) ? "1" : override.selectedLineItem : 0;

    var isSelected;

    if (override === false || override === undefined) {
      if (lineItem.isOptional == 0) {
        // is required
        isSelected = 1;
      } else {
        isSelected = 0;
      }
    } else {
      isSelected = override.selectedLineItem || override;
    }

    return {
      lineItemId: {
        value: lineItem.id,
        isRequired: true,
        immutable: false,
        valid: false,
        touched: false,
        placeholder: "",
        label: "",
        errors: [],
        vr: {
          required: { opt: true, msg: "Required" },
        },
      },
      selectedLineItem: {
        type: "checkbox",
        value: isSelected,
        isRequired: false,
        checked: false,
        immutable: false,
        valid: false,
        label: "",
        touched: false,
        placeholder: "",
        errors: [],
        vr: {},
      },
    };
  }

  async componentDidMount(bgq) {
    /**
     * Get all data needed for page
     */

    const bypassGetQuote = bgq || false;

    let quote = "";

    if (!bypassGetQuote && this.props.quote_id) {
      const params = {
        quoteId: this.props.quote_id,
        contractorId: this.props.contractor_id,
        installRequestId: this.props.request_id,
        token: this.props.token,
        hash: this.props.hash,
      };

      quote = this.ops.getRecord(this, null, params, null, this.props.entity).then(
        function (data) {
          return { quote: data };
        }.bind(this)
      );
    } else {
      quote = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([quote]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        recordId: this.props.quote_id,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "quoteReview",
        loaderStyle: "installRequest",
        service: {
          // endpoint: "/checkout/save_line_items",
          redirectTarget: "/quote/checkout",
          entity: "quoteReview",
          apiPrefix: "pub",
        },
        openModal: {},
        labels: {
          accepting: "Accepting Quote",
          accept: "Accept Quote",
          accepted: "Quote Accepted",
          acceptFailed: "Accept Quote Failed",
          rejecting: "Rejecting Quote",
          reject: "Reject Quote",
          rejected: "Quote Rejected",
          rejectFailed: "Reject Quote Failed",
        },
        viewData: viewData,
        _data: {
          quoteId: null,
          quoteHash: null,
          quoteToken: null,
          contractorId: null,
          installRequestId: null,
        },
        data: {
          selectedLineItems: {},
        },
      });

      let ns = mapData(o.viewData.quote, o);

      ns._data.quoteId = this.props.quote_id;
      ns._data.quoteHash = this.props.hash;
      ns._data.quoteToken = this.props.token;
      ns._data.installRequestId = this.props.request_id;
      ns._data.contractorId = this.props.contractor_id;

      ns.viewData.quote.quote.quoteLineitemsList.map((lineItem) => {
        var override = {};

        if (
          // typeof this.props.location !== 'undefined' &&
          // typeof this.props.location.state !== 'undefined' && this.props.location.state !== null &&
          // typeof this.props.location.state.vd !== 'undefined' && this.props.location.state.vd !== null &&
          // typeof this.props.location.state.vd.selectedLineItems !== 'undefined' &&
          hasOwnDeepProperty(this.props, "location.state.vd.selectedLineItems") &&
          typeof this.props.location.state.vd.selectedLineItems[lineItem.id] !== "undefined"
        ) {
          override = this.props.location.state.vd.selectedLineItems[lineItem.id];
        } else {
          override = false;
        }
        ns.data.selectedLineItems[lineItem.id] = this.lineItemTemplate(lineItem, override);
      });

      this.setState(ns, () => {
        this.mounted();
      });
    });
  }

  cb() {}



  acceptQuote(evt) {
    const dt = this.data(null, true);
    const selectedLineItems = dt.selectedLineItems;

    console.log(selectedLineItems);

    this.acceptQuoteNavigate(selectedLineItems);
  }

  acceptQuoteNavigate(params) {
    const navigate = this.props.navigate;
    const url =
      "/quote/review/" +
      this.props.contractor_id +
      "/" +
      this.props.request_id +
      "/" +
      this.props.quote_id +
      "/" +
      this.props.hash +
      "/" +
      this.props.token +
      "/accept";

    navigate(url, {
      state: {
        vd: {
          selectedLineItems: params,
        },
      },
    });
  }

  rejectQuote() {
    const navigate = this.props.navigate;
    const url =
      "/quote/review/" +
      this.props.contractor_id +
      "/" +
      this.props.request_id +
      "/" +
      this.props.quote_id +
      "/" +
      this.props.hash +
      "/" +
      this.props.token +
      "/reject";

    navigate(url);
  }

  calculateTotal() {
    let total = 0.0;
    Object.keys(this.state.data.selectedLineItems).map((key) => {
      if (this.state.data.selectedLineItems[key].selectedLineItem.value.toString() === "1") {
        this.state.viewData.quote.quote.quoteLineitemsList.map((li) => {
          if (li.id.toString() === key.toString()) {
            total += parseFloat(li.total);
          }
        });
      }
    });

    return total.toFixed(2);
  }

  localChangeHandler(ev, e) {
    this.handleChange(ev, e);

    // this.saveLineItemSelection(ev, e);

    if (this.state.initialized === true) {
      let total = this.calculateTotal();
      StateManager.meld(this, this.state, { totalCostOfQuote: total },
          () => {
              // this.componentDidMount(true);
            }
          );
    }
  }

  form() {
    let total = 0;

    return (
      <>
        <Box sx={{ mb: 3 }}>

          <h4>Quote Prepared For</h4>
          <p style={{marginBottom: '30px'}}>
            {this.state.viewData.quote.customer.firstName} {this.state.viewData.quote.customer.lastName}<br/>
            <span className={"text-muted"}>{this.state.viewData.quote.customer.email}</span><br/>
            <br/>
            <strong>{this.state.viewData.quote.site.address1}</strong>

          </p>


          <CustomerQuote
            cancelPageRequest={this.cancelPageRequest}
            acceptQuote={this.acceptQuote}
            rejectQuote={this.rejectQuote}
            total={parseFloat(this.calculateTotal()).toFixed(2)}
            state={this.state}
            handleChange={this.localChangeHandler}
          />
        </Box>
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(QuoteReviewForm);
