import ZevForm from "../../pages/Forms/ZevForm";
import { APIException } from "../Constants";
import AxiosHandler from "./AxiosHandler";

var inflect = require("i")();

class Operations {
  constructor() {
    this.axios = AxiosHandler;
    this.apiPrefixPrivate = "/api/priv/v1/";
    this.apiPrefixPublic = "/api/pub/v1/";
  }

  async checkSession() {
    const fd = new FormData();
    fd.set("action", "check_session");

    const chk = await this.axios
      .post(this.apiPrefixPrivate + "/check/session", fd, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);

    return chk;
  }

  async search(keyword, params) {
    let endpoint = this.apiPrefixPrivate + params.path;

    let fd = new FormData();
    fd.set("keyword", keyword);

    const results = this.axios
      .post(endpoint, fd, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);

    return results;
  }

  async singlePost(data, params) {
    let endpoint = this.apiPrefixPrivate + params.endpoint;
    const results = await this.axios
      .post(endpoint, data, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);
    return results;
  }

  async publicPost(data, params) {
    let endpoint = this.apiPrefixPublic + params.endpoint;
    const results = await this.axios
      .post(endpoint, data, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);
    return results;
  }

  async updateDashboard() {
    let endpoint = this.apiPrefixPrivate + "/dashboard/update";

    const results = await this.axios
      .post(endpoint, {}, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);

    return results;
  }

  async singleSave(data, params) {
    let endpoint = this.apiPrefixPrivate + params.endpoint;

    const results = await this.axios
      .post(endpoint, data, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);

    return results;
  }

  async submit(form: ZevForm, params) {
    let endpoint = this.apiPrefixPublic + form.endpoint();
    let entity = form.entity();

    const fd = form.json();

    const results = await this.axios
      .post(endpoint, fd, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);
    return results;
  }

  async _submit(form: ZevForm) {
    let endpoint = this.apiPrefixPrivate + form.endpoint();
    let entity = form.entity();

    const fd = form.json();

    const results = await this.axios
      .post(endpoint, fd, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);
    return results;
  }

  async _post(data, target, apiPrefix, cb) {
    let endpoint;

    if (apiPrefix) {
      endpoint = apiPrefix + target;
    } else {
      endpoint = this.apiPrefixPrivate + target;
    }

    const results = await this.axios
      .post(endpoint, data, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((results) => results);
    if (typeof cb === "function") {
      cb();
    }

    return results;
  }

  secureForm(form: ZevForm) {
    const data = form.data(true);
    const fd = new FormData();
    const endpoint = this.apiPrefixPublic + "/security/secure_form";

    let fields = [];
    let fieldsRaw = [];
    let fieldData = [];

    for (let datum in data) {
      fields.push(datum);
      if (data[datum].immutable === true) {
        fieldData.push({ field: datum, value: data[datum].value });
        fieldsRaw.push(datum);
      }
    }

    fd.set("fields", JSON.stringify(fields));
    fd.set("fields_raw", JSON.stringify(fieldsRaw));
    fd.set("field_values", JSON.stringify(fieldData));

    const pl = this.axios
      .post(endpoint, fd, window.globals.formPostHeaders)
      .then((results) => results)
      .catch((error) => {
        console.log(error);

        // error routine
      });

    return pl;
  }

  getRecord(form: ZevForm, recordId, params, suffix, orEntity, orEndpoint) {
    let endpoint = orEndpoint || form.endpoint();
    let entity = form.entity();

    const fd = new FormData();

    var postParams = {};

    if (recordId !== null) {
      if (typeof recordId.field !== "undefined" && typeof recordId.id !== "undefined") {
        fd.set(recordId.field, recordId.id);
        postParams[recordId.field] = recordId.id;
      } else {
        fd.set("id", recordId);
        postParams["id"] = recordId;
      }
    }

    if (params) {
      for (let param in params) {
        fd.set(param, params[param]);
        postParams[param] = params[param];
      }
    }

    if (suffix) {
      endpoint += "?a&" + suffix;
    }

    if (form.api() === null) {
      endpoint = this.apiPrefixPrivate + endpoint;
    } else {
      endpoint = form.api() + endpoint;
    }

    return this.axios
      .post(endpoint, postParams, window.globals.formPostHeaders)
      .then((results) => {
        if (results.hasErrors()) {
          throw new APIException(1001);
        }

        if (results.hasSettings()) {
          form.setSettings(results.getSettings());
        }

        const record = results.data.data[entity];

        return record;
      })
      .catch((e) => {
        console.log(e);
      });
  }




  getRecords(view, section, page, isViewData: Boolean, filters, sort, endpoint) {
    let method, suffix;

    const responseObject = {
      itemsPerPage: 0,
      pageRange: 0,
      totalRecords: 0,
      records: [],
    };
    const fd = new FormData();

    fd.set("page", page);

    if (sort) {
      fd.set("sort", sort.field);
      fd.set("direction", sort.dir);
    }

    if (isViewData) {
      endpoint = endpoint || view.endpoint();
      method = "get";
    } else {
      endpoint = endpoint || view.endpoint() + "/list";

      method = "post";
    }

    if (typeof filters !== "undefined") {
      suffix = "/";
      const mappedFilters = new FormData();
      for (let filter in filters) {
        mappedFilters(filter, filters[filter]);
      }
      fd.set("filters", JSON.stringify(mappedFilters));
    }

    endpoint = this.apiPrefixPrivate + endpoint;

    let options = {
      method: method,
      url: endpoint,
      data: fd,
      timeout: 10000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const $this = this;

    return this.axios(options)
      .then((results) => {
        if (results.data.hasErrors) {
          throw new APIException(1001);
        }

        if (typeof results.data.records !== "undefined") {
          responseObject.records = results.data.records;
        } else {
        }

        return $this.ro(responseObject, results);
      })
      .catch((code) => {
        throw new APIException(1001);
      });
  }

  addRecord(form: ZevForm) {
    const entity = form.entity();
    const endpoint = form.endpoint();
    const data = form.data();
    const fd = new FormData();

    for (let key in data) {
      fd.set(key, data[key]);
    }

    return this.axios.put(endpoint, fd, window.globals.formPostHeaders);
  }

  updateRecord(form: ZevForm) {
    const entity = form.entity;
    const endpoint = form.endpoint();
    const data = form.data();
    const fd = new FormData();

    for (let key in data) {
      fd.set(key, data[key]);
    }

    return this.axios.patch(endpoint, fd, window.globals.formPostHeaders);
  }

  deleteRecord(form: ZevForm) {
    const entity = form.entity();
    const endpoint = form.endpoint();
    const data = form.data();

    for (let name in data) {
      if (name === "id") {
        fd.set("id", data[name]);
      }
    }

    return this.axios.delete(endpoint, fd, window.globals.formPostHeaders);
  }

  ro(ro, response) {
    ro.totalRecords = response.data.totalRecords;
    ro.itemsPerPage = response.data.meta.items_per_page;
    ro.pageRange = response.data.meta.page_range;
    ro.hasErrors = response.data.hasErrors;

    return ro;
  }
}

export default Operations;
