import React from "react";

class ToolTips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      body: props.body,
    };
  }

  listToolTips = (props) => (
    <Tooltip {...props}>
      <h4>{this.state.title}</h4>
      <br />
      <p>{this.state.body}</p>
    </Tooltip>
  );

  render() {
    return (
      <OverlayTrigger placement="top" overlay={this.listToolTips}>
        <span className="tooltip-container">
          <i className="fad fa-question-circle"></i>
        </span>
      </OverlayTrigger>
    );
  }
}

export default ToolTips;
