import R from "./R.js";

class Response extends R {
  constructor(response) {
    super(response);
  }

  getInstance() {
    return this.data.instance;
  }

  getStatus() {
    return this.data.status;
  }

  hasErrors() {
    return this.data.hasErrors === true;
  }

  hasValidationErrors() {
    return this.data.hasValidationErrors === true;
  }

  getErrors() {
    return this.data.errors;
  }

  getValidationErrors() {
    return this.data.validationErrors;
  }

  getMessage() {
    return this.data.message;
  }

  getSearchFields() {
    return this.data.searchFields;
  }

  getTotalRecords() {
    return this.data.totalRecords;
  }

  getMeta() {
    return this.data.meta;
  }

  getItemsPerPage() {
    return this.data.meta.items_per_page;
  }

  getPageRange() {
    return this.data.meta.page_range;
  }
}

export default Response;
