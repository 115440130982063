import React from "react";
import ZevForm from "../ZevForm";
import TextElement from "../../../Reusable/Forms/Elements/TextElement";
import withRouter from "../../../withRouter";

class ResetPasswordForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.getPassword = this.getPassword.bind(this);
  }

  componentDidMount() {
    const o = this.init(
      this,

      {
        loaderStyle: "auth",
        service: {
          endpoint: "/account/password/reset",
          entity: "ResetPassword",
        },
        labels: {
          submit: "Reset Password",
          submitting: "Resetting",
        },
        data: {
          resetToken: {
            label: "Reset Token",
            showLabel: false,
            value: this.props.reset_token,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            errors: {},
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          email: {
            label: "Email Address",
            showLabel: false,
            value: "",
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Enter email address again ",
            errors: {},
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          password: {
            label: "Password",
            showLabel: false,
            value: "",
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "New Password",
            errors: {},
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          confirmPassword: {
            label: "Confirm Password",
            showLabel: false,
            value: "",
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Confirm Password",
            errors: {},
            vr: {
              required: { opt: true, msg: "This field is required" },
              is: { opt: this.getPassword, msg: "Passwords must be equal" },
            },
          },
        },
      }
    );

    this.setState(o, () => {
      this.mounted();
    });
  }

  getPassword() {
    if (
      typeof this.state.data !== "undefined" &&
      typeof this.state.data.password !== "undefined" &&
      typeof this.state.data.password.value !== "undefined"
    ) {
      return this.state.data.password.value;
    } else {
      return "";
    }
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <TextElement
              type="hidden"
              name="resetToken"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.resetToken}
            />
            <TextElement
              type="text"
              name="email"
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.email}
            />
            <TextElement
              type="password"
              name="password"
              placeholder="New Password"
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.password}
            />
            <TextElement
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.confirmPassword}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(ResetPasswordForm);
