import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SuiButton from "../../../../../components/SuiButton";

export const SubmitPayment = (props) => {
  const { submitPayment, processed, failed, processing, state } = props;

  return (
    <SuiButton color="success" variant="gradient" onClick={submitPayment} disabled={processing}>
      <span>
        <FontAwesomeIcon icon={["fad", "credit-card"]} />
        &nbsp;&nbsp;
      </span>
      <span style={{ textShadow: "0 0 3px #555" }}>{state.labels.submitPayment}</span>
    </SuiButton>
  );
};
