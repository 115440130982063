import AuthSignOut from "../pages/Components/AuthSignOut";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class GlobalRoutes {
  constructor() {}

  build(routes) {
    let rtes = [];

    if (routes) {
      routes.map((route) => {
        rtes.push(route);
      });
    }

    rtes.push({
      order: 999,
      noCollapse: true,
      route: "/auth/logout",
      type: "collapse",
      name: "Sign Out",
      key: "signout",
      icon: <FontAwesomeIcon icon={["fad", "right-from-bracket"]} />,
      component: <AuthSignOut />,
    });

    return rtes;
  }

  routes() {}
}

export default GlobalRoutes;
