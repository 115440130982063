import React from "react";
import SuiBox from "../../../../components/SuiBox";
import UsersList from "../../../Lists/core/Users/UsersList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class UsersListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New User"
            path={"/users/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <UsersList />
      </>
    );
  }
}

export default UsersListView;
