import { CardContentHeader, StatusElement } from "../../../../../../Reusable/Misc";
import { CardContent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Card from "@mui/material/Card";
import React from "react";

export const SBStatus = (props) => {
  const { state } = props;

  const SentDateTime = () => {
    const rq = state.viewData.installRequest.irq;
    const rr = state.viewData.installRequest.irr;
    const qt = state.viewData.installRequest.quot;
    return (
      <>
        <strong>Request Created</strong>: {moment.utc(rq.created).local().format("LLL")}
        <br />
        <strong>Quote Sent</strong>:{" "}
        {rr.requestRespondedAt == null ? (
          <>
            Not Yet Sent
            <br />
            <strong>Viewed By Customer</strong>: N/A
          </>
        ) : (
          <>
            {moment.utc(rr.requestRespondedAt).local().format("LLL")}
            <br />
            {qt.viewDate ? (
              <>
                <strong>Viewed By Customer</strong>: {moment.utc(qt.viewDate).local().format("LLL")}
              </>
            ) : (
              <>
                <strong>Viewed By Customer</strong>: Not Yet Viewed
              </>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Card variant="outlined">
        <CardContentHeader>Status</CardContentHeader>
        <CardContent>
          <br />
          <br />
          {!state.viewData.installRequest.quot ? (
            <>
              <StatusElement
                color={"info"}
                icon={<FontAwesomeIcon icon={["fas", "check-circle"]} />}
                status={"New Quote"}
              />
            </>
          ) : state.viewData.installRequest.irq.status === "QUOTE_ACCEPTED_DEPOSIT_PAID" ? (
            <>
              {/*<Chip icon={<FontAwesomeIcon icon={['fas', 'pen-ruler']} />} color={"primary"} className="p3 mt2 mb2" label="Quote Draft Saved"/>*/}
              <StatusElement
                color={"success"}
                icon={<FontAwesomeIcon icon={["fas", "dollar"]} />}
                status={"Deposit Paid"}
              />
              <br />
              <p>
                <strong>Last Modified</strong>:{" "}
                {moment.utc(state.viewData.installRequest.quot.modified).local().format("LLL")}
              </p>
              <SentDateTime />
            </>
          ) : state.viewData.installRequest.irq.status === "INVOICE_PAID" ? (
            <>
              {/*<Chip icon={<FontAwesomeIcon icon={['fas', 'pen-ruler']} />} color={"primary"} className="p3 mt2 mb2" label="Quote Draft Saved"/>*/}
              <StatusElement
                color={"success"}
                icon={<FontAwesomeIcon icon={["fas", "dollar"]} />}
                status={"Balance Paid in Full"}
              />
              <br />
              <p>
                <strong>Last Modified</strong>:{" "}
                {moment.utc(state.viewData.installRequest.quot.modified).local().format("LLL")}
              </p>
              <SentDateTime />
            </>
          ) : state.viewData.installRequest.irq.status === "QUOTE_SENT" ? (
            <>
              {/*<Chip icon={<FontAwesomeIcon icon={['fas', 'pen-ruler']} />} color={"primary"} className="p3 mt2 mb2" label="Quote Draft Saved"/>*/}
              <StatusElement
                color={"primary"}
                icon={<FontAwesomeIcon icon={["fas", "check-circle"]} />}
                status={"Quote Sent"}
              />
              <br />
              <p>
                <strong>Last Modified</strong>:{" "}
                {moment.utc(state.viewData.installRequest.quot.modified).local().format("LLL")}
              </p>
              <SentDateTime />
            </>
          ) : state.viewData.installRequest.irq.status === "QUOTE_DRAFT" ? (
            <>
              {/*<Chip icon={<FontAwesomeIcon icon={['fas', 'pen-ruler']} />} color={"primary"} className="p3 mt2 mb2" label="Quote Draft Saved"/>*/}
              <StatusElement
                color={"warning"}
                icon={<FontAwesomeIcon icon={["fas", "ruler"]} />}
                status={"Draft Saved"}
              />
              <br />
              <p>
                <strong>Last Modified</strong>:{" "}
                {moment.utc(state.viewData.installRequest.quot.modified).local().format("LLL")}
              </p>
              <SentDateTime />
            </>
          ) : (
            <></>
          )}

          <br />

          <br />
        </CardContent>
      </Card>
    </>
  );
};
