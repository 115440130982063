import { useEffect, useMemo, useState } from "react";

// react-router components
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React components
// Soft UI Dashboard PRO React example components
import Sidenav from "ZevModules/Sidenav";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

import "./assets/scss/zevsociety.scss";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
// import routes from "routes";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import PrivateRoute from "./Routes/PrivateRoute";
import Login from "./pages/Auth/Login";
import AdminRoutes from "./Routes/AdminRoutes";
import GlobalRoutes from "./Routes/GlobalRoutes";
import InstallerRoutes from "./Routes/InstallerRoutes";
import Error404 from "./layouts/authentication/error/404";
import Operations from "./Reusable/Controller/Operations";
import axios from "axios";
// import HttpClient from "./Reusable/Controller/HttpClient";
import GlobalMessaging from "./Reusable/Messaging/GlobalMessaging";

// import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register from "./pages/Registration/Register";
import Logout from "./pages/Auth/Logout";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import AxiosHandler, { AxiosInterceptor } from "./Reusable/Controller/AxiosHandler";
import Redirect from "./pages/Views/Redirect";
import QuoteReviewView from "./pages/Checkout/QuoteReviewView";
import QuoteAcceptView from "./pages/Checkout/QuoteAcceptView";
import QuoteRejectView from "./pages/Checkout/QuoteRejectView";
import QuoteDepositPaymentCompleteView from "./pages/Checkout/QuoteDepositPaymentCompleteView";
import FinalPaymentReviewView from "./pages/Checkout/FinalPaymentReviewView";
import FinalPaymentProcessView from "./pages/Checkout/FinalPaymentProcessView";
import FinalPaymentPaymentCompleteView from "./pages/Checkout/FinalPaymentPaymentCompleteView";
import {readCookie} from "./Utility/Cookie";
import AccessDenied from "./pages/Error/AccessDenied";
import ExternalRedirect from "./Reusable/ExternalRedirect";
import Title from "./ZevModules/Title";

library.add(fas);
library.add(far);
library.add(fad);

/**
 * Initialization
 */

window.globals = {};
// window.globals['API_Prefix'] = '/zev.hub/api/pub/v1';
window.globals.formPostHeaders = {
  headers: {
    // 'Content-Type': 'multipart/form-data'
    "content-type": "application/json",
  },
};

/*
    AXIOS SETUP
 */

// HttpClient.bind(axios);

const ops = new Operations(AxiosHandler);
window.globals["operations"] = ops;

const App = function () {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();


  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  let innerRoutes = new GlobalRoutes().build();

  if (1 === 1) {
    innerRoutes = new AdminRoutes().gRoutes(); // user is admin
  } else if (2 === 4) {
    innerRoutes = new InstallerRoutes().gRoutes(); // user is installer
  }

  const location = useLocation();

  useEffect(
      () => { document.title = Title(location) },
      [location]
  )


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const role = readCookie("layout");


  const getRoutes = (allRoutes, isPrivate, inheritedRole) =>
    allRoutes.map((route) => {
      let privateRoute = isPrivate || true;
      const currentRole = readCookie("role");
      let role = null;
      if (route.role) {

        role = route.role;

        if (route.role.indexOf(currentRole) === -1) {
          return <></>
        }
      }

      if (route.collapse) {
        return getRoutes(route.collapse, route.isPrivate, role);
      }

      if (route.route) {
        if (privateRoute) {

          if (inheritedRole) {
            if (inheritedRole.indexOf(currentRole) === -1) {
              return (<></>);
            }
          }


          const spl = route.route.split("/");
          const li = spl[spl.length - 1];

          if (li === "list") {
            spl.pop();

            const jnd = spl.join("/");

            return (
              <>
                <Route exact path={jnd} element={<Redirect path={jnd + "/list"} />} />
                <Route
                  exact
                  path={route.route}
                  element={
                    <PrivateRoute
                      title={route.name}
                      subtitle={route.subtitle}
                      routes={innerRoutes}
                      controller={controller}
                      sidenav={route.sidenav}
                    >
                      {" "}
                      {route.component}{" "}
                    </PrivateRoute>
                  }
                  key={route.key}
                />
                ;
              </>
            );
          }

          return (
            <Route
              exact
              path={route.route}
              element={
                <PrivateRoute
                  title={route.name}
                  subtitle={route.subtitle}
                  routes={innerRoutes}
                  controller={controller}
                  sidenav={route.sidenav}
                >
                  {" "}
                  {route.component}{" "}
                </PrivateRoute>
              }
              key={route.key}
            />
          );
        }

        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const [buildDate, setBuildDate] = useState();
  const [buildNumber, setBuildNumber] = useState();

  const setBuild = (dt, no) => {
    setBuildDate(dt);
    setBuildNumber(no);
  };

  const ApiBuild = () => {
    return (
      <>
        <div className={"api-build-info"}>
          <pre>
            <strong>API Build Date: </strong> {buildDate} &nbsp;&nbsp;&nbsp;
            <strong>Build No: </strong> {buildNumber}
          </pre>
        </div>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <AxiosInterceptor ax={axios} buildData={setBuild}>
        <CssBaseline />
        {layout === "123dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="ZEV Society"
              routes={innerRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/*<Configurator/>*/}
          </>
        )}

        {/*<ApiBuild />*/}
        <GlobalMessaging />

        <Routes>
          <Route exact path="/register" element={<Register />} key="RegisterComponent001" />
          <Route exact path="/auth/login" element={<Login />} key="LoginComponent001" />
          <Route exact path="/auth/logout" element={<Logout />} key="LogoutComponent001" />
          <Route
            exact
            path="/auth/forgot-password"
            element={<ForgotPassword />}
            key="ForgotPasswordComponent001"
          />
          <Route
            exact
            path="/auth/reset-password/:reset_token"
            element={<ResetPassword />}
            key="ResetPasswordComponent001"
          />

          <Route
            exact
            path="/external"
            element={<ExternalRedirect />}
            key="ExternalRedirectComponent001"
          />


          <Route
            exact
            path="/quote/review/:contractor_id/:request_id/:quote_id/:quote_hash/:quote_token/accept"
            element={<QuoteAcceptView />}
            key="QuoteAcceptComponent001"
          />
          <Route
            exact
            path="/quote/review/:contractor_id/:request_id/:quote_id/:quote_hash/:quote_token/reject"
            element={<QuoteRejectView />}
            key="QuoteRejectComponent001"
          />
          <Route
            exact
            path="/quote/review/:contractor_id/:request_id/:quote_id/:quote_hash/:quote_token/"
            element={<QuoteReviewView />}
            key="QuoteReviewComponent001"
          />
          <Route
            exact
            path="/quote/payment_complete/:payment_token"
            element={<QuoteDepositPaymentCompleteView />}
            key="QuoteReceiptComponent001"
          />
          <Route
            exact
            key="jobs-payment-receipt"
            path="/jobs/payment_complete/:job_id/:payment_token"
            element={<FinalPaymentPaymentCompleteView />}
          />
          <Route
            exact
            path="/checkout/final/payment_complete/:payment_token"
            element={<FinalPaymentPaymentCompleteView />}
            key={"FinalPaymentCompleteComponent001"}
          />
          <Route
            exact
            path="/checkout/final/:customer_id/:contractor_id/:request_id/:job_id/:job_token/payment"
            element={<FinalPaymentProcessView />}
            key={"FinalPaymentProcessComponent001"}
          />
          <Route
            exact
            path="/checkout/final/:customer_id/:contractor_id/:request_id/:job_id/:job_token"
            element={<FinalPaymentReviewView />}
            key={"FinalPaymentReviewComponent001"}
          />

          <Route
            path={"*"}
            element={<div />}
            render={({ match: path }) => {
              if (path.url.slice(-1) === "/") {
                navigate(path.url + "/list");
              } else {
                navigate(path.url + "list");
              }
            }}
          />
          {getRoutes(innerRoutes)}

          <Route path="*" element={<AccessDenied />} />
        </Routes>
      </AxiosInterceptor>
    </ThemeProvider>
  );
};

export default App;
