import React from "react";
import ZevList from "../../../Forms/ZevList";
import withRouter from "../../../../withRouter";
import moment from "moment";
import { Alert } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {NavLink} from "react-router-dom";
import Grid from "@mui/material/Grid";

const JobStatus = (props) => {
  const { status } = props;

  switch (status) {
    case "CLOSING_PENDING_PAYMENT":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "dollar-circle" })} />}
          variant={"standard"}
          color={"success"}
        >
          Closing - Pending Payment
        </Alert>
      );
    case "OPEN":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "clock" })} />}
          variant={"standard"}
          color={"info"}
        >
          Open
        </Alert>
      );
    case "OPEN_STARTED":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "clock" })} />}
          variant={"filled"}
          color={"info"}
        >
          Open - Started
        </Alert>
      );
    case "CLOSED_COMPLETE":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "check-circle" })} />}
          variant={"filled"}
          color={"success"}
        >
          Closed - Complete
        </Alert>
      );
    case "CLOSED_INCOMPLETE":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "times-circle" })} />}
          variant={"standard"}
          color={"error"}
        >
          Closed - Incomplete
        </Alert>
      );
    case "CANCELLED":
      return (
        <Alert
          icon={<FontAwesomeIcon icon={icon({ style: "solid", name: "times-circle" })} />}
          variant={"filled"}
          color={"error"}
        >
          Cancelled
        </Alert>
      );
  }

  return <></>;
};

class JobsList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
    this.renderStyledRow = this.renderStyledRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  installDate(installDate) {
    if (installDate) {
      return <>{moment.utc(installDate).local().format("M/D/Y h:mm A")}</>;
    }

    return (
      <>
        <em>Not Yet Scheduled</em>
      </>
    );
  }

  completedDate(completedDate) {
    if (completedDate) {
      return <>{moment.utc(completedDate).local().format("M/D/Y h:mm A")}</>;
    }

    return (
      <>
        <em>Not Yet Completed</em>
      </>
    );
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "jobs",
        endpoint: "/jobs",
      },
      sort: {
        field: "created",
        dir: "DESC"
      },
      sortFields: [
        { f: "id", l: "Job ID" },
        { f: "quoteId.installRequestResponsesId.installRequestId.siteId.customerId.userId.lastName", l: "Title" },
        { f: false, l: "Quote" },
        { f: "quoteId.contractorId.name", l: "Contractor/Quote" },
        { f: "status", l: "Status" },
        { f: "scheduledInstallDate", l: "Installation Date", cls: "text-right" },
        { f: "jobClosedDate", l: "Completed Date", cls: "text-right" },
      ],
    });
  }

  renderStyledRow(row) {
    return (
        <NavLink to={"/jobs/view/" + row.id} className={"stylized-row"}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>
                  <strong>#{row.order_number}</strong>
                </Grid>
                <Grid item xs={7}>
                <span className={"text-muted"}>
                {moment.utc(row.created).local().format("LLL")}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5}>{row.ins_req_name}</Grid>
                <Grid item xs={7}>
                  {row.status}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NavLink>
    );
  }


  renderRow(record) {
    const contractor = <></>;

    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    return {
      id: record.id,
      "quote.title": (
        <>
          <strong>
            {record.customer_user.firstName} {record.customer_user.lastName}
          </strong>
          <br />
          {record.request.installAddress1}
          <br />
          {record.request.installCity}, {record.request.installState} {record.request.installZip}
        </>
      ),
        "quote": <NavLink to={"/install_requests/view/" + record.request.id} className={"list-button-action"}>
          <span>
          #{record.request.id}-{record.quote.id}
          </span>
        </NavLink>,
      "contractor.name": <>{record.contractor.name}</>,
      status: <JobStatus status={record.job.status} />,
      install_date: <>{this.installDate(record.job.scheduledInstallDate)}</>,
      completed_date: <>{this.completedDate(record.job.jobClosedDate)}</>,
    };
  }
}

export default withRouter(JobsList);
