import React from "react";
import ZevList from "../../../Forms/ZevList";
import { caseWords, RequestListingStatus, SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";
import moment from "moment/moment";
import Grid from "@mui/material/Grid";
import {NavLink} from "react-router-dom";

class InstallRequestsList extends ZevList {
  constructor(props) {
    super(props);
    this.props = props;
    this.selectRow = this.selectRow.bind(this);
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "installRequests",
        endpoint: "/install_requests",
      },
      style: 2,
      sort: {
        field: "created",
        dir: "DESC"
      },
      tableClass: "click-row",
      sortFields: [
        { f: "orderId.orderNumber", l: "Order ID" },
        { f: "siteId.customerId.userId.lastName", l: "Requestor Name" },
        { f: ["installState", "installCity"], l: "Install City/State" },
        { f: "contractorId.name", l: "Contractor", cls: 'hidden-xs visible-md' },
        { f: "status", l: "Status" },
        { f: "created", l: "Request Received", cls: "text-right" },
        { f: "modified", l: "Updated", cls: "text-right hidden-xs visible-md" }
      ],
    });
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  renderStyledRow(row): * {

    return (
        <NavLink to={"/install_requests/view/" + row.id} className={"stylized-row"}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <strong>#{row.id}-{row.order_id}</strong>
              </Grid>
              <Grid item xs={7}>
                <span className={"text-muted"}>
                {moment.utc(row.created).local().format("LLL")}
                  </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>{row.ins_req_name}</Grid>
              <Grid item xs={7}>
                {row.status}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </NavLink>
    );

  }

  renderRow(record) {
    const created = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">
            {moment.utc(record.created).local().format("MM/DD/YYYY hh:mm A")}
          </span>
        </div>
      </>
    );

    const updated = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">
              {moment.utc(record.modified).local().format("MM/DD/YYYY hh:mm A")}
          </span>
        </div>
      </>
    );


    const actions = (installRequest) => {
      return(<></>);

      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View Install Request"
            path={"/install_requests/view/" + installRequest.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit Install Request"
            path={"/install_requests/edit/" + installRequest.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete Install Request"
            path={"/install_requests/delete/" + installRequest.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    var contractor = "";

    if (record.contractor == null) {
      contractor = "Unassigned";
    } else {
      contractor = record.contractor.name;
    }

    return {
      orderNumber: record.order_id,
      ins_req_name: caseWords(record.ins_req_name),
      ins_state: record.ins_city + ", " + record.ins_state,
      contractorId: contractor,
      status: RequestListingStatus(record.status),
      created: created,
      updated: updated,
    };
  }
}

export default withRouter(InstallRequestsList);
