import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import withRouter from "../../../../withRouter";
import SuiTypography from "../../../../components/SuiTypography";
import moment from "moment";
import { NavLink } from "react-router-dom";
import GoogleMap from "../../../Components/GoogleMap";
import JobTabs from "./Modules/JobTabs";
import Button from "@mui/material/Button";
import { AddJobNote } from "../../../Modals/AddJobNote";
import { StateManager } from "../../../../Reusable/Core";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FAStack } from "../../../../Utility/FontAwesome";
import BadgePill from "../../../../Reusable/BadgePill";
import { Alert, AlertTitle } from "@mui/material";
import AddScheduledDate from "../../../Modals/AddScheduledDate";
import RescheduleDate from "../../../Modals/RescheduleDate";
import Confirmation from "../../../Modals/Confirmation";
import MessageCustomer from "../../../Modals/MessageCustomer";
import {CloseJob} from "../../../Modals/CloseJob";
import hash from "object-hash";

const DisplayStatus = (props) => {
  const { job } = props;

  if (job.scheduledInstallDate) {
    let mo = moment.utc(job.scheduledInstallDate).local();



    if (mo.isAfter()) {
      return (
        <>
          <Alert
            className={"override-dumb-alert"}
            elevation={1}
            severity="primary"
            style={{ width: "100%" }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={1} justifyContent={"center"}>
                <FontAwesomeIcon
                  size={"4x"}
                  icon={icon({ style: "duotone", name: "calendar-check" })}
                  className={"text-color-primary"}
                />
              </Grid>
              <Grid item xs={11}>
                <AlertTitle>Job Scheduled</AlertTitle>
                <p>This job is currently scheduled for installation</p>
                <p>Date: {moment.utc(job.scheduledInstallDate).local().format("MMMM DD, yyyy")}</p>
                <p>Time: {moment.utc(job.scheduledInstallDate).local().format("h:mm a")}</p>
              </Grid>
            </Grid>
          </Alert>
          <br />
        </>
      );
    } else {
      if (job.status === "OPEN") {
        return (
          <>
            <Alert
              className={"override-dumb-alert"}
              elevation={1}
              severity="primary"
              style={{ width: "100%" }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={1} justifyContent={"center"}>
                  <FontAwesomeIcon
                    size={"4x"}
                    icon={icon({ style: "duotone", name: "calendar-check" })}
                    className={"text-color-primary"}
                  />
                </Grid>
                <Grid item xs={11}>
                  <AlertTitle>Job Starting Soon</AlertTitle>
                  <p>This job should be starting soon.</p>
                  <p>Date: {moment.utc(job.scheduledInstallDate).local().format("MMMM DD, yyyy")}</p>
                  <p>Time: {moment.utc(job.scheduledInstallDate).local().format("h:mm a")}</p>
                </Grid>
              </Grid>
            </Alert>
            <br />
          </>
        );
      } else if (job.status === "OPEN_STARTED") {
        return (
          <>
            <Alert
              className={"override-dumb-alert"}
              elevation={1}
              severity="primary"
              style={{ width: "100%" }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={2} justifyContent={"center"}>
                  <FontAwesomeIcon
                    size={"4x"}
                    icon={icon({ style: "duotone", name: "calendar-check" })}
                    className={"text-color-primary op5"}
                  />
                </Grid>
                <Grid item xs={10}>
                  <AlertTitle>Job Started</AlertTitle>
                  <p>This job has started:</p>
                  <p>Date: {moment.utc(job.jobStartedDate).local().format("MMMM DD, yyyy")}</p>
                  <p>Time: {moment.utc(job.jobStartedDate).local().format("h:mm a")}</p>
                </Grid>
              </Grid>
            </Alert>
            <br />
          </>
        );
      } else if (job.status === "CLOSED_PENDING_PAYMENT") {
        return (
          <>
            <Alert
              className={"override-dumb-alert"}
              elevation={1}
              severity="primary"
              style={{ width: "100%" }}
            >
              <Grid container alignItems={"center"}>
                <Grid item xs={1} justifyContent={"center"}>
                  <FontAwesomeIcon
                    size={"4x"}
                    icon={icon({ style: "duotone", name: "calendar-check" })}
                    className={"text-color-primary"}
                  />
                </Grid>
                <Grid item xs={11}>
                  <AlertTitle>Job Complete (Pending Payment)</AlertTitle>
                  <p>This job is completed, pending payment from customer</p>
                  <p>Date: {moment.utc(job.jobClosedDate).local().format("MMMM DD, yyyy")}</p>
                  <p>Time: {moment.utc(job.jobClosedDate).local().format("h:mm a")}</p>
                </Grid>
              </Grid>
            </Alert>
            <br />
          </>
        );
      }
    }
  }

  return <></>;
};

class JobsAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        installRequestId: null,
        confirmStartJob: false,
        confirmEndJobComplete: false,
        confirmEndJobIncomplete: false,
        confirmCancelJob: false,
        isSendCustomerMessageOpen: false,
        isAddJobNoteOpen: false,
        isScheduleDateOpen: false,
        isRescheduleDateOpen: false,
        viewOnly: this.props.viewOnlyMode,
        private: true,
        overrideSubmitButtons: true,
        service: {
          recordId: props.recordId,
          entity: "job",
          endpoint: "/jobs/get",
        },
      },
      false
    );

    this.navigate = props.navigate;
    this.addJobNote = this.addJobNote.bind(this);
    this.startJob = this.startJob.bind(this);
    this.completeJob = this.completeJob.bind(this);
    this.incompleteJob = this.incompleteJob.bind(this);
    this.cancelJob = this.cancelJob.bind(this);
    this.sendCustomerMessage = this.sendCustomerMessage.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.ops = new Operations();
  }

  addJobNote(s) {
    let status = true;
    if (typeof s !== "undefined") {
      status = s;
    }

    let state = this.state;
    state.isAddJobNoteOpen = status;
    const newState = StateManager.meld(this, this.state, state);
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let job = "";

    if (this.props.recordId) {
      job = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { job: data };
        }.bind(this)
      );
    } else {
      job = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([job]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "job",
        loaderStyle: "job",
        installRequestId: viewData.job.request.id,
        installScheduled: !!viewData.job.job.scheduledInstallDate,
        isJobStarted: !!viewData.job.job.jobStartedDate,
        isJobClosed: !!viewData.job.job.jobClosedDate,
        service: {
          endpoint: "/jobs/get",
          redirectTarget: "/dashboard",
          entity: "job",
        },
        labels: {
          submit: "Save Job",
          submitting: "Saving Job",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          changeType: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Change Type",
            label: "Job Change Type",
            errors: [],
            vr: {},
          },
          isCustomerNotificationSent: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Is Customer Notification Sent",
            label: "Job Is Customer Notification Sent",
            errors: [],
            vr: {},
          },
          isDeleted: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Is Deleted",
            label: "Job Is Deleted",
            errors: [],
            vr: {},
          },
          isElectricianNotificationSent: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Is Electrician Notification Sent",
            label: "Job Is Electrician Notification Sent",
            errors: [],
            vr: {},
          },
          isModified: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Is Modified",
            label: "Job Is Modified",
            errors: [],
            vr: {},
          },
          paymentTotal: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Payment Total",
            label: "Job Payment Total",
            errors: [],
            vr: {},
          },
          quoteId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Quote Id",
            label: "Job Quote Id",
            errors: [],
            vr: {},
          },
          requiredDeposit: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Required Deposit",
            label: "Job Required Deposit",
            errors: [],
            vr: {},
          },
          responseId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Response Id",
            label: "Job Response Id",
            errors: [],
            vr: {},
          },
          scheduledInstallDate: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Scheduled Install Date",
            label: "Job Scheduled Install Date",
            errors: [],
            vr: {},
          },
          status: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Job Status",
            label: "Job Status",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.job, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  sendCustomerMessage(show) {
    const state = this.state;

    let ns = {
      isSendCustomerMessageOpen: show,
    };

    const newState = StateManager.meld(this, state, ns);
  }

  showScheduleJob(show) {
    const state = this.state;

    let ns = {
      isScheduleDateOpen: show,
    };

    const newState = StateManager.meld(this, state, ns);
  }

  showRescheduleJob(show) {
    const state = this.state;

    let ns = {
      isRescheduleDateOpen: show,
    };

    const newState = StateManager.meld(this, state, ns);
  }

  amountDue() {
    let paidStatus = "warning";

    if (this.state.viewData.job.amounts.amountRemaining === 0.0) {
      paidStatus = "success";
    }

    if (paidStatus === "warning") {
      return (
        <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={paidStatus}>
          <SuiTypography fontSize={".8em"} color={"dark"}>
            Amount Due
          </SuiTypography>
          <SuiTypography fontSize={"1.5em"} color={"dark"}>
            ${this.state.viewData.job.amounts.amountRemaining}
          </SuiTypography>
        </SuiBox>
      );
    } else if (paidStatus === "success") {
      return (
        <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={paidStatus}>
          <SuiTypography fontSize={".8em"} color={"dark"}>
            PAID IN FULL
          </SuiTypography>
          <SuiTypography fontSize={"1.5em"} color={"dark"}>
            $0.00
          </SuiTypography>
        </SuiBox>
      );
    }
  }

  depositStatus() {
    let paidStatus = 'dark';

    if (this.state.viewData.job.amounts.amountRemaining === 0) {
      paidStatus = "success";
    }

    let paymentsMade = this.state.viewData.job.amounts.totalPaid;

    return (
      <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={paidStatus}>
        <SuiTypography fontSize={".8em"} color={"light"}>
          Amount Paid
        </SuiTypography>
        <SuiTypography fontSize={"1.5em"} color={"light"}>
          ${paymentsMade}
        </SuiTypography>
      </SuiBox>
    );
  }

  cb() {}

  startJob(s) {
    let status = true;
    if (typeof s !== "undefined") {
      status = s;
    }
    StateManager.meld(this, this.state, {
      confirmStartJob: status,
    });
  }

  completeJob(s) {
    let status = true;
    if (typeof s !== "undefined") {
      status = s;
    }
    StateManager.meld(this, this.state, {
      confirmEndJobComplete: status,
    });
  }

  incompleteJob(s) {
    let status = true;
    if (typeof s !== "undefined") {
      status = s;
    }
    StateManager.meld(this, this.state, {
      confirmEndJobIncomplete: status,
    });
  }

  cancelJob(s) {
    let status = true;
    if (typeof s !== "undefined") {
      status = s;
    }
    StateManager.meld(this, this.state, {
      confirmCancelJob: status,
    });
  }

  form() {
    let statusClass = "primary";
    let statusText = "";
    let jobOpened = moment.utc(this.state.viewData.job.job.created).local().format("MM/DD/YYYY hh:mm A");
    let jobClosed = "Not Closed";
    let jobStarted = "";

    switch (this.state.viewData.job.job.status) {
      case "OPEN_STARTED":
        statusClass = "primary";
        statusText = "Started";
        jobClosed = "Still Open";
        jobStarted = moment.utc(this.state.viewData.job.job.jobStartedDate).local().format("MM/DD/YYYY hh:mm A");
        break;
      case "OPEN":
        statusClass = "primary";
        statusText = "Open";
        jobClosed = "Still Open";
        break;
      case "CLOSED_COMPLETE":
        jobClosed = moment.utc(this.state.viewData.job.job.jobClosedDate).local().format("MM/DD/YYYY hh:mm A");
        statusClass = "success";
        statusText = "Closed - Completed";
        break;
      case "CLOSED_INCOMPLETE":
        jobClosed = moment.utc(this.state.viewData.job.job.jobClosedDate).local().format("MM/DD/YYYY hh:mm A");
        statusClass = "danger";
        statusText = "Closed - Not Completed";
        break;
    }

    const addr =   this.state.viewData.job.request.installAddress1 + ", " +
                            this.state.viewData.job.request.installAddress2 + ", " +
                            this.state.viewData.job.request.installCity + ", " +
                            this.state.viewData.job.request.installState + ", " +
                            this.state.viewData.job.request.installZip;


    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="#fff" className="p2" bgColor={statusClass}>
                    <SuiTypography fontSize={"2em"} color={"#fff"}>
                      <Grid container>
                        <Grid item flexGrow={1}>
                          {this.state.viewData.job.customer_user.firstName}{" "}
                          {this.state.viewData.job.customer_user.lastName}
                        </Grid>
                        <Grid item alignSelf={"center"}>
                          <BadgePill status={statusText} color={statusClass}>
                            Job {statusText}
                          </BadgePill>
                        </Grid>
                      </Grid>
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={12}>
                  <SuiBox variant={"contained"} color="#000" className={"p2 mt2"} bgColor={"light"}>
                    Job ID #{this.state.viewData.job.job.id} /&nbsp;
                    <NavLink to={"/install_requests/view/" + this.state.viewData.job.request.id} title={"View the origination request"}>
                      Req ID #{this.state.viewData.job.request.id}
                    </NavLink>
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6} md={2}>
                  <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={"dark"}>
                    <SuiTypography fontSize={".8em"} color={"light"}>
                      Quote Amount
                    </SuiTypography>
                    <SuiTypography fontSize={"1.5em"} color={"light"}>
                      ${this.state.viewData.job.amounts.total}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={6} md={2}>
                  <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={"dark"}>
                    <SuiTypography fontSize={".8em"} color={"light"}>
                      Required Deposit
                    </SuiTypography>
                    <SuiTypography fontSize={"1.5em"} color={"light"}>
                      ${this.state.viewData.job.amounts.depositRequired}
                    </SuiTypography>
                  </SuiBox>
                </Grid>

                <Grid item xs={6} md={2}>
                  {this.depositStatus()}
                </Grid>

                <Grid item xs={6} md={2}>
                  {this.amountDue()}
                </Grid>

              </Grid>

              <br />

              {!this.state.installScheduled ? (
                <>
                  <Alert severity="error">
                    This job has not been scheduled yet, please click the schedule button below to
                    add it to your schedule.
                  </Alert>
                  <br />
                </>
              ) : (!this.state.isJobStarted && !this.state.isJobClosed) ? (
                <>
                  <Alert
                    className={"override-dumb-alert"}
                    elevation={1}
                    severity="primary"
                    style={{ width: "100%" }}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid item xs={1} justifyContent={"center"}>
                        <FontAwesomeIcon
                          size={"4x"}
                          icon={icon({ style: "duotone", name: "calendar-check" })}
                          className={"text-color-primary"}
                        />
                      </Grid>
                      <Grid item xs={11}>
                        <AlertTitle>Job Scheduled</AlertTitle>
                        <p>This job is currently scheduled for installation</p>
                        <p>
                          Date:{" "}
                          {moment.utc(this.state.viewData.job.job.scheduledInstallDate).local().format(
                            "MMMM DD, yyyy"
                          )}
                        </p>
                        <p>
                          Time:{" "}
                          {moment.utc(this.state.viewData.job.job.scheduledInstallDate).local().format(
                            "h:mm a"
                          )}
                        </p>
                      </Grid>
                    </Grid>
                  </Alert>
                  <br />
                </>
              ) : (
                <>
                  <DisplayStatus job={this.state.viewData.job.job} />
                </>
              )}

              <br />

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <SuiBox variant="gradient" color="#fff" className="p2" bgColor={"dark"}>
                      <SuiTypography fontSize={"1.2em"} color={"#fff"}>
                        Job Controls & Actions
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={"mt3 mb3"} bgcolor={"#fafafa"} container>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Grid container>
                      <Grid item xs={12} className={"mt2"}>
                        <Button
                            variant={"contained"}
                            onClick={() => {w
                              this.sendCustomerMessage(true);
                            }}
                            style={{ backgroundColor: "#215891", width: "100%" }}
                        >
                          <FAStack classes={"mr4"} main={"envelope"} sub={"plus-circle"} />
                          Message Customer
                        </Button>
                        <MessageCustomer
                            onComplete={() => {
                              setTimeout(
                                  () => this.sendCustomerMessage(false)
                                  , 1200
                              )
                            }}
                            isOpen={this.state.isSendCustomerMessageOpen}
                            doClose={
                              () => { this.sendCustomerMessage(false); }
                            }
                            installRequestId={this.state.installRequestId}
                            quoteId={this.state.viewData.job.quote.id}
                            jobId={this.state.viewData.job.job.id}
                            installRequestResponseId={this.state.viewData.job.response.id}
                        />
                      </Grid>
                      <Grid item xs={12} className={"mt2"}>
                        {!this.state.installScheduled &&
                        !this.state.isJobClosed &&
                        !this.state.isJobStarted ? (
                            <>
                              <Button
                                  fullWidth
                                  variant={"contained"}
                                  onClick={() => {
                                    this.showScheduleJob(true);
                                  }}
                                  style={{ backgroundColor: "#e5cd19", color: "#7c570a" }}
                              >
                                <FAStack
                                    classes={"mr4"}
                                    innerColor={"#d19b1c"}
                                    main={"calendar-days"}
                                    sub={"circle-plus"}
                                />
                                Schedule Install
                              </Button>
                              <AddScheduledDate
                                  quoteId={this.state.viewData.job.quote.id}
                                  jobId={this.state.viewData.job.job.id}
                                  ops={this.ops}
                                  isOpen={this.state.isScheduleDateOpen}
                                  handleClose={() => {
                                    this.showScheduleJob(false);
                                  }}
                                  onSuccess={this.componentDidMount}
                              />
                              </>
                        ) : (
                            <>
                              <Button
                                  fullWidth
                                  variant={"contained"}
                                  onClick={() => {
                                    this.showRescheduleJob(true);
                                  }}
                                  disabled={this.state.isJobClosed || this.state.isJobStarted}
                                  style={{ backgroundColor: "#e5cd19", color: "#7c570a" }}
                              >
                                <FAStack classes={"mr4"} main={"calendar-days"} sub={"circle-plus"} />
                                Reschedule Install
                              </Button>
                              <RescheduleDate
                                  quoteId={this.state.viewData.job.quote.id}
                                  currentInstallDate={this.state.viewData.job.job.scheduledInstallDate}
                                  jobId={this.state.viewData.job.job.id}
                                  ops={this.ops}
                                  isOpen={this.state.isRescheduleDateOpen}
                                  handleClose={() => {
                                    this.showRescheduleJob(false);
                                  }}
                                  onSuccess={this.componentDidMount}
                              />
                            </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Grid container>
                        <Grid item xs={12} className={"mt2"}>
                          <Button
                              fullWidth
                              variant={"contained"}
                              onClick={this.addJobNote}
                              style={{ backgroundColor: "#558bc3" }}
                          >
                            <FAStack classes={"mr4"} main={"notes"} sub={"plus-circle"} />
                            Add Job Note
                          </Button>
                          <AddJobNote
                              jobId={this.state.recordId}
                              isOpen={this.state.isAddJobNoteOpen}
                              handleClose={() => this.addJobNote(false)}
                          />
                      </Grid>
                        <Grid item xs={12} className={"mt2"}>
                          <Confirmation
                              handleClose={() => this.startJob(false)}
                              data={{
                                jobId: this.state.viewData.job.job.id,
                                action: "MARK_STARTED",
                              }}
                              params={{
                                endpoint: "/jobs/startJob",
                              }}
                              isOpen={this.state.confirmStartJob}
                              onComplete={this.componentDidMount}
                              title={"Please Confirm"}
                              confirmation={"Do you wish to mark this job as started?"}
                          >
                            <Button
                                fullWidth
                                disabled={
                                    !this.state.installScheduled ||
                                    this.state.isJobStarted ||
                                    this.state.isJobClosed
                                }
                                variant={"contained"}
                                onClick={() => this.startJob()}
                                style={{ backgroundColor: "#058dc2" }}
                            >
                              <FontAwesomeIcon icon={icon({ style: "duotone", name: "clock" })} className={"mr2"} />
                              Start Job
                            </Button>
                          </Confirmation>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Grid container>

                        <Grid item xs={12} className={"mt2"}>
                          <Button
                              fullWidth
                              disabled={
                                  !this.state.installScheduled ||
                                  !this.state.isJobStarted ||
                                  this.state.isJobClosed
                              }
                              variant={"contained"}
                              onClick={() => this.navigate(this.state.viewData.job.redirectUrl)}
                              style={{ backgroundColor: "#090" }}
                          >
                            <FAStack classes={"mr4"} main={"clock"} sub={"check-circle"} />
                            Final Payment
                          </Button>

                      </Grid>
                      <Grid item xs={12} className={"mt2"}>
                           <Confirmation
                              handleClose={() => this.cancelJob(false)}
                              data={{
                                jobId: this.state.viewData.job.job.id,
                                action: "MARK_CANCELLED",
                              }}
                              params={{
                                endpoint: "/jobs/cancelJob",
                              }}
                              isOpen={this.state.confirmCancelJob}
                              onComplete={this.componentDidMount}
                              title={"Please Confirm"}
                              confirmation={"Do you wish to cancel this job?"}
                          >
                            <Button
                                fullWidth
                                disabled={this.state.isJobClosed}
                                variant={"contained"}
                                onClick={() => this.cancelJob()}
                                style={{ backgroundColor: "#ca1616" }}
                            >
                              <FAStack
                                  classes={"mr4"}
                                  main={"clock"}
                                  sub={"times-circle"}
                                  innerColor={"#900"}
                              />
                              CANCEL INSTALL
                            </Button>
                          </Confirmation>
                       </Grid>
                    </Grid>
                  </Grid>

                  {/*<Grid item xs={1}>
                                        <Confirmation
                                            handleClose={() => this.incompleteJob(false)}
                                            data={
                                                {
                                                    jobId: this.state.viewData.job.job.id,
                                                    action: "MARK_INCOMPLETE"
                                                }
                                            }
                                            params={
                                                {
                                                    endpoint: "/jobs/incompleteJob"
                                                }
                                            }
                                            isOpen={this.state.confirmEndJobIncomplete}
                                            onComplete={this.componentDidMount}
                                            title={"Please Confirm"}
                                            confirmation={"Do you wish to mark this job as incomplete?"}
                                        >
                                        <Button disabled={!this.state.installScheduled || !this.state.isJobStarted || this.state.isJobClosed} variant={"contained"} onClick={() => this.incompleteJob()} style={{ backgroundColor: "#ca1616" }} >
                                            <FAStack
                                                classes={"mr4"}
                                                main={"clock"}
                                                sub={"times-circle"}
                                                innerColor={"#900"}
                                            />
                                            Refund Payment
                                        </Button>
                                        </Confirmation>
                                    </Grid>*/}

                </Grid>
              </Grid>
              <p>
                <em>
                  Note: Marking a job as completed will initiate close-out of that job. Invoices
                  will be sent to the customer for final collection if necessary.
                </em>
              </p>
              <br />
              <Grid container spacing={"5"}>
                <Grid item xs={12} md={4}>
                  <SuiBox variant="contained" color="light" className="p2" bgColor="dark">
                    Site
                  </SuiBox>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <p className={"p4"}>
                        <strong>Address</strong>
                        <br />
                        {this.state.viewData.job.request.installAddress1}
                        <br />
                        {this.state.viewData.job.request.installAddress2 && (
                          <>
                            {this.state.viewData.job.request.installAddress2}
                            <br />
                          </>
                        )}
                        {this.state.viewData.job.request.installCity},
                        {this.state.viewData.job.request.installState}{" "}
                        {this.state.viewData.job.request.installZip}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={8} className={"pt4"}>
                      <GoogleMap addr={addr} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <SuiBox variant="contained" color="light" className="p2" bgColor="dark">
                    Contact Information
                  </SuiBox>
                  <p className={"p4"}>
                    <strong>Phone:</strong> {this.state.viewData.job.customer.phone}
                    <br />
                    <strong>Email:</strong>{" "}
                    <a href={"mailto:" + this.state.viewData.job.customer_user.email}>
                      {this.state.viewData.job.customer_user.email}
                    </a>
                  </p>
                </Grid>
                <Grid item xs={12} md={5}>
                  <SuiBox variant="contained" color="light" className="p2" bgColor="dark">
                    Job Details
                  </SuiBox>
                  <div className={"job-details"}>
                    <strong>Job Opened: </strong>{" "}
                    {moment.utc(this.state.viewData.job.job.created).local().format("MM/DD/YYYY h:mm A")}
                    <br />
                    <strong>Job Started: </strong>{" "}
                    {(this.state.viewData.job.job.jobStartedDate) ?
                      <>
                        {moment.utc(this.state.viewData.job.job.jobStartedDate).local().format("MM/DD/YYYY h:mm A")}
                      </> :
                        <>Not Started</>
                    }
                    <br />
                    <strong>Job Updated: </strong>{" "}
                    {moment.utc(this.state.viewData.job.job.modified).local().format("MM/DD/YYYY h:mm A")}
                    <br />
                    <strong>Job Closed: </strong> {jobClosed}
                    <br />
                    <strong>Reference Request: </strong>{" "}
                    <NavLink to={"/install_requests/view/" + this.state.viewData.job.request.id}>
                      Install Request #{this.state.viewData.job.request.id}
                    </NavLink>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />
            <Grid item xs={12}>
              <JobTabs
                orderInfo={this.state.viewData.job.order}
                attributes={this.state.viewData.job.attrs}
                notes={this.state.viewData.job.notes}
                quote={this.state.viewData.job.quote}
                lineItems={this.state.viewData.job.lineItems}
                attachments={this.state.viewData.job.request.installRequestAttachmentsList}
                communication={this.state.viewData.job.communication}
                timeline={this.state.viewData.job.timeline}
                customer={this.state.viewData.job.customer_user}
              />
            </Grid>
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {



    return <>{this.wrapper()}</>;
  }
}

export default withRouter(JobsAddEditForm);
