import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextAreaElementUncontrolled from "../../Reusable/Forms/Elements/TextAreaElementUncontrolled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Operations from "../../Reusable/Controller/Operations";

export const AddJobNote = (props) => {
  const { isOpen, jobId } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [body, setBody] = useState(null);

  const ops = new Operations();

  const onchange = (v, n) => {
    setBody(v.value);
  };

  const onblur = (v, n) => {
    setBody(v.value);
  };

  const doAddNote = () => {
    if (isSaved) {
      handleClose();
      return;
    }

    setIsSaving(true);

    ops
      .singleSave(
        {
          jobId: jobId,
          body: body,
        },
        {
          endpoint: "/job_notes/save",
        }
      )
      .then((results) => {
        if (results.getIsSuccess()) {
          setIsSaved(true);
          setIsSaving(false);
          setTimeout(
              () => {
                props.handleClose();
              }, 1200
          );
        } else {
          setIsError(true);
          setIsSaving(false);
        }
      })
      .catch((error) => {
        setIsSaving(false);
        setIsError(true);
      });
  };

  const handleClose = (e, r) => {
    if (r && r === "backdropClick") {
      return;
    }

    props.handleClose();
  };

  return (
    <div>
      <Dialog maxWidth={"lg"} fullWidth open={props.isOpen} onClose={handleClose}>
        <DialogTitle className={"bg-primary mb3"}>Add New Job Note</DialogTitle>
        <DialogContent>
          <DialogContentText>Use this to add a note to this Job</DialogContentText>
          <br />

          <TextAreaElementUncontrolled
            isRequired={true}
            name={"body"}
            onChange={onchange}
            onBlur={onblur}
            label={"Content"}
          />
        </DialogContent>
        <DialogActions className={"bg-primary"}>
          <Button
            disabled={isSaving}
            onClick={doAddNote}
            color="primary"
            className={"nav-header-button"}
          >
            {isSaving === true ? (
              <>
                <FontAwesomeIcon
                  icon={icon({ style: "duotone", name: "spinner" })}
                  spin
                  fixedWidth
                />
                &nbsp;Saving...
              </>
            ) : isSaved ? (
              <>
                <FontAwesomeIcon
                  icon={icon({ style: "duotone", name: "check-circle" })}
                  fixedWidth
                />
                &nbsp;Saved
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={icon({ style: "duotone", name: "floppy-disk" })}
                  fixedWidth
                />
                &nbsp;Save Note
              </>
            )}
          </Button>
          &nbsp;&nbsp;
          { !isSaved &&
          <Button
            disabled={isSaving}
            color="light"
            onClick={handleClose}
            className={"nav-header-button"}
          >
            <FontAwesomeIcon icon={icon({ style: "duotone", name: "times-circle" })} fixedWidth />
            &nbsp; Cancel
          </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};
