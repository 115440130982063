import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextAreaElementUncontrolled from "../../Reusable/Forms/Elements/TextAreaElementUncontrolled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Operations from "../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PreviewInvoice from "./CloseJob/PreviewInvoice";
import Loader from "../../Reusable/Loader";
import CollectPayment from "./CloseJob/CollectPayment";
import hash from "object-hash";
import {loadStripe} from "@stripe/stripe-js";

export const CloseJob = (props) => {
  const { jobId, state, irqId } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isError, setIsError] = useState(false);
  const [body, setBody] = useState(null);
  const [currentScreen, setCurrentScreen] = useState('home');



  const goBack = (props) => {
    switch(currentScreen) {
      case 'paynow':
      case 'sendinvoice':
        setCurrentScreen('home');
        break;
      case 'paymentscreen':
        setCurrentScreen('paynow');
        break;
    }
  }


  const [ clientSecret, setClientSecret ] = useState('');
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ redirectUrl, setRedirectUrl ] = useState(null);

  async function pd(url, data) {
    const response = await fetch(url, {
      method: 'POST',
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data)
    })

    return response;
  }

  useEffect(() => {
    fetch("/zev.hub/api/pub/v1/checkout/get_stripe_config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const appearance = {
    theme: 'flat',
    labels: 'above',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#f3f3f3',
      colorText: '#30313d',
      gridRowSpacing: "15px",
      gridColumnSpacing: "10px",
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px'
    },
    rules: {
      '.Button': {
        border: '4px solid #000'
      }
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    pd("/zev.hub/api/pub/v1/checkout/get_final_payment_intent", {
      contractorId: contractorId,
      installRequestId: installRequestId,
      quoteId: quoteId,
      quoteHash: quoteHash,
      quoteToken: quoteToken
    })
        .then((res) => res.json())
        .then(({clientSecret, redirectUrl}) => {
          setClientSecret(clientSecret);
          setRedirectUrl(redirectUrl);
        });
  }, []);



  let prodTotal = 0;

  state.viewData.quote.quote.quoteLineitemsList.map((item) => {
    if (!state._data.selectedLineItems.hasOwnProperty(item.id)) {
      return;
    }

    prodTotal += parseFloat(item.quantity) * parseFloat(item.unitPrice);
  });

  let subTotal = prodTotal * (depositPct/100);

  if (state.settings.QUOTE_DEPOSIT_LIMIT_ENABLE === 'YES') {
    if (subTotal > state.settings.QUOTE_DEPOSIT_MAXIMUM) {
      subTotal = state.settings.QUOTE_DEPOSIT_MAXIMUM;
    } else if (subTotal < state.settings.QUOTE_DEPOSIT_MINIMUM) {
      subTotal = state.settings.QUOTE_DEPOSIT_MINIMUM;
    }
  }






  const selectPayNow = (props) => {
    setCurrentScreen('paynow');
  }

  const selectSendInvoice = (props) => {
    setCurrentScreen('sendinvoice');
  }

  const ops = new Operations();

  const onchange = (v, n) => {
    setBody(v.value);
  };

  const onblur = (v, n) => {
    setBody(v.value);
  };



  const goToPaymentScreen = () => {
    setCurrentScreen('paymentscreen');
  }



  const handleClose = (e, r) => {
    if (r && r === "backdropClick") {
      return;
    }

    props.handleClose();
  };


  return (
      <div>
        <Dialog maxWidth={"lg"} fullWidth open={props.isOpen} onClose={handleClose}>
          <DialogTitle className={"bg-primary mb3"}>Close Out Job (Collect Payment)</DialogTitle>

          {(currentScreen === 'home') &&
              <>
            <DialogContent>
              <DialogContentText>Collect payment now, or send invoice to customer?</DialogContentText>
              <br />
              <Grid container>
                <Grid item xs={5} style={{ display: 'flex', justifyContent: "flex-end" }}>
                  <Button color={"success"} className={"shiny-button glower"} style={{alignSelf: "center"}} onClick={selectPayNow}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FontAwesomeIcon icon={icon({ style: "duotone", name: "credit-card" })} className={"fontsize-40"} />
                      </Grid>
                      <Grid item xs={12}>
                        <br/>
                        <Typography fontSize={"14px"}>
                        Collect Payment Now
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5} style={{ display: 'flex', justifyContent: "flex-start" }}>
                  <Button color={"warning"} className={"shiny-button glower"} style={{alignSelf: "center"}} onClick={selectSendInvoice}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FontAwesomeIcon icon={icon({ style: "duotone", name: "envelope" })} className={"fontsize-40"} />
                      </Grid>
                      <Grid item xs={12}>
                        <br/>
                        <Typography fontSize={"14px"}>
                        Send Invoice
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={"bg-primary"}>
              <Button
                  color="light"
                  onClick={handleClose}
                  className={"nav-header-button"}
              >
                <FontAwesomeIcon icon={icon({ style: "duotone", name: "times-circle" })} fixedWidth />
                &nbsp; Cancel
              </Button>

            </DialogActions>
          </>
          }

          {
            (currentScreen === 'paynow') &&
              <>
              <DialogContent>
                <DialogContentText>Invoice Review</DialogContentText>
                <br />

                <Loader isLoaded={!isSaving} style={"six"} loadingMessage={"Loading Job Details"}>
                  <PreviewInvoice jobId={jobId} irqId={irqId} ops={ops} />
                </Loader>

              </DialogContent>
                <DialogActions className={"bg-primary"}>
                  <Button
                      color="light"
                      onClick={goBack}
                      className={"nav-header-button "}
                  >
                    <FontAwesomeIcon icon={icon({ style: "duotone", name: "chevron-circle-left" })} fixedWidth />
                    &nbsp; Go Back
                  </Button>
                  {
                    (currentScreen === 'paynow') &&
                      <>
                        <Button
                            color="primary"
                            onClick={goToPaymentScreen}
                            className={"nav-header-button"}
                        >
                          <FontAwesomeIcon icon={icon({ style: "duotone", name: "chevron-circle-right" })} fixedWidth />
                          &nbsp; Go to Payment
                        </Button>
                      </>
                  }
                  <Button
                      color="light"
                      onClick={handleClose}
                      className={"nav-header-button"}
                  >
                    <FontAwesomeIcon icon={icon({ style: "duotone", name: "times-circle" })} fixedWidth />
                    &nbsp; Cancel
                  </Button>
                </DialogActions>
              </>
          }
          {
            (currentScreen === 'paymentscreen') &&
              <>
              <DialogContent>
                <DialogContentText>Process customer credit payment here</DialogContentText>
                <br />

                <Loader isLoaded={!isSaving} style={"six"} loadingMessage={"Loading Job Details"}>

                </Loader>

              </DialogContent>
                <DialogActions className={"bg-primary"}>
                  <Button
                      color="light"
                      disabled={isSaving}
                      onClick={goBack}
                      className={"nav-header-button "}
                  >
                    <FontAwesomeIcon icon={icon({ style: "duotone", name: "chevron-circle-left" })} fixedWidth />
                    &nbsp; Go Back
                  </Button>
                  {
                    (currentScreen === 'paymentscreen') &&
                      <>
                        <Button
                            color="primary"
                            disabled={isSaving}
                            onClick={submitPayment}
                            className={"nav-header-button"}
                        >
                          {
                              (!isSaving) ? <>
                                <FontAwesomeIcon icon={icon({ style: "duotone", name: "dollar-square" })} fixedWidth />
                                &nbsp; Submit Payment
                              </> :
                                  <>
                                    <FontAwesomeIcon icon={icon({ style: "duotone", name: "refresh" })} spin={true} fixedWidth />
                                    &nbsp; Submitting...
                                  </>
                          }
                        </Button>
                      </>
                  }
                  <Button
                      color="light"
                      disabled={isSaving}
                      onClick={handleClose}
                      className={"nav-header-button "}
                  >
                    <FontAwesomeIcon icon={icon({ style: "duotone", name: "times-circle" })} fixedWidth />
                    &nbsp; Cancel
                  </Button>
                </DialogActions>
              </>
          }

          {
            (currentScreen === 'sendinvoice') &&
              <>


              </>
          }



        </Dialog>
        {props.children}
      </div>
  );
};
