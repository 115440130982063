import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SettingsListView from "../../../pages/Views/core/Settings/SettingsListView";

const SettingsRoute = {
  type: "collapse",
  name: "Settings",
  sidenav: true,
  order: 2,
  key: "admin-settings",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Manage",
      subtitle: "Dashboard",
      sidenav: true,
      key: "settings-list",
      route: "/settings",
      icon: <FontAwesomeIcon icon={["fad", "gears"]} fixedWidth={true} />,
      component: <SettingsListView />,
    },
  ],
};

export default SettingsRoute;
