import React, { useEffect, useState } from "react";
import { CCFieldWrap } from "../Forms/CCFieldWrap";
import FieldValidationErrors from "../Forms/FieldValidationErrors";

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();
let MONTHS = {},
  YEARS = [CURRENT_YEAR];
for (let i = 1; i <= 12; i++) {
  MONTHS[i] = i.toString().length === 1 ? `0${i}` : i.toString();
  YEARS.push(YEARS[0] + i);
}

const ImgCardBack = (props) => {
  const { cb } = props;

  const [cardBack, setCardBack] = useState([]);

  useEffect(() => {
    const fetchImage = async () => {
      const response = await fetch("https://source.unsplash.com/collection/8497941/430x270");
      const url = await response.url;

      setCardBack(url);
    };

    fetchImage();
  }, []);

  return (
    <img
      className="card cover"
      src={cardBack}
      onLoad={() => {
        cb();
      }}
    />
  );
};

class CreditCard extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      sliderLocation: "",
      cardNumber: props.state.data.card.number.value,
      cardName: props.state.data.card.name.value,
      cardMonth: props.state.data.card.expMonth.value,
      cardYear: props.state.data.card.expYear.value,
      cardCvv: props.state.data.card.cvv.value,
      cardZip: props.state.data.card.zip.value,
      cardType: "visa",
      toggleMonth: true,
      toggleYear: true,
      showCard: false,
      cardFlipped: false,
    };

    this.parentHandleChange = props.handleChange.bind(this);
  }

  handleChange = (event, type) => {
    let { value } = event.target;

    if (type === "cardNumber") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        return;
      } else {
        const cardType = this.getCardType(value);
        this.setState({ [type]: value, cardType });
      }
    } else if (type === "cardName") {
      var regName = /^[a-zA-Z\s]*$/;
      if (!regName.test(value)) {
      } else {
        this.setState({ [type]: value });
      }
    } else if (type === "cardMonth") {
      value = Number(value);
      this.setState((prevState) => ({
        [type]: value,
        toggleMonth: !prevState.toggleMonth,
      }));
    } else if (type === "cardYear") {
      value = Number(value);
      const { cardMonth } = this.state;
      if (value === CURRENT_YEAR && cardMonth <= CURRENT_MONTH) {
        this.setState((prevState) => ({
          cardMonth: 0,
          cardYear: value,
          toggleYear: !prevState.toggleYear,
          toggleMonth: !prevState.toggleMonth,
        }));
      } else {
        this.setState((prevState) => ({
          [type]: value,
          toggleYear: !prevState.toggleYear,
        }));
      }
    } else if (type === "cardCvv") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        return;
      } else {
        this.setState({ [type]: value });
      }
    } else if (type === "cardZip") {
      value = value.replace(/ /gi, "");
      if (isNaN(value)) {
        return;
      } else {
        this.setState({ [type]: value });
      }
    }
    this.parentHandleChange(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  canSubmit = () => {
    const { cardNumber, cardName, cardMonth, cardYear, cardCvv } = this.state;

    return (
      cardNumber.length === 16 &&
      cardName.length > 4 &&
      cardCvv.length === 3 &&
      cardMonth !== 0 &&
      cardYear !== 0
    );
  };

  moveSlider = (event, position) => {
    position = ["year", "month"].includes(position) ? "expiration" : position;
    this.setState({ sliderLocation: position });
  };

  setFocus = (event, type) => {
    let { sliderLocation } = this.state;

    if (event.target.className.includes("year")) {
      event.stopPropagation();
    }
    this[`${type}Input`].focus();
  };

  handleClick = (event) => {
    if (!this.cvvInput.contains(event.target)) {
      this.setState({ cardFlipped: false });
    }
    if (
      this.nameCard.contains(event.target) ||
      this.nameInput.contains(event.target) ||
      this.numberCard.contains(event.target) ||
      this.numberInput.contains(event.target) ||
      this.expirationCard.contains(event.target) ||
      this.monthInput.contains(event.target) ||
      this.yearInput.contains(event.target) ||
      this.zipInput.contains(event.target)
    )
      return;
    this.setState({ sliderLocation: "" });
  };

  // HELPERS
  formatCardNumber = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,16}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  getCardType = (number) => {
    let re = new RegExp("^4");
    if (number.match(re) != null) return "visa";
    re = new RegExp("^(34|37)");
    if (number.match(re) != null) return "amex";
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null) return "mastercard";
    re = new RegExp("^6011");
    if (number.match(re) != null) return "discover";
    return "visa";
  };

  render() {
    const {
      cardNumber,
      cardName,
      cardMonth,
      cardYear,
      cardCvv,
      cardZip,
      cardType,
      sliderLocation,
      toggleMonth,
      toggleYear,
      showCard,
      cardFlipped,
    } = this.state;

    let displayNumber = [];

    if (cardType === "amex") {
      for (let i = 0; i < 14; i++) {
        let displayDigit = "#";
        if (typeof cardNumber[i] !== "undefined") {
          displayDigit = i > 3 && i < 11 ? "*" : cardNumber[i];
        }
        displayNumber.push(displayDigit);
      }
    } else {
      for (let i = 0; i < 16; i++) {
        let displayDigit = "#";
        if (typeof cardNumber[i] !== "undefined") {
          displayDigit = i > 3 && i < 12 ? "*" : cardNumber[i];
        }
        displayNumber.push(displayDigit);
      }
    }

    const canSubmit = !this.canSubmit();

    return (
      <div className="card-form" onClick={this.handleClick}>
        <div className={`card container ${showCard ? "show" : ""}`}>
          <div className={`card inner ${cardFlipped ? "flipped" : ""}`}>
            <div className="front">
              <ImgCardBack
                cb={() => {
                  this.setState({ showCard: true });
                }}
              />
              <div className="card overlay" />
              <div
                className={`card slider ${sliderLocation.length > 0 ? `on-${sliderLocation}` : ""}`}
              />
              <div className="card content">
                <div className="chip" />
                <div className={`type ${cardType}`} />
                <div
                  className="number"
                  onClick={(event) => this.setFocus(event, "number")}
                  ref={(node) => (this.numberCard = node)}
                >
                  {displayNumber.map((digit, index) => (
                    <div className="digit-wrapper" key={index}>
                      <div className={digit === "#" ? "digit shown" : "digit hidden"}>#</div>
                      <div className={digit === "#" ? "digit hidden" : "digit shown"}>
                        {digit === "#" ? "" : digit}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="name"
                  onClick={(event) => this.setFocus(event, "name")}
                  ref={(node) => (this.nameCard = node)}
                >
                  <label htmlFor="name">Card Holder</label>
                  <div id="name">
                    <div className={`placeholder ${cardName.length > 0 ? "hidden" : "shown"}`}>
                      FULL NAME
                    </div>
                    <div className="name-container">
                      {cardName.split("").map((char, index) => (
                        <div className={`character ${/\s/.test(char) ? "space" : ""}`} key={index}>
                          {char}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  className="expiration"
                  onClick={(event) => this.setFocus(event, "month")}
                  ref={(node) => (this.expirationCard = node)}
                >
                  <label htmlFor="expiration">Expires</label>
                  <div id="expiration">
                    <div className={`double-digit ${toggleMonth ? "toggle1" : "toggle2"}`}>
                      {cardMonth === 0 ? "MM" : `${cardMonth + 100}`.slice(-2)}
                    </div>
                    <div className="double-digit">/</div>
                    <div
                      className={`year double-digit ${toggleYear ? "toggle1" : "toggle2"}`}
                      onClick={(event) => this.setFocus(event, "year")}
                    >
                      {cardYear === 0 ? "YY" : `${cardYear}`.slice(-2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card cover back">
              <ImgCardBack
                cb={() => {
                  this.setState({ showCard: true });
                }}
              />
              <div className="card overlay" />
              <div className="card content">
                <div className={"stripe"} />
                <div className={"card-back-wrapper"}>
                  <div className={"cvv"}>
                    <div className={"dots"}>{this.state.cardName}</div>
                    <div className={"code"}>{this.state.cardCvv}</div>
                  </div>
                  <div className={`type inline ${cardType}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-inputs">
          <div className="card-form-wrapper">
            <CCFieldWrap
              className={
                (this.props.fields["number"].errors.length ? " field-error " : "") + " lg-input "
              }
              field={this.props.fields["number"]}
            >
              <label htmlFor="cardNumber"> Card Number</label>
              <div className={"input-wrapper"}>
                <input
                  className="number-input"
                  id="cardNumber"
                  type="text"
                  name={"card.number"}
                  onChange={(event) => this.handleChange(event, "cardNumber")}
                  onFocus={(event) => {
                    this.setState({ cardFlipped: false });
                    this.moveSlider(event, "number");
                  }}
                  onSelect={(event) => this.moveSlider(event, "number")}
                  value={this.formatCardNumber(cardNumber)}
                  ref={(node) => (this.numberInput = node)}
                  maxLength="19"
                />
                <FieldValidationErrors fieldErrors={this.props.fields["number"].errors} />
              </div>
            </CCFieldWrap>

            <CCFieldWrap
              className={
                (this.props.fields["name"].errors.length ? " field-error " : "") + " lg-input "
              }
              field={this.props.fields["name"]}
            >
              <label htmlFor="cardName">Cardholders Name</label>
              <div className={"input-wrapper"}>
                <input
                  className="name-input"
                  id="cardName"
                  name={"card.name"}
                  type="text"
                  onChange={(event) => this.handleChange(event, "cardName")}
                  onFocus={(event) => {
                    this.setState({ cardFlipped: false });
                    this.moveSlider(event, "name");
                  }}
                  ref={(node) => (this.nameInput = node)}
                  value={cardName}
                  maxLength="24"
                />
                <FieldValidationErrors fieldErrors={this.props.fields["name"].errors} />
              </div>
            </CCFieldWrap>
            <CCFieldWrap
              className={
                (this.props.fields["expMonth"].errors.length ||
                this.props.fields["expYear"].errors.length
                  ? " field-error "
                  : "") + " med-input "
              }
              field={this.props.fields["expYear"]}
            >
              <label htmlFor="cardMonth">Expiration Date</label>

              <div className={"select input-wrappers"}>
                <div className={"select input-wrapper"}>
                  <select
                    className="month-input"
                    id="cardMonth"
                    name={"card.expMonth"}
                    value={cardMonth}
                    onChange={(event) => this.handleChange(event, "cardMonth")}
                    onFocus={(event) => {
                      this.setState({ cardFlipped: false });
                      this.moveSlider(event, "month");
                    }}
                    ref={(node) => (this.monthInput = node)}
                  >
                    {" "}
                    <option value="0" disabled>
                      Month
                    </option>
                    {Object.keys(MONTHS).map((monthKey) => (
                      <option
                        key={monthKey}
                        value={monthKey}
                        disabled={cardYear === CURRENT_YEAR && Number(monthKey) <= CURRENT_MONTH}
                      >
                        {MONTHS[monthKey]}
                      </option>
                    ))}
                  </select>
                  <FieldValidationErrors
                    fieldErrors={this.props.fields["expMonth"].errors}
                    short={true}
                  />
                </div>
                <div className={"select input-wrapper"}>
                  <select
                    className="year-input"
                    id="cardYear"
                    name={"card.expYear"}
                    value={cardYear}
                    onChange={(event) => this.handleChange(event, "cardYear")}
                    onFocus={(event) => {
                      this.setState({ cardFlipped: false });
                      this.moveSlider(event, "year");
                    }}
                    ref={(node) => (this.yearInput = node)}
                  >
                    {" "}
                    <option value="0" disabled>
                      Year
                    </option>
                    {YEARS.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  <FieldValidationErrors
                    fieldErrors={this.props.fields["expYear"].errors}
                    short={true}
                  />
                </div>
              </div>
            </CCFieldWrap>
            <CCFieldWrap
              className={
                (this.props.fields["cvv"].errors.length ? " field-error " : "") + " sm-input "
              }
              field={this.props.fields["cvv"]}
            >
              <label htmlFor="cardCvv">CVV</label>
              <div className={"input-wrapper"}>
                <input
                  className="cvv-input"
                  name={"card.cvv"}
                  id="cardCvv"
                  onChange={(event) => this.handleChange(event, "cardCvv")}
                  onSelect={() => this.setState({ cardFlipped: true })}
                  onFocus={() => {
                    this.setState({ cardFlipped: true });
                    this.moveSlider(event, "");
                  }}
                  ref={(node) => (this.cvvInput = node)}
                  maxLength={this.state.cardType === "amex" ? 4 : 3}
                  value={cardCvv}
                />
                <FieldValidationErrors fieldErrors={this.props.fields["cvv"].errors} short={true} />
              </div>
            </CCFieldWrap>
            <CCFieldWrap
              className={
                (this.props.fields["zip"].errors.length ? " field-error " : "") + " zip-input "
              }
              field={this.props.fields["zip"]}
            >
              <label htmlFor="cardCvv">ZIP</label>
              <div className={"input-wrapper"}>
                <input
                  className="zip-input"
                  name={"card.zip"}
                  id="cardZip"
                  onChange={(event) => this.handleChange(event, "cardZip")}
                  onSelect={() => this.setState({ cardFlipped: false })}
                  onFocus={() => {
                    this.setState({ cardFlipped: false });
                    this.moveSlider(event, "");
                  }}
                  ref={(node) => (this.zipInput = node)}
                  maxLength="5"
                  value={cardZip}
                />
                <FieldValidationErrors
                  fieldErrors={this.props.fields["zip"].errors}
                  short={false}
                />
              </div>
            </CCFieldWrap>
          </div>
        </div>
      </div>
    );
  }
}

export default CreditCard;
