import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardContentHeader } from "../../../../../Reusable/Misc";
import { CardContent } from "@mui/material";
import SuiTypography from "../../../../../components/SuiTypography";
import { GhostComplete } from "../../../../../ZevModules/GhostComplete";
import TextElement from "../../../../../Reusable/Forms/Elements/TextElement";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Headers = (props) => {
  const { state, setContractor, assignContractor } = props;

  return (
    <>
      <Grid container style={{ marginBottom: "40px" }}>
        <Grid item md={8}>
          <Card
            variant={"elevation"}
            style={{ borderRadius: '5px', backgroundColor: "rgba(0,124,190,0.13)", border: "1px solid #004990" }}
          >
            <CardContentHeader color={"primary"}>Unassigned Request</CardContentHeader>
            <CardContent>
              <br />
              <SuiTypography color={"rgb(120,0,0)"}>
                This request is not assigned to a contractor yet, please assign it now.
              </SuiTypography>
              <Grid item md={12} style={{ marginTop: 20, textAlign: "right" }}>
                <Grid container>
                  <Grid item md={4} />
                  <Grid item md={7} style={{ textAlign: "left" }}>
                    <Grid container>
                      <Grid item md={7}>
                        <GhostComplete
                          path={"/contractors/search"}
                          field={"contractor"}
                          filterLabel={"Find Contractor"}
                          setValue={setContractor}
                        >
                          <TextElement
                            readonly={state.viewOnly}
                            type="text"
                            name="contractor"
                            size="medium"
                            field={state.data.contractorId}
                            displayValue={state.data.contractorName}
                          />
                        </GhostComplete>
                      </Grid>
                      <Grid item md={5}>
                        <div className="" style={{ marginLeft: '15px' }}>
                          <label className="form-label" style={{marginBottom: '14px'}}>&nbsp;</label>
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            type={"button"}
                            onClick={assignContractor}
                          >
                            <FontAwesomeIcon
                              icon={["fad", "check-circle"]}
                              className="text-color-white"
                            />{" "}
                            &nbsp; Save Contractor
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
