import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

// do signout

const AuthSignOut = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate("/auth/logout");
  }, []);

}

export default AuthSignOut;
