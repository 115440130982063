import React, {createRef, useRef} from "react";
import ZevForm from "../ZevForm";
import withRouter from "../../../withRouter";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Operations from "../../../Reusable/Controller/Operations";
import { mapData } from "../../../Reusable/Misc";
import { StateManager } from "../../../Reusable/Core";
import SuiTypography from "../../../components/SuiTypography";
import { AcceptActionButtons } from "./Modules/AcceptActionButtons";
import { QuotePayment } from "./Modules/QuotePayment";
import { validateField } from "../../../Reusable/Validation";
import {FinalPayment} from "./Modules/FinalPayment";

class FinalPaymentProcessForm extends ZevForm {
  constructor(props) {
    super(props);


    this.refForChild = createRef();


    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: false,
        service: {
          recordId: props.recordId,
          entity: "job",
          endpoint: "checkout/get_quote_data",
          apiPrefix: "/api/pub/v1/",
        },
        labels: {},
        status: {
          isPaymentSubmitting: false,
          isPaymentSubmitted: false,
          isPaymentSubmitFailed: false,
        },
      },
      false
    );

    this.ops = new Operations();

    this.localChangeHandler = this.localChangeHandler.bind(this);
    this.cancel = this.cancel.bind(this);
    this.goBackToReview = this.goBackToReview.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.handleCardFocus = this.handleCardFocus.bind(this);
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */


    let job = "";

    if (this.props.params.job_id) {
      const params = {
        jobId: this.props.params.job_id,
        contractorId: this.props.params.contractor_id,
        customerId: this.props.params.customer_id,
        installRequestId: this.props.params.request_id,
        token: this.props.params.job_token,
      };

      job = this.ops.getRecord(this, null, params, null, this.props.entity).then(
        function (data) {
          console.log(data);
          return { job: data };
        }.bind(this)
      );
    } else {
      job = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([job]).then((values) => {
      let viewData = {};

      console.log(values);

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }


      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        recordId: this.props.quote_id,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "quoteReview",
        loaderStyle: "installRequest",
        service: {
          endpoint: "/checkout/submit_payment",
          redirectTarget: "/quote/checkout",
          entity: "quotePayment",
          apiPrefix: "pub",
        },
        openModal: {},
        labels: {
          submittingPayment: "Processing Payment",
          submitPayment: "Process Payment",
          submittedPayment: "Paid",
          submitPaymentFailed: "Submit Payment Failed",
        },
        viewData: viewData,
        _data: {
          quoteId: this.props.quote_id,
          quoteHash: this.props.hash,
          quoteToken: this.props.token,
          contractorId: this.props.contractor_id,
          installRequestId: this.props.request_id,
        },
        data: {},
      });

      let ns = mapData(o.viewData.quote, o);

      // ns._data.quoteId = this.props.quote_id;
      // ns._data.quoteHash = this.props.hash;
      // ns._data.quoteToken = this.props.token;
      // ns._data.installRequestId = this.props.request_id;
      // ns._data.contractorId = this.props.contractor_id;

      this.setState(ns, () => {
        this.mounted();
      });
    });

    this.onSuccess = this.onSuccess.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  parseLineItems(items) {
    let filteredItems = {};

    Object.keys(items).map((itemKey) => {
      if (parseInt(items[itemKey].selectedLineItem) === 1) {
        filteredItems[itemKey] = items[itemKey];
      }
    });

    return filteredItems;
  }

  cb() {}

  goBackToReview() {
    const navigate = this.props.navigate;

    const url =
      "/quote/review/" +
      this.props.contractor_id +
      "/" +
      this.props.request_id +
      "/" +
      this.props.quote_id +
      "/" +
      this.props.hash +
      "/" +
      this.props.token;

    navigate(url, {
      state: {
        vd: {
          selectedLineItems: this.props.location.state.vd.selectedLineItems,
        },
      },
    });
  }

  cancel() {
    const navigate = this.props.navigate;
    navigate("/auth/login");
  }

  onSuccess() {
    var state = this.state;
    state.status.isPaymentSubmitting = false;
    state.status.isPaymentSubmitted = true;
    state.status.isPaymentSubmitFailed = false;

    StateManager.meld(this, this.state, state);
  }

  onFailure() {
    var state = this.state;
    state.status.isPaymentSubmitting = false;
    state.status.isPaymentSubmitted = false;
    state.status.isPaymentSubmitFailed = false;

    StateManager.meld(this, this.state, state);
  }

  async submitPayment(ev) {

    var state = this.state;

    state.status.isPaymentSubmitting = true;
    StateManager.meld(this, this.state, state);
    const {error} = await this.refForChild.current.processPayment();

    if (error !== null) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        let lstate = state;
        lstate.error = error.message;
        state.status.isPaymentSubmitting = false;
        StateManager.meld(this, this.state, lstate);
      } else {
        state.status.isPaymentSubmitting = false;
        StateManager.meld(this, this.state, lstate);

      }

    }

    return;

    var state = this.state;
    var errors = [];
    var valid = true;

    state.status.isPaymentSubmitting = true;

    Object.keys(this.state.data.card).map((cardKey) => {
      const p = validateField(this, this.state.data.card[cardKey]);
      if (!p) {
        valid = false;
      }
    });

    if (!valid) {
      state.status.isPaymentSubmitting = false;
      StateManager.meld(this, this.state, state);
    } else {
      this.handleSubmit(ev, this.onSuccess, this.onFailure);

      return;




    }
  }

  handleCardFocus(e) {
    const ns = {
      cardFocus: e.target.name,
    };

    StateManager.meld(this, this.state, ns);
  }

  localChangeHandler(ev, e) {
    ev.target = e;

    this.handleChange(ev);

    if (this.state.initialized === true) {
      // StateManager.meld(this, this.state, {totalCostOfQuote: total});
    }
  }

  form() {
    let total = 0;


    return (
      <>
        {this.state.initialized === true ? (
          <Box sx={{ mb: 3 }}>
            <Box sx={{ m: 3 }}>
              <SuiTypography fontSize={"20px"} variant={"h2"} color={"primary"}>
                <strong>Payment Details</strong>
              </SuiTypography>

              <FinalPayment
                  refForChild={this.refForChild}
                  state={this.state}
                  handleBlur={this.handleBlur}
                  handleCardFocus={this.handleCardFocus}
                  handleChange={this.handleChange}
              />
            </Box>

            <Box sx={{ m: 0 }}>
              <AcceptActionButtons
                isProcessing={this.state.status.isPaymentSubmitting}
                isProcessed={this.state.status.isPaymentSubmitted}
                isFailed={this.state.status.isPaymentSubmitFailed}
                goBackToReview={this.goBackToReview}
                cancelPayment={this.cancelPayment}
                submitPayment={this.submitPayment}
                state={this.state}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  }


  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(FinalPaymentProcessForm);
