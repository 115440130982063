import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CheckoutLayout from "../../layouts/authentication/components/CheckoutLayout";
import SuiTypography from "../../components/SuiTypography";
import logo from "../../assets/img/logos/trans.png";
import QuoteAcceptForm from "../Forms/Checkout/QuoteAcceptForm";

class QuoteAcceptView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_id: props.params.quote_id,
      contractor_id: props.params.contractor_id,
      request_id: props.params.request_id,
      token: props.params.quote_token,
      hash: props.params.quote_hash,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <CheckoutLayout
          illustration={{
            image: logo,
            title: "Installation Request",
            description: "Complete Your Installation",
          }}
        >
          <Grid item xs={12}>
            <Grid container className={"mt20"} spacing={3}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="body2" fontWeight="regular" color="text">
                  <SuiTypography variant="h5" fontWeight="light">
                    Congratulations on choosing to proceed with your installation!
                  </SuiTypography>
                  <SuiTypography variant="h5" fontWeight="light" className={"mt3"}>
                    Please enter your payment information below, once your payment has been
                    processed - the job will be set up and your installation will get scheduled.
                  </SuiTypography>
                  <hr className={"mt4 mb4"} />
                </SuiTypography>

                <QuoteAcceptForm
                  recordId={this.state.recordId}
                  entity={"quoteReview"}
                  pageScope={this}
                  quote_id={this.state.quote_id}
                  contractor_id={this.state.contractor_id}
                  request_id={this.state.request_id}
                  hash={this.state.hash}
                  token={this.state.token}
                />
              </Grid>
            </Grid>
          </Grid>
        </CheckoutLayout>
      </>
    );
  }
}

export default withRouter(QuoteAcceptView);
