import { Paper } from "@mui/material";
import SuiTypography from "../components/SuiTypography";

const BadgePill = (props) => {
  return (
    <>
      <Paper
        elevation={3}
        style={{
          padding: "5px 15px",
          alignSelf: "center",
          justifySelf: "flex-enda",
          backgroundColor: "#004990",
        }}
        variant={"elevation"}
      >
        <SuiTypography fontSize={"13"} color={"white"}>
          {props.children}
        </SuiTypography>
      </Paper>
    </>
  );
};

export default BadgePill;
