import React from "react";
import ZevList from "../../../Forms/ZevList";
import { caseWords, SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class UsersList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "users",
        endpoint: "/users",
      },
      sortFields: [
        { f: "id", l: "ID" },
        { f: "lastName", l: "Name" },
        { f: "isSystemAdmin", l: "System Admin" },
        { f: false, l: "Account Type", cls: "text-left" },
        { f: "isLocked", l: "Account Locked", cls: "text-right" },
        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record) {
    const contractor = <></>;

    const lastName = record.lastName || "";
    const firstName = record.firstName || "";

    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    const actions = (user) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View user"
            path={"/users/view/" + user.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit user"
            path={"/users/edit/" + user.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete user"
            path={"/users/delete/" + user.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    const accountType = (
      <ol>
        {record.user_type.map((acct) => (
          <li>{caseWords(acct)}</li>
        ))}
      </ol>
    );

    var accountLocked;

    if (record.isLocked) {
      accountLocked = (
        <FontAwesomeIcon
          icon={["fad", "check-circle"]}
          size={"lg"}
          className={"text-color-error"}
          style={{ "--fa-primary-color": "#ffffff" }}
        />
      );
    } else {
      accountLocked = (
        <FontAwesomeIcon
          icon={["fad", "times-circle"]}
          size={"lg"}
          className={"text-color-success"}
          style={{ "--fa-primary-color": "#ffffff" }}
        />
      );
    }

    let sysAdmin = <></>;

    if (record.isSystemAdmin) {
      sysAdmin =   (<FontAwesomeIcon
          icon={["fad", "check-circle"]}
          title={"Is a System Admin"}
          size={"lg"}
          className={"text-color-success"}
          style={{ "--fa-primary-color": "#ffffff" }}
      />
    );
    } else {
      sysAdmin = (
          <FontAwesomeIcon
              icon={["fad", "times-circle"]}
              title={"Is not a System Admin"}
              size={"lg"}
              className={"text-color-primary"}
              style={{ "--fa-primary-color": "#ffffff" }}
          />
      );
    }


    return {
      id: record.id,
      lastName: (
        <>
          {caseWords(record.lastName + ", " + record.firstName)}
          <br />
          <small className="text-muted">{record.email}</small>
        </>
      ),
      isSystemAdmin: sysAdmin,
      accountType: accountType,
      isLocked: accountLocked,
      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(UsersList);
