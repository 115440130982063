import React from "react";
import ZevForm from "../ZevForm";
import TextElement from "../../../Reusable/Forms/Elements/TextElement";
import withRouter from "../../../withRouter";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Operations from "../../../Reusable/Controller/Operations";
import { mapData } from "../../../Reusable/Misc";
import TextAreaElement from "../../../Reusable/Forms/Elements/TextAreaElement";
import { RejectActionButtons } from "./Modules/RejectActionButtons";
import SuiTypography from "../../../components/SuiTypography";

class QuoteRejectForm extends ZevForm {
  constructor(props) {
    super(props);

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: false,
        service: {
          recordId: props.recordId,
          entity: "quote",
          endpoint: "checkout/get_quote",
          apiPrefix: "/api/pub/v1/",
        },
        labels: {},
        status: {
          isQuoteRejecting: false,
          isQuoteRejected: false,
          isQuoteRejectFailed: false,
        },
      },
      false
    );

    this.ops = new Operations();

    this.localChangeHandler = this.localChangeHandler.bind(this);
    this.cancel = this.cancel.bind(this);
    this.rejectQuote = this.rejectQuote.bind(this);
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let quote = "";

    if (this.props.quote_id) {
      const params = {
        quoteId: this.props.quote_id,
        contractorId: this.props.contractor_id,
        installRequestId: this.props.request_id,
        token: this.props.token,
        hash: this.props.hash,
      };

      quote = this.ops.getRecord(this, null, params, null, this.props.entity).then(
        function (data) {
          console.log(data);
          return { quote: data };
        }.bind(this)
      );
    } else {
      quote = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([quote]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        recordId: this.props.quote_id,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "quoteReview",
        loaderStyle: "installRequest",
        service: {
          endpoint: "/checkout/reject_quote",
          redirectTarget: "/quote/checkout",
          entity: "quoteReview",
          apiPrefix: "pub",
        },
        openModal: {},
        labels: {
          rejecting: "Rejecting Quote",
          reject: "Reject Quote & Notify Contractor",
          rejected: "Quote Rejected",
          rejectFailed: "Reject Quote Failed",
        },
        viewData: viewData,
        data: {
          quoteId: {
            value: 0,
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            label: "",
            errors: [],
            vr: {},
          },
          quoteHash: {
            value: 0,
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            label: "",
            errors: [],
            vr: {},
          },
          quoteToken: {
            value: 0,
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            label: "",
            errors: [],
            vr: {},
          },
          contractorId: {
            value: 0,
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            label: "",
            errors: [],
            vr: {},
          },
          installRequestId: {
            value: 0,
            isRequired: true,
            immutable: true,
            valid: false,
            touched: false,
            placeholder: "",
            label: "",
            errors: [],
            vr: {},
          },
          responseSubject: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Message Subject",
            label: "Subject",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          responseMessage: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Message Body",
            label: "Message",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.quote, o);

      ns.data.quoteId.value = this.props.quote_id;
      ns.data.quoteHash.value = this.props.hash;
      ns.data.quoteToken.value = this.props.token;
      ns.data.installRequestId.value = this.props.request_id;
      ns.data.contractorId.value = this.props.contractor_id;

      this.setState(ns, () => {
        this.mounted();
      });
    });
  }

  cb() {
    console.log("poop");
  }

  cancel() {
    const navigate = this.props.navigate;

    navigate(-1);
  }

  rejectQuote(ev) {
    this.handleSubmit(ev);
  }

  localChangeHandler(ev, e) {
    ev.target = e;

    this.handleChange(ev);

    if (this.state.initialized === true) {
      // StateManager.meld(this, this.state, {totalCostOfQuote: total});
    }
  }

  form() {
    let total = 0;

    return (
      <>
        {this.state.initialized === true ? (
          <Box sx={{ mb: 3 }}>
            <Box sx={{ m: 3 }}>
              <SuiTypography fontSize={"20px"} variant={"h3"} color={"primary"}>
                <strong>Message to Contractor</strong>
              </SuiTypography>
            </Box>

            <TextElement
              field={this.state.data.responseSubject}
              placeholder={this.state.data.responseSubject.placeholder}
              touched={this.state.data.responseSubject.touched}
              valid={this.state.data.responseSubject.valid}
              defaultValue={this.state.data.responseSubject.value}
              errors={this.state.data.responseSubject.errors}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              name={"responseSubject"}
            />

            <TextAreaElement
              setFieldData={this.setFieldData}
              placeholder={this.state.data.responseMessage.placeholder}
              touched={this.state.data.responseMessage.touched}
              valid={this.state.data.responseMessage.valid}
              defaultValue={this.state.data.responseMessage.value}
              errors={this.state.data.responseMessage.errors}
              onChange={this.localChangeHandler}
              onBlur={this.handleBlur}
              name={"responseMessage"}
              field={this.state.data.responseMessage}
            />

            <Box sx={{ m: 0 }}>
              <RejectActionButtons
                reject={this.rejectQuote}
                cancelReject={this.cancel}
                state={this.state}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(QuoteRejectForm);
