import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CheckoutLayout from "../../layouts/authentication/components/CheckoutLayout";
import SuiTypography from "../../components/SuiTypography";
import logo from "../../assets/img/logos/trans.png";
import { WarningHeader } from "../../Reusable/Misc";
import QuoteRejectForm from "../Forms/Checkout/QuoteRejectForm";

class QuoteRejectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_id: props.params.quote_id,
      contractor_id: props.params.contractor_id,
      request_id: props.params.request_id,
      token: props.params.quote_token,
      hash: props.params.quote_hash,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <CheckoutLayout
          headerTitle={""}
          illustration={{
            image: logo,
            title: "Installation Request",
            description: "Complete Your Installation",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <WarningHeader rounded={true} color={"error"}>
                  <SuiTypography variant={"h3"} className={"mb5"}>
                    Are you sure you wish to reject this quote?
                  </SuiTypography>
                  <p>Rejecting this will send it back to the contractor handling your request.</p>
                  <p>
                    Please send a response to the contractor with any concerns that you may have.
                  </p>
                </WarningHeader>

                <QuoteRejectForm
                  recordId={this.state.recordId}
                  entity={"quoteReview"}
                  pageScope={this}
                  quote_id={this.state.quote_id}
                  contractor_id={this.state.contractor_id}
                  request_id={this.state.request_id}
                  hash={this.state.hash}
                  token={this.state.token}
                />
              </Grid>
            </Grid>
          </Grid>
        </CheckoutLayout>
      </>
    );
  }
}

export default withRouter(QuoteRejectView);
