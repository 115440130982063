import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../../components/SuiButton";
import React from "react";

export const Cancel = (props) => {
  const { cancel } = props;

  return (
    <SuiButton color="secondary" variant="gradient" className={""} onClick={cancel}>
      <span>
        <FontAwesomeIcon icon={["fad", "times-circle"]} spin={false} />
        &nbsp;&nbsp;
      </span>
      <span>Cancel</span>
    </SuiButton>
  );
};
