import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UsersListView from "../../../pages/Views/core/Users/UsersListView";
import UsersEditView from "../../../pages/Views/core/Users/UsersEditView";
import UsersAddView from "../../../pages/Views/core/Users/UsersAddView";
import UsersDeleteView from "../../../pages/Views/core/Users/UsersDeleteView";

const UsersRoute = {
  type: "collapse",
  name: "Users",
  sidenav: true,
  order: 2,
  key: "admin-users",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "User Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "users-list",
      route: "/users/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <UsersListView />,
    },
    {
      hide: true,
      name: "View User",
      sidenav: true,
      key: "users-view",
      route: "/users/view/:userId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <UsersEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Edit User",
      sidenav: true,
      key: "users-edit",
      route: "/users/edit/:userId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <UsersEditView />,
    },
    {
      hide: true,
      name: "Delete User",
      sidenav: true,
      key: "users-delete",
      route: "/users/delete/:userId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <UsersDeleteView />,
    },
    {
      name: "Add New User",
      sidenav: true,
      key: "users-add",
      route: "/users/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <UsersAddView />,
    },
  ],
};

export default UsersRoute;
