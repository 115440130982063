import React from "react";
import { mapData } from "../../Reusable/Misc";
import Grid from "@mui/material/Grid";
import ZevForm from "../Forms/ZevForm";
import Operations from "../../Reusable/Controller/Operations";
import withRouter from "../../withRouter";
import Loader from "../../Reusable/Loader";

class PaymentsOnboardingView extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      validated: false,
      entity: 'stripe',
      isLoading: true,
      isLoaded: false,
      viewData: {
        stripe: {
          data: {
            data: {
              stripe_valid: null
            }
          }
        }
      }
    };

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */
    const params = {
      endpoint: "completeSetup/getStripeUrl",
    };

    let stripe = this.ops.singlePost({}, params).then(
      function (data) {
        return { stripe: data };
      }.bind(this)
    );

    this.promise = await Promise.all([stripe]).then((values) => {
      let viewData = {};



      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }



      const o = this.init(this, {
        isLoading: false,
        isLoaded: true,
        viewData: viewData,
        overrideSubmitButtons: true,
      });

      let ns = mapData(o.viewData.stripe, o);

      this.setState(ns, () => {
        if (this.state.viewData?.stripe?.data?.data?.stripe_valid === "VALID") {
          setTimeout(
              function() { window.location = '/'; }, 3000
          );
        }
      });
    });

  }

  render() {

    return (
      <>
        <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center" >
          <Grid item md={12} alignItems="center" xs={12} justifyContent="center">
            <Loader
              isLoaded={this.state.isLoaded}
              loadingMessage={"We need to redirect you to Stripe to complete your account setup."}
            >
              {this.state.viewData?.stripe?.data?.data?.stripe_valid === "VALID" ? (
                  <>
                <Grid item justifyContent={"center"} alignItems={"center"}>Your account has already been validated, sending you back to the application.</Grid>
                  </>
              ) : (
                <>Redirecting you to Stripe, please wait.</>
              )}
            </Loader>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(PaymentsOnboardingView);
