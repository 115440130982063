import React from "react";
import ZevForm from "../ZevForm";
import TextElement from "../../../Reusable/Forms/Elements/TextElement";
import withRouter from "../../../withRouter";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class RegisterForm extends ZevForm {
  constructor(props) {
    super(props);

    this.getPassword = this.getPassword.bind(this);
  }

  componentDidMount() {
    const o = this.init(this, {
      flow: "register",
      loaderStyle: "",
      service: {
        endpoint: "/register/create",
        redirectTarget: "/auth/login",
        entity: "Register",
      },
      labels: {
        submit: "Register",
        submitting: "Registering",
        submitted: "Registered!",
        failed: "Error",
        submitIcon: <FontAwesomeIcon icon={["fad", "person-circle-plus"]} className="mr2" />,
      },
      data: {
        firstName: {
          value: "",
          immutable: false,
          isRequired: true,
          valid: false,
          label: "First Name",
          touched: false,
          placeholder: "First Name",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
        lastName: {
          value: "",
          isRequired: true,
          immutable: false,
          valid: false,
          label: "Last Name",
          touched: false,
          placeholder: "Last Name",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
        email: {
          value: "",
          immutable: false,
          valid: false,
          touched: false,
          isRequired: true,
          placeholder: "Email Address",
          label: "Email",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },

        password: {
          value: "",
          immutable: false,
          valid: false,
          isRequired: true,
          touched: false,
          label: "Password",
          placeholder: "Password",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
        confirmPassword: {
          value: "",
          immutable: false,
          isRequired: true,
          valid: false,
          touched: false,
          placeholder: "Confirm Password",
          label: "Confirm Password",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
            is: { opt: this.getPassword, msg: "Passwords must be equal" },
          },
        },
      },
    });

    this.setState(o, () => {
      this.mounted();
    });
  }

  getPassword() {
    if (
      typeof this.state.data !== "undefined" &&
      typeof this.state.data.password !== "undefined" &&
      typeof this.state.data.password.value !== "undefined"
    ) {
      return this.state.data.password.value;
    } else {
      return "";
    }
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
            <>
              <Box sx={{mb: 3}}>
                <TextElement
                    type="email"
                    name="email"
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.email}
                />
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12} md={6}>
                    <TextElement
                        type="text"
                        name="firstName"
                        size="large"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        field={this.state.data.firstName}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    <TextElement
                        type="text"
                        name="lastName"
                        size="large"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        field={this.state.data.lastName}
                    />
                  </Grid>
                </Grid>
                <TextElement
                    type="password"
                    name="password"
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.password}
                />
                <TextElement
                    type="password"
                    name="confirmPassword"
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.confirmPassword}
                />
              </Box>

              <br/>

              <h5 align={"center"}>By clicking 'Register', you agree to adhere to the following agreement.</h5>
              <br/>


              <div className={"toc-window"}>

                <p className="MsoNormal" align="center" style={{textAlign: "center"}}>
                  <b>
                    <u>
                    <span style={{fontFamily: "Arial,serif", letterSpacing: "-.15pt"}}>
                      BUSINESS REFERRAL CO-MARKETING AND NON-
                    </span>
                    </u>
                  </b>
                </p>

                <p className="MsoNormal" align="center" style={{textAlign: "center"}}>
                  <b>
                    <u>
                    <span style={{fontFamily: "Arial,serif", letterSpacing: "-.15pt"}}>
                      CIRCUMVENTION AGREEMENT
                    </span>
                    </u>
                  </b>
                </p>

                <p className="MsoNormal" style={{textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif", letterSpacing: "-.1pt"}}>&nbsp;</span>
                </p>

                <p className="Default">
                  <b>
                    <span style={{fontFamily: "Arial,serif"}}>This CO-MARKETING AGREEMENT </span>
                  </b>
                  <span style={{fontFamily: "Arial,serif"}}>
                  ("the Agreement") is entered into this date of {moment.utc().local().format("MMMM D, YYYY")}, ("Electrician"),
                  on the one hand, and Zev Society, LLC d/b/a Chargewow ("Chargewow"), on the other
                  hand.{" "}
                </span>
                </p>

                <p className="MsoNormal">
                  <b>
                    <span style={{fontFamily: "Arial,serif", color: "black"}}>&nbsp;</span>
                  </b>
                </p>

                <p className="MsoNormal" style={{textAlign: "justify"}}>
                  <b>
                    <u>
                      <span style={{fontFamily: "Arial,serif"}}>RECITALS</span>
                    </u>
                  </b>
                </p>

                <p className="MsoNormal" style={{textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif", letterSpacing: "-.1pt"}}>&nbsp;</span>
                </p>

                <p
                    className="MsoNormal"
                    style={{marginLeft: "1.0in", textAlign: "justify", textIndent: "-.5in"}}
                >
                <span style={{fontFamily: "Arial,serif"}}>
                  A.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                  <span style={{fontFamily: "Arial,serif"}}>
                  Electrician is a licensed and insured electrical contractor company employing
                  licensed electricians and providing professional and workmanlike service
                  throughout the State of Michigan.{" "}
                </span>
                </p>

                <p className="MsoNormal" style={{marginLeft: "1.0in", textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif"}}>&nbsp;</span>
                </p>

                <p
                    className="MsoNormal"
                    style={{marginLeft: "1.0in", textAlign: "justify", textIndent: "-.5in"}}
                >
                <span style={{fontFamily: "Arial,serif"}}>
                  B.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                  <span style={{fontFamily: "Arial,serif"}}>Chargewow </span>is an authorized
                  distributor of various electric vehicles chargers and operates various websites
                  providing content regarding electric vehicles and chargers and, among other things,
                  provides a service to match potential customers interested in installing electric
                  vehicle chargers with electricians.
                </p>

                <p className="MsoNormal" style={{marginLeft: "1.0in", textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif"}}>&nbsp;</span>
                </p>
                <p
                    className="MsoNormal"
                    style={{marginLeft: "1.0in", textAlign: "justify", textIndent: "-.5in"}}
                >
                <span style={{fontFamily: "Arial,serif"}}>
                  C.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                  <span style={{fontFamily: "Arial,serif"}}>
                  Chargewow markets, promotes and provides referral opportunities relating to the
                  sales and installation of Electric Vehicle Chargers ("EVC" "Referral
                  Opportunit(ies)" to residential, commercial and/or industrial customers
                  "Customer(s)"), in participation with select electricians who affiliate with the
                  Chargewow network ("Network").
                </span>
                </p>

                <p className="MsoNormal" style={{marginLeft: "1.0in", textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif"}}>&nbsp;</span>
                </p>

                <p
                    className="MsoNormal"
                    style={{marginLeft: "1.0in", textAlign: "justify", textIndent: "-.5in"}}
                >
                <span style={{fontFamily: "Arial,serif"}}>
                  D.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                  <span style={{fontFamily: "Arial,serif"}}>
                  The Electrician desires to join the Network subject to the terms of this
                  Agreement.
                </span>
                </p>

                <p className="MsoNormal" style={{textAlign: "justify"}}>
                  <span style={{fontFamily: "Arial,serif"}}>&nbsp;</span>
                </p>

                <p className="MsoNormal" style={{textAlign: "justify"}}>
                  <b>
                    <span style={{fontFamily: "Arial,serif"}}> </span>
                  </b>
                  <span style={{fontFamily: "Arial,serif"}}>Now, therefore</span>
                  <span style={{fontFamily: "Arial,serif"}}>, the Parties agree as follows: </span>
                </p>

                <p className="MsoNormal">
                  <span style={{fontFamily: "Arial,serif", letterSpacing: "-.1pt"}}>&nbsp;</span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                  <b>
                  <span style={{fontSize: "12pt"}}>
                    1.
                    <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                  </b>
                  <b>
                    <span style={{fontSize: "12pt"}}>Adoption of Recital. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Electrician and Chargewow hereby adopt the above state recitals as an accurate
                  statement of inducement to enter into this Agreement, subject to the further
                  inducements, representations and warranties more fully stated below.
                </span>
                </p>
                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  2.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}>Referral Opportunities. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Chargewow will communicate Referral Opportunities to Electrician in a mutually
                  agreeable manner. Each Referral Opportunity will include the name, contact
                  information and basic Customer requirements ("The Customer Package").
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}> </span>
                  </b>
                </p>

                <hr/>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  3.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}>Electrician Immediate Follow-up</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  . With respect to each Referral Opportunity, no later than 5pm EST on the business
                  day following Chargewow’s transmittal of the Customer Package to Electrician (The
                  "Deadline"), Electrician will either: 1) Accept the terms of the Customer Package
                  and provide a quoted price and date for installation of an EVC as requested by the
                  Customer, or 2) Advise Chargewow in writing that it reject the Customer Package
                  altogether and will not act on the referral. With respect to each Prospective
                  Customer that Electrician accepts (option 1), Electrician will contact that
                  Customer prior to the Deadline electronically (or telephonically if preferred by
                  the Customer) providing a copy of any electronic communication to Chargewow and
                  otherwise notifying Chargewow electronically of any telephonic communication.
                  Electrician will use its best efforts to secure an engagement from the Customer
                  and to offer installation within a week of the Deadline. Upon Electrician’s
                  acceptance of a Customer Package, Chargewow shall attempt to direct subsequent
                  Customer communications to the Electrician.{" "}
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  4.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}>Electrician Standard of Care.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    Electrician will provide workmanlike and professional service to each Customer,
                  and shall at all times comply with all laws, regulations, codes and industry
                  standards in its professional dealings with Customers and in its performance under
                  this Agreement.{" "}
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  5.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}>Referral Fees. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  In connection with each Customer Package that results in the sale of goods or
                  services by Electrician to that Customer or an affiliate of that Customer,
                  Chargewow shall retain 20% of the payment for electricians services as a referral
                  fee, and shall further retain an amount equal to 20% of future sales by
                  Electrician to that Customer or that Customers affiliate during the 12-month
                  period following delivery of the Customer Package (The "Referral Fee" for any
                  "Engagement" during the "Period"). Chargewow will remit 80% of payments collected
                  for the Engagement to the Electrician, minus processing costs, within 30 days of
                  the installation. Electrician will remit 20% of any payments collected as a result
                  of the Engagement to Chargewow within 30 days of the installation.{" "}
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textIndent: ".25in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  6.
                  <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </span>
                  <b>
                    <span style={{fontSize: "12pt"}}>Insurance.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    Electrician agrees to maintain suitable liability insurance coverage with policy
                  limits of no less than two million dollars, and to name Chargewow as an additional
                  named insured with respect to each Customer Engagement.{" "}
                </span>
                </p>

                <p
                    className="MsoNormal"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "0in",
                      textAlign: "justify",
                      textIndent: ".25in",
                    }}
                >
                  <b>
                  <span style={{fontFamily: "Arial,serif", color: "black"}}>
                    7.
                    <span style={{fontSize: "7pt", fontFamily: "arial"}}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                  </b>
                  <b>Cross Indemnification</b>
                  <span style={{fontFamily: "Arial,serif", color: "black"}}>
                  The Parties agree that each Party will assume its own proper responsibility in
                  connection with any claims made by any third party against the other Party.&nbsp;
                    If the acts of the agent, servants, and employees of Chargewow are the proximate
                  cause of any action brought against Electrician, Chargewow will assume
                  responsibility for the defense of said action and the payment of any resulting
                  judgment and indemnify the Electrician.&nbsp; If the acts of the agents, servants
                  and employees of the Electrician are the proximate cause of any action brought
                  against Chargewow, the Electrician will assume responsibility for the defense of
                  said action and payment of any resulting judgment and indemnify Chargewow.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt", textIndent: ".25in"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>8. Confidential Information. </span>
                  </b>
                  <a name="_Ref492108652">
                  <span style={{fontSize: "12pt"}}>
                    This Agreement shall apply to any and all confidential and proprietary
                    information disclosed by Chargewow to Electrician on or after the Effective
                    Date, whether disclosed verbally, in writing, or by inspection of tangible
                    objects and may include, but is not limited to: Customer information, vendor
                    information, pricing methods and practices, business methods and processes,
                    patents, copyrights, trade secrets, trademarks, service marks, trade dress,
                    designs, sketches, prototypes, proprietary information, audio and digital
                    technologies, business methods and practices, business opportunities, supplier,{" "}
                  </span>
                  </a>
                  <a name="_Hlk509233540">
                  <span style={{fontSize: "12pt"}}>
                    vendor and service provider identities and opportunities,
                  </span>
                  </a>
                  <span style={{fontSize: "12pt"}}>
                  data, client and contracting party identities and opportunities, contact
                  information, scripts, presentations, agreements, blueprints, manuals, production
                  schedules, spreadsheets, notes, correspondence handbooks, strategies, computer
                  code, design and architecture, computer and electronic hardware, features,
                  techniques, inventions, know-how, processes, customer and distributor lists,
                  business forecasts, sales and merchandising, and marketing plans and information
                  ("Confidential Information"). The terms of this Agreement and the existence of any
                  business negotiations, discussions, consultations or agreements in progress
                  between the Parties shall be considered Confidential Information, unless otherwise
                  agreed in writing by the Parties.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>9. Non-Use/Non-Disclosure. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Electrician shall use at least the same degree of care to prevent the unauthorized
                  use or disclosure of Chargewow’s Confidential Information as Electrician uses to
                  protect its own confidential information, but in any event with no less than a
                  reasonable degree of care. Electrician agrees that at all times it will hold in
                  strict confidence and not disclose Chargewow’s Confidential Information to any
                  third party, and will use Chargewow’s Confidential Information for no purpose
                  other than to evaluate a potential business relationship between the Parties.
                  Electrician shall only permit access to Chargewow’s Confidential Information to
                  its and its Affiliates’ employees, officers, agents, representatives or
                  consultants having a need to know and who have contractually agreed to be bound by
                  confidentiality obligations at least as restrictive as those contained herein
                  prior to their use thereof. "Affiliate" means an entity that controls, is
                  controlled by or is under common control with the Electrician, either directly or
                  indirectly via one or more intermediaries. For purposes of determining Affiliate
                  status, "control" of an entity refers to ownership or voting control of at least
                  fifty percent (50%) of the securities of the entity that are entitled to vote for
                  the election of directors or of comparable ownership interests in the
                  organization. Electrician shall immediately notify Chargewow in the event of any
                  loss or unauthorized disclosure of any of Chargewow’s Confidential Information or
                  any other breach of this Agreement by Electrician and will cooperate with
                  Chargewow as reasonably requested by Chargewow to help Chargewow regain possession
                  of its Confidential Information and to prevent its further disclosure. If
                  Chargewow discloses to Electrician any software or hardware containing software in
                  object or executable code format, Electrician is prohibited from disassembling,
                  de-compiling, reverse-engineering or otherwise attempting to discover or disclose
                  Chargewow’s prototypes, software, other tangible objects, or methods or concepts
                  embodied in such software or hardware.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                  <span style={{fontSize: "12pt"}}>
                    10. Exceptions to Confidential Information.
                  </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    Electrician shall have no obligation with respect to information that Electrician
                  can demonstrate: (i) was rightfully in possession of or known to Electrician
                  without any obligation of confidentiality prior to receiving it from Chargewow;
                  (ii) is, or subsequently becomes, publicly available without breach of this
                  Agreement; (iii) is rightfully obtained by Electrician from a source other than
                  Chargewow without any obligation of confidentiality; (iv) is developed by or for
                  Electrician without use of Chargewow’s Confidential Information; or (v) is
                  disclosed pursuant to the prior written consent of the Chargewow. Further,
                  Electrician may disclose Chargewow’s Confidential Information pursuant to a valid
                  order issued by a court or government agency, provided that Electrician provides
                  Chargewow prompt written notice of such obligation and a reasonable opportunity to
                  oppose such disclosure or obtain a protective order prior to Electrician
                  disclosing or offering to disclose the Confidential Information.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>11. Non-Circumvention.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Electrician hereby acknowledges and agrees that it <i>and/or its Affiliate(s) </i>
                  shall not directly or indirectly circumvent, or attempt to circumvent, avoid
                  bypass, or obviate Chargewow’s interests, business dealings or relationships,
                  and/or the interests of any entity, party, assigns and/or associates thereof, in
                  Chargewow’s dealings or planned dealings with any Customers, suppliers, vendors,
                  officers, employees, suppliers, vendors, service providers, contracting parties,
                  franchisors, franchisees, licensees, licensors, business associates, competitors,
                  executives, owners, independent contractors, processors, buyers, seller, brokers
                  and investors ("Contacts"), whether to satisfy curiosity, change or avoid
                  established procedures, contractual and/or other provisions, prices, fees, and/or
                  for any purpose(s) whatsoever.{" "}
                </span>
                </p>

                <hr/>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>12.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    <b>Relief.</b> Any breach of Paragraphs 7-11 above, or attempted conduct violative
                  thereof, shall immediately constitute breach hereof and any party shall act as it
                  deems necessary, appropriate, or required with the respect to protecting the
                  interests of any and all other parties, which may specifically include, without
                  limitation, any and all of the following :{" "}
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "1.5in",
                      textIndent: "-.5in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  i. Immediate termination of this Agreement and any agreements entered into by the
                  Parties between the Effective Date and date of such breach;
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "1.5in",
                      textIndent: "-.5in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  ii. Withholding any and all monies that would otherwise be due breaching
                  Party(ies) until any and all damages, monetary or otherwise, are accessed and
                  restituted in full to injured non-breaching Parties; and/or{" "}
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "1.5in",
                      textIndent: "-.5in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  iii. Filing suit in the appropriate state and/or federal court to enforce all
                  rights and seek any and all remedies that may be available, specifically including
                  injunctive relief, the irreparable harm associated with any such damage hereby
                  being acknowledged; and
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "1.5in",
                      textIndent: "-.5in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  iv. Filing suit in the appropriate state and/or federal court to enforce all
                  rights and seek any and all remedies that may be available, specifically including
                  money damages associated with any resulting business loss.
                </span>
                </p>

                <p
                    className="MsoBodyText"
                    style={{
                      marginTop: "0in",
                      marginRight: "0in",
                      marginBottom: "12pt",
                      marginLeft: "1.0in",
                      textIndent: "-.5in",
                    }}
                >
                <span style={{fontSize: "12pt"}}>
                  b. For the avoidance of doubt, the non-circumvention provisions in this Agreement
                  shall not apply where the relevant contact of one of the Parties previously had an
                  active business relationship with the other Party, or where the relevant other
                  Party secures a deal with the relevant contact due to the good faith efforts of an
                  independent third party at some point in the future. Evidence of such previous
                  relationship shall be delivered to the other Party, in writing, prior to any
                  contact with any such party.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>13. No Warranty. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Chargewow offers to warranty with respect to the accuracy or completeness of the
                  Customer Referral Packages, which are deemed "as is" without warranty of any kind
                  by Chargewow. Electrician will be solely responsible for obtaining all necessary
                  technical data to quote, budget and provide its services and secure payment.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>14. No Licenses; No Further Agreement.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    No provision of this Agreement shall be deemed to grant any rights to Electrician
                  to any intellectual property, including but not limited to patents, service marks,
                  trade dress, trademarks or copyrights. Nothing in this Agreement shall be
                  construed or implied to obligate one party to furnish any confidential information
                  to the other party, or to create any obligation for either Party to enter into any
                  transaction with the other Party.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>15. Independent Contractors. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  The relationship of the Parties is solely and exclusively that of independent
                  contractors, and nothing contained in this Agreement shall be construed to (i)
                  give either Party the power to direct or control the activities of the other, (ii)
                  constitute the parties as partners, joint venturers, agents, co-owners or
                  otherwise as participants in a joint undertaking, or (iii) allow either Party to
                  create or assume any obligation on behalf of the other Party for any purpose
                  whatsoever; or (iv) constitute an exclusive relationship preventing Chargewow from
                  sending referral opportunities to other electricians or preventing Electrician
                  from purchasing EVC for uses outside of the Referral Opportunities from other
                  sources.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>16. Term and Termination.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  This Agreement may be terminated by either party without cause upon 30 days
                  written notice. Payment obligations for amounts due and owing prior to
                  termination, and the obligations stated in paragraphs 7-13, shall survive and
                  continue in full force and effect.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                  <span lang="FR" style={{fontSize: "12pt"}}>
                    17. Non Exclusive.{" "}
                  </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Nothing herein shall be deemed to award Electrician an exclusive right to Customer
                  Referrals in any particular territory of duration.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>18. Export. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Neither party shall export, directly or indirectly, any technical data acquired
                  from the other pursuant to this Agreement or any product utilizing any such data
                  to any country for which the U.S. Government or any agency thereof at the time of
                  export requires an export license or other governmental approval without first
                  obtaining such license or approval.<a name="_Ref492102915"> </a>
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <a name="_Ref509825808">
                    <b>
                      <span style={{fontSize: "12pt"}}>19. Assignment.</span>
                    </b>
                  </a>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    Electrician shall not assign or transfer any rights or obligations under this
                  Agreement without the prior written consent of Chargewow, which may be granted or
                  withheld in Chargewow’s sole discretion. Any attempted assignment or transfer in
                  violation of the foregoing shall be null and void.{" "}
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>20. Governing Law. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  This Agreement shall be governed by, construed and enforced solely and exclusively
                  in accordance with the laws of the State of Michigan, USA, without reference to
                  conflict of law principles. Any and all disputes under this Agreement must be
                  brought solely and exclusively in the state courts in Oakland County, Michigan,
                  or, if it has or can acquire jurisdiction, the United States District Court for
                  the Eastern District of Michigan, Southern Division, and the parties hereby
                  irrevocably consent to the personal jurisdiction and venue of these courts.
                  Notwithstanding the preceding sentence, nothing in this Agreement shall restrict
                  or limit a party from seeking injunctive or similar relief in any jurisdiction
                  necessary to achieve relief.
                </span>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    The parties waive all rights to a jury trial.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>21. Irreparable Harm. </span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  Electrician hereby agrees that breach of this Agreement will cause irreparable
                  damage for which recovery of damages would be inadequate, and that the Chargewow
                  shall therefore, notwithstanding anything to the contrary contained herein, be
                  entitled to obtain timely injunctive relief under this Agreement wherever it deems
                  appropriate, as well as such further relief as may be afforded to it by law.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>22. Severability.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  {" "}
                    If one or more provisions in this Agreement are ruled entirely or partly invalid
                  or unenforceable by any court of competent jurisdiction in any jurisdiction, then
                  (a)&nbsp;the validity and enforceability of all provisions not ruled to be invalid
                  or unenforceable shall remain unaffected; (b)&nbsp;the effect of such ruling shall
                  be limited to the jurisdiction of the court making the ruling; (c)&nbsp;the
                  provision(s) held wholly or partly invalid or unenforceable shall be deemed
                  amended, and the parties shall reform the provision(s) to the minimum extent
                  necessary to render them valid and enforceable in conformity with the parties’
                  intent as manifested herein; and (d)&nbsp;if the ruling, or the controlling
                  principle of law or equity leading to the ruling, is subsequently overruled,
                  modified, or amended, then the provision(s) in question, as originally set forth
                  in this Agreement, shall be deemed valid and enforceable to the maximum extent
                  permitted by the new controlling principle of law or equity.
                </span>
                </p>

                <p className="MsoBodyText" style={{marginBottom: "12pt"}}>
                  <b>
                    <span style={{fontSize: "12pt"}}>23. Entire Agreement.</span>
                  </b>
                  <span style={{fontSize: "12pt"}}>
                  This Agreement represents the sole, final and entire agreement between the Parties
                  with respect to the subject matter hereof and shall supersede and terminate all
                  prior or contemporaneous agreements and communications of the Parties, verbal or
                  written.
                  <span>
                    {" "}
                    This Agreement shall not be amended except by a separate writing signed by both
                    parties to this Agreement.
                  </span>
                </span>
                </p>

                <p
                    className="MsoNormal"
                    align="center"
                    style={{marginBottom: "12pt", textAlign: "center"}}
                >
                  <b>24. Independent Development.</b> The terms of this Agreement shall not be
                  construed to limit Chargewow’s right to independently develop or acquire products,
                  services, or its network without use of the Electrician. The terms of this Agreement
                  shall also not be construed to limit Electrician’s right to install electrical
                  vehicle chargers independent of Chargewow referrals.
                </p>
              </div>
            </>
        ) : (
            <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(RegisterForm);
