import React from "react";
import hash from "object-hash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

class FieldValidationErrors extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderValidationError(error) {
    return (
      <li key={hash(error)}>
        <FontAwesomeIcon fixedWidth={true} icon={solid("exclamation-circle")} />
        {error}
      </li>
    );
  }

  renderValidationErrors() {
    if (Array.isArray(this.props.fieldErrors)) {
      return this.props.fieldErrors.map((error) => {
        return this.renderValidationError(error);
      });
    } else {
      return <></>;
    }
  }

  renderErrors() {
    if (Object.keys(this.props.fieldErrors).length) {
      if (this.props.short) {
        return (
          <li key={hash(this.props.fieldErrors)}>
            <FontAwesomeIcon fixedWidth={true} icon={solid("exclamation-circle")} />
          </li>
        );
      } else {
        return Object.keys(this.props.fieldErrors).map((errorKey) => {
          return this.renderValidationError(this.props.fieldErrors[errorKey]);
        });
      }
    }
  }

  render() {
    var shortError = this.props.short ? " short-error " : " ";

    return (
      <ul className={"validation-error-list " + shortError} title={"This field has an error"}>
        {this.renderErrors()}
      </ul>
    );
  }
}

export default FieldValidationErrors;
