import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";

import pattern from "assets/images/shapes/pattern-lines.svg";
import BuildInfo from "../../../Reusable/BuildInfo";

function CheckoutLayout({
  color,
  header,
  title,
  description,
  headerTitle,
  illustration,
  children,
}) {
  return (
    <PageLayout background="white">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={7} xl={6} sx={{ mx: "auto" }}>
          <SuiBox
            display="flex"
            p={2}
            flexDirection="column"
            justifyContent="center"
            // height="100vh"
          >
            <Grid container>
              <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                <SuiBox
                  pt={3}
                  px={0}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"flex-start"}
                >
                  {!header ? (
                    <>
                      <SuiBox mb={4}>
                        <SuiTypography variant="h4" fontWeight="bold">
                          {headerTitle}
                        </SuiTypography>
                      </SuiBox>
                    </>
                  ) : (
                    header
                  )}
                </SuiBox>
              </Grid>
              <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
            </Grid>
            <Grid container>
              <Grid item xs={0} sm={0} />
              <Grid item xs={12} sm={12}>
                <SuiBox p={0}>{children}</SuiBox>
              </Grid>
              <Grid item xs={0} sm={0} />
            </Grid>
            <SuiBox
              color="error"
              sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}
            >
              <BuildInfo />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} lg={5}>
          <SuiBox
            display={{ xs: "none", lg: "flex" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 3rem)"
            height="calc(100vh - 2rem)"
            position="relative"
            borderRadius="lg"
            textAlign="center"
            bgColor={color}
            variant="gradient"
            m={2}
            px={13}
            sx={{ overflow: "hidden", height: '98%' }}
          >
            <SuiBox
              component="div"
              // src={pattern}
              alt="pattern-lines"
              // width="100rem"
              position="absolute"
              bgColor={'url('+pattern+')'}
              topl={0}
              style={{height: '100%', width: '100%', overflow: 'hidden'}}
              left={0}
              opacity={0.4}
            />
            {illustration.image && (
              <SuiBox
                component="img"
                src={illustration.image}
                alt="chat-illustration"
                width="100%"
                maxWidth="31.25rem"
              />
            )}
            {illustration.title && (
              <SuiBox mt={6} mb={1}>
                <SuiTypography variant="h1" color="white" fontWeight="bold">
                  {illustration.title}
                </SuiTypography>
              </SuiBox>
            )}
            {illustration.description && (
              <SuiBox mb={1}>
                <SuiTypography variant="body2" color="white">
                  {illustration.description}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
CheckoutLayout.defaultProps = {
  color: "primary",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
CheckoutLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default CheckoutLayout;
