import React, { useState } from "react";
import { Alert, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Operations from "../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";

const Confirmation = (props) => {
  const { title, data, params, confirmation, isOpen, handleClose, confirmText, declineText } =
    props;
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);

  const confirm = confirmText || "Confirm";
  const decline = declineText || "Cancel";

  const doClose = (e, r) => {
    if (r && r === "backdropClick") {
      return;
    }

    setIsSaved(false);
    setIsSaving(false);
    setIsError(false);

    handleClose();
  };

  const doConfirm = () => {
    const ops = new Operations();

    if (isSaved) {
      handleClose();
      return;
    }


    setIsSaving(true);

    ops
      .singleSave(data, params)
      .then((results) => {
        if (results.getIsSuccess()) {
          setIsSaved(true);
          setIsSaving(false);
          props.onComplete();
        } else {
          setIsError(true);
          setIsSaving(false);
        }

        // setTimeout(
        //     () => {
        //
        //         handleClose();
        //     }, 4000
        // );
      })
      .catch((error) => {
        setIsSaving(false);
        setIsError(true);
      });
  };

  return (
    <>
      {props.children}
      <Dialog
        key={Math.random()}
        className="overflow-modal w666"
        open={isOpen}
        onClose={doClose}
        keepMounted
        disableEscapeKeyDown
      >
        <DialogTitle>{isSaved ? "Success" : title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                {isSaved ? (
                  <>
                    <Alert
                      className={"mt4"}
                      variant={"filled"}
                      icon={
                        <FontAwesomeIcon
                          icon={icon({ style: "solid", name: "check-circle" })}
                          color={"#fff"}
                        />
                      }
                      color={"success"}
                    >
                      Save Successful
                    </Alert>
                  </>
                ) : (
                  confirmation
                )}
              </Grid>
              {isError ? (
                <Grid item xs={12}>
                  <Alert
                    className={"mt4"}
                    variant={"filled"}
                    icon={
                      <FontAwesomeIcon
                        icon={icon({ style: "solid", name: "times-circle" })}
                        color={"#fff"}
                      />
                    }
                    color={"error"}
                  >
                    An error occurred while performing this action
                  </Alert>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              disabled={isSaving}
              onClick={doConfirm}
              color="primary"
              className={"nav-header-button"}
            >
              {isSaving === true ? (
                <>
                  <FontAwesomeIcon
                    icon={icon({ style: "duotone", name: "spinner" })}
                    spin
                    fixedWidth
                  />
                  &nbsp;Saving...
                </>
              ) : isSaved ? (
                <>
                  <FontAwesomeIcon
                    icon={icon({ style: "duotone", name: "check-circle" })}
                    fixedWidth
                  />
                  &nbsp;Saved
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={icon({ style: "duotone", name: "floppy-disk" })}
                    fixedWidth
                  />
                  &nbsp;{confirm}
                </>
              )}
            </Button>

            {(!isSaved &&
            <Button
              disabled={isSaving}
              color="light"
              onClick={doClose}
              className={"nav-header-button ml6"}
            >
              <FontAwesomeIcon icon={icon({ style: "duotone", name: "times-circle" })} fixedWidth />
              &nbsp;
              {decline}
            </Button>)
            }
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Confirmation;
