import React from "react";
import { blurHandler, changeHandler } from "../../Reusable/FormHandler";
import Controller from "../../Reusable/Controller";
import { StateManager } from "../../Reusable/Core";
import Sorter from "../../Reusable/Sorter";
import Pagination from "../../Reusable/Pagination/Pagination";
import Operations from "../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";
import SuiBox from "../../components/SuiBox";
import { CardContent, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Reusable/Loader";

class ZevList extends React.Component {
  response = null;

  apiPrefix = "/zev.hub/api/priv/v1";

  constructor(props) {
    super(props);

    this.ops = new Operations();
    this.children = props.children;
    this.pgScope = props.pageScope;

    this.state = {
      confirmation: {
        isError: false,
        isOpen: false,
        title: null,
        content: null,
      },
      style: 1,
      selectRowEnable: true,
      selectRowDefaultAction: this.selectRow,
      tableClass: null,
      isLoading: false,
      initialized: false,
      loaded: false,
      records: {},
      service: {
        entity: null,
        endpoint: null,
      },
      labels: {},
      lastStatus: null,
      sort: {
        field: "id",
        dir: "ASC",
      },
      pageRange: 0,
      itemsPerPage: 0,
      totalRecords: 0,
      currentPage: 1,
    };

    this.init = this.init.bind(this);
    this.Controller = new Controller(this, this.stateCallback);
    this.handleState = this.handleState.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callback = this.callback.bind(this);
    this.sortCallback = this.sortCallback.bind(this);
    this.setState = this.setState.bind(this);
    this.wrapper = this.wrapper.bind(this);
    this.list = this.list.bind(this);
    this.tableheader = this.tableheader.bind(this);
    this.tablefooter = this.tablefooter.bind(this);
    this.sorter = this.sorter.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setNotLoading = this.setNotLoading.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderStyledList = this.renderStyledList.bind(this);
    this.renderStyledRow = this.renderStyledRow.bind(this);
  }

  m(s) {}

  lastStatus(status) {
    const newState = {
      lastStatus: status,
    };

    StateManager.meld(this, this.state, newState);
  }

  componentDidMount() {}

  displayErrors(errors, validationErrors) {
    const stateObj = {};

    stateObj.data = {};

    Object.keys(validationErrors).forEach((key) => {
      const vErrorsByField = validationErrors[key];
      stateObj.data[key] = {};
      stateObj.data[key].errors = vErrorsByField;
    });
    StateManager.meld(this, this.state, stateObj);
  }

  changePage(page) {
    this.setState({
      activePage: page,
      loaded: false,
    });

    this.renderList(page);
  }

  sortCallback(field, dir) {
    const sort = {
      field: field,
      dir: dir,
    };

    this.setState({ sort: sort, loaded: false }, () => {
      this.renderList(this.state.currentPage);
    });
  }

  setLoading() {
    this.setState({ isLoading: true });
  }

  setNotLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 450);
  }

  renderList(page) {
    this.setLoading();
    page = page || 1;
    const records = this.ops.getRecords(this, "users", page, false, {}, this.state.sort);

    records.then((rcds) => {
      this.setState(
        {
          records: rcds.records,
          totalRecords: rcds.totalRecords,
          itemsPerPage: rcds.itemsPerPage,
          pageRange: rcds.pageRange,
          loaded: true,
        },
        this.setNotLoading
      );
    });
  }

  json() {
    const data = this.data();
    return JSON.stringify(data);
  }

  data(full) {
    const data = {};

    if (full) {
      return this.state.data;
    }

    for (let item in this.state.data) {
      data[item] = this.state.data[item].value;
    }

    return data;
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  endpoint() {
    return this.state.service.endpoint;
  }

  entity() {
    return this.state.service.entity;
  }

  init(scope, state) {
    const obj = _.merge({}, this.state, state, { initialized: true });
    // return obj;

    scope.setState(obj, () => {
      this.renderList(1);
    });
  }

  callback() {}

  handleState() {
    let newState = this.state;
    newState.data[name].value = value;
    newState.data[name].touched = true;
    newState.data[name].errors = [];
    this.setState(newState);
  }

  handleBlur(evt) {
    const b = blurHandler(evt, this);
    this.setState(
      {
        data: b.data,
      },
      () => {
        this.forceUpdate();
      }
    );
  }

  handleChange(evt) {
    const c = changeHandler(evt, this.handleState, this);
    this.setState({
      data: c.data,
    });
  }

  stateCallback = (stateChanges) => {
    const state = this.state;
    const nState = _.merge({}, state, stateChanges);
    this.setState(nState);
  };

  handlePageChange(o) {
    this.renderList(o);
  }

  async mounted() {
    const response = await security.secure();
    const hashes = response.payload();

    this.setState(
      {
        security: {
          token: hashes.fields,
          hash: hashes.values,
        },
      },
      () => {}
    );
  }

  displayRow() {}

  tableheader() {
    return <></>;
  }

  tablefooter() {
    return <></>;
  }

  pagination() {
    return (
      <>
        {this.state.loaded && (
          <Pagination
            linkClassFirst="page-link-first"
            linkClassPrev="page-link-prev"
            linkClassNext="page-link-next"
            linkClassLast="page-link-last"
            firstPageText={<FontAwesomeIcon icon={["fad", "arrow-alt-to-left"]} />}
            lastPageText={<FontAwesomeIcon icon={["fad", "arrow-alt-to-right"]} />}
            prevPageText={<FontAwesomeIcon icon={["fad", "arrow-alt-left"]} />}
            nextPageText={<FontAwesomeIcon icon={["fad", "arrow-alt-right"]} />}
            activePage={this.state.currentPage}
            itemsCountPerPage={this.state.itemsPerPage}
            itemClass="page-item"
            innerClass="pagination mx-auto"
            linkClass="page-link"
            activeLinkClass="active-link"
            totalItemsCount={this.state.totalRecords}
            pageRangeDisplayed={this.state.pageRange}
            onChange={this.handlePageChange.bind(this)}
          />
        )}
      </>
    );
  }

  list() {
    return <></>;
  }

  sorter() {
    return (
      <>
        <Sorter
          fields={this.state.sortFields}
          loading={this.state.isLoading}
          selectRow={this.state.selectRowEnable}
          selectRowDefaultAction={this.selectRow}
          displayFunc={this.renderRow}
          loaded={this.state.loaded}
          records={this.state.records}
          render={this.sortCallback}
          sort={this.state.sort}
          activePage={this.state.currentPage}
        />
      </>
    );
  }

  renderRow() {}

  render() {
    return (
      <>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SuiBox mb={3}>
                <Card className={"overflow-x-scroll"}>
                  <CardHeader></CardHeader>
                  <CardContent>{this.wrapper()}</CardContent>
                </Card>
              </SuiBox>
              <SuiBox mb={3}></SuiBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <SuiBox mb={3} position="relative"></SuiBox>
          </Grid>
        </Grid>
      </>
    );
  }

  renderStyledList() {

    return (
        <div className={"zev-responsive"}>
          {(this.state.loaded) ?
          <>
            <div className={"stylized-rows"}>
              {this.state.records.map(
              record => this.renderStyledRow(record)
          )}
            </div>
          </> :
            <>
              <Loader />
            </>
          }
        </div>
    );


  }

  renderStyledRow(row) {

    return <></>;

  }

  stylize() {

      return <>
        <div className={"hidden-xs visible-md "}>
          {this.sorter()}
          {this.list()}
        </div>
        <div className={"hidden-md visible-xs"}>
          {this.renderStyledList()}
        </div>
      </>
  }

  wrapper() {
    return (
      <>
        {this.tableheader()}
        {this.state.initialized && (
          <>
            {this.stylize()}
            {this.pagination()}
            {this.tablefooter()}
          </>
        )}
      </>
    );
  }
}

export default ZevList;
