import React from "react";
import InstallRequestsList from "../../../Lists/irq/InstallRequests/InstallRequestsList";

class InstallRequestsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <InstallRequestsList />
      </>
    );
  }
}

export default InstallRequestsListView;
