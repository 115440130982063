import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InstallRequestsListView from "../../../pages/Views/irq/InstallRequests/InstallRequestsListView";
import InstallRequestsEditView from "../../../pages/Views/irq/InstallRequests/InstallRequestsEditView";
import InstallRequestsDeleteView from "../../../pages/Views/irq/InstallRequests/InstallRequestsDeleteView";
import { FAStack } from "../../../Utility/FontAwesome";

const InstallRequestsRoute = {
  type: "collapse",
  name: "Install Requests",
  sidenav: true,
  order: 2,
  key: "install_requests-list",
  route: "/install_requests/list",
  component: <InstallRequestsListView />,
  icon: (
    <>
      <FAStack main="user" sub="plus-circle" />
      &nbsp;
    </>
  ),
  hideCollapse: true,
  collapse: [
    {
      name: "Install Request Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "install_requests-list",
      route: "/install_requests/list",
      icon: <FAStack main="user" sub="plus-circle" />,
      component: <InstallRequestsListView />,
      hide: true,
    },
    {
      hide: true,
      name: "View Install Request",
      sidenav: true,
      key: "install_requests-view",
      route: "/install_requests/view/:installRequestId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <InstallRequestsEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Edit Install Request",
      sidenav: true,
      key: "install_requests-edit",
      route: "/install_requests/edit/:installRequestId",
      component: <InstallRequestsEditView />,
    },
    {
      hide: true,
      name: "Delete Install Request",
      sidenav: true,
      key: "install_requests-delete",
      route: "/install_requests/delete/:installRequestId",
      component: <InstallRequestsDeleteView />,
    },
  ],
};

export default InstallRequestsRoute;
