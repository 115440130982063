import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FAInset = (props) => {
  let { scale, innerColor, disabled, outerColor, innerIcon, outerIcon } = props;
  let styles = {};
  let innerStyles = {};

  if (typeof innerColor === "undefined") {
    innerColor = "white";
  }
  if (typeof outerColor === "undefined") {
    outerColor = "text-color-primary";
  }
  if (typeof scale === "undefined") {
    scale = 1;
  } else {
    scale = scale / 100;
  }

  const oc = props.onClick;

  if (disabled) {
    styles.opacity = 0.3;
    styles.cursor = "not-allowed";
    innerStyles.cursor = "not-allowed";
  }

  styles.zoom = scale;
  innerStyles.fontSize = ".6em";

  return (
    <span
      onClick={oc}
      className={"fa-stack fa-2x " + props.className}
      title={props.title}
      style={styles}
    >
      <FontAwesomeIcon
        icon={["fas", outerIcon]}
        color={outerColor}
        className={"fa-stack-1x "}
      ></FontAwesomeIcon>
      <FontAwesomeIcon
        icon={["fas", innerIcon]}
        color={innerColor}
        className={"fa-inverse fa-stack-1x "}
        style={innerStyles}
      ></FontAwesomeIcon>
    </span>
  );
};

export const FAStack = (props) => {
  let { innerColor, style, main, sub, size, offsetY, offsetX, classes } = props;

  classes = classes || "";
  innerColor = innerColor || "#0B0";

  let osY = offsetY || -4.5;
  let osX = offsetX || 4;

  // calculate offsets

  if (osY < 1) {
    osY = "down-" + Math.abs(osY);
  } else {
    osY = "up-" + Math.abs(osY);
  }

  if (osX < 1) {
    osX = "left-" + Math.abs(osX);
  } else {
    osX = "right-" + Math.abs(osX);
  }

  if (!style) {
    style = "solid";
  }

  if (!size) {
    size = "fa-m";
  }

  main = "fa-" + main;
  if (sub) {
    sub = "fa-" + sub;
  }

  style = "fa-" + style;

  if (sub === false) {
    return (
      <span
        className={"fa-layers fa-fw " + size + " " + classes}
        style={{ "--fa-inverse": "#333" }}
      >
        <FontAwesomeIcon icon={main + " " + style} />
      </span>
    );
  }

  return (
    <span className={"fa-layers fa-fw " + size + " " + classes} style={{ "--fa-inverse": "#333" }}>
      <FontAwesomeIcon icon={main + " " + style} />
      <FontAwesomeIcon
        icon={"fa-circle fa-solid"}
        transform={osX + " " + osY + " shrink-3"}
        color="#fff"
      />
      <FontAwesomeIcon
        icon={sub + " " + style}
        transform={osX + " " + osY + " shrink-5"}
        style={{ color: innerColor, backgroundColor: "" }}
        inverse
        className=" svg-12icon-bg-color-white"
      />
    </span>
  );
};
