import ZevForm from "./ZevForm";
import { StateManager } from "../../Reusable/Core";

class Handler {
  tempFormStatus = {
    status: {
      isValidationError: null,
      isSubmitting: true,
      doPause: true,
      isSubmitSuccessful: null,
      isSubmitFailure: null,
      isCurrentSubmitFailure: null,
    },
  };
  newState = {};

  constructor(response: R, form: ZevForm, parentPageScope, callback) {
    this.form = form;
    this.response = response;
    this.newState = StateManager.meld(form, form.state, this.tempFormStatus); // _.merge({}, scope.state, this.tempFormStatus);
    this.data = form.data(true);
    this.scope = parentPageScope;
    this.cb = callback;

    this.reset = this.reset.bind(this);
  }

  reset() {
    const newState = {
      status: {
        isValidationError: false,
        isSubmitting: false,
        doPause: false,
        isSubmitDisabled: false,
        isSubmitFailure: false,
        isSubmitSuccessful: false,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  doPause() {
    const newState = {
      status: {
        doPause: true,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  markFailed() {
    const newState = {
      status: {
        isValidationError: false,
        isSubmitting: false,
        doPause: true,
        isSubmitDisabled: false,
        isSubmitFailure: true,
        isCurrentSubmitFailure: true,
        isSubmitSuccessful: false,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  markValidationError() {
    const newState = {
      status: {
        isValidationError: true,
        isSubmitting: false,
        isSubmitDisabled: false,
        doPause: true,
        isSubmitFailure: true,
        isCurrentSubmitFailure: true,
        isSubmitSuccessful: false,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  markException(isValidationError) {
    const ve = isValidationError || false;
    const newState = {
      status: {
        isValidationError: ve,
        isException: true,
        isSubmitting: false,
        doPause: true,
        isSubmitDisabled: false,
        isSubmitFailure: true,
        isCurrentSubmitFailure: true,
        isSubmitSuccessful: false,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  markSuccess() {
    const newState = {
      status: {
        isException: false,
        isSubmitting: false,
        doPause: true,
        isSubmitDisabled: false,
        isSubmitFailure: false,
        isCurrentSubmitFailure: false,
        isSubmitSuccessful: true,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  setRedirect(target) {
    const newState = {
      service: {
        redirectTarget: target,
      },
    };

    StateManager.meld(this.form, this.form.state, newState);
  }

  callback() {}

  animate() {}

  success() {}

  failed() {}

  handleAnimation() {}

  handle(state) {
    const prm = new Promise((res, rej) => {});

    if (state.flow === "auth") {
      if (this.response.hasValidationErrors()) {
        console.log("bafdadsfhg");
      }

      this.handleAnimation();
    }

    for (let dt in this.data) {
      this.newState.data[dt].touched = false;
    }

    this.scope.setState(this.newState);
    /*this.handler(this.form, this.data).then(
            response => {

            }
        ).catch(error => {

        });*/

    return prm;
  }
}

export default Handler;
