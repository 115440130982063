import R from "./R.js";

class RateLimit extends R {
  constructor(response) {
    super(response);
  }

  getInstance() {
    return this.data.instance;
  }

  getStatus() {
    return this.data.status;
  }

  hasErrors() {
    return this.data.hasErrors === true;
  }

  // hasValidationErrors() {
  //     return this.data.hasValidationErrors === true;
  // }

  getErrors() {
    return this.data.errors;
  }

  // getValidationErrors() {
  //     return this.data.validationErrors;
  // }

  getMessage() {
    return this.data.message;
  }
}

export default RateLimit;
