import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StoresListView from "../../../pages/Views/core/Stores/StoresListView";
import StoresEditView from "../../../pages/Views/core/Stores/StoresEditView";
import StoresAddView from "../../../pages/Views/core/Stores/StoresAddView";
import StoresDeleteView from "../../../pages/Views/core/Stores/StoresDeleteView";

const StoresRoute = {
  type: "collapse",
  name: "Stores",
  sidenav: true,
  order: 2.3,
  key: "admin-stores",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Store Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "stores-list",
      route: "/stores/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <StoresListView />,
    },
    {
      hide: true,
      name: "Edit Store",
      sidenav: true,
      key: "stores-edit",
      route: "/stores/edit/:storeId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <StoresEditView />,
    },
    {
      hide: true,
      name: "View Store",
      sidenav: true,
      key: "stores-view",
      route: "/stores/view/:storeId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <StoresEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Delete Store",
      sidenav: true,
      key: "stores-delete",
      route: "/stores/delete/:storeId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <StoresDeleteView />,
    },
    {
      name: "Add New Store",
      sidenav: true,
      key: "stores-add",
      route: "/stores/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <StoresAddView />,
    },
  ],
};

export default StoresRoute;
