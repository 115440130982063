import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const productImage = (product) => {
  if (
    product === false ||
    typeof product.productId.productImagesList === "undefined" ||
    product.productId.productImagesList.length === 0
  ) {
    return (
      <>
        <div
          style={{
            padding: "10px",
            backgroundColor: "#dddddd",
            margin: "10px 30%",
            width: "max-content",
            borderRadius: "8px",
          }}
        >
          <FontAwesomeIcon icon={["fad", "box-open-full"]} size={"2xl"} />
        </div>
      </>
    );
  }
  return <img height={60} src={product.productId.productImagesList[0].s3src} />;
};
