import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../../../components/SuiButton";
import React from "react";

export const CancelQuoteChanges = (props) => {
  const { cancelAction, requestStatus } = props;

  if ( requestStatus === 'QUOTE_ACCEPTED_BY_CUSTOMER') {
      return (
          <SuiButton color="primary" variant="contained" className={""} onClick={cancelAction}>
              <span>
                <FontAwesomeIcon icon={["fad", "chevron-circle-left"]} fixedWidth={true} spin={false} />
                  &nbsp;&nbsp;
              </span>
              <span>Go Back</span>
          </SuiButton>
      )
  }

  return (
    <SuiButton color="secondary" variant="contained" className={""} onClick={cancelAction}>
      <span>
        <FontAwesomeIcon icon={["fad", "times-circle"]} spin={false} />
        &nbsp;&nbsp;
      </span>
      <span>Cancel</span>
    </SuiButton>
  );
};
