import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = (Wrapper) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return <Wrapper {...props} params={params} navigate={navigate} location={location} />;
};

export default withRouter;
