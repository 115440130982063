import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContentHeader, mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import Grid from "@mui/material/Grid";
import withRouter from "../../../../withRouter";
import SuiTypography from "../../../../components/SuiTypography";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import SelectElement from "../../../../Reusable/Forms/Elements/SelectElement";
import states from "../../../../libs/states";

class ContractorsAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "contractor",
          endpoint: "/contractors/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let contractor = "";

    if (this.props.recordId) {
      contractor = this.ops
        .getRecord(this, this.props.recordId, null, null, this.props.entity)
        .then(
          function (data) {
            return { contractor: data };
          }.bind(this)
        );
    } else {
      contractor = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([contractor]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "contractor",
        loaderStyle: "contractor",
        service: {
          endpoint: "/contractors/save",
          redirectTarget: "/dashboard",
          entity: "Contractor",
        },
        labels: {
          submit: "Save Contractor",
          submitting: "Saving Contractor",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          name: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Name",
            label: "Contractor Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              minLength: { opt: 3, msg: "Min length of 3 required" },
              maxLength: { opt: 50, msg: "Max length of 50 required" },
            },
          },
          address1: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Address1",
            label: "Contractor Address1",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          address2: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Address2",
            label: "Contractor Address2",
            errors: [],
            vr: {},
          },
          city: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor City",
            label: "Contractor City",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          email: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Email",
            label: "Contractor Email",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          friEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Fri End",
            label: "Contractor Fri End",
            errors: [],
            vr: {},
          },
          friStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Fri Start",
            label: "Contractor Fri Start",
            errors: [],
            vr: {},
          },
          insurancePolicyCoverage: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Insurance Policy Coverage",
            label: "Contractor Insurance Policy Coverage",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              numericDecimal: { opt: true, msg: "Numeric/Decimal Required" },
            },
          },
          insurancePolicyId: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Insurance Policy Id",
            label: "Contractor Insurance Policy Id",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          isDeleted: {
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Is Deleted",
            label: "Contractor Is Deleted",
            errors: [],
            vr: {},
            defaultValue: 0,
          },
          monEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Mon End",
            label: "Contractor Mon End",
            errors: [],
            vr: {},
          },
          monStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Mon Start",
            label: "Contractor Mon Start",
            errors: [],
            vr: {},
          },
          phone: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Phone",
            label: "Contractor Phone",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          satEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Sat End",
            label: "Contractor Sat End",
            errors: [],
            vr: {},
          },
          satStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Sat Start",
            label: "Contractor Sat Start",
            errors: [],
            vr: {},
          },
          state: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor State",
            label: "Contractor State",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          sunEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Sun End",
            label: "Contractor Sun End",
            errors: [],
            vr: {},
          },
          sunStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Sun Start",
            label: "Contractor Sun Start",
            errors: [],
            vr: {},
          },
          thuEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Thu End",
            label: "Contractor Thu End",
            errors: [],
            vr: {},
          },
          thuStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Thu Start",
            label: "Contractor Thu Start",
            errors: [],
            vr: {},
          },
          tueEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Tue End",
            label: "Contractor Tue End",
            errors: [],
            vr: {},
          },
          tueStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Tue Start",
            label: "Contractor Tue Start",
            errors: [],
            vr: {},
          },
          website: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Website",
            label: "Contractor Website",
            errors: [],
            vr: {},
          },
          wedEnd: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Wed End",
            label: "Contractor Wed End",
            errors: [],
            vr: {},
          },
          wedStart: {
            value: null,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Wed Start",
            label: "Contractor Wed Start",
            errors: [],
            vr: {},
          },
          workFri: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Fri",
            label: "Contractor Work Fri",
            errors: [],
            vr: {},
          },
          workMon: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Mon",
            label: "Contractor Work Mon",
            errors: [],
            vr: {},
          },
          workSat: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Sat",
            label: "Contractor Work Sat",
            errors: [],
            vr: {},
          },
          workSun: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Sun",
            label: "Contractor Work Sun",
            errors: [],
            vr: {},
          },
          workThu: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Thus",
            label: "Contractor Work Thus",
            errors: [],
            vr: {},
          },
          workTue: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Tue",
            label: "Contractor Work Tue",
            errors: [],
            vr: {},
          },
          workWed: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Work Wed",
            label: "Contractor Work Wed",
            errors: [],
            vr: {},
          },
          zip: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Contractor Zip",
            label: "Contractor Zip",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.contractor, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid container>
              <Grid item md={8}>
                <Card variant={"outlined"}>
                  <CardContentHeader>Work Summary</CardContentHeader>
                  {/*<h3 className="mt2">Order Details</h3>*/}
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="name"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    value={this.state.data.name.value}
                  />
                  <br />
                  <hr />
                  <SuiTypography>Wo</SuiTypography>
                  <SuiTypography> </SuiTypography>
                  <br />
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Requests</CardContentHeader>
                  <br />
                  <Grid container>
                    <Grid item md={2}></Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={3}>
                <Card variant="outlined">
                  <CardContentHeader>Notes</CardContentHeader>
                  <CardContent>
                    <SuiTypography>Add note</SuiTypography>
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Contractor</CardContentHeader>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="name"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    value={this.state.data.name.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="phone"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.phone}
                    value={this.state.data.phone.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="email"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.email}
                    value={this.state.data.email.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="insurancePolicyCoverage"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.insurancePolicyCoverage}
                    value={this.state.data.insurancePolicyCoverage.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="insurancePolicyId"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.insurancePolicyId}
                    value={this.state.data.insurancePolicyId.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="address1"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address1}
                    value={this.state.data.address1.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="name"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address2}
                    value={this.state.data.address2.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="city"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.city}
                    value={this.state.data.city.value}
                  />
                  <SelectElement
                      options={states}
                    readonly={this.state.viewOnly}
                    type="text"
                    name="state"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.state}
                    value={this.state.data.state.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="zip"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.zip}
                    value={this.state.data.zip.value}
                  />
                  <CardContent>
                    <br />
                    <h3 className="mt2 mb2"></h3>
                  </CardContent>
                </Card>
                <Card className={"mt9"} variant="outlined">
                  <CardContentHeader>Some Detail</CardContentHeader>
                  <CardContent>
                    <TextElement
                      readonly={this.state.viewOnly}
                      type="text"
                      name="sku"
                      focus={true}
                      size="large"
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      field={this.state.data.name}
                      value={this.state.data.name.value}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(ContractorsAddEditForm);
