import React from "react";
import { RejectQuote } from "./AcceptRejectActionButtons/RejectQuote";
import Grid from "@mui/material/Grid";
import { Cancel } from "./AcceptRejectActionButtons/Cancel";

export const RejectActionButtons = (props) => {
  const { state, cancelReject, reject } = props;

  console.log(state);

  return (
    <>
      <Grid
        container
        align={"flex-end"}
        display={"flex"}
        className={"mt6"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Grid item align={"flex-end"} display={"flex"}>
          <RejectQuote reject={reject} state={state} />
          &nbsp;&nbsp;
          <Cancel cancelAction={cancelReject} state={state} />
        </Grid>
      </Grid>
    </>
  );
};
