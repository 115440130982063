import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SuiButton from "../../../../../components/SuiButton";

export const AcceptQuote = (props) => {
  const { accept, state } = props;

  return (
    <SuiButton color="primary" variant="gradient" onClick={accept}>
      <span>
        <FontAwesomeIcon icon={["fad", "thumbs-up"]} />
        &nbsp;&nbsp;
      </span>
      <span>{state.labels.accept}</span>
    </SuiButton>
  );
};
