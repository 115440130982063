import React from "react";
import withRouter from "../../../../withRouter";
import Grid from "@mui/material/Grid";
import { CardContent, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import InstallersAddEditForm from "../../../Forms/AddEdit/ins/InstallersAddEditForm";

class InstallersEditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: props.params.installerId,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardContent>
                  <InstallersAddEditForm
                    recordId={this.state.recordId}
                    entity={"installers"}
                    pageScope={this}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(InstallersEditView);
