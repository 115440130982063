import React from "react";
import SuiBox from "../../../../components/SuiBox";
import InstallersList from "../../../Lists/ins/Installers/InstallersList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class InstallersListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Installer"
            path={"/installers/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <InstallersList />
      </>
    );
  }
}

export default InstallersListView;
