import React from "react";
import ZevForm from "../ZevForm";
import withRouter from "../../../withRouter";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Operations from "../../../Reusable/Controller/Operations";
import {mapData, phone} from "../../../Reusable/Misc";
import logo from "../../../assets/img/logos/trans.png";
import moment from "moment";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { TableFooter } from "@mui/material";

class FinalPaymentReceiptForm extends ZevForm {
  constructor(props) {
    super(props);

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: false,
        service: {
          recordId: props.payment_token,
          entity: "payment",
          endpoint: "checkout/get_final_payment",
          apiPrefix: "/api/pub/v1/",
        },
        labels: {},
        status: {},
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let payment = "";
    if (this.props.paymentToken) {
      const params = {
        paymentToken: this.props.paymentToken,
      };

      payment = this.ops.getRecord(this, null, params, null, this.props.entity).then(
        function (data) {
          return { payment: data };
        }.bind(this)
      );
    } else {
      payment = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([payment]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        recordId: this.props.paymentToken,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "finalPaymentReview",
        loaderStyle: "installRequest",
        service: {
          endpoint: null,
          redirectTarget: null,
          entity: "paymentReceipt",
          apiPrefix: "pub",
        },
        openModal: {},
        labels: {},
        viewData: viewData,
        _data: {
          paymentToken: null,
        },
        data: {},
      });

      let ns = mapData(o.viewData.paymentToken, o);

      /*ns.viewData.quote.quote.quoteLineitemsList.map(
                    (lineItem) => {
                        var override = {};

                        if (
                            // typeof this.props.location !== 'undefined' &&
                            // typeof this.props.location.state !== 'undefined' && this.props.location.state !== null &&
                            // typeof this.props.location.state.vd !== 'undefined' && this.props.location.state.vd !== null &&
                            // typeof this.props.location.state.vd.selectedLineItems !== 'undefined' &&
                            hasOwnDeepProperty (this.props, 'location.state.vd.selectedLineItems') &&

                            typeof this.props.location.state.vd.selectedLineItems[lineItem.id] !== 'undefined'
                        ) {
                            override = this.props.location.state.vd.selectedLineItems[lineItem.id];
                        } else {
                            override = false;
                        }
                        ns.data.selectedLineItems[lineItem.id] = this.lineItemTemplate(lineItem, override);

                    }
                );
*/

      console.log(ns);

      this.setState(ns, () => {
        this.mounted();
      });
    });
  }

  cb() {}

  form() {
    let total = 0;

    return (
      <>
        <Box sx={{ mb: 3 }}>
          <div
            style={{
              backgroundImage: "linear-gradient(310deg,#0876bf,#029aff)",
              height: "62px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={logo} style={{ marginLeft: "20px" }} />
            <h2
              style={{
                justifySelf: "end",
                textAlign: "right",
                flexGrow: 1,
                marginRight: "20px",
                color: "#fff",
              }}
            >
              Payment Receipt
            </h2>
          </div>

          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={3}>
              <p>{this.state.viewData.payment.contractor.name}</p>
              <p>{this.state.viewData.payment.contractor.address1}</p>
              <p>{this.state.viewData.payment.contractor.address2}</p>
              <p>{this.state.viewData.payment.contractor.city}, {this.state.viewData.payment.contractor.state} {this.state.viewData.payment.contractor.zip}</p>
            </Grid>
            <Grid item xs={4}>
              <p>{phone(this.state.viewData.payment.contractor.phone)}</p>
              <p>
                <a href={this.state.viewData.payment.contractor.email}>{this.state.viewData.payment.contractor.email}</a>
              </p>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "25px" }}>
            <Grid item xs={3}>
              <h4>BILLED TO</h4>
              <p>
                {this.state.viewData.payment.user.firstName}&nbsp;
                {this.state.viewData.payment.user.lastName}
              </p>
              <p>
                {this.state.viewData.payment.site.address}
                <br />
                {this.state.viewData.payment.site.address2 ? (
                  <>
                    {this.state.viewData.payment.site.address2}
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {this.state.viewData.payment.site.city}, {this.state.viewData.payment.site.state}{" "}
                {this.state.viewData.payment.site.zip}
                <br />
                {this.state.viewData.payment.customer.phone}
              </p>
            </Grid>
            <Grid item xs={9}>
              <h2>Final Payment Received</h2>
              <h4>Thank you for your business</h4>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "60px" }}>
            <Grid item xs={2}>
              <h2>RECEIPT</h2>
              <br />
              <h4>RECEIPT NUMBER</h4>
              <p>{this.state.viewData.payment.payment.id}</p>
              <br />
              <h4>ISSUED</h4>
              <p>
                {moment
                  .utc(this.state.viewData.payment.payment.created)
                  .local()
                  .format("MMMM DD, yyyy")}
              </p>
              <p>
                {moment.utc(this.state.viewData.payment.payment.created).local().format("h:mm a")}
              </p>
            </Grid>
            <Grid item xs={10}>
              <Table className="table-striped data-list-table">
                <TableHead color={"primary"} sx={{ display: "table-header-group !important" }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell align={"right"}>Quantity</TableCell>
                    <TableCell align={"right"}>Price</TableCell>
                    <TableCell align={"right"}>Total</TableCell>
                  </TableRow>
                </TableHead>

                {this.state.viewData.payment.quote.paymentsList.map(
                    (paymentItem, ii) => {
                      return (
                          <>
                            <TableBody>
                              {paymentItem.paymentLineItemsList.map((item, i) => {
                                return (
                                    <TableRow>
                                      <TableCell>{++i}.</TableCell>
                                      <TableCell>
                                        <strong>{item.quoteLineItemId.productService}</strong>
                                        <br />
                                        <p>{item.quoteLineItemId.description}</p>
                                      </TableCell>
                                      <TableCell align={"right"}>{item.quoteLineItemId.quantity}</TableCell>
                                      <TableCell align={"right"}>$&nbsp;{item.quoteLineItemId.unitPrice}</TableCell>
                                      <TableCell align={"right"}>$&nbsp;{item.quoteLineItemId.total}</TableCell>
                                    </TableRow>
                                );
                              })}
                            </TableBody>
                          </>
                      )
                    }
                )}



                <TableFooter>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      Subtotal
                    </TableCell>
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      $&nbsp;{this.state.viewData.payment.total}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      Amount Received
                    </TableCell>
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      $&nbsp;{this.state.viewData.payment.totalPaid}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      Remaining Balance
                    </TableCell>
                    <TableCell align={"right"} style={{ fontSize: "16px", paddingRight: "5px" }}>
                      $&nbsp;{this.state.viewData.payment.remainingBalance}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>

          {/*<CustomerQuote cancelPageRequest={this.cancelPageRequest} acceptQuote={this.acceptQuote} rejectQuote={this.rejectQuote} total={parseInt(this.calculateTotal()).toFixed(2)} state={this.state} handleChange={this.localChangeHandler} />*/}
        </Box>
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(FinalPaymentReceiptForm);
