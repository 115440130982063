import hash from "object-hash";
import TableCell from "@mui/material/TableCell";
import { CostFormat, OnOffIcon } from "../../../../../../Reusable/Misc";
import { FAInset } from "../../../../../../Utility/FontAwesome";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { productImage } from "../../../../../../Reusable/QuoteUtilities";

export const InstallerLineItem = (props) => {
  const { lineItem, count, i, quoteStatus, removeLineItem } = props;

  return (
    <>
      <TableRow
        className={"fix-top-of-row rendered-installer-line-item"}
        style={{ verticalAlign: "top" }}
        key={hash([i, lineItem])}
      >
        <TableCell align={"right"}>{count}.</TableCell>
        <TableCell align={"center"}>{productImage(false)}</TableCell>
        <TableCell>
          <span className={"strong"}>{lineItem.productServiceName.value}</span>
          <br />
          <small className={"color-charcoal"}>{lineItem.description.value}</small>
        </TableCell>
        <TableCell>{lineItem.quantity.value}</TableCell>
        <TableCell align={"right"}>
          $
          {parseFloat(lineItem.price.value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </TableCell>
        <TableCell align={"right"}>
          {quoteStatus === "DEPOSIT_PAID" ? (
            <>
              <strong>Deposit</strong>
              <br />
              Paid
            </>
          ) : (
            <>{lineItem.price.value == 0 ? "Free" : "Not Paid"}</>
          )}
        </TableCell>
        <TableCell align={"right"}>
          <OnOffIcon
            yes={"Optional"}
            value={lineItem.optionalItem.value}
            onIcon={"option"}
            offIcon={"dollar-sign"}
            no={"Required"}
            extClass={"glower"}
            offColor={"#900"}
            onColor={"rgba(0,220,0)"}
          />
        </TableCell>
        <TableCell align={"right"}>
          <CostFormat cost={lineItem.price.value * lineItem.quantity.value} />
        </TableCell>
        <TableCell align={"center"}>
          <FAInset
            disabled={quoteStatus === "DEPOSIT_PAID"}
            scale={100}
            onClick={() => {
              if (quoteStatus === "DEPOSIT_PAID") {
                return false;
              } else {
                removeLineItem(lineItem, i);
              }
            }}
            className={"glower"}
            outerColor={"rgba(180,0,0,.6)"}
            outerIcon={"circle"}
            innerIcon={"trash-can"}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
