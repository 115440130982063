import SuiButton from "../../../../../../components/SuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const SaveQuoteDraft = (props) => {
  const { state, saveHandler } = props;

  return (
    <>
      <SuiButton
        color="warning"
        disabled={state.status.isDraftSaving === true}
        variant="gradient"
        className={
          state.status.isDraftFailed
            ? "glow-error-border"
            : (state.status.isDraftSaved ? "glow-primary-border" : "") + "  "
        }
        onClick={saveHandler}
      >
        {state.status.isDraftSaving ? (
          <>
            <span>
              <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true} />
              &nbsp;&nbsp;
            </span>
            <span>{state.labels.draftSaving}</span>
          </>
        ) : !state.status.isDraftSaving && state.status.isDraftSaved ? (
          <>
            <span>
              <FontAwesomeIcon icon={["fad", "check-circle"]} />
              &nbsp;&nbsp;
            </span>
            <span>{state.labels.draftSaved}</span>
          </>
        ) : !state.status.isDraftSaving && state.status.isDraftFailed ? (
          <>
            <span>
              <FontAwesomeIcon icon={["fad", "times-circle"]} />
              &nbsp;&nbsp;
            </span>
            <span>{state.labels.draftFailed}</span>
          </>
        ) : (
          <>
            <span>
              <FontAwesomeIcon icon={["fad", "floppy-disk"]} />
              &nbsp;&nbsp;
            </span>
            <span>{state.labels.draftSave}</span>
          </>
        )}
      </SuiButton>
    </>
  );
};
