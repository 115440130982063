import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SuiButton from "../SuiButton";

const Confirmation = (props) => {
  const { selValue, onClose, isError, title, content, open } = props;

  const handleClose = () => {
    onClose();
  };

  const { popupClass, pButtonClass } = isError
    ? { popupClass: "confirm-popup-error-state", pButtonClass: "error" }
    : { popupClass: "confirm-popup-success-state", pButtonClass: "primary" };

  return (
    <>
      <Dialog
        sx={{ zIndex: 4001 }}
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog"
      >
        <div className={popupClass + " confirmation-dialog"}>
          <DialogTitle className="confirmation-header">{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <SuiButton color={pButtonClass} onClick={handleClose} variant="gradient" size="medium">
              Okay, thanks
            </SuiButton>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default Confirmation;
