import React from "react";
import SuiBox from "../../../components/SuiBox";
import LabelElement from "./LabelElement";
import FieldValidationErrors from "../FieldValidationErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "@mui/material/Switch";

class CheckboxElement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.field = props.field;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {}

  render() {
    return (
      <>
        <SuiBox className="target-fields-wrapper" mb={2}>
          <LabelElement field={this.props.field} aboveLabel={this.props.aboveLabel}>
            {this.props.viewOnly ? (
              <>
                {this.props.value ? (
                  <>
                    <FontAwesomeIcon icon={["fad", "circle-check"]} />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={["fad", "circle-times"]} />
                  </>
                )}
              </>
            ) : (
              <>
                <Switch
                  checked={this.props.field.value == 1}
                  disableRipple={false}
                  defaultValue={0}
                  disabled={this.props.disabled}
                  // elevation
                  name={this.props.name}
                  // size="medium"
                  value={this.props.field.value == 1 ? 0 : 1}
                  onChange={this.props.onChange}
                  // sx={{ '& .MuiSvgIcon-root': {  fill: '#333', fontSize: 28 } }}
                  className="mr4"
                />
              </>
            )}
          </LabelElement>
          <div>
            <FieldValidationErrors fieldErrors={this.props.field.errors} />
          </div>
        </SuiBox>
      </>
    );
  }
}

export default CheckboxElement;
