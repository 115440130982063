import React, {useEffect, useState} from "react";
import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import SuiTypography from "../../../../components/SuiTypography";
import { productImage } from "../../../../Reusable/QuoteUtilities";
import Grid from "@mui/material/Grid";
import { CardContent, CardHeader } from "@mui/material";
import rgba from "../../../../assets/theme/functions/rgba";
import CreditCard from "../../../../Reusable/CreditCard";
import Card from "@mui/material/Card";
import StripeCheckoutWidget from "../../../../Reusable/StripeCheckoutWidget";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`&.${tableRowClasses.head} th`]: {
    color: "#fff !important",
    fontSize: 14,
  },
  [`&.${tableRowClasses.footer} `]: {
    backgroundColor: `${rgba(theme.palette.primary.main, 0.3)}`,
  },
  [`&.${tableRowClasses.footer} td p`]: {
    color: "#555 !important",
  },
  [`&.${tableRowClasses.footer} td`]: {
    fontSize: 14,
  },
}));

export const FinalPayment = (props) => {
  const { state, handleBlur, refForChild, handleChange, depositPct, total, cancelPageRequest, handleCardFocus } = props;

  const [ clientSecret, setClientSecret ] = useState('');
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ redirectUrl, setRedirectUrl ] = useState(null);

  async function pd(url, data) {
    const response = await fetch(url, {
      method: 'POST',
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data)
    })

    return response;
  }

  useEffect(() => {
    fetch("/zev.hub/api/pub/v1/checkout/get_stripe_config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const appearance = {
    theme: 'flat',
    labels: 'above',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#f3f3f3',
      colorText: '#30313d',
      fontSize: '.8',
      gridRowSpacing: "15px",
      gridColumnSpacing: "10px",
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px'
    },
    rules: {
      '.Button': {
        border: '4px solid #000'
      }
    }
  };

  useEffect(() => {
    console.log(state);

    // Create PaymentIntent as soon as the page loads
    pd("/zev.hub/api/pub/v1/checkout/get_final_payment_intent", {
      contractorId: state.viewData.job.contractor.id,
      installRequestId: state.viewData.job.irq.id,
      quoteId: state.viewData.job.quote.id
    })
        .then((res) => res.json())
        .then(({clientSecret, redirectUrl}) => {
          setClientSecret(clientSecret);
          setRedirectUrl(redirectUrl);
        });
  }, []);



  let prodTotal = 0;

  state.viewData.job.quoteLineItems.map((item) => {
    // if (!state._data.selectedLineItems.hasOwnProperty(item.id)) {
    //   return;
    // }

    prodTotal += parseFloat(item.quantity) * parseFloat(item.unitPrice);
  });

  let subTotal = prodTotal * (depositPct/100);

  if (state.settings.QUOTE_DEPOSIT_LIMIT_ENABLE === 'YES') {
    if (subTotal > state.settings.QUOTE_DEPOSIT_MAXIMUM) {
      subTotal = state.settings.QUOTE_DEPOSIT_MAXIMUM;
    } else if (subTotal < state.settings.QUOTE_DEPOSIT_MINIMUM) {
      subTotal = state.settings.QUOTE_DEPOSIT_MINIMUM;
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <SuiTypography variant="h4" fontWeight="bold">
            Your Order Item(s)
          </SuiTypography>
        </CardHeader>
        <CardContent>

          <>
            {state.viewData.job.quoteLineItems.map((lineItem) => {

              return (
                <Grid container>
                  <Grid
                    display={"flex"}
                    item
                    className={"p4 mt4 mb4"}
                    xs={1}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  ></Grid>
                  <Grid
                    display={"flex"}
                    xs={5}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                  >
                    <h4>{lineItem.prodSvc}</h4>
                    <h5 style={{ fontWeight: "normal" }}>{lineItem.desc}</h5>
                  </Grid>
                  <Grid
                    display={"flex"}
                    xs={2}
                    justifyContent={"center"}
                    alignItems={"flex-end"}
                    flexDirection={"column"}
                  >
                    <p className="small">
                      {lineItem.quantity}
                      <strong> @ </strong>${parseFloat(lineItem.pp).toFixed(2)} each
                    </p>
                  </Grid>
                  <Grid
                    display={"flex"}
                    xs={4}
                    justifyContent={"center"}
                    alignItems={"flex-end"}
                    flexDirection={"column"}
                  >
                    <h4>
                      ${" "}
                      {parseFloat(
                        lineItem.total
                      ).toFixed(2)}
                    </h4>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container>
              <Grid item xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container className={"mt4"}>
              <Grid
                display={"flex"}
                item
                xs={8}
                justifyContent={"flex-end"}
                alignItems={"center"}
                style={{}}
              ></Grid>
              <Grid
                display={"flex"}
                item
                xs={4}
                justifyContent={"flex-end"}
                alignItems={"center"}
                style={{}}
              >
                <>Total &nbsp;&nbsp;&nbsp;$ {parseFloat(state.viewData.job.total).toFixed(2)}</>
              </Grid>
            </Grid>
            <Grid container className={"mt4"}>
              <Grid
                  display={"flex"}
                  item
                  xs={8}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  style={{}}
              ></Grid>
              <Grid
                  display={"flex"}
                  item
                  xs={4}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  style={{}}
              >
                <span className={"text-color-primary"}>Deposit Paid &nbsp;&nbsp;&nbsp;$ {parseFloat(state.viewData.job.totalPaid).toFixed(2)}</span>
              </Grid>
            </Grid>


            <Grid container className={"mt2"}>
              <Grid
                display={"flex"}
                item
                xs={8}
                justifyContent={"flex-end"}
                alignItems={"center"}
                style={{}}
              ></Grid>
              <Grid
                display={"flex"}
                item
                xs={4}
                justifyContent={"flex-end"}
                alignItems={"center"}
                style={{}}
              >
                <strong>
                  Due Today &nbsp;&nbsp;&nbsp;${" "}
                  {parseFloat(state.viewData.job.remainingBalance).toFixed(2)}
                </strong>
              </Grid>
            </Grid>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                <StripeCheckoutWidget ref={refForChild} redirectUrl={redirectUrl} />
              </Elements>
            )}
          </>
        </CardContent>
      </Card>



      <Grid container className={"mt6 pt3 pb5"} style={{ zoom: '1'}}>
        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>

        </Grid>
      </Grid>
    </>
  );
};
