import React from "react";
import { ToastContainer } from "react-toastify";

class GlobalMessaging extends React.Component {
  constructor() {
    super();
  }

  cls() {}

  render() {
    return (
      <>
        <ToastContainer />
      </>
    );
  }
}

export default GlobalMessaging;
