import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Authentication layout components
import AuthLayout from "../../layouts/authentication/components/AuthLayout";

// Image
import logo from "assets/img/logos/trans.png";

import LoginForm from "../Forms/Auth/LoginForm";
import Grid from "@mui/material/Grid";

class Login extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <AuthLayout
        title="Sign In"
        description="Enter your email and password to sign in"
        illustration={{
          image: logo,
          title: "",
          description: "",
        }}
      >
        <SuiBox component="" role="">
          <Grid container spacing={1} mt={1}>
            {/*<Grid item xs={0} sm={0} md={1} lg={0} xl={1}/>*/}
            {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={10}>*/}
            <LoginForm pageScope={this} />
            {/*</Grid>*/}
            {/*<Grid item xs={0} sm={0} md={1} lg={0} xl={1}/>*/}
          </Grid>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={0} sm={0} md={1} />
            <Grid item xs={12} sm={12} md={10}>
              <SuiBox mt={0} textAlign="center">
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  <SuiTypography
                    component={Link}
                    to="/auth/forgot-password"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Forgot password
                  </SuiTypography>
                </SuiTypography>
                &nbsp; &nbsp; &nbsp;
                <SuiTypography variant="button" color="text" fontWeight="regular">
                  <SuiTypography
                    component={Link}
                    to="/register"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </SuiTypography>
                </SuiTypography>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </AuthLayout>
    );
  }
}

export default Login;
