import { Navigate, NavLink } from "react-router-dom";
import React from "react";
import { buttonIcon, buttonStyle, prepData, StateManager } from "./Core";
import _ from "lodash";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Type } from "./Constants";
import moment from "moment";
import { isResourceAllowed } from "./Auth/Check";
import axios from "axios";
import SuiButton from "../components/SuiButton";
import { setProperty } from "dot-prop";
import { FAInset } from "../Utility/FontAwesome";
import Grid from "@mui/material/Grid";
import SuiTypography from "../components/SuiTypography";
import gsap from "gsap";

library.add(fad);

export const pl = (word, count) => {
  if (count === 1) {
    return word;
  }
  return word + "s";
};

export const size = (obj) => {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export const caseWords = (words) => {
  if (words === null) {
    return null;
  }
  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export const copy = (obj, ...args) => {
  let copiedObj = _.cloneDeep(obj);

  copiedObj = merge(copiedObj, ...args);

  return copiedObj;
};

export const html = (str, className) => {
  const cn = className || "";
  return <div className={cn} dangerouslySetInnerHTML={{ __html: str }} />;
};

export const cbvalue = (val) => {
  if (val === true) {
    return 1;
  } else if (val === "1") {
    return 1;
  } else if (val === "true") {
    return 1;
  } else {
    return 0;
  }
};

export const formatDate = (date) => {
  if (date === "") {
    return "";
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const mapData = (data, schema) => {
  for (let k in data) {
    for (let v in schema.data) {
      if (v === k) {
        /*if (typeof data[k].subs !== "undefined") {
                  for (let sub in data[k].subs) {
                    schema.data[v][sub].value = data[k].subs[sub];
                    // console.log("setting schema.data[" + v + "][" + sub + "].value = " + data[k].subs[sub]);
        
                  }
                } else
                */
        if (typeof schema.data[k] === "undefined") {
        } else {
          if (typeof schema.data[k].type !== "undefined") {
            if (schema.data[k].type === Type.DATE) {
              // schema.data[k].value = Date.parse(data[k], 'yyyy-MM-dd');
              schema.data[k].value = moment.utc(data[k]).local().toDate();
              continue;
            }
          }

          if (typeof schema.data[k].selectvalue !== "undefined") {
            schema.data[k].selectvalue = data[k];
            schema.data[k].value = data[k].value;
          } else if (typeof schema.data[k].checked !== "undefined") {
            if (
              typeof schema.data[k].is_multi !== "undefined" &&
              schema.data[k].is_multi === true
            ) {
              schema.data[k].checked = data[k];
            } else if (
              typeof schema.data[k].is_radio !== "undefined" &&
              schema.data[k].is_radio === true
            ) {
              schema.data[k].checked = data[k];
            } else {
              schema.data[k].checked = data[k] === true || data[k] === 1 || data[k] === "1";
            }

            // schema.data[k].value = 1;
          } else {
            schema.data[k].value = data[k];
          }
        }
      }
    }
  }
  return schema;
};

export const merge = (obj1, ...args) => {
  let mergedObj = obj1;

  if (args.length > 0) {
    for (let k in args) {
      let arg = args[k];
      mergedObj = _.merge({}, mergedObj, arg);
    }
  }

  return mergedObj;
};

export const getIcon = (icon, status) => {
  let clsWrap = "";
  let title = "";

  if (status === true) {
    title = "Active";
    clsWrap = "icon-yes";
  } else if (status === false) {
    clsWrap = "icon-no";
    title = "Inactive";
  }

  return (
    <span title={title} className="icon-status">
      <FontAwesomeIcon className={clsWrap} icon={icon} />
    </span>
  );
};

export function getDotProp(dotNotationPath, sourceObject) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    if (typeof returnData[subPath] === "undefined") {
      return `Property ${subPath} not found`;
    }
    returnData = returnData[subPath];
  });
  return returnData;
}

export function setDotProp(dotNotationPath, sourceObject, value) {
  setProperty(sourceObject, dotNotationPath, value);
}

export const isDescendantOfClass = function (child, className) {
  let node = child.parentNode;
  while (node) {
    if (typeof node.className === "string" && node.className.indexOf(className) > -1) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export function extractKV(objects) {
  let returnObject = [];

  objects.map((object) => {
    let so = {};
    Object.keys(object).map((k) => {
      so[k] = object[k].value;
    });
    returnObject.push(so);
  });
  return returnObject;
}

export const rounder = (inval) => {
  if (inval) {
    return parseInt(inval).toFixed(2);
  }

  return "N/A";
};

export const SiteLinkButton = ({
  label,
  short,
  iconPosition,
  path,
  btnIcon,
  btnStyle,
  block,
  btnSize,
  disabled,
  ...extra
}) => {
  let style = buttonStyle(btnStyle);
  let icon = buttonIcon(btnIcon);
  let blockCls = "";
  let size = "";
  let title = "";
  let target = () => {};

  if (typeof extra.perms !== "undefined") {
    const perms = extra;
    if (!isResourceAllowed(perms)) {
      return null;
    }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  if (typeof extra.target !== "undefined") {
    if (extra.target === "blank") {
      target = (evt) => {
        evt.preventDefault();
        if (typeof path.pathname !== "undefined") {
          openInNewTab(path.pathname);
        } else {
          openInNewTab(path);
        }
      };
    }
  } else if (typeof extra.target === "function") {
    target = (evt) => {
      evt.preventDefault();
      extra.target(extra.cbParams);
    };
  }

  let btnSizes = ["xs", "sm", "default", "lg"];

  if (typeof btnSize !== "undefined") {
    if (btnSizes.indexOf(btnSize) !== -1) {
      size = "zev-btn-size-" + btnSize;
    } else {
      size = "zev-btn-size-default";
    }
  } else {
    size = "zev-btn-size-default";
  }

  if (block) {
    blockCls = " btn-block ";
  }

  let iconPos;

  if (typeof iconPosition !== "undefined" && iconPosition === "right") {
    iconPos = "idtr";
  } else {
    iconPos = "idt";
  }

  if (disabled) {
    return (
      <>
        <SuiButton
          variant="gradient"
          color={style}
          className={"zev-btn " + iconPos + " " + blockCls + " " + size + " " + style}
          to={path}
          disabled
          {...extra}
        >
          {icon}
          {label}
        </SuiButton>
      </>
    );
  }

  const es = extra.style || {};
  const spanStyle = _.merge(es, {
    margin: "7.5px",
    lineHeight: "28px",
  });

  let warning = "";

  if (extra.warning) {
    warning = (
      <span style={spanStyle} className="text-muted">
        {extra.warning}
      </span>
    );
  }

  if (short) {
    title = label;
    label = "";
  }

  return (
    <>
      <span>
        <NavLink to={path}>
          <SuiButton
            component="span"
            title={title}
            variant="gradient"
            color={style}
            iconOnly={short}
            className={"zev-btn " + iconPos + " " + blockCls + " " + size + " " + style}
            {...extra}
          >
            {icon}
            {label}
          </SuiButton>
        </NavLink>
        {warning}
      </span>
    </>
  );
};

export const wrapTimer = (delay) => {
  const p = new Promise((res, rej) => {
    setTimeout(res, delay);
  });

  return p;
};

export const cbTimer = (delay, callback) => {
  wrapTimer(delay).then(callback);
};

export const Redirection = ({ scope }) => {
  if (scope.state.status.doRedirect === true) {
    return (
      <Navigate
        replace={true}
        to={scope.state.service.redirectTarget}
        state={{ from: `${location.pathname}${location.search}` }}
      />
    );
  }

  return <></>;
};

export const switchValues = (data) => {
  for (let k in data) {
    if (typeof data[k].checked !== "undefined") {
      if (data[k].checked) {
        data[k].value = data[k].onValue;
      } else {
        data[k].value = data[k].offValue;
      }
    }
  }
  return data;
};

export const heartbeat = () => {
  const fd = new FormData();
  fd.set("ping", new Date().getTime());

  console.log("in here why?");

  const pr = new Promise((res, rej) => {
    setTimeout(function () {
      res("success");
    }, 1000);
  });

  return pr;

  return axios.post("/vwasa/heartbeat", fd, window.globals.formPostHeaders);
};

export const modify = (formData, value) => {
  if (typeof formData.modifier !== "undefined") {
    if (formData.modifier === "toUpperCase") {
      return value.toUpperCase();
    } else {
      return value;
    }
  }
  return value;
};

export const timer = (delay, cb) => {
  return setTimeout(() => {
    cb();
  }, delay);
};

const processAndUpdateUI = (response, scope, errors, cb) => {
  if (errors === true) {
    let path = "";

    if (response.hasValidationErrors === true) {
      let errorObj = {};

      let resp = {
        formStatus: {},
      };

      for (let idx in response.validationErrors) {
        if (typeof errorObj[response.validationErrors[idx].field] === "undefined") {
          errorObj[response.validationErrors[idx].field] = [];
        }

        if (response.validationErrors[idx].value === "MULTIPLE") {
          errorObj[response.validationErrors[idx].field] = response.validationErrors[idx].error;
        } else {
          errorObj[response.validationErrors[idx].field].push(response.validationErrors[idx].error);
        }
      }

      const SM = new StateManager(scope);

      for (let errField in errorObj) {
        path = "formData." + errField + ".errors";

        SM.merge(path, errorObj[errField]);
      }

      /**
       * @TODO: ADD THE VALIDATION ERRORS TO CB BLOCK BELOW YO
       */

      SM.commit(true);

      cb({
        formProps: {
          currentSubmitLabel: scope.state.formProps.submitLabel,
        },
        formStatus: {
          isSubmitSuccessful: false,
          isCurrentSubmitFailure: true,
          isValidationError: true,
          isOtherError: false,
          isSubmitFailure: true,
          isSubmitting: false,
          errorMessages: errorObj,
        },
      });
    } else {
      let errorMessage;

      if (typeof response.errors !== "undefined") {
        if (typeof response.errors["CUSTOM"] !== "undefined") {
          errorMessage = response.errors["CUSTOM"];
        } else if (typeof response.errors["GENERAL_ERROR"] !== "undefined") {
          errorMessage = response.errors["GENERAL_ERROR"];
        } else {
          errorMessage = "Unknown Error";
        }
      } else {
        errorMessage = "Unknown Error";
      }

      cb({
        formProps: {
          currentSubmitLabel: scope.state.formProps.submitLabel,
        },
        formStatus: {
          isSubmitSuccessful: false,
          isCurrentSubmitFailure: true,
          isValidationError: false,
          isOtherError: errorMessage,
          isSubmitFailure: true,
          isSubmitting: false,
        },
      });
    }
  } else {
    if (response.status === true && response.hasErrors === false) {
      const SM = new StateManager(scope);
      SM.merge("formStatus.isSubmitSuccessful", true);
      SM.merge("formStatus.isCurrentSubmitFailure", false);
      SM.commit();

      timer(300, () => {
        const SM = new StateManager(scope);
        SM.merge("formStatus.isSubmitting", false);
        SM.commit();
      });

      cb();
    } else if (response.status === false && response.hasErrors === true) {
      scope.state.formProps.currentSubmitLabel = scope.state.formProps.submitLabel;

      const SM = new StateManager(scope);
      SM.merge("formProps.currentSubmitLabel", scope.state.formProps.submitLabel);
      // SM.merge('formStatus.isCurrentSubmitSuccessful', false);
      SM.merge("formStatus.isSubmitFailure", true);
      SM.commit();

      cb({
        formStatus: {
          isSubmitSuccessful: false,
          isCurrentSubmitFailure: true,
          isValidationError: false,
          isOtherError: true,
          isSubmitFailure: true,
          isSubmitting: false,
        },
      });

      timer(300, () => {
        const SM = new StateManager(scope);
        SM.merge("formStatus.isSubmitting", false);
        SM.commit();
      });
    }
  }
};

export const formSubmissionResponseHandler = (scope, formdata, handler, recordId, form, cb, ex) => {
  const data = prepData(formdata);
  const id = recordId || null;

  const fs = {
    formStatus: {
      isSubmitting: true,
      isSuccess: null,
      isFailed: null,
    },
  };

  const ext = ex || {};

  const newState = _.merge({}, scope.state, fs, ext);

  for (let d in newState.formData) {
    newState.formData[d].touched = false;
  }

  scope.setState(newState);

  handler(form, data, id)
    .then((response) => {
      processAndUpdateUI(response.data, form, null, cb);
    })
    .catch((error) => {
      if (typeof error.response !== "undefined") {
        if (error.response.data.hasValidationErrors) {
          processAndUpdateUI(error.response.data, scope, true, cb);
        } else {
          processAndUpdateUI(error.response.data, scope, true, cb);
        }
      } else {
        processAndUpdateUI(
          {
            errors: {
              CUSTOM: "The request to the server timed out",
            },
          },
          scope,
          true,
          cb
        );
      }
    });
};

export const scrollToElement = (element, durationTop, durationLeft) => {
  var startTop = element.scrollTop,
    startLeft = element.scrollLeft,
    changeTop = durationTop - startTop,
    changeLeft = durationLeft - startLeft, //window.scrollX - startLeft,
    currentTimeTop = 0,
    currentTimeLeft = 0,
    increment = 20;

  var animateScrollTop = function () {
    currentTimeTop += increment;
    var val = Math.easeInOutQuad(currentTimeTop, startTop, changeTop, durationTop);
    element.scrollTop = val;
    if (currentTimeTop < durationTop) {
      setTimeout(animateScrollTop, increment);
    }
  };

  var animateScrollLeft = function () {
    currentTimeLeft += increment;
    var val = Math.easeInOutQuad(currentTimeLeft, startLeft, changeLeft, durationLeft);
    element.scrollLeft = val;
    if (currentTimeLeft < durationLeft) {
      setTimeout(animateScrollLeft, increment);
    }
  };

  animateScrollTop();
  animateScrollLeft();
};

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const CardContentHeader = (props) => {
  const { color } = props;
  var style = {};
  if (color === "error") {
    style = { backgroundColor: "#900" };
  } else if (color === "success") {
    style = { backgroundColor: "#090" };
  }

  return (
    <div className="card-content-header">
      <div className="card-content-data" style={style}>
        {props.children}
      </div>
    </div>
  );
};

export const WarningHeader = (props) => {
  const { color, rounded } = props;

  var style = {};

  let c = color || "error";
  let r = rounded ? " wb-rounded " : "";

  return (
    <Grid
      className={"mt5 warning-block-outer-body " + c + r}
      container
      style={{ padding: "20px", marginBottom: "40px" }}
    >
      <Grid className="warning-block-inner-body" item md={12}>
        <h3>{props.header}</h3>

        <SuiTypography variant={"div"} color={"secondary"}>
          {props.children}
        </SuiTypography>
      </Grid>
    </Grid>
  );
};

export function phone(value) {

  function clean(v) {
    v = new String(v);
    return v.toString().replace(/\D+/g, '');
  }

  function cleanDiff(v) {
    if (v == null) {
      return true;
    }
    let str = clean(v);
    let lenStr = str.length;
    let lenV   = v.length;

    let pct    = (lenStr/lenV);

    pct = Math.round(pct*1000) / 10;

    return pct > 40;
  }


  if (value !== undefined) {
    if (cleanDiff(value)) {
      var cleaned = clean(value);
      var match = cleaned.match(/^(\+1?)?\W?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return `(${match[2]}) ${match[3]}-${match[4]}`;
      } else {
        return value;
      }
    } else {
      return value;
    }
  } else {
    return 'N/A';
  }

}

export const OnOffIcon = (props) => {
  const { yes, no, value, offIcon, onIcon, offColor, onColor, outerIcon, extClass } = props;

  if (value == 1) {
    return (
      <>
        <FAInset
          className={extClass}
          scale={70}
          innerIcon={onIcon || "check"}
          outerColor={onColor || "rgb(0,200,0)"}
          outerIcon={outerIcon || "circle"}
        />{" "}
        {yes}
      </>
    );
  } else {
    return (
      <>
        <FAInset
          className={extClass}
          scale={70}
          innerIcon={offIcon || "xmark"}
          outerColor={offColor || "rgb(200,0,0)"}
          outerIcon={outerIcon || "circle"}
        />{" "}
        {no}
      </>
    );
  }
};

export const YesOrNo = (props) => {
  const { yes, no, value } = props;
  return (
    <>
      <OnOffIcon yes={yes} value={value} no={no} extClass={"glower"} />
    </>
  );
};

export const CostFormat = (props) => {
  let price = props.cost;

  price = Math.round(price * 100) / 100;

  return (
    <>${price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
  );
};

export class StatusElement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    setTimeout(this.renderSvgItems, 400);
  }

  renderSvgItems() {
    const createSVG = (width, height, className, childType, childAttributes) => {
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

      svg.classList.add(className);

      const child = document.createElementNS("http://www.w3.org/2000/svg", childType);

      svg.setAttributeNS("http://www.w3.org/2000/svg", "viewBox", `0 0 ${width} ${height}`);

      for (const attr in childAttributes) {
        child.setAttribute(attr, childAttributes[attr]);
      }

      svg.appendChild(child);

      return { svg, child };
    };

    document.querySelectorAll(".StatusElementContainer").forEach((element) => {
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      const style = getComputedStyle(element);

      const { svg, child: circle } = createSVG(width, height, "dots", "circle", {
        cx: "0",
        cy: "0",
        r: "0",
      });

      const strokeGroup = document.createElement("div");
      strokeGroup.classList.add("stroke");

      const { svg: stroke } = createSVG(width, height, "stroke-line", "rect", {
        x: "0",
        y: "0",
        width: "100%",
        height: "100%",
        rx: parseInt(style.borderRadius, 10),
        ry: parseInt(style.borderRadius, 10),
        pathLength: "10",
      });

      element.appendChild(svg);

      strokeGroup.appendChild(stroke);
      strokeGroup.appendChild(stroke.cloneNode(true));

      element.appendChild(strokeGroup);

      const timeline = gsap.timeline({ paused: true });

      const DOT_AMOUNT = 20;

      for (var i = 0; i < DOT_AMOUNT; i++) {
        var p = circle.cloneNode(true);
        svg.appendChild(p);

        gsap.set(p, {
          attr: {
            cx: gsap.utils.random(width * 0.25, width * 0.75),
            cy: gsap.utils.random(height * 0.5, height * 0.5),
            r: 0,
          },
        });

        var durationRandom = gsap.utils.random(10, 12);

        var tl = gsap.timeline();

        tl.to(
          p,
          {
            duration: durationRandom,
            rotation: i % 2 === 0 ? 200 : -200,
            attr: {
              r: gsap.utils.random(0.75, 1.5),
              cy: -width * gsap.utils.random(1.25, 1.75),
            },
            physics2D: {
              angle: -90,
              gravity: gsap.utils.random(-4, -8),
              velocity: gsap.utils.random(10, 25),
            },
          },
          "-=" + durationRandom / 2
        ).to(
          p,
          {
            duration: durationRandom / 3,
            attr: {
              r: 0,
            },
          },
          "-=" + durationRandom / 4
        );

        timeline.add(tl, i / 3);
      }

      svg.removeChild(circle);

      const finalTimeline = gsap.to(timeline, {
        duration: 10,
        repeat: -1,
        time: timeline.duration(),
        paused: true,
      });

      const stars = gsap.to(element, {
        repeat: -1,
        repeatDelay: 0.75,
        paused: true,
        keyframes: [
          {
            "--status-element-star-2-scale": ".5",
            "--status-element-star-2-opacity": ".25",
            "--status-element-star-3-scale": "1.25",
            "--status-element-star-3-opacity": "1",
            duration: 0.3,
          },
          {
            "--status-element-star-1-scale": "1.5",
            "--status-element-star-1-opacity": ".5",
            "--status-element-star-2-scale": ".5",
            "--status-element-star-3-scale": "1",
            "--status-element-star-3-opacity": ".5",
            duration: 0.3,
          },
          {
            "--status-element-star-1-scale": "1",
            "--status-element-star-1-opacity": ".25",
            "--status-element-star-2-scale": "1.15",
            "--status-element-star-2-opacity": "1",
            duration: 0.3,
          },
          {
            "--status-element-star-2-scale": "1",
            duration: 0.35,
          },
        ],
      });

      element.addEventListener("pointerenter", () => {
        gsap.to(element, {
          "--status-element-dots-opacity": ".5",
          duration: 0.25,
          onStart: () => {
            finalTimeline.restart().play();
            setTimeout(() => stars.restart().play(), 500);
          },
        });
      });

      element.addEventListener("pointerleave", () => {
        gsap.to(element, {
          "--status-element-dots-opacity": "0",
          "--status-element-star-1-opacity": ".25",
          "--status-element-star-1-scale": "1",
          "--status-element-star-2-opacity": "1",
          "--status-element-star-2-scale": "1",
          "--status-element-star-3-opacity": ".5",
          "--status-element-star-3-scale": "1",
          duration: 0.15,
          onComplete: () => {
            finalTimeline.pause();
            stars.pause();
          },
        });
      });
    });
  }

  render() {
    return (
      <Grid container className={"StatusElementContainer " + this.props.color}>
        <Grid item className={"StatusElementIcon"}>
          {this.props.icon}
        </Grid>
        <Grid item className={"StatusElementStatus"}>
          <span>{this.props.status}</span>
        </Grid>
      </Grid>
    );
  }
}

export const RequestListingStatus = (s) => {
  var color, text;

  switch (s) {
    case "ASSIGNED":
      text = "Request Assigned";
      color = "warning";
      break;
    case "QUOTE_SENT":
      text = "Quote Sent";
      color = "primary";
      break;
    case "QUOTE_DRAFT":
      text = "Quote Draft";
      color = "light";
      break;
    case "QUOTE_REJECTED":
      text = "Rejected";
      color = "error";
      break;
    case "QUOTE_ACCEPTED_DEPOSIT_PAID":
    case "QUOTE_ACCEPTED":
    case "ACCEPTED":
      text = "Accepted & Deposit Paid";
      color = "success";
      break;
    case "INVOICE_PAID":
      text = "Closed & Invoice Paid";
      color = "success";
      break;
    case "PEND_CONTRACTOR_RESPONSE":
      text = "Pending Cont. Resp.";
      color = "warning";
      break;
    case "PEND_CUSTOMER_RESPONSE":
      text = "Pending Cust. Resp.";
      color = "warning";
      break;
    case "JOB_STARTED":
      text = "Job Started";
      color = "dblue";
      break;
    case "NO_RESPONSE":
      text = "No Response";
      color = "dark";
      break;
    case "PENDING_ASSIGNMENT":
    default:
      text = "Pending Assignment";
      color = "secondary";
      break;
  }

  return <div className={"gt-alert gt-alert-" + color}>{text}</div>;
};

export const hasOwnDeepProperty = (obj, propertyPath) => {
  if (!propertyPath) return false;

  var properties = propertyPath.split(".");

  for (var i = 0; i < properties.length; i++) {
    var prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return false;
    } else {
      obj = obj[prop];
    }
  }

  return true;
};
