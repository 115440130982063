import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";

class InstallersDeleteForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        private: true,
        service: {
          recordId: props.recordId,
          entity: "installer",
          endpoint: "/installers/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let installer = "";

    if (this.props.recordId) {
      installer = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { installer: data };
        }.bind(this)
      );
    } else {
      installer = new Promise(function (resolve, reject) {
        reject();
      });
    }

    this.promise = await Promise.all([installer]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: <FontAwesomeIcon icon={["fad", "trash"]} fixedWidth={true} className={"mr2"} />,
        flow: "installer",
        loaderStyle: "installer",
        service: {
          endpoint: "/installers/delete",
          redirectTarget: "/installers/list",
          entity: "Installer",
        },
        labels: {
          submit: "Delete Installer",
          submitting: "Deleting Installer",
          submitted: "Deleted",
          failed: "Delete Failed",
        },
        viewData: viewData,
        data: {
          confirmDelete: {
            type: "checkbox",
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            label: "Confirm Delete",
            touched: false,
            placeholder: "",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.installer, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    Please confirm delete
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2}></Grid>

                <Grid item xs={8} className="bg-primary p2">
                  <Grid item className="pr1" xs={12}>
                    <h3>Confirm Delete:</h3>

                    <h5>
                      <pre>Installer:</pre>
                    </h5>
                    <br />
                    <p>
                      Are you sure you wish to delete this installer? This action cannot be undone.
                    </p>
                  </Grid>
                  <Grid item className="pr1" xs={12}>
                    <CheckboxElement
                      onChange={this.handleChange}
                      name={"confirmDelete"}
                      field={this.state.data.confirmDelete}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2} className="p2"></Grid>
              </Grid>
            </Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(InstallersDeleteForm);
