import React, { ReactElement, useEffect, useRef } from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function Widget({ center, zoom }: { center: google.maps.LatLngLiteral, zoom: number }) {
  const ref = useRef();

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  });

  return <div ref={ref} style={{ height: "320px" }} id="map" />;
}

function IframeWidget(props) {

  const re = /([\s,]+)/

  const address = props.addr.replace(re, "+");

  return (
      <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling=""
          loading={"lazy"}
          referrerPolicy={"no-referrer"}
          allow="accelerometer; autoplay;"
          src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyD_7P89aAJoVRixqfQ7pjP6HqV571hzTJk&q=" + address}
      >
      </iframe>
  )
}

const GoogleMap = (props) => {
  const ll = {
    lat: 42.200679779052734,
    lng: -83.67185974121094,
  };

  return (
    <Wrapper apiKey={"AIzaSyD_7P89aAJoVRixqfQ7pjP6HqV571hzTJk"}>
      {/*<Widget center={ll} zoom={15} />*/}
      <IframeWidget addr={props.addr}/>
    </Wrapper>
  );
};

export default GoogleMap;
