import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";

class CustomerSurveysQuestionsAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "customerSurveysQuestion",
          endpoint: "/customerSurveysQuestions/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let customerSurveysQuestion = "";

    if (this.props.recordId) {
      customerSurveysQuestion = this.ops
        .getRecord(this, this.props.recordId, null, null, this.props.entity)
        .then(
          function (data) {
            return { customerSurveysQuestion: data };
          }.bind(this)
        );
    } else {
      customerSurveysQuestion = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([customerSurveysQuestion]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "customerSurveysQuestion",
        loaderStyle: "customerSurveysQuestion",
        service: {
          endpoint: "/customerSurveysQuestions/save",
          redirectTarget: "/dashboard",
          entity: "CustomerSurveysQuestion",
        },
        labels: {
          submit: "Save CustomerSurveysQuestion",
          submitting: "Saving CustomerSurveysQuestion",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          name: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Customer Surveys Question Name",
            label: "Customer Surveys Question Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              minLength: { opt: 3, msg: "Min length of 3 required" },
              maxLength: { opt: 100, msg: "Max length of 100 required" },
            },
          },
          description: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Customer Surveys Question Description",
            label: "Customer Surveys Question Description",
            errors: [],
            vr: {},
          },
          isActive: {
            type: "checkbox",
            value: 1,
            valid: true,
            checked: false,
            errors: [],
          },
          isDeleted: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Customer Surveys Question Is Deleted",
            label: "Customer Surveys Question Is Deleted",
            errors: [],
            vr: {},
          },
        },
      });

      let ns = mapData(o.viewData.customerSurveysQuestion, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="firstName"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    value={this.state.data.name.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <TextAreaInput
                      sz="1"
                      label="Description"
                      name="description"
                      placeholder={this.state.data.description.placeholder}
                      setFieldData={this.setFieldData}
                      touched={this.state.data.description.touched}
                      valid={this.state.data.description.valid}
                      value={this.state.data.description.value}
                      errors={this.state.data.description.errors}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={12}>
                  <CheckboxElement
                    readonly={this.state.viewOnly}
                    field={this.state.data.isActive}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={12}>
                  <CheckboxElement
                    readonly={this.state.viewOnly}
                    field={this.state.data.isDeleted}
                  />
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(CustomerSurveysQuestionsAddEditForm);
