import React from "react";
import ZevList from "../../../Forms/ZevList";
import { SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";
import moment from "moment";

class ProductsList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "products",
        endpoint: "/products",
      },
      sortFields: [
        { f: "id", l: "ID" },
        { f: false, l: "" },
        { f: "name", l: "Product name" },
        { f: "vendor", l: "Vendor" },
        { f: "sku", l: "SKU" },
        { f: "total", l: "Price" },
        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record) {
    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    const actions = (product) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View product"
            path={"/products/view/" + product.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit product"
            path={"/products/edit/" + product.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete product"
            path={"/products/delete/" + product.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    return {
      id: record.id,
      images: (
        <div align={"center"}>
          <img src={record.images[0].s3src} style={{ maxHeight: "80px" }} />
        </div>
      ),
      name: record.name,
      vendor: record.vendor,
      sku: record.sku,
      price: "$" + record.total.toFixed(2),

      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(ProductsList);
