import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../../../components/SuiButton";
import React from "react";

export const AcceptSendQuote = (props) => {
  const { sendQuoteDisabled, state, sendHandler } = props;

  if (state.viewData.installRequest.irr.status === 'QUOTE_ACCEPTED_BY_CUSTOMER') {
      return (
          <SuiButton
              color="dark"
              disabled={true}
              variant="contained"
          >
              <span>
                  <FontAwesomeIcon icon={["fad", "check-circle"]} />
                  &nbsp;&nbsp;
          </span>
              <span>Quote Sent and Accepted</span>
          </SuiButton>
      );
  }

  return (
    <SuiButton
      color="primary"
      disabled={sendQuoteDisabled || state.status.isQuoteSending === true}
      variant="contained"
      className={
        state.status.isQuoteFailed
          ? "glow-error-border"
          : (state.status.isQuoteSent ? "glow-success-border" : "") + " "
      }
      onClick={sendHandler}
    >
      {state.status.isQuoteSending ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "spinner-third"]} spin={true} />
              &nbsp;&nbsp;
          </span>
            <span>{state.labels.quoteSending}</span>
        </>
      ) : !state.status.isQuoteSending && state.status.isQuoteSent ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "check-circle"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.quoteSent}</span>
        </>
      ) : !state.status.isQuoteSending && state.status.isQuoteFailed ? (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "times-circle"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.quoteFailed}</span>
        </>
      ) : (
        <>
          <span>
            <FontAwesomeIcon icon={["fad", "envelope"]} />
            &nbsp;&nbsp;
          </span>
          <span>{state.labels.quoteSend}</span>
        </>
      )}
    </SuiButton>
  );
};
