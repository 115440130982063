import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VehiclesListView from "../../../pages/Views/cst/Vehicles/VehiclesListView";
import VehiclesEditView from "../../../pages/Views/cst/Vehicles/VehiclesEditView";
import VehiclesAddView from "../../../pages/Views/cst/Vehicles/VehiclesAddView";
import VehiclesDeleteView from "../../../pages/Views/cst/Vehicles/VehiclesDeleteView";

const VehiclesRoute = {
  type: "collapse",
  name: "Vehicles",
  sidenav: true,
  order: 2,
  key: "admin-vehicles",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Vehicle Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "vehicles-list",
      route: "/vehicles/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <VehiclesListView />,
    },
    {
      hide: true,
      name: "Edit Vehicle",
      sidenav: true,
      key: "vehicles-edit",
      route: "/vehicles/edit/:vehicleId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <VehiclesEditView />,
    },
    {
      hide: true,
      name: "Delete Vehicle",
      sidenav: true,
      key: "vehicles-delete",
      route: "/vehicles/delete/:vehicleId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <VehiclesDeleteView />,
    },
    {
      name: "Add New Vehicle",
      sidenav: true,
      key: "vehicles-add",
      route: "/vehicles/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <VehiclesAddView />,
    },
  ],
};

export default VehiclesRoute;
