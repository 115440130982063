import ZevForm from "../../pages/Forms/ZevForm";

class FormSecurity {
  constructor(form: ZevForm) {
    this.formData = form;
  }

  secure() {
    const secured = window.globals.operations.secureForm(this.formData);
    return secured;
  }
}

export default FormSecurity;
