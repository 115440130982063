import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import withRouter from "../../../../withRouter";
import SelectInput from "@mui/material/Select/SelectInput";

class SitesAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
        this,
        {
          viewOnly: this.props.viewOnlyMode,
          private: true,
          service: {
            recordId: props.recordId,
            entity: "site",
            endpoint: "/sites/get",
          },
        },
        false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let site = "";

    if (this.props.recordId) {
      site = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
          function (data) {
            return { site: data };
          }.bind(this)
      );
    } else {
      site = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([site]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
            <FontAwesomeIcon
                icon={["fad", "floppy-disk-circle-arrow-right"]}
                fixedWidth={true}
                className={"mr2"}
            />
        ),
        flow: "site",
        loaderStyle: "site",
        service: {
          endpoint: "/sites/save",
          redirectTarget: "/dashboard",
          entity: "Site",
        },
        labels: {
          submit: "Save Site",
          submitting: "Saving Site",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          address: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site Address",
            label: "Site Address",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          address2: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site Address2",
            label: "Site Address2",
            errors: [],
            vr: {},
          },
          city: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site City",
            label: "Site City",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          customerId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site Customer Id",
            label: "Site Customer Id",
            errors: [],
            vr: {},
          },
          isDeleted: {
            type: "checkbox",
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site Is Deleted",
            label: "Site Is Deleted",
            errors: [],
            vr: {},
          },
          state: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site State",
            label: "Site State",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          zip: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Site Zip",
            label: "Site Zip",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.site, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
        <>
          {this.state.initialized === true ? (
              <>
                  <Grid container>
                    <Grid item xs={12}>
                      <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                        DETAILS
                      </SuiBox>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item className="pr1" xs={6}>
                      <TextElement
                          readonly={this.state.viewOnly}
                          type="text"
                          name="firstName"
                          focus={true}
                          size="large"
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          field={this.state.data.address}
                          value={this.state.data.address.value}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item sm="12">
                      &nbsp;
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item className="pr1" xs={6}>
                      <TextElement
                          readonly={this.state.viewOnly}
                          type="text"
                          name="firstName"
                          focus={true}
                          size="large"
                          onChange={this.handleChange}
                          onBlur={this.handleBlur}
                          field={this.state.data.address2}
                          value={this.state.data.address2.value}
                      />
                    </Grid>
                  </Grid>
                    <Grid container>
                      <Grid item sm="12">
                        &nbsp;
                      </Grid>
                    </Grid>
                  <Grid container>

                  </Grid>

                    <Grid container>
                      <Grid item className="pr1" xs={4}>
                        <TextElement
                            readonly={this.state.viewOnly}
                            type="text"
                            name="firstName"
                            focus={true}
                            size="large"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            field={this.state.data.city}
                            value={this.state.data.city.value}
                        />
                      </Grid>
                      <Grid item className="pr1" xs={3}>
                        <TextElement
                            readonly={this.state.viewOnly}
                            type="text"
                            name="firstName"
                            focus={true}
                            size="large"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            field={this.state.data.state}
                            value={this.state.data.state.value}
                        />
                      </Grid>

                      <Grid item sm="3">
                        <TextElement
                            readonly={this.state.viewOnly}
                            type="text"
                            name="firstName"
                            focus={true}
                            size="large"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            field={this.state.data.zip}
                            value={this.state.data.zip.value}
                        />
                      </Grid>

                    </Grid>
                    <hr className="mt5 mb5" />
                <Grid container>
                  <Grid item className="pr1" xs={12}>
                    <CheckboxElement
                        readonly={this.state.viewOnly}
                        field={this.state.data.isDeleted}
                    />
                  </Grid>
                </Grid>


                    <br />
                    <br />
                  </>
                  ) : (
                  <></>
                  )}
                </>
                );
                }

                render() {
                return <>{this.wrapper()}</>;
          }
          }

          export default withRouter(SitesAddEditForm);
