import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrdersListView from "../../../pages/Views/ord/Orders/OrdersListView";
import OrdersEditView from "../../../pages/Views/ord/Orders/OrdersEditView";
import OrdersAddView from "../../../pages/Views/ord/Orders/OrdersAddView";
import OrdersDeleteView from "../../../pages/Views/ord/Orders/OrdersDeleteView";

const OrdersRoute = {
  type: "collapse",
  name: "Orders",
  sidenav: true,
  order: 2,
  key: "admin-orders",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Order Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "orders-list",
      route: "/orders/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <OrdersListView />,
    },
    {
      hide: true,
      name: "Edit Order",
      sidenav: true,
      key: "orders-edit",
      route: "/orders/edit/:orderId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <OrdersEditView />,
    },
    {
      hide: true,
      name: "View Order",
      sidenav: true,
      key: "orders-view",
      route: "/orders/view/:orderId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <OrdersEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Delete Order",
      sidenav: true,
      key: "orders-delete",
      route: "/orders/delete/:orderId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <OrdersDeleteView />,
    },
    {
      name: "Add New Order",
      sidenav: true,
      key: "orders-add",
      route: "/orders/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <OrdersAddView />,
    },
  ],
};

export default OrdersRoute;
