import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CheckoutLayout from "../../layouts/authentication/components/CheckoutLayout";
import SuiTypography from "../../components/SuiTypography";
import logo from "../../assets/img/logos/trans.png";
import PaymentReceiptForm from "../Forms/Checkout/QuotePaymentReceiptForm";
import FinalPaymentReceiptForm from "../Forms/Checkout/FinalPaymentReceiptForm";

class FinalPaymentPaymentCompleteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quote_id: props.params.quote_id,
      contractor_id: props.params.contractor_id,
      request_id: props.params.request_id,
      token: props.params.quote_token,
      hash: props.params.quote_hash,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <CheckoutLayout
          illustration={{
            image: logo,
            title: "Installation Request",
            headerTitle: "Tailored for you",
            description: "Complete Your Installation",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="body2" fontWeight="regular" color="text">

                </SuiTypography>

                <FinalPaymentReceiptForm
                    recordId={this.state.recordId}
                    entity={"paymentReview"}
                    pageScope={this}
                    jobId={this.props.params.job_id}
                    paymentToken={this.props.params.payment_token}
                />
              </Grid>
            </Grid>
          </Grid>
        </CheckoutLayout>
      </>
    );
  }
}

export default withRouter(FinalPaymentPaymentCompleteView);
