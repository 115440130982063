import React from "react";
import SuiBox from "../../../../components/SuiBox";
import SitesList from "../../../Lists/cst/Sites/SitesList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class SitesListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Site"
            path={"/sites/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <SitesList />
      </>
    );
  }
}

export default SitesListView;
