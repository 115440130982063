import Card from "@mui/material/Card";
import { CardContentHeader, caseWords } from "../../../../../../Reusable/Misc";
import { CardContent } from "@mui/material";
import React from "react";

export const SBContractor = (props) => {
  const { state } = props;

  return (
    <>
      {state.viewData.installRequest.irq.contractorId !== null && (
        <Card className={"mt9"} variant="outlined">
          <CardContentHeader>Assigned Contractor</CardContentHeader>
          <CardContent>
            <br />
            <h3 className="mt2 mb2">
              {caseWords(state.viewData.installRequest.irq.contractorId.name)}
            </h3>
            <p>{state.viewData.installRequest.irq.contractorId.address1}</p>
            <p>{state.viewData.installRequest.irq.contractorId.address2}</p>
            <p>
              {state.viewData.installRequest.irq.contractorId.city},{" "}
              {state.viewData.installRequest.irq.contractorId.state}{" "}
              {state.viewData.installRequest.irq.contractorId.zip}
            </p>
          </CardContent>
        </Card>
      )}
    </>
  );
};
