import { snakeCase } from "snake-case";
import _ from "lodash";

var inflect = require("i")();

export class APIException {
  errors = {
    1001: "Record not found",
    1002: "API Error",
  };

  code = "";

  constructor(err) {
    this.error = this.message(err);
    this.code = err;
  }

  message(code) {
    return this.errors[code];
  }

  e() {
    return this.errors;
  }
}

export const getAcceptedValues = (obj) => {
  let output = [];
  obj.map(function (v, i) {
    output.push(v.value);
  });
  return output;
};

export class Opts {
  loaded = false;
  options = [];

  constructor(options) {
    this.options = options || [];
    this.loaded = true;
  }

  isLoaded() {
    return this.loaded;
  }

  get() {
    return this.options;
  }

  setOptions(options) {
    for (let k in options) {
      if (options[k].value === "---") {
        options[k].value = "";
        options[k].separator = true;
      }
    }

    this.options = options;
    this.loaded = true;
  }

  /**
   * Get values of this.options
   */
  values() {
    let values = [];
    for (let k in this.options) {
      if (typeof this.options[k].value !== "undefined") {
        values.push(this.options[k].value.toString());
      }
    }
    return values;
  }

  add(label, value, ext, sub) {
    let item = {};
    let extra = ext || {};
    let subitem = sub || null;

    item.value = value;
    item.label = label;
    item.sub = subitem;

    item = _.merge(item, extra);

    if (item.value === "---") {
      item.value = "";
      item.separator = true;
    }

    this.options.push(item);
    return this;
  }

  dnd() {
    let values = [];

    for (let k in this.options) {
      const id = this.options[k].value;
      const name = this.options[k].label;

      const cc = snakeCase(name);
      const key = inflect.camelize(cc + "_" + id);

      const obj = {};
      obj.id = id;
      obj.name = name;
      obj.key = key;

      values.push(obj);
    }

    return values;
  }

  /**
   * get current value object, for use in <Select /> constructs
   * @param value - the current value to be parsed out
   */
  current(value) {
    const p = this.options.filter(function (x) {
      if (x.value === value) {
        return true;
      }
    });

    if (p.length > 0) {
      return p[0];
    }

    return false;
  }

  /**
   * Get label associated with value `v`
   * @param v
   */
  label(v) {
    const p = this.options.filter(function (x) {
      if (x.value === v) {
        return true;
      }
    });

    if (p) {
      return p[0].label;
    } else return "N/A";
  }

  lv() {}
}

export class Languages extends Opts {
  static options = [];
}

export class Locales extends Opts {
  static options = [];
}

export class Regions extends Opts {
  static options = [];
}

export class Usages extends Opts {
  static options = [];
}

export const Constants = {
  LOGIN_SUCCESS: "success",
  LOGIN_FAILED: "failed",
  LOGIN_PENDING: "pending",
  LOGIN_PROCESSING: "processing",
  LOGIN_FORBIDDEN: "forbidden",
};

export const Type = {
  DATE: "date",
  DATETIME: "datetime",
  TIME: "time",
  STRING: "string",
  BOOL: "bool",
  INT: "int",
};

export const ACL_DEFINITIONS = {};
