import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CheckoutLayout from "../../layouts/authentication/components/CheckoutLayout";
import SuiTypography from "../../components/SuiTypography";
import logo from "../../assets/img/logos/trans.png";
import PaymentReceiptForm from "../Forms/Checkout/QuotePaymentReceiptForm";

class QuoteDepositPaymentCompleteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <CheckoutLayout
          illustration={{
            image: logo,
            title: "Installation Request",
            headerTitle: "Tailored for you",
            description: "Complete Your Installation",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="body2" fontWeight="regular" color="text">
                  <SuiTypography variant="h1" fontWeight="medium">
                    Thank you for your payment
                  </SuiTypography>
                  <SuiTypography variant="h5" fontWeight="light" className={"mt3"}>
                    Your receipt of payment is below. You will also receive an emailed receipt.
                  </SuiTypography>
                  <hr className={"mt4 mb4"} />
                </SuiTypography>

                <PaymentReceiptForm
                  recordId={this.state.recordId}
                  entity={"paymentReview"}
                  pageScope={this}
                  jobId={this.props.params.jobId}
                  paymentToken={this.props.params.payment_token}
                />
              </Grid>
            </Grid>
          </Grid>
        </CheckoutLayout>
      </>
    );
  }
}

export default withRouter(QuoteDepositPaymentCompleteView);
