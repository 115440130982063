import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuiButton from "../../../../../components/SuiButton";
import React from "react";

export const GoBackToReviewQuote = (props) => {
  const { goBackToReview } = props;

  return (
    <SuiButton color="primary" variant="gradient" className={""} onClick={goBackToReview}>
      <span>
        <FontAwesomeIcon icon={["fad", "circle-arrow-left"]} spin={false} />
        &nbsp;&nbsp;
      </span>
      <span>Go Back to Review</span>
    </SuiButton>
  );
};
