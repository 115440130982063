import React from "react";
import _ from "lodash";
import { Type } from "./Constants";
import { formatDate } from "./Misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class StateManager {
  constructor(scope) {
    this.scope = scope;
    this.ref = {};
    this.state = { ...scope.state };
  }

  static meld(scope, state, newState, cb) {
    let callback = function () {};
    if (typeof cb === "function") {
      callback = cb;
    }

    const meldedState = _.merge({}, state, newState);
    scope.setState(meldedState, callback);
    return meldedState;
  }

  get() {
    return this.state;
  }

  merge(path, value) {
    const splitPath = path.split(".");
    const depth = splitPath.length;

    this.ref = this.state;

    for (let i = 0; i < depth; i += 1) {
      if (i === depth - 1) {
        if (typeof this.ref !== "undefined") {
          this.ref[splitPath[i]] = value;
        }
      } else {
        if (
          typeof this.ref[splitPath[i]] !== "undefined" &&
          typeof this.ref[splitPath[i]]["ignore"] !== "undefined"
        ) {
          if (this.ref[splitPath[i]]["ignore"] === true) {
            break;
          }
        }
        this.ref = this.ref[splitPath[i]];
      }
    }

    return this;
  }

  commit(l) {
    const log = l === true || false;
    this.scope.setState(this.state);
    if (log) {
      console.log(this.state);
    }
  }
}

export class Bubbler {
  constructor(Component) {
    this.Component = Component;
  }

  bubble(state) {
    const currentState = this.Component.state;

    const newState = _.merge({}, currentState, state);

    console.log(newState);

    this.Component.setState(newState);
  }
}

export const updateState = (path, value, scope) => {
  const splitPath = path.split(".");
  const depth = splitPath.length;
  const state = { ...scope.state };
  let ref = state;

  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      ref[splitPath[i]] = value;
    } else {
      ref = ref[splitPath[i]];
    }
  }
  scope.setState(state);
};

export const buttonStyle = (style) => {
  switch (style) {
    case "p":
      return "primary";
      break;
    case "d":
      return "light";
      break;
    case "del":
      return "error";
      break;
    case "w":
      return "warning";
      break;
    case "s":
      return "success";
      break;
  }
};

export const buttonIcon = (icon) => {
  switch (icon) {
    case "newuser":
      return (
        <FontAwesomeIcon icon={["fas", "person-circle-plus"]} className="mr2" fixedWidth={true} />
      );
      break;
    case "goback":
      return "goback";
      break;
    case "gofwd":
      return "gofwd";
      break;
    case "gear":
      return <FontAwesomeIcon icon={["fas", "gears"]} className="mr2" fixedWidth={true} />;
      break;
    case "addlocation":
      return "addlocation";
      break;
    case "adduser":
      return "adduser";
      break;
    case "addfile":
      return "addfile";
      break;
    case "home":
      return "home";
      break;
    case "editfile":
      return "editfile";
      break;
    case "edit":
      return <FontAwesomeIcon icon={["fas", "user-pen"]} fixedWidth={true} />;
      break;
    case "view":
      return <FontAwesomeIcon icon={["fas", "user-magnifying-glass"]} fixedWidth={true} />;
      break;
    case "refresh":
      return "refresh";
      break;
    case "delete":
      return <FontAwesomeIcon icon={["fas", "user-minus"]} fixedWidth={true} />;
      break;
    case "puzzle":
      return "puzzle";
      break;
    case "lock":
      return "lock";
      break;
    case "search":
      return "search";
      break;
  }
};

const wrap = (obj) =>
  _.transform(obj, (acc, val, k) => {
    acc[k] = _.isObject(val) ? wrap(val) : { val };
  });

export const prepData = (data) => {
  let formattedData = {};

  for (let name in data) {
    if (typeof data[name]["mapData"] !== "undefined" && data[name]["ignore"]) {
      continue;
    }

    if (typeof data[name]["hasSubs"] !== "undefined" && data[name]["hasSubs"]) {
      // formattedData[name] = {};
      data[name].value = prepData(data[name]);
    }

    if (typeof data[name].type !== "undefined") {
      switch (data[name].type) {
        case Type.DATE:
          formattedData[name] = formatDate(data[name].value);
          break;
        case Type.DATETIME:
          break;
        case Type.TIME:
          break;
      }
    } else if (typeof data[name].toJson !== "undefined" && data[name].toJson === true) {
      formattedData[name] = JSON.stringify(data[name].value);
    } else if (typeof data[name].checked !== "undefined") {
      if (Array.isArray(data[name].checked)) {
        if (data[name].checked.length === 0) {
          formattedData[name] = "";
        } else if (typeof data[name].is_multi !== "undefined" && data[name].is_multi === false) {
          formattedData[name] = data[name].checked[0];
        } else {
          formattedData[name] = JSON.stringify(data[name].checked);
        }
      } else if (data[name].checked) {
        formattedData[name] = 1;
      } else {
        formattedData[name] = 0;
      }
    } else {
      formattedData[name] = data[name].value;
    }
  }

  return formattedData;
};
