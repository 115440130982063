import React from "react";
import ZevList from "../../../Forms/ZevList";
import { SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";
import ActiveStatus from "../../../../Reusable/ActiveStatus";
import moment from "moment";

class StoresList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "stores",
        endpoint: "/stores",
      },
      sortFields: [
        { f: "id", l: "ID" },
        { f: "storeName", l: "Store Name" },
        { f: "owner", l: "Store Owner" },
        { f: "domain", l: "Domain" },
        { f: "status", l: "Status" },

        { f: "modified", l: "Last Updated By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record, store, id) {
    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    const actions = (store) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View store"
            path={"/stores/view/" + store.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit store"
            path={"/stores/edit/" + store.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete store"
            path={"/stores/delete/" + store.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    return {
      id: record.id,
      name: record.name,
      owner: record.user.lastName + ", " + record.user.firstName,
      domain: record.domain,
      status: <ActiveStatus active={record.isActive} />,
      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(StoresList);
