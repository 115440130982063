import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ghostcomplete.scss";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, FormControlLabel, Input, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Operations from "../../Reusable/Controller/Operations";
import axios from "axios";
import hash from "object-hash";

function Confirmation(props) {
  const { onClose, value: valueProp, path, title, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [options, setOptions] = React.useState({});
  const [error, setError] = React.useState();
  const radioGroupRef = React.useRef(null);
  const ops = new Operations(axios);
  const [searching, setSearching] = React.useState();

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(options[value]);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const textIntent = (t) => {
    const searchValue = t.target.value;
    setSearching(true);

    const opts = {
      path: path,
    };

    ops
      .search(searchValue, opts)
      .then((res: Response) => {
        setSearching(false);
        setError(false);
        const data = res.getData("contractors");
        var parsedOpts = {};
        data.map((datum) => {
          parsedOpts[hash(datum)] = datum;
        });
        setOptions(parsedOpts);
        console.log(parsedOpts);
      })
      .catch((err) => {
        console.log(err);
        setSearching(false);
        setError(true);
      });
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="md"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 150 }} dividers>
        <Input
          placeholder="Filter"
          onChange={_.debounce((v) => textIntent(v), 550)}
          className={"mb0"}
        />

        <Box
          className={"p2"}
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: "10px",
            p: "0 !important",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              minWidth: 700,
              m: 0,
              p: 0,
            }}
          >
            {Object.keys(options).length > 0 ? (
              <RadioGroup
                ref={radioGroupRef}
                aria-label="ringtone"
                name="ringtone"
                value={value}
                onChange={handleChange}
              >
                {Object.keys(options).map((optKey) => {
                  const option = options[optKey];
                  const row = (
                    <>
                      <div className={"ml2"}>
                        <h4 className={"mb2"}>{option.main}</h4>
                        <p style={{ fontWeight: "400" }}>{option.sub}</p>
                      </div>
                    </>
                  );

                  return (
                    <FormControlLabel
                      value={optKey}
                      className={"ghostcomplete-filter-control-label"}
                      key={hash(option)}
                      control={<Radio />}
                      label={row}
                    />
                  );
                })}
              </RadioGroup>
            ) : (
              <>
                {searching ? (
                  <>
                    <div className="ghostcomplete-searching-wrapper">
                      <p>Searching...</p>
                      <div className="ghostcomplete-searching">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        height: 120,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Enter a search above
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} variant={"contained"} onClick={handleOk}>
          Ok
        </Button>
        <Button color={"secondary"} variant={"contained"} autoFocus onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Confirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export const GhostComplete = (props) => {
  const { path, title, field } = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();

  const handleSearchClick = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);
    if (newValue) {
      props.setValue(newValue);
    }
  };

  return (
    <>
      <Confirmation
        onClose={handleClose}
        open={open}
        value={value}
        path={path}
        title={props.filterLabel}
        options={["test"]}
      />
      <div className="ghostcomplete">
        {props.children}
        <FontAwesomeIcon
          onClick={handleSearchClick}
          title={props.title}
          icon={["fas", "search"]}
          className="ghostcomplete-trigger"
        />
      </div>
    </>
  );
};

GhostComplete.propTypes = {
  path: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
};
