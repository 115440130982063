import React from "react";
import withRouter from "../../../../withRouter";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardContent, CardHeader } from "@mui/material";
import MakesDeleteForm from "../../../Forms/Delete/cst/MakesDeleteForm";

class MakesDeleteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: props.params.makeId,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardContent>
                  <MakesDeleteForm
                    recordId={this.state.recordId}
                    entity={"makes"}
                    pageScope={this}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(MakesDeleteView);
