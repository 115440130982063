import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerSurveysQuestionsListView from "../../../pages/Views/srv/CustomerSurveysQuestions/CustomerSurveysQuestionsListView";
import CustomerSurveysQuestionsEditView from "../../../pages/Views/srv/CustomerSurveysQuestions/CustomerSurveysQuestionsEditView";
import CustomerSurveysQuestionsAddView from "../../../pages/Views/srv/CustomerSurveysQuestions/CustomerSurveysQuestionsAddView";
import CustomerSurveysQuestionsDeleteView from "../../../pages/Views/srv/CustomerSurveysQuestions/CustomerSurveysQuestionsDeleteView";
import { FAStack } from "../../../Utility/FontAwesome";

const CustomerSurveysQuestionsRoute = {
  type: "collapse",
  name: "Customer Surveys Questions",
  sidenav: true,
  order: 2,
  key: "admin-customerSurveysQuestions",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Customer Surveys Question Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "customerSurveysQuestions-list",
      route: "/customer_surveys_questions/list",
      icon: <FAStack main="user" sub="plus-circle" />,
      component: <CustomerSurveysQuestionsListView />,
    },
    {
      hide: true,
      name: "Edit Customer Surveys Question",
      sidenav: true,
      key: "customerSurveysQuestions-edit",
      route: "/customer_surveys_questions/edit/:customerSurveysQuestionId",
      component: <CustomerSurveysQuestionsEditView />,
    },
    {
      hide: true,
      name: "Delete Customer Surveys Question",
      sidenav: true,
      key: "customerSurveysQuestions-delete",
      route: "/customer_surveys_questions/delete/:customerSurveysQuestionId",
      component: <CustomerSurveysQuestionsDeleteView />,
    },
    {
      name: "Add New Customer Surveys Question",
      sidenav: true,
      key: "customerSurveysQuestions-add",
      route: "/customer_surveys_questions/add",
      component: <CustomerSurveysQuestionsAddView />,
    },
  ],
};

export default CustomerSurveysQuestionsRoute;
