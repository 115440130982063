// Soft UI Dashboard PRO React base styles
import boxShadows from "assets/theme/base/boxShadows";
import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO React helper functions

const { lg } = boxShadows;
const { size } = typography;
const { text, white } = colors;
const { borderRadius } = borders;

const touchRipple = {
  styleOverrides: {
    root: {
      // backgroundColor: "yellow"
    },

    paper: {
      // backgroundColor: "yellow"
    },
  },
};

export default touchRipple;
