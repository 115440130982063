import React from "react";
import ZevForm from "../../ZevForm";
import TextElement from "../../../../Reusable/Forms/Elements/TextElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mapData } from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import withRouter from "../../../../withRouter";

class DealersAddEditForm extends ZevForm {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        private: true,
        service: {
          recordId: props.recordId,
          entity: "dealer",
          endpoint: "/dealers/get",
        },
      },
      false
    );

    this.ops = new Operations();
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let dealer = "";

    if (this.props.recordId) {
      dealer = this.ops.getRecord(this, this.props.recordId, null, null, this.props.entity).then(
        function (data) {
          return { dealer: data };
        }.bind(this)
      );
    } else {
      dealer = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([dealer]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "dealer",
        loaderStyle: "dealer",
        service: {
          endpoint: "/dealers/save",
          redirectTarget: "/dashboard",
          entity: "Dealer",
        },
        labels: {
          submit: "Save Dealer",
          submitting: "Saving Dealer",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          name: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Name",
            label: "Dealer Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
              minLength: { opt: 3, msg: "Min length of 3 required" },
              maxLength: { opt: 150, msg: "Max length of 150 required" },
            },
          },
          address1: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Address1",
            label: "Dealer Address1",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          address2: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Address2",
            label: "Dealer Address2",
            errors: [],
            vr: {},
          },
          city: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer City",
            label: "Dealer City",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          contactFirstName: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Contact First Name",
            label: "Dealer Contact First Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          contactLastName: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Contact Last Name",
            label: "Dealer Contact Last Name",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          email: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Email",
            label: "Dealer Email",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          isDeleted: {
            value: 0,
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Is Deleted",
            label: "Dealer Is Deleted",
            errors: [],
            defaultValue: 0,
            vr: {},
          },
          dealerId: {
            value: "",
            isRequired: false,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Is Deleted",
            label: "Dealer Is Deleted",
            errors: [],
            vr: {},
          },
          phone: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Phone",
            label: "Dealer Phone",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          state: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer State",
            label: "Dealer State",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
          zip: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "Dealer Zip",
            label: "Dealer Zip",
            errors: [],
            vr: {
              required: { opt: true, msg: "This field is required" },
            },
          },
        },
      });

      let ns = mapData(o.viewData.dealer, o);

      this.setState(ns);
    });

    /**
     * End getting data
     */
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SuiBox variant="gradient" color="light" className="p2" bgColor="dark">
                    DETAILS
                  </SuiBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="name"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.name}
                    value={this.state.data.name.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="address1"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address1}
                    value={this.state.data.address1.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="address2"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.address2}
                    value={this.state.data.address2.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="city"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.city}
                    value={this.state.data.city.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="contactFirstName"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.contactFirstName}
                    value={this.state.data.contactFirstName.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="contactLastName"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.contactLastName}
                    value={this.state.data.contactLastName.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="email"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.email}
                    value={this.state.data.email.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item className="pr1" xs={6}>
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="phone"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.phone}
                    value={this.state.data.phone.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="state"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.state}
                    value={this.state.data.state.value}
                  />
                  <TextElement
                    readonly={this.state.viewOnly}
                    type="text"
                    name="zip"
                    focus={true}
                    size="large"
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    field={this.state.data.zip}
                    value={this.state.data.zip.value}
                  />
                </Grid>
                <Grid container>
                  <Grid item sm="12">
                    &nbsp;
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <hr className="mt5 mb5" />

            <Grid item xs={12}></Grid>
            <br />
            <hr />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(DealersAddEditForm);
