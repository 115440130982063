import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SuiBox from "../../../../../components/SuiBox";
import SuiTypography from "../../../../../components/SuiTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { Backdrop, Modal } from "@mui/material";
import hash from "object-hash";
import Fade from "@mui/material/Fade";
import moment from "moment";
import ZevTimeline from "./ZevTimeline";
import ZevNotes from "./ZevNotes";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function JobTabs(props) {
  const { notes, attributes, lineItems, customer, quote, orderInfo, attachments, timeline, communication } = props;

  const [value, setValue] = React.useState(0);

  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const renderAttribute = (attribute) => {
    return (
      <div className={"mt2 mb2"}>
        <SuiTypography fontSize={".75em"} color={"primary"}>
          {attribute.attributeKey}
        </SuiTypography>
        <SuiTypography fontSize={"1em"} color={"dark"}>
          {attribute.attributeValue}
        </SuiTypography>
      </div>
    );
  };

  const renderAttachment = (attachment) => {
    return (
      <Grid item xs={4} style={{ textAlign: "center" }} key={hash(attachment)}>
        <Backdrop className="default-overlay" onClick={closeModal} open={openModal}>
          <Modal
            className="center-modal-contents"
            open={openModal}
            aria-labeledby={"modal-modal-title"}
          >
            <Fade in={openModal} timeout={500}>
              <img src={attachment.filepath} />
            </Fade>
          </Modal>
        </Backdrop>

        <SuiBox
          component="div"
          shadow={"md"}
          borderRadius={"lg"}
          height="100px"
          className="clickable"
          onClick={(e) => {
            setOpenModal(true);
          }}
          style={{
            display: "inline-block",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundImage: `url(${attachment.filepath})`,
          }}
        >
          <img src={attachment.filepath} style={{ visibility: "hidden", maxHeight: "100px" }} />
        </SuiBox>
        <h5 align={"center"}>{attachment.filename}</h5>
      </Grid>
    );
  };

  const From = (props) => {
    const { comm } = props;

    if (comm.sender === "CUSTOMER") {
      return <>{customer.name}</>;
    }
    return <>{comm.contractor.name}</>;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} a>
        <Tabs
          textColor={"primary"}
          indicatorColor={"transparent"}
          variant={"standard"}
          scrollButtons={"auto"}
          value={value}
          onChange={handleChange}
          className={"tabs-wrapper"}
          aria-label=""
        >
          <Tab
            icon={<FontAwesomeIcon icon={icon({ style: "duotone", name: "bags-shopping" })} />}
            label="Order Information"
            {...a11yProps(0)}
          />
          <Tab
            icon={<FontAwesomeIcon icon={icon({ style: "duotone", name: "user-helmet-safety" })} />}
            label="Job Specific Information"
            {...a11yProps(1)}
          />
          <Tab
            icon={<FontAwesomeIcon icon={icon({ style: "duotone", name: "envelope" })} />}
            label="Communication"
            {...a11yProps(2)}
          />
          <Tab
            icon={<FontAwesomeIcon icon={icon({ style: "duotone", name: "note" })} />}
            label="Notes"
            {...a11yProps(2)}
          />
          <Tab
            icon={<FontAwesomeIcon icon={icon({ style: "duotone", name: "timeline" })} />}
            label="Timeline"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={"light"}>
              <SuiTypography fontSize={".8em"} color={"dark"}>
                <strong>PURCHASE(S)</strong>
                <hr />
                <br />
              </SuiTypography>
              {orderInfo.orderLineItemsList.map((lineItem) => {
                return (
                  <>
                    <Grid container>
                      <Grid item xs={5}>
                        <img
                          src={lineItem.productId.productImagesList[0].s3src}
                          style={{ maxHeight: "80px" }}
                        />
                        <br />
                      </Grid>
                      <Grid item xs={7}>
                        {lineItem.productId.name}
                        <br />
                        <strong>{lineItem.productId.vendor}</strong>
                        <br />${lineItem.productId.total}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </SuiBox>
          </Grid>
          <Grid item xs={12}>
            <SuiBox variant={"contained"} className={"p2 mt2"} bgColor={"light"} style={{  }}>
              <SuiTypography fontSize={".8em"} color={"dark "}>
                <strong>QUOTE ITEMS</strong>
                <hr />
              </SuiTypography>
              <br />

              <div className={"hidden-md visible-xs"}>
                {lineItems.map((lineItem) => {
                  return (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12}>
                              <strong>
                                {lineItem.productService}
                              </strong>
                              &nbsp;
                              &nbsp;
                              &nbsp;
                              ${lineItem.total}
                            </Grid>
                            <Grid item xs={12}>
                              Qty: {lineItem.quantity}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                  );
                })}
              </div>


              <Table style={{width: '100%'}} className="table-striped data-list-table visible-md hidden-xs">
                <TableHead style={{width: '100%'}} sx={{ display: "table-header-group !important" }}>
                  <TableRow sx={{ backgroundColor: "var(--toastify-color-info)" }}>
                    <TableCell sx={{ color: "var(--toastify-color-light) !important" }}>
                      Product/Service
                    </TableCell>
                    <TableCell sx={{ color: "var(--toastify-color-light) !important" }}>
                      Quantity
                    </TableCell>
                    <TableCell sx={{ color: "var(--toastify-color-light) !important" }}>
                      Unit Price
                    </TableCell>
                    <TableCell sx={{ color: "var(--toastify-color-light) !important" }}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineItems.map((lineItem) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {lineItem.productService}
                          <br />
                          <em>
                            <small>{lineItem.description}</small>
                          </em>
                        </TableCell>
                        <TableCell>{lineItem.quantity}</TableCell>
                        <TableCell >
                          <strong>$</strong>
                          {lineItem.unitPrice}
                        </TableCell>
                        <TableCell>
                          <strong>$</strong>
                          {lineItem.total}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </SuiBox>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <SuiTypography fontSize={"1.2rem"}>
              Request Attachments (Attached by Customer)
            </SuiTypography>
            <hr />
            <Grid className={"pt3 pb3"} container>
              {attachments.map((attachment) => {
                return (
                  <Grid item xs={4}>
                    {renderAttachment(attachment)}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <SuiTypography fontSize={"1.2rem"}>Questions/Attributes</SuiTypography>
            <hr />
            <Grid className={"pt3 pb3"} container>
              {attributes.map((attribute) => {
                return (
                  <Grid item xs={12}>
                    {renderAttribute(attribute)}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container>
          {communication.map((communication) => {
            return (
              <div className={"communication-row"}>
                <Grid xs={2} className={"send-date-column p2"} style={{ fontSize: "14px" }}>
                  <p align={"right"}>{moment.utc(communication.created).local().format("MMMM DD, yyyy")}</p>
                  <p align={"right"}>{moment.utc(communication.created).local().format("h:mm a")}</p>
                </Grid>
                <Grid xs={10} className={"sender-column"}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={"pl2 pt1 pb1 pr2 sender-header"}
                      color={"#fff"}
                    >
                      <From comm={communication} />
                    </Grid>
                    <Grid item xs={12} className={"p2 sender-body"}>
                      <pre>{communication.body}</pre>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ZevNotes notes={notes} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ZevTimeline timeline={timeline} />
      </CustomTabPanel>
    </Box>
  );
}
