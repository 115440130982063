import React from "react";
import Grid from "@mui/material/Grid";
import withRouter from "../../withRouter";
import CheckoutLayout from "../../layouts/authentication/components/CheckoutLayout";
import SuiTypography from "../../components/SuiTypography";
import logo from "../../assets/img/logos/trans.png";
import FinalPaymentReviewForm from "../Forms/Checkout/FinalPaymentReviewForm";

class FinalPaymentReviewView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: props.params.job_id,
      contractor_id: props.params.contractor_id,
      customer_id: props.params.customer_id,
      request_id: props.params.request_id,
      token: props.params.job_token,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <CheckoutLayout
          illustration={{
            image: logo,
            title: "Installation Request",
            headerTitle: "Tailored for you",
            description: "Complete Your Installation",
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SuiTypography variant="body2" fontWeight="regular" color="text">
                  <SuiTypography variant="h5" fontWeight="light">
                    <h4>Final Payment</h4>
                  </SuiTypography>
                  <SuiTypography variant="h5" fontWeight="light" className={"mt3"}>

                  </SuiTypography>
                  <hr className={"mt4 mb4"} />
                </SuiTypography>

                <FinalPaymentReviewForm
                  recordId={this.state.recordId}
                  entity={"finalPaymentReview"}
                  pageScope={this}
                  customer_id={this.state.customer_id}
                  contractor_id={this.state.contractor_id}
                  request_id={this.state.request_id}
                  job_id={this.state.job_id}
                  token={this.state.token}
                />
              </Grid>
            </Grid>
          </Grid>
        </CheckoutLayout>
      </>
    );
  }
}

export default withRouter(FinalPaymentReviewView);
