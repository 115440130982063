import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SitesListView from "../../../pages/Views/cst/Sites/SitesListView";
import SitesEditView from "../../../pages/Views/cst/Sites/SitesEditView";
import SitesAddView from "../../../pages/Views/cst/Sites/SitesAddView";
import SitesDeleteView from "../../../pages/Views/cst/Sites/SitesDeleteView";

const SitesRoute = {
  type: "collapse",
  name: "Sites",
  sidenav: true,
  order: 2,
  key: "admin-sites",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Site Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "sites-list",
      route: "/sites/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <SitesListView />,
    },
    {
      hide: true,
      name: "Edit Site",
      sidenav: true,
      key: "sites-edit",
      route: "/sites/edit/:siteId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <SitesEditView />,
    },
    {
      hide: true,
      name: "Edit Site",
      sidenav: true,
      key: "sites-edit",
      route: "/sites/view/:siteId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <SitesEditView />,
    },
    {
      hide: true,
      name: "Delete Site",
      sidenav: true,
      key: "sites-delete",
      route: "/sites/delete/:siteId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <SitesDeleteView />,
    },
    {
      name: "Add New Site",
      sidenav: true,
      key: "sites-add",
      route: "/sites/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <SitesAddView />,
    },
  ],
};

export default SitesRoute;
