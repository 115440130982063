import {Link, useLocation} from "react-router-dom";

function Title(location) {

    let title = [];

    if (location.pathname !== '/') {
        const route = location.pathname.split("/").slice(1);

        const routes = route.slice(0);

        console.log(route);


        title = routes.map((el) => {

            if (el === "view" || el === "add" || el === "delete") {
                return (_.startCase(el));
            }

            let short = null;

            if (el === 'install_requests') {
                short = 'requests';
            } else {
                short = el;
            }

            if (short) {
                return _.startCase(short);
            }
        });
    } else {
        title.push('Dashboard');
    }



    return 'ZEV Society | ' + title.join(' - ');
}

export default Title;
