import React from "react";
import AuthLayout from "../../layouts/authentication/components/AuthLayout";
import logo from "../../assets/img/logos/trans.png";
import SuiBox from "../../components/SuiBox";
import Grid from "@mui/material/Grid";
import RegisterForm from "../Forms/Registration/RegisterForm";

class Register extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <AuthLayout
        title="Register New Account"
        description=" "
        illustration={{
          image: logo,
          title: "",
          description: "",
        }}
      >
        <SuiBox component="" role="">
          <>
            <RegisterForm pageScope={this} />
          </>

          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={12}>
              <SuiBox mt={0} textAlign="center"></SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
      </AuthLayout>
    );
  }
}

export default Register;
