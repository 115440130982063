import React from "react";
import SuiBox from "../../../../components/SuiBox";
import ContractorsList from "../../../Lists/ins/Contractors/ContractorsList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class ContractorsListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Contractor"
            path={"/contractors/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <ContractorsList />
      </>
    );
  }
}

export default ContractorsListView;
