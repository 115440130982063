/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const RevenueData = (data) => {
    const labels = [
        {mno: "1", label: "Jan"},
        {mno: "2", label: "Feb"},
        {mno: "3", label: "Mar"},
        {mno: "4", label: "Apr"},
        {mno: "5", label: "May"},
        {mno: "6", label: "Jun"},
        {mno: "7", label: "Jul"},
        {mno: "8", label: "Aug"},
        {mno: "9", label: "Sep"},
        {mno: "10", label: "Oct"},
        {mno: "11", label: "Nov"},
        {mno: "12", label: "Dec"}
    ];

    const newLabels = [];
    const dataRevenue = [];
    const dataAppFees = [];

    labels.map(
        (label) => {
            const curMonth = _.find(
                data,
                function(d) {
                    return d.monthNumber == label.mno
                }
            );
            if (curMonth) {
                newLabels.push(label.label);
                dataRevenue.push(curMonth.revenue);
                dataAppFees.push(curMonth.appFee);
            } else {
                newLabels.push(label.label);
                dataRevenue.push(0);
                dataAppFees.push(0);
            }
        }
    );

    return {
        labels: newLabels,
        datasets: [
            {
                label: "Revenue",
                color: "success",
                data: dataRevenue
            },
            {
                label: "App Fees",
                color: "info",
                data: dataAppFees
            }
        ]
    }
}


/*
const gradientLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Mobile apps",
      color: "info",
      data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    {
      label: "Websites",
      color: "dark",
      data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
    },
  ],
};*/

export default RevenueData;
