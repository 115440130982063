import React from "react";
import ZevForm from "../../ZevForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardContentHeader,
  extractKV,
  mapData,
  setDotProp,
  WarningHeader,
} from "../../../../Reusable/Misc";
import Operations from "../../../../Reusable/Controller/Operations";
import SuiBox from "../../../../components/SuiBox";
import Grid from "@mui/material/Grid";
import withRouter from "../../../../withRouter";
import { Backdrop, CardContent, Modal } from "@mui/material";
import Card from "@mui/material/Card";
import SuiTypography from "../../../../components/SuiTypography";
import Fade from "@mui/material/Fade";
import validate from "../../../../Reusable/Validation";
import { StateManager } from "../../../../Reusable/Core";
import hash from "object-hash";
import _ from "lodash";
import moment from "moment";
import { QuoteActionButtons } from "./Modules/QuoteActionButtons";
import { ProductLineItems } from "./Modules/ProductLineItems";
import { AddAdditionalLineItems } from "./Modules/AddAdditionalLineItems";
import { InstallRequestSidebar } from "./Modules/InstallRequestSidebar";
import { Headers } from "./Modules/Headers";
import { RejectRequest } from "./Modules/QuoteActionButtons/RejectRequest";
import {NavLink} from "react-router-dom";
import {SBStatus} from "./Modules/InstallRequestSidebar/SBStatus";
import {SBCustInfo} from "./Modules/InstallRequestSidebar/SBCustInfo";
import {SBContractor} from "./Modules/InstallRequestSidebar/SBContractor";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

class InstallRequestsAddEditForm extends ZevForm {
  lineItemCounter = 0;

  constructor(props) {
    super(props);
    this.props = props;

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: true,
        quoteLineItems: {},
        service: {
          recordId: props.recordId,
          entity: "installRequest",
          endpoint: "/install_requests/get",
        },
        labels: {
          draftSave: "Save Draft",
          draftSaved: "Draft Saved",
          draftSaving: "Saving Draft",
          draftFailed: "Failed",
          quoteSend: "Accept & Send Quote",
          quoteSent: "Quote Sent",
          quoteSending: "Sending Quote",
          quoteFailed: "Failed",
          requestReject: "Reject Request",
          requestRejected: "Request Rejected",
          requestRejecting: "Rejecting Request",
          requestRejectFailed: "Failed",
        },
        status: {
          isLineItemsSufficient: false,
          isRequestRejecting: false,
          isRequestRejectFailed: false,
          isRequestRejected: false,
          isDraftSaving: false,
          isDraftFailed: false,
          isDraftSaved: false,
          isQuoteSending: false,
          isQuoteFailed: false,
          isQuoteSent: false,
        },
      },
      false
    );

    this.ops = new Operations();

    this.addNewLineItem = this.addNewLineItem.bind(this);
    this.setContractor = this.setContractor.bind(this);
    this.assignContractor = this.assignContractor.bind(this);
    this.customSubmit = this.customSubmit.bind(this);
    this.sendQuote = this.sendQuote.bind(this);
    this.rejectQuote = this.rejectQuote.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
    this.removeLineItem = this.removeLineItem.bind(this);
    this.updateDeposits = this.updateDeposits.bind(this);
  }

  noop(el) {
    if (el.target.tagName === "LABEL") {
      return false;
    }
  }

  cancelAction() {
    this.props.navigate("/install_requests/list");
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let installRequest = "";

    if (this.props.recordId) {
      installRequest = this.ops
        .getRecord(this, this.props.recordId, null, null, this.props.entity)
        .then(
          function (data) {
            return { installRequest: data };
          }.bind(this)
        );
    } else {
      installRequest = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([installRequest]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        requiredCostOfQuote: 0,
        optionalCostOfQuote: 0,
        depositOfQuote: 0,
        recordId: this.props.recordId,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "installRequest",
        loaderStyle: "installRequest",
        service: {
          endpoint: "/install_requests/get",
          redirectTarget: "/dashboard",
          entity: "installRequest",
        },
        openModal: {},
        labels: {
          submit: "Save InstallRequest",
          submitting: "Saving InstallRequest",
          submitted: "Saved",
          failed: "Save Failed",
        },
        viewData: viewData,
        data: {
          contractorName: "",
          contractorId: {
            value: "",
            isRequired: true,
            immutable: false,
            valid: false,
            touched: false,
            placeholder: "",
            label: "Assigned Contractor",
            errors: [],
          },
          depositPct: {
            defaultValue: this.setting("QUOTE_DEPOSIT_PCT"),
            isRequired: true,
            immutable: false,
            valid: false,
            value: this.setting("QUOTE_DEPOSIT_PCT"),
            touched: false,
            placeholder: "Enter Deposit %",
            label: "Deposit %",
            errors: [],
            vr: {
              numericDecimal: { opt: true, msg: "Numeric only" },
              required: { opt: true, msg: "Required" },
              between: { opt: true, upper: 100, lower: 0, msg: "Must be between 0 and 100" },
            },
          },
          addQuoteLineItem: {
            optionalItem: {
              type: "checkbox",
              value: 0,
              isRequired: false,
              checked: false,
              immutable: false,
              valid: false,
              label: "Optional Item",
              touched: false,
              placeholder: "",
              errors: [],
              vr: {},
            },
            productServiceName: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Product/Service",
              label: "Product/Service Name",
              errors: [],
              vr: {
                required: { opt: true, msg: "Required" },
              },
            },
            description: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "Description of Product/Service",
              label: "Description",
              errors: [],
              vr: {
                required: { opt: true, msg: "Required" },
              },
            },
            quantity: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "0",
              label: "Quantity",
              errors: [],
              vr: {
                integerOnly: { opt: true, msg: "Numeric only" },
                required: { opt: true, msg: "Required" },
              },
            },
            price: {
              value: "",
              isRequired: true,
              immutable: false,
              valid: false,
              touched: false,
              placeholder: "$0.00",
              label: "Price",
              errors: [],
              vr: {
                numericDecimal: { opt: true, msg: "Numeric only" },
                required: { opt: true, msg: "Required" },
              },
            },
          },
          quoteLineItems: [],
        },
      });

      let ns = mapData(o.viewData.installRequest, o);
      ns = mapData(o.viewData.installRequest.quot, o);

      let qli = [];

      if (ns.viewData.installRequest.quot !== null && ns.viewData.installRequest.quot) {
        ns.viewData.installRequest.lineitems.map((lineItem) => {
          var $newObject = _.merge({}, ns.data.addQuoteLineItem);

          $newObject.optionalItem.value = lineItem.isOptional;
          $newObject.productServiceName.value = lineItem.productService;
          $newObject.description.value = lineItem.description;
          $newObject.quantity.value = lineItem.quantity;
          $newObject.price.value = lineItem.unitPrice;

          qli.push($newObject);
        });

        ns.data.quoteLineItems = qli;

        this.setState(ns, this.compileCost);
      } else {
        this.setState(ns, this.compileCost);
      }
    });

    /**
     * End getting data
     */
  }

  cb() {}

  rejectQuote() {
    this.activateRejectQuote();

    const response = this.ops
      ._post(
        {
          // quoteLineItems: this.state.data.quoteLineItems,
          installRequestId: this.state.recordId,
          quoteLineItems: extractKV(this.state.data.quoteLineItems),
        },
        "install_requests/reject_request"
      )
      .then((res: Response) => {
        this.rejectQuoteStatus(res.getIsSuccess());
      });
  }

  saveDraft() {
    // StateManager.meld(this, this.state, {status: { isDraftSaving: true}});

    this.activateSavingDraft();

    const response = this.ops
      ._post(
        {
          // quoteLineItems: this.state.data.quoteLineItems,
          installRequestId: this.state.recordId,
          depositPct: this.state.data.depositPct.value,
          quoteLineItems: extractKV(this.state.data.quoteLineItems),
        },
        "install_requests/save_draft"
      )
      .then((res: Response) => {
        console.log(res);
        this.saveDraftStatus(res.getIsSuccess());
      });
  }

  activateSavingDraft() {
    StateManager.meld(this, this.state, {
      status: {
        isDraftSaving: true,
        isDraftSaved: false,
        isDraftFailed: false,
      },
    });
  }

  activateSendingQuote() {
    StateManager.meld(this, this.state, {
      status: {
        isQuoteSending: true,
        isQuoteSent: false,
        isQuoteFailed: false,
      },
    });
  }

  activateRejectQuote() {
    StateManager.meld(this, this.state, {
      status: {
        isRequestRejecting: true,
        isRequestRejected: false,
        isRequestRejectFailed: false,
      },
    });
  }

  rejectQuoteStatus(success) {
    let rejectedFlagProp, failedFlagProp;

    if (success === true) {
      rejectedFlagProp = true;
      failedFlagProp = false;
    } else {
      rejectedFlagProp = false;
      failedFlagProp = true;
    }

    StateManager.meld(
      this,
      this.state,
      {
        status: {
          isRequestRejecting: false,
          isRequestRejected: rejectedFlagProp,
          isRequestRejectFailed: failedFlagProp,
        },
      },
      () => {
        setTimeout(() => {
          StateManager.meld(this, this.state, {
            status: {
              isRequestRejecting: false,
              isRequestRejected: false,
              isRequestRejectFailed: false,
            },
          });
        }, 1600);
      }
    );
  }

  saveDraftStatus(success) {
    let savedFlagProp, failedFlagProp;

    if (success === true) {
      savedFlagProp = true;
      failedFlagProp = false;
    } else {
      savedFlagProp = false;
      failedFlagProp = true;
    }

    StateManager.meld(
      this,
      this.state,
      {
        status: {
          isDraftSaving: false,
          isDraftSaved: savedFlagProp,
          isDraftFailed: failedFlagProp,
        },
      },
      () => {
        setTimeout(() => {
          StateManager.meld(
            this,
            this.state,
            {
              status: {
                isDraftSaving: false,
                isDraftSaved: false,
                isDraftFailed: false,
              },
            },
            () => {
              this.componentDidMount();
            }
          );
        }, 1600);
      }
    );
  }

  deactivateSendQuote() {
    setTimeout();
  }

  sendQuote() {
    this.activateSendingQuote();

    const response = this.ops._post(
      {
        // quoteLineItems: this.state.data.quoteLineItems,
        installRequestId: this.state.recordId,
        depositPct: this.state.data.depositPct.value,
        quoteLineItems: extractKV(this.state.data.quoteLineItems),
      },
      "/install_requests/send_quote",
      false,
      () => {
        this.componentDidMount();
      }
    );
  }

  lineItemsSufficient() {

    let cost = 0;
    let optional = 0;
    let required = 0;

    let sufficient = false;
    this.state.data.quoteLineItems.map((lineItem) => {
      cost = parseFloat(
          parseFloat(cost) + parseFloat(lineItem.price.value) * parseFloat(lineItem.quantity.value)
      ).toFixed(2);

      if (lineItem.optionalItem.value == 1) {
        optional = parseFloat(
            parseFloat(optional) +
            parseFloat(lineItem.price.value) * parseFloat(lineItem.quantity.value)
        ).toFixed(2);
      } else {
        required = parseFloat(
            parseFloat(required) +
            parseFloat(lineItem.price.value) * parseFloat(lineItem.quantity.value)
        ).toFixed(2);

        sufficient = true;
      }

    });

    return {
      cost: cost,
      optional: optional,
      required: required,
      sufficient: sufficient
    };
  }


  compileCost() {

    let rCost = this.lineItemsSufficient();

    let sufficient = rCost.sufficient;

    this.setState({
      totalCostOfQuote: rCost.cost,
      optionalCostOfQuote: rCost.optional,
      requiredCostOfQuote: rCost.required,

    },
        () => {
          StateManager.meld(this, this.state, {status: { isLineItemsSufficient: sufficient}});
          this.updateDeposits();
        }
    );



  }

  updateDeposits() {
    const enableQuoteLimits = this.setting("QUOTE_DEPOSIT_LIMIT_ENABLE") === "YES";
    const quoteDepPct = this.setting("QUOTE_DEPOSIT_PCT");
    const quoteDepMax = this.setting("QUOTE_DEPOSIT_MAXIMUM");
    const quoteDepMin = this.setting("QUOTE_DEPOSIT_MINIMUM");

    const depAmount = this.state.totalCostOfQuote * (this.state.data.depositPct.value / 100);

    let rCost = this.lineItemsSufficient();

    const ns = {
      depositOfQuote: depAmount,
      status: {
        isLineItemsSufficient: rCost.sufficient
      }
    };

    return StateManager.meld(this, this.state, ns);
  }

  updateFieldState(name, field) {
    const SM = new StateManager(this);
    SM.merge("data.addQuoteLineItem." + name, field);
    SM.commit();
  }

  assignContractor() {
    const data = {
      irqId: this.props.recordId,
      contractorId: this.state.data.contractorId.value,
    };

    this.ops
      .singleSave(data, { endpoint: "/install_requests/assign_contractor" })
      .then((response: Response) => {
        this.componentDidMount();
      })
      .catch((error) => {});
  }

  addNewLineItem() {
    var lineItemComponents = this.state.data.addQuoteLineItem;
    var isValid = true;

    Object.keys(lineItemComponents).map((lkey, i) => {
      const lineItemComponent = lineItemComponents[lkey];
      const valid = validate(lineItemComponent, lineItemComponent.vr);

      if (!valid.valid) {
        // lineItemComponent.errors = valid.errors;

        setDotProp("errors", lineItemComponent, valid.errors);
        setDotProp("valid", lineItemComponent, false);

        // lineItemComponent.valid = false;
        isValid = false;
      } else {
        // lineItemComponent.errors = [];
        // lineItemComponent.valid = true;
        // setDotProp("errors", lineItemComponent, true);
        // setDotProp("valid", lineItemComponent, []);
      }

      this.updateFieldState(lkey, lineItemComponent);
    });

    if (isValid) {
      var quoteObject = _.cloneDeep(this.state.data.addQuoteLineItem);

      let cs = this.state;

      cs.data.quoteLineItems.push(quoteObject);

      this.setState(cs);

      this.resetAddQuoteLineItem();
      this.compileCost();
    }
  }

  resetAddQuoteLineItem() {



    // const cs = this.state;
    const cs = this.updateDeposits();

    const liKeys = Object.keys(this.state.data.addQuoteLineItem);
    liKeys.map((liKey) => {
      if (cs.data.addQuoteLineItem[liKey].type === "checkbox") {
        cs.data.addQuoteLineItem[liKey].value = 0;
      } else {
        cs.data.addQuoteLineItem[liKey].value = "";
      }

      cs.data.addQuoteLineItem[liKey].valid = false;
      cs.data.addQuoteLineItem[liKey].touched = false;
      cs.data.addQuoteLineItem[liKey].errors = [];
    });



    this.setState(cs, () => {
      // this.updateDeposits();
    });
  }

  removeLineItem(lineItem, ii) {
    const state = this.state;
    const lineItems = state.data.quoteLineItems;

    const newLineItems = [];

    lineItems.map((li, i) => {
      if (i !== ii) {
        newLineItems.push(li);
      }
    });

    state.data.quoteLineItems = newLineItems;

    this.setState(state, this.compileCost);
  }

  renderAttribute(attribute) {

    let attributeKey = attribute.attributeKey;
    const regex = /\d+\.\W+/
    attributeKey = attributeKey.replace(regex, "");



    return (
        <TableRow style={{width: '100%'}}>
          <TableCell style={{width: '70%'}}>
            <strong>{attributeKey}</strong>
          </TableCell>
          <TableCell style={{width: '30%'}}>
            <p style={{paddingLeft: "20px"}}>{attribute.attributeValue}</p>
          </TableCell>
        </TableRow>

    );
  }

  handleImageModal(productImage) {
    let hook;

    if (typeof this.state.openModal[productImage] === "undefined") {
      hook = false;
    } else {
      hook = this.state.openModal[productImage];
    }

    this.setState({
      openModal: {
        [productImage]: !hook,
      },
    });
  }

  setContractor(contractor) {
    console.log(contractor);

    const curState = this.state;

    curState.data.contractorId.value = contractor.id;
    curState.data.contractorName = contractor.main;

    this.setState(curState);
  }

  isModalOpen(attachmentId) {
    if (typeof this.state.openModal[attachmentId] !== "undefined") {
      return this.state.openModal[attachmentId];
    } else {
      return false;
    }
  }

  closeModal(attachmentId) {
    this.setState({
      openModal: {
        [attachmentId]: false,
      },
    });
  }

  renderAttachment(attachment) {
    return (
      <Grid item style={{ textAlign: "left" }} key={hash(attachment)}>
        <Backdrop
          className="default-overlay"
          onClick={this.closeModal.bind(this, attachment.id)}
          open={this.isModalOpen(attachment.id)}
        >
          <Modal
            className="center-modal-contents"
            open={this.isModalOpen(attachment.id)}
            aria-labeledby={"modal-modal-title"}
          >
            <Fade in={this.isModalOpen(attachment.id)} timeout={500}>
              <img src={attachment.filepath} />
            </Fade>
          </Modal>
        </Backdrop>
            <div
              style={{
                display: "inline-block",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${attachment.filepath})`,
              }}
              onClick={this.handleImageModal.bind(this, attachment.id)}
              className={"clickable image-tile"}
            >
              <h4 className={"tile-details"} align={"center"}>{attachment.filename}</h4>
              <img src={attachment.filepath} style={{ visibility: "hidden", maxHeight: "100px" }} />
            </div>
      </Grid>
    );
  }

  form() {
    var lineItemCount = 0;
    var lastSent;
    var sendQuoteDisabled = false;

    if (this.state.initialized === true) {
      if (
        this.state.viewData.installRequest.quot &&
        this.state.viewData.installRequest.quot.lastSendDate
      ) {
        lastSent = moment.utc(this.state.viewData.installRequest.quot.lastSendDate).local();

        var nowStamp = moment.utc().local();

        var timeOffset = moment.duration(nowStamp.diff(lastSent));
        var seconds = timeOffset.asSeconds();

        if (seconds < 60 * this.setting("QUOTE_TIME_BETWEEN_SENDS")) {
          sendQuoteDisabled = true;
        }
      }
    }

    let jobLink = "#";

    if (this.state.viewData.installRequest.irq.status === "QUOTE_ACCEPTED_DEPOSIT_PAID" ||
        this.state.viewData.installRequest.irq.status === "INVOICE_PAID") {
      jobLink = "/jobs/view/" + this.state.viewData.installRequest.job.id;
    }

    return (
      <>
        {this.state.initialized === true ? (
          <>
            {this.state.viewData.installRequest.irq.contractorId === null ? (
              <Headers
                state={this.state}
                setContractor={this.setContractor}
                assignContractor={this.assignContractor}
              />
            ) : (
              <Grid container>
                <Grid item md={12}>
                  <SBStatus state={this.state} />
                </Grid>
                <Grid item md={12}>
                  <Grid container className={"mb6 pb6"}>
                    <Grid item md={6} className={"pr5 pt5"}>
                      <SBCustInfo state={this.state} />
                    </Grid>
                    <Grid item md={6} className={"pl5 pt5"}>
                      <SBContractor state={this.state} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>


                  {this.state.viewData.installRequest.quot &&
                    this.state.viewData.installRequest.irq.status ===
                      "QUOTE_ACCEPTED_DEPOSIT_PAID" && (
                      <>
                        <WarningHeader
                          rounded={true}
                          color={"success"}
                          header="DEPOSIT HAS BEEN PAID"
                        >
                          <p>
                            This quote has been paid, and a &nbsp;
                            <NavLink
                              to={jobLink}
                              key={hash(["link", jobLink])}
                              sx={{ textDecoration: "none" }}
                            >
                              job has been created.
                            </NavLink>
                          </p>
                        </WarningHeader>
                      </>
                    )}

                  {this.state.viewData.installRequest.quot &&
                    this.state.viewData.installRequest.irq.status === "INVOICE_PAID" && (
                      <>
                        <WarningHeader
                          rounded={true}
                          color={"success"}
                          header={"JOB COMPLETE AND BALANCE PAID"}
                        >
                          <p>
                            This quote has been paid, and a &nbsp;
                            <NavLink
                              to={jobLink}
                              key={hash(["link", jobLink])}
                              sx={{ textDecoration: "none" }}
                            >
                              job has been created.
                            </NavLink>
                          </p>
                        </WarningHeader>
                      </>
                    )}

                  <Grid container>
                    <Grid item xs={8} className={"pr5 pt5"}>

                  <Card variant={"outlined"}>
                    <CardContentHeader>Order Details</CardContentHeader>
                    <CardContent>
                      <br />
                      <h3 className={"mt2 mb2"}>Customer Supplied Information</h3>
                      <hr />
                      <br />

                      <Grid container>
                        <Grid item xs={12}>
                          <p className={"text-color-dark mb6"}>
                            The following information was supplied by the customer during their
                            order checkout. If more information is needed, please contact the
                            customer.
                          </p>
                        </Grid>

                        <Grid item xs={12}>
                          <h4>Attachments</h4>
                          <Grid container>
                            {Array.isArray(
                              this.state.viewData.installRequest.irq.installRequestAttachmentsList
                            ) &&
                              this.state.viewData.installRequest.irq.installRequestAttachmentsList.map(
                                (attachment) => {
                                  return this.renderAttachment(attachment);
                                }
                              )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className={"mt6"}>
                          <h4>Additional Install Information</h4>
                          <Grid container>
                            <Table style={{width: "100%"}}>
                              <TableHead style={{width: "100%", display: 'table-header-group'}}>
                                <TableRow style={{width: "100%"}}>
                                  <TableCell style={{width: '70%'}}>Question</TableCell>
                                  <TableCell style={{width: '30%'}}>Response</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Array.isArray(
                                  this.state.viewData.installRequest.ord.orderAttributesList
                                ) ?
                                  this.state.viewData.installRequest.ord.orderAttributesList.map(
                                    (attribute) => {
                                      return this.renderAttribute(attribute);
                                    }
                                  ) :
                                  <>
                                    No answered questions
                                  </>
                                }
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                    </Grid>
                    <Grid item xs={4} className={"pr5 pt5"}>
                      <Card variant={"outlined"}>
                        <CardContentHeader>Communication</CardContentHeader>
                        <CardContent>
                          <br />
                          <h3 className={"mt2 mb2"}>Replies/Responses</h3>
                          <hr />
                          <br />

                          <Grid container>
                            <Grid item xs={12}>
                              <p className={"text-color-dark mb6"}>
                                Messages between contractor and customer related to rejections and responses, appear here.
                              </p>

                              {
                                (this.state.viewData.installRequest.comm) ? <>
                                {
                                  (this.state.viewData.installRequest.comm.map(
                                      comm => {
                                        return this.renderComm(comm);
                                      }
                                  ))
                                }
                                </> : <></>
                              }

                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                  </Grid>


                  <Card className={"mt10"} variant={"outlined"}>
                    <CardContentHeader>Quote Details</CardContentHeader>
                    <CardContent>
                      <br />
                      <Grid container>
                        <Grid item xs={12}>
                          <p className={"mr3 ml3 "} align={"right"}></p>
                        </Grid>
                      </Grid>
                      <br />
                      <ProductLineItems
                        orderLineItemsList={
                          this.state.viewData.installRequest.ord.orderLineItemsList
                        }
                        setting={this.setting}
                        state={this.state}
                        status={this.state.viewData.installRequest.irq.status}
                        removeLineItem={this.removeLineItem}
                        handleChange={this.handleChange}
                        handleBlur={this.updateDeposits}
                        depositPctField={this.state.data.depositPct}
                      />
                      <br />
                      <Grid container>
                        <Grid item sm={4} align={"right"} />
                        <Grid
                          item
                          sm={8}
                          display={"flex"}
                          style={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                            textAlign: "right",
                          }}
                        >
                          <>
                            {this.state.viewData.installRequest.irr.requestRespondedAt &&
                            this.state.viewData.installRequest.quot != null &&
                            this.state.viewData.installRequest.quot.lastSendDate !== null ? (
                              <Grid className={"mr3"} display={"inline-flex"}>
                                <SuiTypography
                                  fontSize={"1rem"}
                                  color={"primary"}
                                  className={"small"}
                                >
                                  Last sent at{" "}
                                  {moment
                                    .utc(this.state.viewData.installRequest.quot.lastSendDate)
                                    .local()
                                    .format("LLL")}
                                </SuiTypography>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </>
                          {this.state.viewData.installRequest.irq.status !== "INVOICE_PAID" && (
                            <QuoteActionButtons
                              state={this.state}
                              cancel={this.cancelAction}
                              save={this.saveDraft}
                              send={this.sendQuote}
                              sendDisabled={sendQuoteDisabled || !this.state.status.isLineItemsSufficient}
                            />
                          )}
                        </Grid>
                      </Grid>

                      {this.state.viewData.installRequest.irq.status !== "INVOICE_PAID" && (
                        <AddAdditionalLineItems
                          state={this.state}
                          handleChange={this.handleChange}
                          addNewLineItem={this.addNewLineItem}
                          handleBlur={this.handleBlur}
                          noop={this.noop}
                        />
                      )}
                    </CardContent>
                  </Card>

                  {this.state.viewData.installRequest.quot === null && (
                      <>
                        <WarningHeader color={"primary"} header="Notice: Quote not saved yet">
                          <p>This is just a preview of a quote.</p>
                          <br />
                          <p>
                            A quote has <strong>not</strong> been generated yet. Verify the
                            information below is correct, and add any additional line items necessary.
                            You may save progress by clicking <strong>SAVE DRAFT</strong>. When you
                            are ready, click <strong>ACCEPT & SEND QUOTE</strong> to save and send the
                            quote to the user; the user will be sent an email confirmation to accept
                            or reject the proposal.
                          </p>
                        </WarningHeader>
                      </>
                  )}

                </Grid>
                {/*<Grid item md={1}></Grid>*/}
                {/*<Grid item md={3}>*/}
                {/*  <InstallRequestSidebar state={this.state} />*/}
                {/*</Grid>*/}
              </Grid>
            )}
            <br />
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  customSubmit() {
    if (this.state.viewData.installRequest.irq.status !== "INVOICE_PAID") {
      return (
        <>
          {this.state.viewData.installRequest.irq.contractorId !== null && (
            <Grid container>
              <Grid item md={12} style={{ textAlign: "right ", justifyContent: "flex-end" }}>
                <RejectRequest
                  state={this.state}
                  rejectRequestDisabled={false}
                  className={"mt6"}
                  rejectHandler={this.rejectQuote}
                />
              </Grid>
            </Grid>
          )}
        </>
      );
    }
  }

  renderComm(comm) {
    const body = { __html: comm.body};
    return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <span className={"text-muted"}>
                {moment.utc(comm.created).local().format("LLL")}
              </span><br/>
              <strong>From: </strong>{comm.sender}
            </Grid>
            <Grid item={12}>
              <div dangerouslySetInnerHTML={body}></div>
            </Grid>
          </Grid>
          <hr className={"mt3 mb3"}/>
        </>
    );

  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(InstallRequestsAddEditForm);
