import ZevForm from "../ZevForm";
import Handler from "../Handler";
import R from "../../../Reusable/Responses/R";

class ResponseHandler extends Handler {
  newState = {};

  constructor(response: R, form: ZevForm, scope, callback) {
    super(response, form, scope, callback);

    this.newState = _.merge({}, scope.state, this.tempFormStatus);
    this.data = form.data(true);
    this.scope = scope;
    this.callback = callback;
  }

  async handle(state) {
    return await new Promise((res, rej) => {
      if (this.response.getIsSuccess()) {
        // this.doPause();
        this.markSuccess();

        if (typeof this.newState.data === "undefined") {
          this.newState.data = {};
        }

        for (let dt in this.data) {
          if (typeof this.newState.data[dt] === "undefined") {
            this.newState.data[dt] = {};
          }
          this.newState.data[dt].touched = false;
        }

        this.scope.setState(this.newState);

        setTimeout(res, 1);

        return this.response;
      } else {
        switch (this.response.getStatus()) {
          case "ERR_VALIDATION":
            this.markValidationError();

            this.form.displayErrors(this.response.getErrors(), this.response.getValidationErrors());

            this.doPause();
            setTimeout(rej, 500);
            break;
          default:
            this.markException(false);

            this.form.displayErrors(this.response.getErrors());

            this.doPause();
            setTimeout(rej, 500);

            break;
        }
      }
    })
      .then(() => {
        return this.response;
      })
      .catch((err) => {
        this.reset();
        return this.response;
      });
  }
}

export default ResponseHandler;
