import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fad, faTireRugged, faUser } from "@fortawesome/pro-duotone-svg-icons";
import Select, { components } from "react-select";
import "react-quill/dist/quill.snow.css";
import { html, SiteLinkButton } from "./Misc";
import Tooltips from "./Tooltips";
import ExpandCollapse from "./ExpandCollapse";
import fileDownload from "js-file-download";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faShield } from "@fortawesome/pro-regular-svg-icons";
import Upload from "./Upload";
import SuiInput from "../components/SuiInput";
import SuiButton from "../components/SuiButton";
import SuiBox from "../components/SuiBox";
import Grid from "@mui/material/Grid";
import LabelElement from "./Forms/Elements/LabelElement";

library.add(fad);
library.add(faTireRugged);
library.add(faUser);
library.add(faShield);

export const FieldErrors = ({ name, touched, errors }) => {
  if (Array.isArray(errors)) {
    return (
      <>
        <div className="field-error-list">
          {errors.map((error, idx) => (
            <p key={"field-error-" + name + "-" + idx} className="field-error">
              <FontAwesomeIcon
                icon={["fas", "exclamation-triangle"]}
                style={{ color: "#900" }}
                className="mr-2"
              />
              {error}
            </p>
          ))}
        </div>
      </>
    );
  } else if (typeof errors === "string" && errors !== null) {
    return (
      <>
        <div className="field-error-list">
          <p key={"field-error-" + name + "-"} className="field-error">
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              style={{ color: "#900" }}
              className="mr-2"
            />
            {errors}
          </p>
        </div>
      </>
    );
  } else {
    return "";
  }
};

const isFieldRequired = (vr, attr) => {
  for (let rule in vr) {
    if (rule === "required" && vr[rule].opt === true) {
      return attr === "class" ? "required-field" : attr === "title" ? "This field is required" : "";
    }
  }

  return attr === "class" ? "" : attr === "title" ? "This field is optional" : "";
};

const sizer = (size, isLabel, fullWidth) => {
  let cls = "";

  if (fullWidth) {
    cls = "col-xs-12";
  } else {
    if (isLabel) {
      if (size === "1") {
        cls = "col-xs-12 col-sm-2 col-md-2 col-lg-1";
      } else if (size === "2") {
        cls = "col-xs-12 col-sm-2 col-md-1 col-lg-1";
      } else if (size === "3") {
        cls = "col-xs-12 mt-2 col-sm-1 col-md-2 col-lg-1";
      } else if (size === "4") {
        cls = "col-xs-12 mt-2 col-sm-1 col-md-2 col-lg-1";
      } else {
        cls = "col-xs-12 col-sm-2 col-md-2 col-lg-1";
      }
    } else {
      if (size === "0") {
        cls = "";
      } else if (size === "1") {
        cls = "col-xs-12 col-sm-10 col-md-10";
      } else if (size === "2") {
        cls = "col-xs-12 col-sm-5 col-md-4";
      } else if (size === "3") {
        cls = "col-xs-12 mt-2 col-sm-11 col-md-10 col-lg-2";
      } else if (size === "4") {
        cls = "col-xs-12 mt-2 col-sm-11 col-md-5 col-lg-2";
      } else if (size === "9999") {
        cls = "col-xs-12";
      } else {
        cls = "col-sm-1";
      }
    }
  }
  return cls;
};

export const RadioInputs = ({ sz, label, name, touched, options, checked, ...props }) => {
  const renderOption = (opt, idx) => {
    return (
      <Label key={name + "_" + opt.value} className="match-method-radios">
        <Input
          type="radio"
          name={name}
          checked={props.value === opt.value}
          value={opt.value}
          onChange={props.onChange}
        />
        {opt.label}
      </Label>
    );
  };

  let clsError = "";

  if (touched && !props.valid) {
    clsError = "control-error";
  } else {
    clsError = "";
  }

  return (
    <>
      <div className={sizer(sz, true) + " " + clsError + " text-right"}>
        <Label
          className={"col-form-label " + isFieldRequired(props.vr, "class")}
          title={isFieldRequired(props.vr, "title")}
          htmlFor={props.id || props.name}
        >
          {label}
        </Label>
      </div>
      <div className={sizer(sz) + " ml-4 " + props.class}>
        {options.map((opt, idx) => renderOption(opt, idx))}
      </div>
      <div className={sizer(sz, true) + " " + clsError + " text-right"}>&nbsp;</div>
      <div className={sizer(sz) + " " + props.class}>
        <FieldErrors name={props.name} errors={props.errors} touched={touched} />
      </div>
    </>
  );
};

export const TextInput = ({ sz, label, touched, ...props }) => {
  let clsError = "";
  let hideLabel = false;

  if (props.hideLabel) {
    hideLabel = true;
  } else {
    hideLabel = false;
  }

  if (touched && !props.valid) {
    clsError = "control-error";
  } else {
    clsError = "";
  }

  return (
    <>
      {hideLabel === false ? (
        <div className={sizer(sz, true) + " " + clsError + " text-right"}>
          <Label
            className={"col-form-label " + isFieldRequired(props.vr, "class")}
            title={isFieldRequired(props.vr, "title")}
            htmlFor={props.id || props.name}
          >
            {label}
          </Label>
        </div>
      ) : (
        ""
      )}
      <div className={sizer(sz)}>
        <SuiInput
          type={field_type}
          placeholder={placeholder}
          size={sz}
          className={clsError}
          {...props}
        />

        <FieldErrors name={props.name} errors={props.errors} touched={touched} />
      </div>
    </>
  );
};

export class AjaxButtonCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.ajax = this.props.ajax;

    this.state = {
      value: 1,
      name: this.props.name,
      recordId: this.props.recordId,
      isSaving: false,
      savingValue: null,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.resetSaveState = this.resetSaveState.bind(this);
  }

  isSelected(v) {
    if (typeof v !== "undefined" && typeof this.state.value !== "undefined") {
      return this.state.value.toString() === v.toString();
    } else {
      return false;
    }
  }

  resetSaveState() {
    this.setState({
      isSaving: false,
      savingValue: null,
    });
  }

  onChangeHandler(ev) {
    const saveValue = ev.target.checked ? 1 : 0;

    this.setState(
      {
        value: saveValue,
        isSaving: true,
        savingValue: saveValue,
      },
      () => {
        this.props.handleChange(this.props.name, saveValue, this.resetSaveState);
      }
    );

    // do ajax
  }

  option(option) {
    const inputId = this.state.name + option.value;
    const isSelected = this.state.value === option.value ? "checked" : null;
    const selClass = this.state.value === option.value ? "zev-btn-success " : "zev-btn-danger ";
    const ckey = "select-value-" + this.props.recordId + "." + this.props.name + "." + option.value;

    let selectButtonIcon = "";
    let isDisabled = false;
    let dClass = "";
    let label = option.label;
    let icon = "";
    let aClass = "";

    if (this.props.form.checked) {
      icon = "check-circle";
      aClass = "zev-btn-primary";
    } else {
      icon = "times-circle";
      aClass = "zev-btn-danger";
    }

    if (this.state.isSaving && this.state.savingValue === option.value) {
      selectButtonIcon = <FontAwesomeIcon icon={["fas", "tire"]} className="fa-spin" />;
      dClass = "btn-disabled disabled";
      isDisabled = true;
      label = "Saving";
    } else {
      selectButtonIcon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={["fas", icon]} className="onepointfiveicon" />
          <span
            className="option-value-indicator inner-shield-layer fa-layers-text fa-inverse"
            data-fa-transform="shrink-2 down-2"
            style={{ fontWeight: 900 }}
          >
            {option.sub}
          </span>
        </span>
      );
    }

    return (
      <span key={ckey} className="select-value text-center">
        <Input
          disabled={isDisabled}
          type="checkbox"
          name={option.name}
          id={inputId}
          checked={this.props.form.checked}
          value={option.value}
          pv={option.sub}
          onChange={this.onChangeHandler}
        />
        <Label
          htmlFor={inputId}
          className={"btn zev-btn zev-btn-size-lg text-center " + aClass + " " + dClass}
        >
          {selectButtonIcon}
          &nbsp;
          {label}
        </Label>
      </span>
    );
  }

  render() {
    return (
      <>
        {this.option({
          label: this.props.label,
          name: this.state.name + "_r",
          value: this.state.value,
        })}
      </>
    );
  }
}

export class ScenarioImpactRadio extends React.Component {
  constructor(props) {
    super(props);

    this.saveMethod = this.props.ajaxSaveImpact;

    this.state = {
      initialValue: this.props.selection,
      value: this.props.selection,
      name: this.props.name,
      isSaving: false,
      savingValue: null,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.resetSaveState = this.resetSaveState.bind(this);
  }

  isSelected(v) {
    if (typeof v !== "undefined" && typeof this.state.value !== "undefined") {
      return this.state.value.toString() === v.toString();
    } else {
      return false;
    }
  }

  resetSaveState() {
    this.setState({
      isSaving: false,
      savingValue: null,
    });
  }

  onChangeHandler(ev) {
    this.setState({
      value: ev.target.value,
      isSaving: true,
      savingValue: ev.target.value,
    });
    this.saveMethod(
      this.props.scenarioId,
      this.props.sectionKey,
      ev.target.value,
      this.resetSaveState
    );
  }

  option(option) {
    const inputId = this.state.name + option.value;
    const isSelected = this.state.value === option.value ? "checked" : null;
    const selClass = this.state.value === option.value ? "zev-btn-primary " : "zev-btn-default ";
    const ckey =
      "damage-scenario-triads-triad-impact-" +
      this.props.scenarioId +
      "." +
      this.props.sectionKey +
      "." +
      option.value;

    let selectButtonIcon = "";
    let isDisabled = false;
    let dClass = "";
    let label = option.label;

    if (this.state.isSaving && this.state.savingValue === option.value) {
      selectButtonIcon = <FontAwesomeIcon icon={["fas", "tire"]} className="fa-spin" />;
      dClass = "btn-disabled disabled";
      isDisabled = true;
      label = "Saving";
    } else {
      selectButtonIcon = (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={["fas", "shield"]} className="onepointfiveicon" />
          <span
            className="option-value-indicator inner-shield-layer fa-layers-text fa-inverse"
            data-fa-transform="shrink-2 down-2"
            style={{ fontWeight: 900 }}
          >
            {option.sub}
          </span>
        </span>
      );
    }

    return (
      <Col key={ckey} xs="2" className="select-impact-value">
        <Input
          disabled={isDisabled}
          type="radio"
          name={this.state.name}
          id={inputId}
          checked={this.isSelected(option.value)}
          value={option.value}
          pv={option.sub}
          onChange={this.onChangeHandler}
        />
        <Label
          htmlFor={inputId}
          className={"btn zev-btn btn-block zev-btn-size-lg text-center " + selClass + " " + dClass}
        >
          {selectButtonIcon}
          &nbsp;
          {label}
        </Label>
      </Col>
    );
  }

  savingIndicator() {
    return (
      <>
        <i className="fas fa-spinner-third fa-spin" style={{ color: "009900" }}></i> Saving
      </>
    );
  }

  render() {
    return (
      <>
        <Row>
          <Col xs="1" className="scenario-impact current-value"></Col>
          <Col xs="11">
            <Row>
              {this.props.options.map((option) => this.option(option))}
              <Col xs="2" className="text-center flex-column align-center">
                {this.props.isError ? (
                  <>
                    <span className="triad-error-message">
                      <FontAwesomeIcon
                        className="error-indicator"
                        icon={["fas", "exclamation-square"]}
                        size="2x"
                      />
                      Error
                    </span>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export class CheckboxRadioListInputs extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      selectAll: false,
      label: props.label,
      type: props.type || "radio",
      style: props.style || "icon",
      checked: props.checked || [],
      name: props.name,
      appendKey: props.appendKey || false,
      size: props.size,
      options: props.options, // array of label => value's
    };

    this.selectAll = this.selectAll.bind(this);
    this.allSelected = this.allSelected.bind(this);
    this.selectAllToggle = this.selectAllToggle.bind(this);
  }

  changeHandler = (ev) => {
    if (!this.props.multiple) {
      let newArr = [];
      newArr.push(ev.target.value);

      this.setState({ checked: newArr, touched: true }, () => {
        this.props.manySet(this.state.name, this.state.checked);
      });
    } else {
      const idx = this.state.checked.indexOf(ev.target.value);
      let newArr = this.state.checked;

      if (idx > -1) {
        newArr.splice(idx, 1);
      } else {
        newArr.push(ev.target.value);
      }

      this.setState({ checked: newArr, touched: true }, () => {
        this.allSelected();
      });

      this.props.manySet(this.state.name, this.state.checked);
    }
  };

  isChecked(value) {
    const check = value.toString();

    if (this.props.checked.indexOf(check) > -1) {
      return true;
    }

    return false;
  }

  renderObject(opt, i) {
    const inputType = this.state.type;
    const fieldId = this.state.name + "_" + opt.value;
    let append = "";

    if (this.state.appendKey) {
      append = "<strong>[" + opt[this.state.appendKey].toUpperCase() + "]</strong>";
    }

    if (this.state.style === "icon") {
      return (
        <>
          <Input
            type={inputType}
            className="asa_cbr_selector"
            id={fieldId}
            name={this.state.name}
            value={opt.value}
            checked={!!this.isChecked(opt.value)}
            onChange={this.changeHandler}
          />
          <div className="asa_cbr_option ">
            <Label for={fieldId}>
              <span className="asa_cbr_option_icon">{opt.icon}</span>
              <div align="center">{html(opt.label)}</div>
            </Label>
          </div>
        </>
      );
    } else if (this.state.style === "list") {
      return (
        <>
          <Input
            type={inputType}
            className="asa_cbr_selector"
            id={fieldId}
            name={this.state.name}
            value={opt.value}
            checked={!!this.isChecked(opt.value)}
            onChange={this.changeHandler}
          />
          <Col className="asa_cbr_option" sm={12} key={fieldId}>
            <Label for={fieldId}>
              <div align="left" className="text-container">
                <span className="indicator" />
                {html(opt.label)}
                {html(append, "ml-3")}
              </div>
            </Label>
          </Col>
        </>
      );
    } else if (this.state.style === "condensed") {
      return (
        <>
          <Input
            type={inputType}
            className="asa_cbr_selector"
            id={fieldId}
            name={this.state.name}
            value={opt.value}
            checked={!!this.isChecked(opt.value)}
            onChange={this.changeHandler}
          />
          <Col className="asa_cbr_option condensed" sm={12} key={fieldId}>
            <Label for={fieldId}>
              <div align="left" className="text-container">
                <span className="indicator" />
                {html(opt.label)}
                {html(append, "ml-3")}
              </div>
              <p className="scenario-subtitle">{opt.subtitle}</p>
            </Label>
          </Col>
        </>
      );
    }
  }

  allSelected() {
    let allSelected = true;

    for (let k = 0; k < this.props.options.length; k++) {
      if (this.state.checked.indexOf(this.props.options[k].value.toString()) === -1) {
        allSelected = false;
      }
    }

    if (allSelected) {
      this.setState({
        selectAll: true,
      });
    } else {
      this.setState({
        selectAll: false,
      });
    }

    return allSelected === true || this.state.selectAll === true;
  }

  selectAllToggle() {
    if (this.state.selectAll === true) {
      // deselect all

      this.setState(
        {
          checked: [],
          selectAll: false,
        },
        () => {
          this.props.manySet(this.state.name, this.state.checked);
        }
      );
    } else {
      // select all
      let newArr = [];
      for (let k = 0; k < this.props.options.length; k++) {
        newArr.push(this.props.options[k].value.toString());
      }

      this.setState(
        {
          checked: newArr,
          selectAll: true,
        },
        () => {
          this.props.manySet(this.state.name, this.state.checked);
        }
      );
    }
  }

  selectAll() {
    const fieldId =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    return (
      <div key={fieldId} className="list-item">
        <Input
          type="checkbox"
          className="asa_cbr_selector"
          id={fieldId}
          name="selectall"
          value="1"
          checked={this.state.selectAll}
          onChange={this.selectAllToggle}
        />
        <Col className="asa_cbr_option condensed" sm={12} key={fieldId}>
          <Label for={fieldId}>
            <div align="left" className="text-container">
              <span className="indicator" />
              <div className="select-all-checkboxes">Select All</div>
            </div>
          </Label>
        </Col>
      </div>
    );
  }

  renderOption(opt, i) {
    return (
      <div key={i} className="list-item">
        {this.renderObject(opt, i)}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.label !== false ? (
          <div className="col-md-1 text-right">
            <Label
              className={isFieldRequired(this.props.vr, "class") + " mb-3 col-form-label"}
              title={isFieldRequired(this.props.vr, "title")}
              htmlFor=""
            >
              {this.state.label}
              {this.props.tooltip ? (
                <Tooltips title={this.props.tooltip.title} body={this.props.tooltip.body} />
              ) : (
                ""
              )}
            </Label>
          </div>
        ) : (
          " "
        )}
        <div className={sizer(this.props.size) + " asa_cbr asa_cbr_" + this.state.style}>
          {this.props.multiple ? this.selectAll() : ""}
          {this.props.options.map((opt, idx) => {
            opt.icon = <FontAwesomeIcon icon={["fad", this.props.icon]} />;
            return this.renderOption(opt, idx);
          })}
        </div>
        <div className="display: block; width: 100%;">
          {!this.props.touched ? (
            <FieldErrors
              name={this.props.name}
              errors={this.props.errors}
              touched={this.props.touched}
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export class CheckboxRadioListInputsFiltered extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      filter: {
        options: [],
        suboptions: {},
        vr: [],
        value: "",
        valid: false,
        touched: false,
        errors: [],
      },
      label: props.label,
      style: props.style || "icon",
      checked: props.checked.map(Number) || [],
      name: props.name,
      size: props.size,
      options: props.options, // array of label => value's
    };

    this.state.filter.options = this.props.options.map((v, i) => {
      const option = {
        label: v.label + " (" + v.count + " threats)",
        labelnocount: v.label,
        value: v.value,
      };

      this.state.filter.suboptions[v.value] = v.threats;
      return option;
    });
  }

  handleChangeFilter(e, v) {
    const filter = this.state.filter;
    filter.value = e;

    this.setState({
      filter: filter,
    });
  }

  removeThreat(threatId) {
    let selectedThreats = this.state.checked;
    let index = selectedThreats.indexOf(threatId);

    if (selectedThreats[index] === threatId) {
      selectedThreats.splice(index, 1);
      this.setState({ checked: selectedThreats }, () => {
        this.props.manySet("threats", this.state.checked);
      });
    }
  }

  addThreat(threatId) {
    let selectedThreats = this.state.checked;

    selectedThreats.push(threatId);

    this.setState({ checked: selectedThreats }, () => {
      this.props.manySet("threats", this.state.checked);
    });
  }

  isChecked(value) {
    const check = value.toString();

    if (this.state.checked.indexOf(check) > -1) {
      return true;
    }

    return false;
  }

  /*
    getTThreatFromList(threatId, list) {
        const opts = list || this.state.filter.suboptions;
        const keys = Object.keys(opts);
        for (let k = 0; k < keys.length; k++) {
            if (Array.isArray(opts[keys[k]])) {
                return this.getThreatFromList(threatId, opts[keys[k]]);
            } else {
                if (opts[keys[k]].value === threatId) {
                    return opts[keys[k]];
                }
            }
        }
  
        return false;
    }
    */

  getThreatFromList(threatId, list) {
    const opts = list || this.state.options;
    let type = "";
    threatId = parseInt(threatId);

    for (let k = 0; k < opts.length; k++) {
      if (opts[k].threats.length > 0) {
        for (const threat in opts[k].threats) {
          if (opts[k].threats[threat].value === threatId) {
            type = opts[k].threats[threat];
          }
        }
      }
    }

    return type;
  }

  getThreatTypeFromList(threatId, list) {
    const opts = list || this.state.options;
    let type = "";

    threatId = parseInt(threatId);

    for (let k = 0; k < opts.length; k++) {
      if (opts[k].threats.length > 0) {
        for (const threat in opts[k].threats) {
          if (opts[k].threats[threat].value === threatId) {
            type = opts[k].label;
          }
        }
      }
    }

    return type;
  }

  getType(threatId) {
    const type = this.getThreatFromList(threatId);
  }

  renderSelectedObject(threatId, i) {
    const opt = this.getThreatFromList(threatId); //, fltr.value

    const fieldId = this.state.name + "_" + opt.value;

    const type = this.getThreatTypeFromList(threatId);

    return (
      <>
        <Col className="asa_filtered_list_option" sm={12} key={fieldId}>
          <div className="fbox target">
            <div className="fitem-button">
              <Button
                className="zev-btn pr-3 pl-5 zev-btn-danger cancel idt zev-btn-size-sm"
                onClick={() => {
                  this.removeThreat(opt.value);
                }}
              >
                Remove
              </Button>
            </div>
            <div className="fitem-text target">
              {html("<strong>" + opt.tid + ":</strong>&nbsp;&nbsp; " + opt.label)}
              {html('<p class="text-muted">' + type + "</p>")}
            </div>
          </div>
        </Col>
      </>
    );
  }

  renderObject(opt, i) {
    const fieldId = this.state.name + "_" + opt.value;

    return (
      <>
        <Col className="asa_filtered_list_option" sm={12} key={fieldId}>
          <div className="fbox source">
            <div className="fitem-text source">
              {html("<strong>" + opt.tid + ":</strong>&nbsp;&nbsp; " + opt.label, "threat-title")}
            </div>
            <div className="fitem-button source">
              <Button
                className="zev-btn pl-3 pr-5 zev-btn-success chk-rt idtr zev-btn-size-sm"
                onClick={() => {
                  this.addThreat(opt.value);
                }}
              >
                Add
              </Button>
            </div>
          </div>
          <ExpandCollapse
            previewHeight="80px"
            className="middle-button"
            ellipsis={false}
            expandText={html('<i class="fad fa-caret-circle-down"></i> Show More')}
            collapseText={html('<i class="fad fa-caret-circle-up"></i> Hide')}
          >
            <div>{html(opt.description, "pt-1")}</div>
          </ExpandCollapse>
        </Col>
      </>
    );
  }

  renderOption(opt, i) {
    if (this.state.checked.indexOf(opt.value) > -1) {
      return null;
    }

    return (
      <div key={i} className="list-item">
        {this.renderObject(opt, i)}
      </div>
    );
  }

  renderSelectedOption(threatId, i) {
    return (
      <div key={i} className="list-item ">
        {this.renderSelectedObject(threatId, i)}
      </div>
    );
  }

  handleBlurFilter() {}

  render() {
    return (
      <>
        <Col sm="12">
          <Row>
            {this.state.label !== false ? (
              <div className="col-md-1 text-right">
                <Label
                  className={isFieldRequired(this.props.vr, "class") + " mb-3 col-form-label"}
                  title={isFieldRequired(this.props.vr, "title")}
                  htmlFor=""
                >
                  {this.state.label}
                  {this.props.tooltip ? (
                    <Tooltips title={this.props.tooltip.title} body={this.props.tooltip.body} />
                  ) : (
                    ""
                  )}
                </Label>
              </div>
            ) : (
              " "
            )}
            <SelectInput
              size="2"
              label={false}
              onChange={this.handleChangeFilter.bind(this)}
              onBlur={this.handleBlurFilter}
              name="filter"
              value={this.state.filter.value}
              touched={this.state.filter.touched}
              valid={this.state.filter.valid}
              vr={this.state.filter.vr}
              errors={this.state.filter.errors}
              placeholder="Select threat(s)..."
              options={this.state.filter.options}
            />
          </Row>
          <Row className="mt-4">
            <Col sm="12" md="1" />
            <Col sm="12" md="5">
              <h5>Available Threats</h5>
              <div
                id="SelectedThreats"
                className="asa_cbr asa_cbr_list threat-map selected-threats "
              >
                {this.state.filter.value !== ""
                  ? this.state.filter.suboptions[this.state.filter.value.value].map((opt, idx) => {
                      return this.renderOption(opt, idx);
                    })
                  : ""}
              </div>
              {!this.props.touched ? (
                <FieldErrors
                  name={this.props.name}
                  errors={this.props.errors}
                  touched={this.props.touched}
                />
              ) : (
                ""
              )}
            </Col>
            <Col sm="12" md="5">
              <h5>Selected Threats</h5>
              <div id="SelectedThreatsSelected" className="threat-map selected-threats target">
                {this.state.checked.map((threatId, idx) => {
                  return this.renderSelectedOption(threatId, idx);
                })}
              </div>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export class CheckboxInput extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      checked: this.props.checked,
      animClass: "",
    };

    this.value = true;

    this.handleSwitch = this.handleSwitch.bind(this);

    this.sz = props.sz;
    this.name = props.name;
    this.label = props.label;
  }

  handleSwitch(el, checked) {
    this.setState({ checked: checked, animClass: "fa-spin" }, () => {
      this.props.onChange(el, { name: this.name, checked: checked });
    });
    // this.setState({animClass: "fa-spin"});
    // this.props.onChange(el, {name: this.name, checked: checked});

    setTimeout(() => {
      this.setState({ animClass: "" });
    }, 500);
  }

  render() {
    const animator = (
      <FontAwesomeIcon icon={faTireRugged} className={"switch-padding " + this.state.animClass} />
    );

    return (
      <>
        <div className={sizer(this.sz, true) + " text-right"}>
          <Label
            className={"mb-3 col-form-label " + isFieldRequired(this.props.vr)}
            title={isFieldRequired(this.props.vr, "title")}
            htmlFor={this.props.id || this.name}
          >
            {this.label}
            {this.props.tooltip ? (
              <Tooltips title={this.props.tooltip.title} body={this.props.tooltip.body} />
            ) : (
              ""
            )}
          </Label>
        </div>
        <div className={sizer(this.sz) + " flx-ctr"}>
          <FormGroup className="mb-0" check>
            <Switch
              offColor="primary"
              offText={<i className="fa fa-times-circle" />}
              onColor="primary"
              onText={<i className="fa fa-check-circle" />}
              name={this.name}
              labelText={animator}
              // value={this.state.checked}
              // defaultValue={this.props.dv}
              // onChange={(el, state) => this.handleSwitch(el, state)}
              //{...this.props}
              // checked={this.state.checked}
              onChange={this.handleSwitch}
              defaultValue={this.state.checked}
              value={this.state.checked}
            />{" "}
          </FormGroup>
        </div>

        <div className={sizer(this.sz, true) + " text-right"}>
          <Label className="mb-3 col-form-label"></Label>
        </div>

        <div className="col-xs-12 col-sm-1 col-md-1 flx-ctr">&nbsp;</div>

        <div className="col-xs-12 col-sm-10 col-md-8 flx-ctr">
          <FieldErrors
            name={this.props.name}
            errors={this.props.errors}
            touched={this.props.touched}
          />
        </div>
      </>
    );
  }
}

export const HiddenInput = (props) => {
  if (props.value && props.name) {
    return (
      <>
        <input type="hidden" name={props.name} value={props.value} />
      </>
    );
  } else {
    return <></>;
  }
};

export const NonRtfTextAreaInput = ({ sz, label, touched, ...props }) => {
  let clsError = "";

  let defaultValue = props.defaultValue;

  const [value, setValue] = useState(props);

  if (touched && !props.valid) {
    clsError = "control-error";
  } else {
    clsError = "";
  }

  const handleChange = (event) => {
    console.log("ballz");
    setValue(event.target.value);
    // props.onChange(event);
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%", flexGrow: "1", maxHeight: "100%" }}>
        <textarea
          className={"form-control scenario-comment-entry" + " " + clsError}
          autoComplete="off"
          ref={props.cref}
          defaultValue={defaultValue}
          {...props}
          onChange={handleChange}
          onBlur={props.onBlur}
        />
      </div>
      <FieldErrors name={props.name} errors={props.errors} touched={touched} />
    </>
  );
};

/*
export class TextAreaInput extends React.Component {

  label = "";
  sz = "";

  constructor(props) {
    super(props);
    this.state = { focus: false, editorHtml: false };
    this.props = props;
    this.name = props.name;
    this.label = props.label;
    this.sz = props.sz;
  }

  handleChange = (html, e) => {
    // html = html.replace(/<p><br><\/p>/g, "<p></p>");
    this.setState({ editorHtml: html });
    this.props.onChange({ value: html }, { name: this.props.name });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
    this.props.onBlur({ value: this.state.editorHtml }, { name: this.props.name });
  };

  getClass() {
    if (this.state.focus === true) {
      return "is-focused";
    } else {
      return "not-focused";
    }
  }

  hiddenTextAreaChange() {

  }

  lineBreakMatcher() {
    const Delta = Quill.import("delta");
    let newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
  };


  render() {
    var editorClass = this.getClass();
    let classes = "";

    if (this.label !== false) {
      classes = sizer(this.sz);
    }

    return (
      <>
        {(this.label !== false) ?
          <div className={sizer(this.sz, true) + " text-right"}>
            <Label className={"col-form-label " + isFieldRequired(this.props.vr, "class")}
                   htmlFor={this.props.id || this.props.name}
                   title={isFieldRequired(this.props.vr, "title")}>{this.label}</Label>
          </div>
          :
          ""
        }
        <div className={classes}>
          <ReactQuill
            id={this.props.name}
            theme="snow"
            placeholder={this.props.placeholder}
            className={editorClass}
            modules={{
              clipboard: {
                matchVisual: false,
              },
            }}
            value={this.state.editorHtml || this.props.value}
            onChange={this.handleChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <FieldErrors name={this.props.name} errors={this.props.errors} touched={this.props.touched} />
        </div>
      </>
    );
  }
}
*/

const IsOptionDisabled = (obj1) => {
  if (typeof obj1.separator !== "undefined" && obj1.separator === true) {
    return true;
  }

  return false;
};

const CustomOptionLabel = ({ value, label, separator, sub }) => {
  if (separator === true) {
    return (
      <>
        <div className="option-separator">
          <div className="hr-line">
            <span className="hr-text">{label}</span>
          </div>
        </div>
      </>
    );
  }

  let subtext = "";

  if (sub) {
    subtext = <p className="text-muted ml-3 mb-0">{sub}</p>;
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>{label}</div>
        {subtext}
      </div>
    </>
  );
};
export const SelectInput = ({ name, touched, label, value, ...props }) => {
  const blurHandler = (ev, el) => {
    props.onBlur({}, { name: name });
  };

  let emptyLabel = "Please select an option";
  let placeHolder = "Please select an option";
  let Reloader = "";
  let sClass = "";

  if (typeof props.emptyLabel !== "undefined") {
    emptyLabel = props.emptyLabel;
  }

  if (typeof props.placeholder !== "undefined") {
    placeHolder = props.placeholder;
  }

  if (typeof props.reload !== "undefined") {
    sClass = "shrink90";
    Reloader = (
      <a
        className="select-box-reloader"
        href="#"
        onClick={props.reload.callback}
        title={props.reload.title}
      >
        <i className="fad fa-sync"></i>
      </a>
    );
  }

  const extClass = props.valid ? " is-valid" : "";
  const Input = (props) => {
    return <components.Input {...props} autoComplete="new-password" />;
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  return (
    <>
      {label !== false ? (
        <div className={sizer(props.size, true, props.shrinkLabel) + " text-right"}>
          <LabelElement
            title={isFieldRequired(props.vr, "title")}
            className={isFieldRequired(props.vr, "class") + " col-form-label"}
            htmlFor={name}
          >
            {label}
          </LabelElement>
        </div>
      ) : (
        ""
      )}
      <div className={"diflx flx-wrap " + sizer(props.size)}>
        <Select
          id={name}
          name={name}
          ref={props.noderef}
          components={{ Input }}
          value={value}
          placeholder={placeHolder}
          isOptionDisabled={IsOptionDisabled}
          formatOptionLabel={CustomOptionLabel}
          className={sClass + " react-select primary " + extClass}
          classNamePrefix="react-select"
          onBlur={blurHandler}
          onChange={props.onChange}
          options={props.options}
        />
        {Reloader}
        <FieldErrors name={name} errors={props.errors} touched={touched} />
      </div>
    </>
  );
};

export const SubmitCancelButtonInput = ({ sz, label, ...props }) => {
  let dispLabel = label;

  const doCancel = () => {
    window.history.back();
  };

  const onClickLabel = (e) => {
    dispLabel = props.actionLabel;
  };

  const classes = "zev-btn addlayer idt zev-btn-primary spinner ";

  return (
    <>
      <div className="submit-cancel-block">
        <div className={sizer(sz, true)} />
        <div className={sizer(sz) + " text-right"}>
          <Button
            color="primary"
            className={classes}
            onClick={onClickLabel}
            type="submit"
            {...props}
          >
            {dispLabel}
          </Button>
          <Button
            color="cancel"
            className="zev-btn cancel idt zev-btn-cancel"
            type="button"
            onClick={doCancel}
            {...props}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

class CustomInput extends React.Component {
  static propTypes = {};

  render = () => (
    <span className="calendar-icon">
      <Input
        className="form-control"
        label={this.props.label}
        onChange={this.props.handleChange}
        {...this.props}
        readOnly
      />
    </span>
  );
}

export class DTPicker extends React.Component {
  constructor(props) {
    super(props);
    this.sz = this.props.size;
    if (this.props.hideLabel) {
      this.hideLabel = true;
    } else {
      this.hideLabel = false;
    }
  }

  render() {
    let clsError = "";

    if (this.touched && !this.props.valid) {
      clsError = "control-error";
    } else {
      clsError = "";
    }

    return (
      <>
        {this.hideLabel === false ? (
          <div className={sizer(this.sz, true) + " " + clsError + " text-right"}>
            <Label
              className={"col-form-label " + isFieldRequired(this.props.vr, "class")}
              title={isFieldRequired(this.props.vr, "title")}
              htmlFor={this.props.id || this.props.name}
            >
              {this.props.label}
            </Label>
          </div>
        ) : (
          <></>
        )}
        <div className={sizer(this.sz)}>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            className={this.props.classes}
            popperPlacement="top-end"
            name={this.props.name}
            selected={this.props.selectedvalue}
            customInput={<CustomInput />}
            onChange={this.props.onChange.bind(this, { name: this.props.name })}
            timeFormat={false}
          />
          <FieldErrors
            name={this.props.name}
            errors={this.props.errors}
            touched={this.props.touched}
          />
        </div>
      </>
    );
  }
}

export class SubmitCancelButtonInputNew extends React.Component {
  constructor(props) {
    super(props);
    this.scope = this;
    this.props = props;
    this.actionLabel = props["data-action-label"];
    this.state = {
      disabled: props.disabled,
      size: props.sz,
      label: props.label,
      displayLabel: props.label,
      btnClass: props.btnclass || "primary",
    };
  }

  doCancel() {
    window.history.back();
  }

  onClickLabel(e) {
    // this.setState('displayLabel', this.actionLabel);
  }

  render() {
    if (this.props.sac) {
      this.classes = "zev-btn zev-btn-rt gofwd idtr zev-btn-" + this.state.btnClass + " spinner ";
    } else {
      this.classes = "zev-btn addlayer idt zev-btn-" + this.state.btnClass + " spinner ";
    }

    if (this.props.disabled) {
      this.classes += " disabled ";
    }

    let cancelBtnType = "primary";

    if (this.props.textCancel) {
      this.cancelClass = "btn-link";
      cancelBtnType = "link";
    } else {
      this.cancelClass = "zev-btn cancel idt zev-btn-cancel";
    }

    let submitSize = 6;

    let fullWidth = true;
    let pos = {};
    let bw = {};

    if (this.props.flow === "auth") {
      submitSize = 12;
      fullWidth = false;
      bw = {
        px: 6,
      };

      pos = {
        alignItems: "end",
        justifyContent: "end",
        display: "flex",
      };
    }

    return (
      <>
        {this.props.fullWidth ? "" : <div className={sizer(this.state.size, true)} />}
        <div className={sizer(this.state.size, false, this.props.fullWidth) + " text-right "}>
          <div className="submit-cancel-block">
            {this.props.back ? (
              <SiteLinkButton
                warning={this.props.warning}
                block={false}
                label={this.props.back.label}
                path={this.props.back.url}
                iconPosition="left"
                btnIcon="goback"
                btnStyle="p"
                style={{ float: "left" }}
              />
            ) : (
              ""
            )}

            <SuiBox mt={3} sx={{ textAlign: "center" }}>
              <Grid container={true} spacing={2}>
                <Grid xs={0} md={4} lg={(this.props.flow === 'auth') ? 6 : 4} />
                <Grid
                  sx={pos}
                  item={true}
                  xs={this.props.flow === "auth" ? 12 : 6}
                  md={this.props.flow === "auth" ? 12 : 4}
                  lg={this.props.flow === "auth" ? 6 : 4}
                >
                  <SuiButton
                    sx={bw}
                    fullWidth={fullWidth}
                    disabled={this.props.disabled}
                    variant="gradient"
                    className={this.classes}
                    onClick={this.onClickLabel}
                    type="submit"
                    color="primary"
                    size="medium"
                  >
                    {this.props.icon}
                    {this.props.label}
                  </SuiButton>
                </Grid>
                {this.props.flow !== "auth" ? (
                  <Grid item={true} xs={6} md={4}>
                    <SuiButton
                      fullWidth
                      className={this.cancelClass + " zev-btn-light "}
                      variant="gradient"
                      type="button"
                      size="medium"
                      color="light"
                      onClick={this.doCancel}
                    >
                      {this.props.cancelIcon}
                      Cancel
                    </SuiButton>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </SuiBox>
          </div>
        </div>
      </>
    );
  }
}

export class FileAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.Upload = new Upload({ section: props.section, assessmentId: props.assessmentId });

    switch (this.props.size) {
      case "sm":
        this.size = 3;
        break;
      case "md":
        this.size = 6;
        break;
      case "lg":
        this.size = 12;
        break;
      default:
        this.size = 3;
    }

    this.state = {
      alert: "",
      progress: 0,
      currentFile: undefined,
      selectedFiles: undefined,
      hoverDelete: {},
      message: "",
      fileData: [],
    };

    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.doDeleteAttachment = this.doDeleteAttachment.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.doHover = this.doHover.bind(this);
    this.doHoverOut = this.doHoverOut.bind(this);
  }

  selectFile(event, cb) {
    this.setState(
      {
        selectedFiles: event.target.files,
      },
      cb
    );
  }

  componentDidMount() {
    this.Upload.getFiles().then((response) => {
      this.setState({
        fileData: response.data.data.attachments.files,
      });
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({
      progress: 0,
      currentFile: currentFile,
    });

    this.Upload.upload(currentFile, (event) => {
      this.setState({
        progress: (Math.round((1000 * event.loaded) / event.total) / 1000) * 100,
      });
    })
      .then((response) => {
        this.setState({
          fileData: response.data.data.data.files,
        });

        setTimeout(() => {
          this.setState({
            currentFile: undefined,
          });
        }, 3500);
      })
      /*.then(
      
                (files) => {
                    this.setState(
                        {
                            fileData: files.data
                        }
                    );
                }
            )*/
      .catch(() => {
        this.setState({
          progress: 0,
          message: "Upload Error",
          currentFile: undefined,
        });
      });

    this.setState({
      selectedFiles: undefined,
    });
  }

  hideAlert() {
    this.setState({
      alert: "",
    });
  }

  downloadAttachment(fileId, assessmentId, ev) {
    this.Upload.download(fileId, assessmentId).then((response) => {
      let headerLine = response.headers["content-disposition"];
      let fileNameStart = headerLine.indexOf('"') + 1;
      let fileNameEnd = headerLine.lastIndexOf('"');
      let fileName = headerLine.substring(fileNameStart, fileNameEnd);
      fileDownload(response.data, fileName);
    });

    ev.preventDefault();
  }

  doDeleteAttachment(fileId) {
    this.hideAlert();

    this.Upload.delete(fileId, (event) => {
      this.setState({
        progress: (Math.round((1000 * event.loaded) / event.total) / 1000) * 100,
      });
    }).then((response) => {
      this.setState({
        fileData: response.data.data.data.files,
      });
      // return this.Upload.getFiles();
    });
  }

  deleteAttachment(fileId, ev) {
    ev.stopPropagation();
    ev.preventDefault();

    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "40%" }}
          title="Confirmation Required"
          onConfirm={() => this.doDeleteAttachment(fileId)}
          onCancel={() => this.hideAlert()}
          customClass=""
          showCancel={true}
          confirmBtnText="Yes, I am sure"
          confirmBtnCssClass="zev-btn zev-btn-danger idt chk-rt"
          confirmBtnBsStyle="primary"
          cancelBtnCssClass="zev-btn cancel idt zev-btn-default"
          cancelBtnText="Cancel"
          reverseButtons={true}
          btnSize=""
        >
          <div className="error-alert">Are you sure you wish to delete this file?</div>
        </ReactBSAlert>
      ),
    });
  }

  render() {
    const { selectedFiles, currentFile, progress, hoverDelete, message, fileData } = this.state;

    // const isHovered = (this.state.hoverDelete) ? 'hover' : '';

    return (
      <>
        {this.state.alert}
        <div className="file-container">
          <div className="button-wrapper">
            <button type="button" className="btn zev-btn idt upload zev-btn-primary">
              Add File Attachment
              <input
                type="file"
                onChange={(e) => {
                  this.selectFile(e, this.upload);
                }}
              />
            </button>
            {fileData.length === 0 ? (
              <div className="text-muted no-files-uploaded">No files attached here</div>
            ) : (
              <></>
            )}
          </div>

          {currentFile && (
            <div className="pb-wrapper">
              <div id="pb">
                <div
                  id="progress"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: progress + "%" }}
                >
                  <div id="done">{progress < 100 ? <>Uploading {progress}%</> : <>Complete</>}</div>
                </div>
                {progress < 100 ? <>Uploading {progress}%</> : <>Complete</>}
              </div>
            </div>
          )}

          {fileData.length > 0 ? (
            <div className="attached-files-container">
              <p className="attached-files-header text-dark">Attached Files</p>
              <p className="text-muted">Click a filename to download the attachment</p>
              <Row className="attached-files-list">
                {fileData.length > 0 &&
                  fileData.map((file, index) => {
                    const isHovered = hoverDelete[index] === true ? "hover" : "";

                    return (
                      <Col xs="12" md={this.size} className="attached-files-item" key={index}>
                        <a
                          className="attached-files-meta-wrapper"
                          title="Click to download file"
                          onClick={this.downloadAttachment.bind(
                            this,
                            file.id,
                            this.props.assessmentId
                          )}
                        >
                          <span className="fixedbg">
                            <span className="attached-files-icon">
                              <FontAwesomeIcon icon={["fad", "download"]} className="fa-fw" />
                            </span>
                            <span className="attached-files-name">{file.filename}</span>
                            <span className="attached-files-size">
                              <span>{file.filesize}</span>
                            </span>

                            <span
                              className={"attached-files-delete " + isHovered}
                              title="Delete Attachment"
                              onMouseOver={() => {
                                this.doHover(index);
                              }}
                              onMouseOut={() => {
                                this.doHoverOut(index);
                              }}
                              onClick={this.deleteAttachment.bind(this, file.id)}
                            >
                              <FontAwesomeIcon
                                size="2x"
                                icon={["fas", "trash-alt"]}
                                className="fa-fw"
                              />{" "}
                              Delete File
                            </span>
                          </span>
                        </a>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  doHover(i) {
    let hoverState = this.state.hoverDelete;
    hoverState[i] = true;
    this.setState({ hoverDelete: hoverState });
  }

  doHoverOut(i) {
    let hoverState = this.state.hoverDelete;
    hoverState[i] = false;

    this.setState({ hoverDelete: hoverState });
  }
}
