import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import React from "react";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import SuiTypography from "../../../../components/SuiTypography";
import { productImage } from "../../../../Reusable/QuoteUtilities";
import Grid from "@mui/material/Grid";
import { html, WarningHeader } from "../../../../Reusable/Misc";
import CheckboxElement from "../../../../Reusable/Forms/Elements/CheckboxElement";
import { TableFooter } from "@mui/material";
import rgba from "../../../../assets/theme/functions/rgba";
import { QuoteActionButtons } from "./QuoteActionButtons";
import Box from "@mui/material/Box";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`&.${tableRowClasses.head} th`]: {
    color: "#fff !important",
    fontSize: 14,
  },
  [`&.${tableRowClasses.footer} `]: {
    backgroundColor: `${rgba(theme.palette.primary.main, 0.3)}`,
  },
  [`&.${tableRowClasses.footer} td p`]: {
    color: "#555 !important",
  },
  [`&.${tableRowClasses.footer} td`]: {
    fontSize: 14,
  },
}));

export const CustomerQuote = (props) => {
  const { state, handleChange, total, acceptQuote, rejectQuote, cancelPageRequest } = props;

  return (
    <>
      <SuiTypography variant="h4" fontWeight="bold">
        Your Order Item(s)
      </SuiTypography>

      {state.viewData.quote.order.orderLineItemsList.map((lineItem) => {
        return (
          <Grid container>
            <Grid
              display={"flex"}
              item
              className={"p4 mt4 mb4"}
              xs={2}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              {productImage(lineItem)}
            </Grid>
            <Grid
              display={"flex"}
              xs={6}
              justifyContent={"center"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              <h5 style={{ fontWeight: "normal" }}>{lineItem.productId.vendor}</h5>
              <h4>{lineItem.productId.name}</h4>
            </Grid>
            <Grid
              display={"flex"}
              xs={4}
              justifyContent={"center"}
              alignItems={"flex-end"}
              flexDirection={"column"}
            >
              <h4>${lineItem.productId.total}</h4>
              <h5 style={{ fontWeight: "normal" }}>(Paid)</h5>
            </Grid>
          </Grid>
        );
      })}

      <WarningHeader rounded={true} color={"info"}>
        <p>
          In addition to the items you purchased above, the contractor has also specified the
          following items related to this installation. You may deselect any <em>optional</em> items
          that you do not wish to include as part of the installation.
        </p>
        <br />
        <p>
          You cannot disable <em>required</em> items.
        </p>
      </WarningHeader>

      <SuiTypography variant="h4" fontWeight="bold">

      </SuiTypography>
      <Table className="table-striped data-list-table">
        <TableHead color={"primary"} sx={{ display: "table-header-group !important" }}>
          <StyledTableRow>
            <TableCell align="center">Selected</TableCell>
            <TableCell></TableCell>
            <TableCell align={"left"}>Product/Service</TableCell>
            <TableCell align={"right"}>Optional</TableCell>
            <TableCell align={"right"}>Cost</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody className={"table-bb-thin"}>
          {state.viewData.quote.quote.quoteLineitemsList.map((lineItem) => {
            return (
              <>
                <TableRow>
                  <TableCell align={"center"}>
                    <CheckboxElement
                      onChange={handleChange}
                      disabled={lineItem.isOptional.toString() === "0"}
                      name={"selectedLineItems." + lineItem.id + ".selectedLineItem"}
                      field={state.data.selectedLineItems[lineItem.id].selectedLineItem}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell className={"pt3 pb3"} align={"left"}>
                    <p>{lineItem.productService}</p>
                    <p className={"muted"}>{lineItem.description}</p>
                    <hr className={"mt2 mb2"} />
                    <p className="small">
                      {lineItem.quantity}
                      <strong> @ </strong>${parseFloat(lineItem.unitPrice).toFixed(2)} each
                    </p>
                  </TableCell>
                  <TableCell align={"right"}>
                    {lineItem.isOptional ? html("<strong>Optional</strong>") : "Required"}
                  </TableCell>
                  <TableCell align={"right"}>
                    ${" "}
                    {parseFloat(
                      parseFloat(lineItem.quantity) * parseFloat(lineItem.unitPrice)
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <TableCell colSpan={2} />
            <TableCell colSpan={2} className={"pr0"} align={"right"}>
              <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                Total of Selected Components
              </SuiTypography>
            </TableCell>
            <TableCell align={"right"} className={"pr1"}>
              <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                $ {total}
              </SuiTypography>
            </TableCell>
          </StyledTableRow>
        </TableFooter>
      </Table>

      <Box sx={{ m: 0 }}>
        <QuoteActionButtons
          state={state}
          accept={acceptQuote}
          reject={rejectQuote}
          cancel={cancelPageRequest}
        />
      </Box>
    </>
  );
};
