import Box from "@mui/material/Box";
import CheckboxElement from "../../../../../Reusable/Forms/Elements/CheckboxElement";
import TextElement from "../../../../../Reusable/Forms/Elements/TextElement";
import Tooltip from "@mui/material/Tooltip";
import SuiButton from "../../../../../components/SuiButton";
import { FAStack } from "../../../../../Utility/FontAwesome";
import Table from "@mui/material/Table";
import React from "react";
import TableRow from "@mui/material/TableRow";

export const AddAdditionalLineItems = (props) => {
  const { handleChange, state, handleBlur, noop, addNewLineItem } = props;

  return (
    <>
      <Table className="table-striped mt10 " >
        <thead>
          <tr>
            <th colSpan={6} className={"bg-primary"}>
              Add Additional Line Item(s)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ verticalAlign: "top" }}>
            <td>
              <Box justifyContent={"center"} alignContent={"center"}>
                <CheckboxElement
                  onChange={handleChange}
                  aboveLabel={true}
                  name={"addQuoteLineItem.optionalItem"}
                  field={state.data.addQuoteLineItem.optionalItem}
                />
              </Box>
            </td>
            <td>
              <TextElement
                type="text"
                name="addQuoteLineItem.productServiceName"
                focus={false}
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                field={state.data.addQuoteLineItem.productServiceName}
                defaultValue={state.data.addQuoteLineItem.productServiceName.value}
              />
            </td>
            <td>
              <TextElement
                type="text"
                name="addQuoteLineItem.description"
                focus={false}
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                field={state.data.addQuoteLineItem.description}
                defaultValue={state.data.addQuoteLineItem.description.value}
              />
            </td>
            <td width={"150"}>
              <TextElement
                type="text"
                name="addQuoteLineItem.quantity"
                focus={false}
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                field={state.data.addQuoteLineItem.quantity}
                defaultValue={state.data.addQuoteLineItem.quantity.value}
              />
            </td>
            <td width={"150"}>
              <TextElement
                type="text"
                name="addQuoteLineItem.price"
                focus={false}
                size="large"
                onChange={handleChange}
                onBlur={handleBlur}
                field={state.data.addQuoteLineItem.price}
                defaultValue={state.data.addQuoteLineItem.price.value}
              />
            </td>
            <td align="right">
              <div className="target-fields-wrapper MuiBox-root">
                <label onClick={noop} htmlFor={"#"} className={"form-label default-cursor"}>
                  <Tooltip placement={"top"} title="Add new Product or Service">
                    <SuiButton
                      className={""}
                      style={{ marginTop: "23.98px", height: "47.3333px" }}
                      onClick={addNewLineItem}
                    >
                      <FAStack main="box-open-full" sub="plus-circle" offsetY={-5} offsetX={11} />
                    </SuiButton>
                  </Tooltip>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      &nbsp;
      <Table style={{fontSize: '.8em', color: '#666'}} className="">
        <TableRow>
          <td colSpan={5} />
          <td colSpan={7} align={"left"} style={{ paddingTop: '20px !important', backgroundColor: "#fff" }}>
            <em>
              †&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Deposit here is an estimate, as the customer may select any optional items or
              not.
            </em>
          </td>
        </TableRow>
        <TableRow>
          <td colSpan={5} />
          <td colSpan={7} align={"left"} style={{ backgroundColor: "#fff" }}>
            <em>
              ‡&nbsp;&nbsp;&nbsp;&nbsp;Total will be calculated at payment depending on how much deposit is collected
              up front.
            </em>
          </td>
        </TableRow>
      </Table>
    </>
  );
};
