import React from "react";
import ZevForm from "../ZevForm";
import TextElement from "../../../Reusable/Forms/Elements/TextElement";
import withRouter from "../../../withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LoginForm extends ZevForm {
  constructor(props) {
    super(props);

    // this.handleResponse = this.handleResponse.bind(this);
  }

  componentDidMount() {
    const o = this.init(this, {
      auth: true,
      submitIcon: (
        <FontAwesomeIcon icon={["fad", "right-to-bracket"]} fixedWidth={true} className={"mr2"} />
      ),
      flow: "auth",
      loaderStyle: "auth",
      service: {
        endpoint: "auth/login",
        redirectTarget: "/dashboard",
        entity: "Login",
      },
      labels: {
        submit: "Login",
        submitting: "Authenticating",
        submitted: "Authenticated!",
        failed: "Login Failure",
      },
      data: {
        email: {
          value: "",
          immutable: false,
          valid: false,
          touched: false,
          placeholder: "Email Address",
          label: "Email Address",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
        password: {
          value: "",
          immutable: false,
          valid: false,
          touched: false,
          label: "Password",
          placeholder: "Password",
          errors: {},
          vr: {
            required: { opt: true, msg: "This field is required" },
          },
        },
      },
    });

    this.setState(o, () => {
      this.mounted();
    });
  }

  cb() {}

  form() {
    return (
      <>
        {this.state.initialized === true ? (
          <>
            <TextElement
              type="email"
              name="email"
              focus={true}
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.email}
            />
            <TextElement
              type="password"
              name="password"
              size="large"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              field={this.state.data.password}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(LoginForm);
