import React from "react";
import ZevForm from "../ZevForm";
import withRouter from "../../../withRouter";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Operations from "../../../Reusable/Controller/Operations";
import {hasOwnDeepProperty, html, mapData, WarningHeader} from "../../../Reusable/Misc";
import { StateManager } from "../../../Reusable/Core";
import Toastr from "../../../Reusable/Toastr";
import SuiTypography from "../../../components/SuiTypography";
import Grid from "@mui/material/Grid";
import {productImage} from "../../../Reusable/QuoteUtilities";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow, {tableRowClasses} from "@mui/material/TableRow";
import CheckboxElement from "../../../Reusable/Forms/Elements/CheckboxElement";
import {TableFooter} from "@mui/material";
import {styled} from "@mui/material/styles";
import rgba from "../../../assets/theme/functions/rgba";
import SuiButton from "../../../components/SuiButton";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
  },
  [`&.${tableRowClasses.head} th`]: {
    color: "#fff !important",
    fontSize: 14,
  },
  [`&.${tableRowClasses.footer} `]: {
    backgroundColor: `${rgba(theme.palette.primary.main, 0.3)}`,
  },
  [`&.${tableRowClasses.footer} td p`]: {
    color: "#555 !important",
  },
  [`&.${tableRowClasses.footer} td`]: {
    fontSize: 14,
  },
}));


class FinalPaymentReviewForm extends ZevForm {
  constructor(props) {
    super(props);

    this.navigate = props.navigate;

    this.state = this.init(
      this,
      {
        viewOnly: this.props.viewOnlyMode,
        overrideHandlers: false,
        private: false,
        service: {
          recordId: props.recordId,
          entity: "job",
          endpoint: "checkout/get_quote_data",
          apiPrefix: "/api/pub/v1/",
        },
        labels: {},
        status: {
          isQuoteSending: false,
          isQuoteFailed: false,
          isQuoteSent: false,
        },
      },
      false
    );

    this.ops = new Operations();


    this.continueToPayment = this.continueToPayment.bind(this);
    this.localChangeHandler = this.localChangeHandler.bind(this);
    this.cancelPageRequest = this.cancelPageRequest.bind(this);
  }

  cancelPageRequest() {

    Toastr.info(
      "Request Cancelled",
      "You have not taken action on this quote yet. Click the link in your email to come back to this page at any time."
    );

    navigate("/auth/login");
  }

  continueToPayment() {


    this.navigate("/checkout/final/" + this.props.customer_id + "/" + this.props.contractor_id + "/" + this.props.request_id + "/" + this.props.job_id + "/" + this.props.token +  "/payment")
  }

  async componentDidMount() {
    /**
     * Get all data needed for page
     */

    let quote = "";

    if (this.props.job_id) {
      const params = {
        jobId: this.props.job_id,
        contractorId: this.props.contractor_id,
        customerId: this.props.customer_id,
        installRequestId: this.props.request_id,
        token: this.props.token,
      };

      quote = this.ops.getRecord(this, null, params, null, this.props.entity).then(
        function (data) {
          return { quote: data };
        }.bind(this)
      );



    } else {
      quote = new Promise(function (resolve, reject) {
        resolve();
      });
    }

    this.promise = await Promise.all([quote]).then((values) => {
      let viewData = {};

      for (let k in values) {
        if (values[k] !== undefined) {
          const obj = values[k];
          const keys = Object.keys(obj);
          for (let key in keys) {
            viewData[keys[key]] = obj[keys[key]];
          }
        }
      }

      const o = this.init(this, {
        overrideSubmitButtons: true,
        totalCostOfQuote: 0,
        recordId: this.props.quote_id,
        submitIcon: (
          <FontAwesomeIcon
            icon={["fad", "floppy-disk-circle-arrow-right"]}
            fixedWidth={true}
            className={"mr2"}
          />
        ),
        flow: "quoteReview",
        loaderStyle: "installRequest",
        service: {
          entity: "quoteReview",
          apiPrefix: "pub",
        },
        openModal: {},
        labels: {
          accepting: "Accepting Quote",
          accept: "Accept Quote",
          accepted: "Quote Accepted",
          acceptFailed: "Accept Quote Failed",
          rejecting: "Rejecting Quote",
          reject: "Reject Quote",
          rejected: "Quote Rejected",
          rejectFailed: "Reject Quote Failed",
        },
        viewData: viewData,
        _data: {
          quoteId: null,
          quoteHash: null,
          quoteToken: null,
          contractorId: null,
          installRequestId: null,
        },
        data: {
        },
      });

      let ns = mapData(o.viewData.quote, o);

      ns._data.quoteId = this.props.quote_id;
      ns._data.quoteHash = this.props.hash;
      ns._data.quoteToken = this.props.token;
      ns._data.installRequestId = this.props.request_id;
      ns._data.contractorId = this.props.contractor_id;


      this.setState(ns, () => {
        this.mounted();
      });
    });
  }

  cb() {}


  proceed(params) {
    const navigate = this.props.navigate;
    const url =
      "/checkout/final/" +
      this.props.contractor_id +
      "/" +
      this.props.request_id +
      "/" +
      this.props.quote_id +
      "/" +
      this.props.hash +
      "/" +
      this.props.token +
      "/payment";

    navigate(url, {
      state: {
        vd: {
          selectedLineItems: params,
        },
      },
    });
  }


  localChangeHandler(ev, e) {
    this.handleChange(ev, e);

    if (this.state.initialized === true) {
      let total = this.calculateTotal();
      StateManager.meld(this, this.state, { totalCostOfQuote: total });
    }
  }

  form() {
    let total = 0;


    return (
      <>
        <Box sx={{ mb: 3 }}>

          <SuiTypography variant="h4" fontWeight="bold">
            Your Order
          </SuiTypography>

          <SuiTypography variant="h4" fontWeight="bold">

          </SuiTypography>
          <Table className="table-striped data-list-table">
            <TableHead color={"primary"} sx={{ display: "table-header-group !important" }}>
              <StyledTableRow>

                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align={"left"}>Product/Service</TableCell>
                <TableCell align={"right"}>Status</TableCell>
                <TableCell align={"right"}>Cost</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody className={"table-bb-thin"}>
              {this.state.viewData.quote.quoteLineItems.map((lineItem) => {
                return (
                    <>
                      <TableRow>
                        <TableCell align={"center"}>

                        </TableCell>
                        <TableCell />
                        <TableCell className={"pt3 pb3"} align={"left"}>
                          <p>{lineItem.prodSvc}</p>
                          <p className={"muted"}>{lineItem.desc}</p>
                          <hr className={"mt2 mb2"} />
                          <p className="small">
                            {lineItem.quantity}
                            <strong> @ </strong>${parseFloat(lineItem.pp).toFixed(2)} each
                          </p>
                        </TableCell>
                        <TableCell align={"right"}>
                          PAID
                        </TableCell>
                        <TableCell align={"right"}>
                          ${" "}
                          {parseFloat(
                              parseFloat(lineItem.total)
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </>
                );
              })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={2} className={"pr0"} align={"right"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    Total of Selected Components
                  </SuiTypography>
                </TableCell>
                <TableCell align={"right"} className={"pr1"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    $ {parseFloat(this.state.viewData.quote.total).toFixed(2)}
                  </SuiTypography>
                </TableCell>
              </StyledTableRow>
              <TableRow>
                <TableCell colSpan={2}  />
                <TableCell colSpan={2} className={"pr0"} align={"right"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    Payment(s) Made
                  </SuiTypography>
                </TableCell>
                <TableCell align={"right"} className={"pr1"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    $ {parseFloat(this.state.viewData.quote.totalPaid).toFixed(2)}
                  </SuiTypography>
                </TableCell>
              </TableRow>
              <StyledTableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={2} className={"pr0"} align={"right"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    Total Due
                  </SuiTypography>
                </TableCell>
                <TableCell align={"right"} className={"pr1"}>
                  <SuiTypography fontSize={"16px"} color={"dark"} fontWeight={"medium"}>
                    $ {parseFloat(this.state.viewData.quote.remainingBalance).toFixed(2)}
                  </SuiTypography>
                </TableCell>
              </StyledTableRow>
            </TableFooter>
          </Table>

        </Box>

        <SuiButton color="primary" variant="gradient" onClick={this.continueToPayment} className="fa-pull-right">
          <span>
            <FontAwesomeIcon icon={["fad", "credit-card"]} />
            &nbsp;&nbsp;
          </span>
          <span>Continue to Payment</span>
        </SuiButton>

      </>
    );
  }

  render() {
    return <>{this.wrapper()}</>;
  }
}

export default withRouter(FinalPaymentReviewForm);
