import React from "react";
import SuiBox from "../../../../components/SuiBox";
import DealersList from "../../../Lists/dlr/Dealers/DealersList";
import { SiteLinkButton } from "../../../../Reusable/Misc";

class DealersListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
      document.title = "ZEV Society - Dealers"
  }

  render() {
    return (
      <>
        <SuiBox sx={{ mb: 3 }}>
          <SiteLinkButton
            perms={[]}
            label="Add New Dealer"
            path={"/dealers/add"}
            btnIcon="newuser"
            btnStyle="p"
          />
        </SuiBox>
        <DealersList />
      </>
    );
  }
}

export default DealersListView;
