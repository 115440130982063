import React from "react";
import { cbTimer, modify, Redirection, setDotProp, timer } from "./Misc";
import { StateManager } from "./Core";
import validate from "./Validation";
import isReactSyntheticEvent from "is-react-synthetic-event/src";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import ZevForm from "../pages/Forms/ZevForm";
import { getProperty } from "dot-prop";

export const blurHandler = (ev, scope, el) => {
  let name = "";

  if (typeof el !== "undefined") {
    name = el.name;
  } else if (typeof ev.target !== "undefined") {
    name = ev.target.name;
  }

  let updatedFormElement = getProperty(scope.state.data, name);

  if (typeof updatedFormElement.vr !== "undefined") {
    let validated = validate(updatedFormElement, updatedFormElement.vr);
    // updatedFormElement.valid = validated.valid;
    // updatedFormElement.errors = validated.errors;

    setDotProp(name + ".valid", scope.state.data, validated.valid);
    setDotProp(name + ".errors", scope.state.data, validated.errors);
    setDotProp(name + ".touched", scope.state.data, true);
  } else {
    // updatedFormElement.valid = true;
    setDotProp(name + ".valid", scope.state.data, true);
    setDotProp(name + ".touched", scope.state.data, true);
  }

  return {
    data: scope.state.data,
  };

  // const updatedFields = { ...scope.state.data };
  // const updatedFormElement = { ...updatedFields[name] };

  let value = updatedFormElement["value"];

  if (name.indexOf(".") <= 0) {
    if (typeof updatedFormElement.vr !== "undefined") {
      let validated = validate(updatedFormElement, updatedFormElement.vr);
      updatedFormElement.valid = validated.valid;
      updatedFormElement.errors = validated.errors;
    } else {
      updatedFormElement.valid = true;
    }

    updatedFields[name] = updatedFormElement;

    return {
      data: updatedFields,
    };
  } else {
    const property = name;

    setDotProp(name + ".valid", scope.state.data, value);
    setDotProp(name + ".errors", scope.state.data, value);
    setDotProp(name + ".touched", scope.state.data, value);
  }

  // scope.setState(
  //   {
  //     formData: updatedFields,
  //   },
  // );
};

export const changeHandler = (ev, stHndlr, scope: ZevForm) => {
  let el = ev.target;

  let value = "";
  let name = "";
  let sv = "";
  let checked = "";
  let sub = "";
    
  if (
    typeof el !== "undefined" &&
    typeof el.constructor.name !== "undefined" &&
    typeof el.name !== "undefined" &&
    el.name.indexOf(".") > -1
  ) {
    const split = el.name.split(".");

    setDotProp(el.name + ".value", scope.state.data, el.value);

    const propName = el.name + ".value";

    name = el.name;
    value = el.value;

    // value = ev.value;
    // name = el.name;

    name = split[1];
    sub = split[0];
  } else if (
    typeof ev.value !== "undefined" &&
    typeof ev.target !== "undefined" &&
    typeof ev.target.name !== "undefined"
  ) {
    value = ev.value;
    name = ev.target.name;
  } else if (
    typeof el !== "undefined" &&
    typeof el.constructor.name !== "undefined" &&
    el instanceof Date
  ) {
    name = ev.name;
    value = el;
  } else if (
    typeof ev.constructor.name !== "undefined" &&
    (isReactSyntheticEvent(ev) || ev.constructor.name === "qt" || ev instanceof Event)
  ) {
    value = ev.target.value;
    name = ev.target.name;
  } else if (
    typeof el !== "undefined" &&
    typeof el.action !== "undefined" &&
    el.action === "select-option"
  ) {
    value = ev.value;
    name = el.name;
    sv = ev;
  } else if (
    typeof el !== "undefined" &&
    typeof el.action !== "undefined" &&
    el.action === "rules-conditions-flush"
  ) {
    value = ev.value;
    name = el.name;
    sv = ev;
    // } else if (typeof ev.constructor.name !== 'undefined' && ev instanceof Switch) {
    //     name = ev.props.name;
    //     checked = (ev.state.value);
  } else if (typeof ev.value !== "undefined" && Array.isArray(ev.value)) {
    if (typeof el !== "undefined" && typeof el.name !== "undefined") {
      name = el.name;
    } else if (typeof ev.target !== "undefined") {
      name = ev.target.name;
    }

    checked = ev.value.map((v, i) => {
      return v.id.toString();
    });
  } else {
    if (typeof el !== "undefined" && typeof el.name !== "undefined") {
      name = el.name;
    } else if (typeof ev.target !== "undefined") {
      name = ev.target.name;
    }

    if (typeof ev.target !== "undefined") {
      value = ev.target.value;
    } else if (typeof ev.value !== "undefined" && typeof el !== "undefined") {
      value = ev.value;
    }
  }

  const updatedFields = { ...scope.state.data };
  let updatedFormElement;

  if (sub) {
    updatedFormElement = { ...updatedFields[sub][name] };
  } else {
    updatedFormElement = { ...updatedFields[name] };
  }

  if (sv) {
    updatedFormElement.selectvalue = sv;
  }

  if (checked !== "") {
    updatedFormElement.checked = checked;
  }

  if (sub) {
    updatedFormElement.value = modify(scope.state.data[sub][name], value);
  } else {
    updatedFormElement.value = modify(scope.state.data[name], value);
    updatedFormElement.defaultValue = modify(scope.state.data[name], value);
  }

  if (typeof updatedFormElement.vr !== "undefined") {
    let validated = validate(updatedFormElement, updatedFormElement.vr);

    if (validated.revalidate !== null) {
      let rv = validate(
        updatedFields[validated.revalidate],
        updatedFields[validated.revalidate].vr,
        true
      );
    }

    updatedFormElement.valid = validated.valid;
    updatedFormElement.errors = validated.errors;
  } else {
    updatedFormElement.valid = true;
    updatedFormElement.errors = [];
  }

  updatedFormElement.touched = true;

  if (updatedFormElement.type === "checkbox") {
    if (updatedFormElement.value === "1") {
      updatedFormElement.value = 1;
    } else if (updatedFormElement.value === "0") {
      updatedFormElement.value = 0;
    }
  }

  if (sub) {
    updatedFields[sub][name] = updatedFormElement;
  } else {
    updatedFields[name] = updatedFormElement;
  }

  return {
    data: updatedFields,
  };
};

export const APIWait = (props) => {
  return (
    <>
      <div className={"wait"}>
        <div id="cssload-pgloading">
          <div className="cssload-loadingwrap">
            <ul className="cssload-bokeh">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <h4>{props.message}</h4>
      </div>
    </>
  );
};

export const FieldErrors = (data, field) => {
  if (typeof data[field] !== "undefined") {
    if (typeof data[field].errors !== "undefined") {
      return data[field].errors;
    }
  }

  return {};
};

class Error extends React.Component {
  constructor(props) {
    super(props);

    this.t = {
      t1: null,
      t2: null,
    };

    this.props = props;
    this.parentState = props.state;

    this.state = {
      isDismissed: props.isDismissed,
      message: props.message || false,
      extraDismissClass: "",
    };

    this.initDismiss = this.initDismiss.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  initDismiss() {
    this.setState({ extraDismissClass: "fade-out" });
  }

  dismiss(ev, instant) {
    let delay = 0;
    const $this = this;

    if (instant) {
      delay = instant;
    } else {
      delay = 1;
    }

    clearTimeout(this.t.t1);
    clearTimeout(this.t.t2);

    this.t.t1 = timer(delay, $this.initDismiss);
    this.t.t2 = timer(delay + 400, this.props.um);
  }

  componentDidMount() {
    this.dismiss(null, 4000);
  }

  render() {
    return (
      <div className={"status-indicator svg-failure " + this.state.extraDismissClass}>
        <div
          className="status-error-wrapper"
          title="Click anywhere in this popup to dismiss close alert."
          onClick={this.dismiss}
        >
          <div className="warning-bar top-bar" />
          <Grid container spacing={3}>
            <Grid item md="5" sm="12">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle
                  className="path circle"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeMiterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <line
                  className="path cross"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  x1="34.4"
                  y1="37.9"
                  x2="95.8"
                  y2="92.3"
                />
                <line
                  className="path cross"
                  fill="none"
                  stroke="#D06079"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  x1="95.8"
                  y1="38"
                  x2="34.4"
                  y2="92.2"
                />
              </svg>
            </Grid>
            <Grid item md="7" sm="12">
              <p className="status-message">
                Operation <br />
                <span>Unsuccessful</span>
                <br />
                {this.state.message ? (
                  <>
                    <strong>{this.state.message}</strong>
                  </>
                ) : (
                  ""
                )}
              </p>
            </Grid>
          </Grid>
          <p className="dismiss-message">Click this box to dismiss</p>
          <div className="warning-bar bottom-bar" />
        </div>
      </div>
    );
  }
}

class Success extends React.Component {
  constructor(props) {
    super(props);

    this.t = {
      t1: null,
      t2: null,
      t3: null,
    };

    this.props = props;
    this.parentState = props.state;
    this.mainFormInstance = props.mf;

    this.state = {
      isDismissed: props.isDismissed,
      extraDismissClass: "",
    };

    this.initDismiss = this.initDismiss.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
  }

  initDismiss() {
    this.setState({ extraDismissClass: "fade-out" });
  }

  setRedirect() {
    const SM = new StateManager(this.mainFormInstance);
    SM.merge("formStatus.doRedirect", true);
    SM.commit();
  }

  dismiss(ev, instant) {
    let delay = 0;
    // const $this = this;

    if (instant) {
      delay = instant;
    } else {
      delay = 1;
    }

    clearTimeout(this.t.t1);
    clearTimeout(this.t.t2);
    clearTimeout(this.t.t3);

    this.t.t1 = timer(delay, this.initDismiss);
    this.t.t2 = timer(delay + 400, this.props.um);
    this.t.t3 = timer(delay + 350, this.setRedirect);
  }

  componentDidMount() {
    this.dismiss(null, 74000);
  }

  render() {
    return (
      <div className={"status-indicator svg-success " + this.state.extraDismissClass}>
        <div
          className="status-success-wrapper"
          title="Click anywhere in this popup to dismiss close alert."
          onClick={this.dismiss}
        >
          <div className="success-bar top-bar" />
          <Grid container spacing={3}>
            <Grid item md="5" sm="12">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle
                  className="path circle"
                  fill="none"
                  stroke="#73AF55"
                  strokeWidth="6"
                  strokeMiterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <polyline
                  className="path check"
                  fill="none"
                  stroke="#73AF55"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                />
              </svg>
            </Grid>
            <Grid item md="7" sm="12">
              <p className="status-message">
                Operation <br />
                <span>Successful</span>
              </p>
            </Grid>
          </Grid>
          <p className="dismiss-message">Click this box to dismiss</p>
          <div className="success-bar bottom-bar" />
        </div>
      </div>
    );
  }
}

class FormHandler extends React.Component {
  constructor(props) {
    super();

    this.mainFormInstance = props.scope;

    this.props = props;

    this.state = {
      timers: {},
      classes: {},
      pause: false,
      style: props.style || "default",
      message: props.errorMessage,
      isSuccess: props.isSuccess,
      isFailed: props.isFailed,
      isLoaded: props.isLoaded,
      isDismissed: props.isDismissed,
      isOverlay: props.isOverlay,
      isSubmitting: props.isSubmitting,
      isSubmitted: props.isSubmitted,
      isValidationFailed: false,
      isErrored: false,
      isBannerMessageHidden: false,
    };

    this.unmountError = this.unmountError.bind(this);
    this.unmountSuccess = this.unmountSuccess.bind(this);
  }

  unmountError() {
    const SM = new StateManager(this);
    SM.merge("isBannerMessageHidden", false);
    SM.merge("isOverlay", false);
    SM.merge("isFailed", false);
    SM.commit();

    const SMParent = new StateManager(this.props.scope);
    SMParent.merge("formStatus.isCurrentSubmitFailure", false);
    SMParent.commit();
  }

  unmountSuccess() {
    const SM = new StateManager(this);
    SM.merge("isBannerMessageHidden", false);
    SM.merge("isOverlay", false);
    SM.merge("isFailed", false);
    SM.commit();

    const SMParent = new StateManager(this.mainFormInstance);
    SMParent.merge("formStatus.isSubmitFailure", false);
    SMParent.merge("formStatus.isDismissed", true);
    SMParent.merge("formStatus.isCurrentSubmitFailure", false);

    SMParent.commit();
  }

  render() {
    let insertContent = "";
    let showhide = "hide";

    let loadingMessage = this.props.loadingMessage || "Loading, please wait";
    let message = this.props.message || "Loading, please wait";

    if (
      !this.state.isDismissed &&
      (this.props.isSubmitting === true ||
        this.props.isLoaded !== true ||
        this.props.doPause === true ||
        this.props.isSuccess === true ||
        this.props.isFailed === true)
    ) {
      showhide = "show";
    }

    const extra_classes = this.props.isOverlay ? "blk-overlay" : "";
    const loadingAnimation = (
      <div className={"wait"}>
        <div id="cssload-pgloading">
          <div className="cssload-loadingwrap">
            <ul className="cssload-bokeh">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <h4>{message}</h4>
      </div>
    );

    let successClass, loadingClass;
    if (this.props.isSubmitting || (this.props.doPause && !this.state.pause)) {
      loadingClass = "loading";
    } else {
      loadingClass = "";
    }

    if (this.props.isSuccess) {
      successClass = "loaded";
      loadingClass = "";
    } else {
      successClass = "";
    }

    const lockAnimation = (
      <div className={"wait"}>
        <div className="auth-lock-wrapper">
          <svg viewBox="0 0 100 100">
            <path
              y="50"
              className={"lock-top " + successClass + " " + loadingClass}
              d="M64,50V18.7C64,12,58.9,6.6,52.6,6.6h-3.5c-6.3,0-11.3,5.4-11.3,12.1v25.9"
            />
            <circle className="lock-outline" cx="50.9" cy="65.4" r="27" />
            <path
              className={"lock-body " + loadingClass}
              d="M50.9,41.4c-13.2,0-24,10.7-24,24c0,13.2,10.7,24,24,24c13.2,0,24-10.7,24-24C74.9,52.2,64.1,41.4,50.9,41.4z M56.2,61.9
            c-1.1,1.5-1.3,3-1.3,4.8c0.1,3,0.1,6.1,0,9.1c-0.1,2.8-1.6,4.4-4,4.5c-2.5,0.1-4.3-1.6-4.5-4.4c-0.1-1.9,0-3.9,0-5.8c0,0,0,0,0,0
            c0-1.4,0.1-2.8,0-4.2c-0.2-1.3-0.5-2.7-1.2-3.8c-1.5-2.7-1.1-6.3,1.1-8.3c2.4-2.2,6-2.3,8.6-0.2C57.3,55.5,58,59.2,56.2,61.9z"
            />
            <path
              className={"lock-spinner " + loadingClass}
              d="M73.3,65.7c0,12.2-9.9,22.1-22.1,22.1"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 50.9 65.4"
                to="360 50.9 65.4"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
          <h4 className={successClass}>{message}</h4>
        </div>
      </div>
    );

    if (this.props.style === "auth") {
      insertContent = lockAnimation;

      if (this.props.isLoaded && this.props.isSubmitting) {
        let $this = this;
      } else if (
        this.props.isLoaded &&
        !this.props.isSubmitting &&
        this.props.doPause &&
        this.state.pause === false
      ) {
        let $this = this;

        cbTimer(2000, () => {
          const newState = { pause: true };
          const ns = _.merge({}, this.state, newState);
          this.setState(ns);
        });
      } else if (this.props.isLoaded && !this.props.isSubmitting && this.props.doPause) {
        let $this = this;

        insertContent = <Redirection scope={this.mainFormInstance} />;
      }
    } else {
      if (this.props.isLoaded === true && this.props.isSubmitting) {
        if (this.props.style === "auth") {
          insertContent = lockAnimation;

          let $this = this;
        } else {
          insertContent = loadingAnimation;
        }
      } else if (this.props.isLoaded === false) {
        insertContent = <APIWait message={loadingMessage} />;
      } else {
        if (!this.props.isDismissed) {
          if (this.props.isSuccess === true) {
            insertContent = (
              <>{/*<Success um={this.unmountSuccess} mf={this.mainFormInstance}/>*/}</>
            );
          } else if (this.props.isFailed === true) {
            // insertContent = <Error message={this.state.message} um={this.unmountError} mf={this.mainFormInstance}/>;
          }
        } else {
          if (this.props.isSuccess === true) {
            insertContent = <Redirection scope={this.mainFormInstance} />;
          }
        }
      }
    }

    if (insertContent && this.props.doPause && !this.state.pause) {
      return (
        <>
          <div className={"anim-wrapper " + extra_classes + " " + showhide}>{insertContent}</div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default FormHandler;
