import React from "react";
import Grid from "@mui/material/Grid";
import { Cancel } from "./AcceptRejectActionButtons/Cancel";
import { GoBackToReviewQuote } from "./ActionButtons/GoBackToReviewQuote";
import { SubmitPayment } from "./ActionButtons/SubmitPayment";

export const AcceptActionButtons = (props) => {
  const { state, cancelPayment, submitPayment, goBackToReview } = props;

  return (
    <>
      <Grid
        container
        display={"flex"}
        className={"mt6"}
        flexDirection={"row"}
        style={{ width: "100%" }}
      >
        <Grid item alignSelf={"flex-start"} display={"flex"} flexGrow={1}>
          <GoBackToReviewQuote goBackToReview={goBackToReview} state={state} />
        </Grid>
        <Grid
          item
          alignSelf={"flex-end"}
          align={"flex-end"}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
         {<SubmitPayment
            processing={props.isProcessing}
            processed={props.isProcessed}
            failed={props.isFailed}
            submitPayment={submitPayment}
            state={state}
             />}
          &nbsp;&nbsp;
          <Cancel processing={props.isProcessing} cancelAction={cancelPayment} state={state} />
        </Grid>
      </Grid>
    </>
  );
};
