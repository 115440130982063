// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "assets/img/logos/trans.png";

// Soft UI Dashboard PRO React page layout routes
// Images
import pattern from "assets/images/shapes/pattern-lines.svg";
import BuildInfo from "../../../Reusable/BuildInfo";
import { useSearchParams } from "react-router-dom";
import { Alert } from "@mui/material";

function AuthLayout({ color, header, title, description, illustration, children }) {
  const [searchParams, setSearchParams] = useSearchParams();

  let queryParam = searchParams.get("result");

  return (
    <PageLayout background="white">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6} sx={{ mx: "auto" }}>
          <SuiBox display="flex" flexDirection="column" justifyContent="top" height="100vh">
            <Grid className={"hidden-lg"} container alignItems="center" justifyContent="center">
              <Grid item xs={12} bgcolor={"#029aff"} style={{ justifySelf: 'center', alignItems:"center" }}>
                <center>
                  <img src={logo} alt="logo" className={'pt3'} />
                </center>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
              <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                <SuiBox
                  pt={3}
                  px={3}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  {!header ? (
                    <>
                      <SuiBox mb={1}>
                        <SuiTypography variant="h4" fontWeight="bold">
                          {title}
                        </SuiTypography>
                      </SuiBox>
                      <SuiTypography variant="body2" fontWeight="regular" color="text">
                        {description}
                      </SuiTypography>
                    </>
                  ) : (
                    header
                  )}
                </SuiBox>
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
            </Grid>

            {queryParam === "please_verify_email" ? (
                    <Grid container>
                      <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <SuiBox pl={3} mt={2} pr={3}>
                          <Alert color={"info"}>
                            Your registration was successful; please verify your
                            email address to sign in by clicking the link in the
                            email you were just sent.
                          </Alert>
                        </SuiBox>
                      </Grid>
                      <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
                    </Grid>
            ) :
            queryParam === "successful_registration" ? (
              <Grid container>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                  <SuiBox pl={3} mt={2} pr={3}>
                    <Alert>
                      Email confirmed! You may now sign in.
                    </Alert>
                  </SuiBox>
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
              </Grid>
            ) : queryParam === "invalid_registration_code" ? (
              <Grid container>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                  <SuiBox pl={3} mt={2} pr={3}>
                    <Alert color={"error"}>
                      Invalid registration code - you may already be verified.
                    </Alert>
                  </SuiBox>
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
              </Grid>
            ) : (
              <></>
            )}

            <Grid container>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
              <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                <SuiBox p={1}>{children}</SuiBox>
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2} />
            </Grid>
            <SuiBox
              color="error"
              sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}
            >
              <BuildInfo />
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SuiBox
            display={{ xs: "none", xl: "flex" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            position="relative"
            borderRadius="lg"
            textAlign="center"
            bgColor={color}
            variant="gradient"
            m={2}
            px={13}
            sx={{ overflow: "hidden" }}
          >
            <SuiBox
              component="img"
              src={pattern}
              alt="pattern-lines"
              width="120rem"
              position="absolute"
              topl={0}
              left={0}
              opacity={0.4}
            />
            {illustration.image && (
              <SuiBox
                component="img"
                src={illustration.image}
                alt="chat-illustration"
                width="100%"
                maxWidth="31.25rem"
              />
            )}
            {illustration.title && (
              <SuiBox mt={6} mb={1}>
                <SuiTypography variant="h4" color="white" fontWeight="bold">
                  {illustration.title}
                </SuiTypography>
              </SuiBox>
            )}
            {illustration.description && (
              <SuiBox mb={1}>
                <SuiTypography variant="body2" color="white">
                  {illustration.description}
                </SuiTypography>
              </SuiBox>
            )}
          </SuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
AuthLayout.defaultProps = {
  color: "primary",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
AuthLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default AuthLayout;
