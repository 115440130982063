import React from "react";
import ZevList from "../../../Forms/ZevList";
import { SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";

class InstallationQuestionsList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "installationQuestions",
        endpoint: "/installation_questions",
      },
      sortFields: [
        { f: "id", l: "ID" },

        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record) {
    const contractor = <></>;

    const lastName = record.lastName || "";
    const firstName = record.firstName || "";

    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    const actions = (installationQuestion) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View installationQuestion"
            path={"/installationQuestions/view/" + installationQuestion.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit installationQuestion"
            path={"/installationQuestions/edit/" + installationQuestion.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete installationQuestion"
            path={"/installationQuestions/delete/" + installationQuestion.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    return {
      id: record.id,

      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(InstallationQuestionsList);
