import { PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import {forwardRef, useImperativeHandle, useState} from "react";


const StripeCheckoutWidget = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({

        async processPayment() {
            return await submitPayment();
        }

    }));

    const { redirectUrl } = props;

    const stripe = useStripe();

    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    const submitPayment = async (e) => {
        // e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        return await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${redirectUrl}`,
            },
        });
    }


    const peo = {
        layout: "tabs",
        billingDetails: {
            fields: {
                email: "never"
            }
        }
    }

    return (
        <>
            {/*<form id="payment-form" onSubmit={handleSubmit}>*/}
            <div style={{marginTop: '40px'}}>
                <div style={{display: 'block'}}>
                    {/*<LinkAuthenticationElement id="link-authentication-element"*/}

                    {/*    // Access the email value like so:*/}
                    {/*    // onChange={(event) => {*/}
                    {/*    //  setEmail(event.value.email);*/}
                    {/*    // }}*/}
                    {/*    //*/}
                    {/*    // Prefill the email field like so:*/}
                    {/*    // options={{defaultValues: {email: 'foo@bar.com'}}}*/}
                    {/*/>*/}

                    <PaymentElement id="payment-element" options={peo} />

                    <div >
                        <button className={"Button"} disabled={isLoading || !stripe || !elements} id="submit" style={{
                            border: 'none',
                            display: 'none',
                            cursor: "pointer",
                            lineHeight: '16.8px',
                            padding: '12px 24px',
                            textTransform: "uppercase",
                            borderRadius: '8px',
                            marginTop: '50px',
                            fontFamily: 'Roboto',
                            fontWeight: '600',
                            backgroundImage: "linear-gradient(180deg, rgba(100, 100, 100, 0.07), rgba(0, 0, 0, 0.4))",
                            backgroundColor: "#558bc3",
                            color: "#fff"
                        }}>
                            <span id="button-text">
                                <span><svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="credit-card" className="svg-inline--fa fa-credit-card " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><g className="fa-duotone-group"><path className="fa-secondary" fill="currentColor"
                                                                                                                                                                                                                                                                               d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z"></path><path className="fa-primary" fill="currentColor" d="M576 224H0V128H576V224z"></path></g></svg>
                                    &nbsp;&nbsp;</span>
                                {isLoading ? <div className="spinner" id="spinner"></div> : "Process Payment"}
                            </span>
                        </button>
                    </div>
                </div>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
                {/*</form>*/}
            </div>
        </>
    )
});

export default StripeCheckoutWidget;
