import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "App";


// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

const API_PREFIX = "/api/pub/v1";

const hist = createBrowserHistory();


ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <App />
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
