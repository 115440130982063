import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import moment from "moment";
import * as React from "react";
import SuiTypography from "../../../../../components/SuiTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Grid from "@mui/material/Grid";
import {html} from "../../../../../Reusable/Misc";

const ZevNoteDetail = (props) => {

    const { item } = props;

    return (
        <>

        </>
    );

}



const ZevNotesItem = (props) => {

    const { item, i } = props;

    return (
        <>
            <Grid container style={{borderTop: "2px solid #ccc"}}>
                <Grid item xs={2}>
                    <Grid container bgcolor={"#333"} color={"#fff"} sx={{p: 1}} justifyContent={"flex-end"}>
                        <Grid item xs={2}>
                            {(i+1)}.
                        </Grid>
                        <Grid item xs={10} style={{textAlign: "right"}} sx={{pr: 2}}>
                            <strong>{moment.utc(item.created).local().format("MMMM DD, yyyy")}</strong><br/>
                            {moment.utc(item.created).local().format("h:mm a")}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Grid container height={"100%"} bgcolor={"#fafafa"} color={"#333"} sx={{p: 1}}>
                        <Grid item>
                            {html(item.body)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );



}



const ZevNotes = (props) => {

    const { notes } = props;

    return (
        <Timeline position={"alternate"}>
            {
                notes.map(
                    (item, i) => {
                        return (
                            <ZevNotesItem item={item} i={i} />
                        );
                    }
                )
            }
        </Timeline>
    )

}

export default ZevNotes;
