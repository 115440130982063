function bakeCookie(name, value, pth) {
  const path = pth || "/";

  var cookie = [
    name,
    "=",
    JSON.stringify(value),
    "; domain_.",
    window.location.host.toString(),
    "; path=" + path + ";",
  ].join("");
  document.cookie = cookie;
}

function deleteCookie(name) {
  document.cookie = [
    name,
    "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain.",
    window.location.host.toString(),
  ].join("");
}

function readCookie(name) {
  var result = document.cookie.match(new RegExp(name + "=([^;]+)"));

  if (result != null) {
    try {
      result = result != null ? JSON.parse(result[1]) : [];
    } catch (e) {
      result = result != null ? result[1] : [];
    }
  }
  return result;
}

export { readCookie, bakeCookie, deleteCookie };
