import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import moment from "moment";
import * as React from "react";
import SuiTypography from "../../../../../components/SuiTypography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";

const ZevTimelineDetail = (props) => {

    const { item } = props;

    let iconDefinition = null;
    let colorDefinition = null;
    let colorBgDefinition = null;
    let title = null;
    let details = item.details;

    switch (item.event) {
        case "JOB_CREATED":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "helmet-safety"})} />
            colorBgDefinition = '#558bc3';
            colorDefinition   = '#fff';
            title = "Job Created";
            break;
        case "JOB_STARTED":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "clock-three"})} />
            colorBgDefinition = '#1960ab';
            colorDefinition   = '#fff';
            title = "Job Started";
            break;
        case "MailSentToCustomer":
        case "CUSTOMER_NOTIFICATION_SENT":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "envelope-circle-check"})} />
            colorBgDefinition = '#239ea2';
            colorDefinition   = '#fff';
            title = "Customer Notification Sent";
            break;
        case "CUSTOMER_NOTIFICATION_SEND_ERROR":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "circle-exclamation"})} />
            colorBgDefinition = '#b11414';
            colorDefinition   = '#fff';
            title = "Error Sending Customer Notification";
            break;
        case "CONTRACTOR_NOTIFICATION_SENT":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "envelope-circle-check"})} />
            colorBgDefinition = '#239ea2';
            colorDefinition   = '#fff';
            title = "Contractor Notification Sent";
            break;
        case "CONTRACTOR_NOTIFICATION_SEND_ERROR":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "circle-exclamation"})} />
            colorBgDefinition = '#b11414';
            colorDefinition   = '#fff';
            title = "Error Sending Customer Notification";
            break;
        case "JOB_CLOSED_COMPLETE_INVOICE_SENT":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "file-invoice-dollar"})} />
            colorBgDefinition = '#3ca31c';
            colorDefinition   = '#fff';
            title = "Job Completed / Closed - Invoice Sent";
            break;
        case "JOB_CLOSED_INCOMPLETE":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "question-circle"})} />
            colorBgDefinition = '#b11414';
            colorDefinition   = '#fff';
            title = "Job Closed - Incomplete";
            break;
        case "JOB_CLOSED_CANCELLED":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "times-circle"})} />
            colorBgDefinition = '#eea71b';
            colorDefinition   = '#fff';
            title = "Job Closed - Cancelled";
            break;
        case "JOB_RESCHEDULED":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "calendar-circle-exclamation"})} />
            colorBgDefinition = '#e6c710';
            colorDefinition   = '#333';
            title = "Job Rescheduled";
            break;
        case "JOB_SCHEDULED":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "calendar-circle-plus"})} />
            colorBgDefinition = '#f0e70f';
            colorDefinition   = '#333';
            title = "Job Scheduled";
            break;
        case "JOB_BALANCE_PAID":
            iconDefinition = <FontAwesomeIcon icon={icon({style: "solid", name: "check-circle"})} />
            colorBgDefinition = '#28c91f';
            colorDefinition = "#333";
            title = "Job Balance Paid";
            break;
    }


    return (
        <>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot  style={{ backgroundColor: colorBgDefinition, padding: '8px', color: colorDefinition }}>
                    {iconDefinition}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
                sx={{py: '12px', px: 2}}
            >
                <SuiTypography variant={'h6'} component={"span"}>
                    {title}
                </SuiTypography>
                <SuiTypography>
                    {details}
                </SuiTypography>
            </TimelineContent>
        </>
    );

}



const ZevTimelineItem = (props) => {

    const { item } = props;

    return (
        <TimelineItem>
            <TimelineOppositeContent
                sx={{m: 'auto 0'}}
                align="right"
                variant={"body2"}
                color="text.secondary"
            >
                {moment.utc(item.created).local().format('MMMM DD, YYYY')}<br/>
                {moment.utc(item.created).local().format("h:mm a")}
            </TimelineOppositeContent>
            <ZevTimelineDetail item={item} />
        </TimelineItem>
    );



}



const ZevTimeline = (props) => {

    const { timeline } = props;

    return (
        <Timeline position={"alternate"}>
            {
                timeline.map(
                    (item) => {
                        return (
                            <ZevTimelineItem item={item} />
                        );
                    }
                )
            }
        </Timeline>
    )

}

export default ZevTimeline;
