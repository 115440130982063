import ZevForm from "../ZevForm";
import Handler from "../Handler";
import R from "../../../Reusable/Responses/R";

class ExceptionHandler extends Handler {
  constructor(response: R, form: ZevForm, parentPageScope, callback) {
    super(response, form, parentPageScope, callback);
  }

  async handle(state) {
    return await new Promise((res, rej) => {
      this.markException(this.response.hasValidationErrors());
      this.markFailed();

      for (let dt in this.data) {
        this.newState.data[dt].touched = false;
      }
      this.scope.setState(this.newState);

      setTimeout(() => {
        this.reset();
        res();
      }, 1);
    });
  }
}

export default ExceptionHandler;
