import React from "react";

const APIWait = (props) => {
  return (
    <>
      <div className={"wait"}>
        <div id="cssload-pgloading">
          <div className="cssload-loadingwrap">
            <ul className="cssload-bokeh">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <h4>{props.message}</h4>
      </div>
    </>
  );
};

const APIWait2 = (props) => {
  return (
    <>
      <div className="asa1-loading">
        <div className="asa1-loading-1"></div>
        <div className="asa1-loading-2"></div>
        <div className="asa1-loading-3"></div>
        <div className="asa1-loading-4"></div>
        <div className="asa1-loading-5"></div>
        <div className="asa1-loading-6"></div>
        <div className="asa1-loading-7"></div>
        <div className="asa1-loading-8"></div>
        <div className="asa1-loading-9"></div>
        <div className="asa1-loading-10"></div>
      </div>
      <h4>{props.message}</h4>
    </>
  );
};

const APIWait3 = (props) => {
  return (
    <>
      <div className="asa2-loading"></div>
      <h4>{props.message}</h4>
    </>
  );
};

const APIWait4 = (props) => {
  return (
    <>
      <div className="asa3-loading">
        <svg viewBox="-2000 -1000 4000 2000">
          <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
          <use href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use>
          {/*xlink:*/}
        </svg>
        <h4>{props.message}</h4>
      </div>
    </>
  );
};

const APIWait5 = (props) => {
  return (
    <>
      <div className="asa-load-container">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
            />
          </filter>
        </defs>
      </svg>

      <p style={{ width: "100%", textAlign: "center", bottom: "40px", position: "absolute" }}>
        {props.message}
      </p>
    </>
  );
};

class Loader extends React.Component {
  constructor(props) {
    super();
    this.props = props;

    this.state = {
      isLoaded: props.isLoaded,
    };
  }

  render() {
    let insertContent = "";
    let showhide = "show";
    let style = "three";
    if (typeof this.props.style !== "undefined") {
      style = this.props.style;
    }

    let loadingMessage = this.props.loadingMessage || "Loading, please wait";
    let message = this.props.message || "Loading, please wait";

    // if (this.props.isSubmitting === true || this.props.isLoaded !== true || (this.props.isSuccess === true || this.props.isFailed === true )) {
    //     showhide = 'show';
    // }

    const extra_classes = this.state.isOverlay ? "blk-overlay" : "";

    if (this.props.isLoaded === false) {
      if (style === "one") {
        insertContent = <APIWait message={loadingMessage} />;
      } else if (style === "two") {
        insertContent = <APIWait2 message={loadingMessage} />;
      } else if (style === "three") {
        insertContent = <APIWait3 message={loadingMessage} />;
      } else if (style === "four") {
        insertContent = <APIWait4 message={loadingMessage} />;
      } else if (style === "five") {
        insertContent = <APIWait5 message={loadingMessage} />;
      } else {
        insertContent = <APIWait message={loadingMessage} />;
      }
    }

    if (insertContent) {
      return (
        <>
          <div className={"anim-wrapper-listing " + extra_classes + " " + showhide}>
            {insertContent}
          </div>
        </>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default Loader;
