import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MakesListView from "../../../pages/Views/cst/Makes/MakesListView";
import MakesEditView from "../../../pages/Views/cst/Makes/MakesEditView";
import MakesAddView from "../../../pages/Views/cst/Makes/MakesAddView";
import MakesDeleteView from "../../../pages/Views/cst/Makes/MakesDeleteView";

const MakesRoute = {
  type: "collapse",
  name: "Makes",
  sidenav: true,
  order: 2,
  key: "admin-makes",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Make Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "makes-list",
      route: "/makes/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <MakesListView />,
    },
    {
      hide: true,
      name: "Edit Make",
      sidenav: true,
      key: "makes-edit",
      route: "/makes/edit/:makeId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <MakesEditView />,
    },
    {
      hide: true,
      name: "Delete Make",
      sidenav: true,
      key: "makes-delete",
      route: "/makes/delete/:makeId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <MakesDeleteView />,
    },
    {
      name: "Add New Make",
      sidenav: true,
      key: "makes-add",
      route: "/makes/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <MakesAddView />,
    },
  ],
};

export default MakesRoute;
