import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DealersListView from "../../../pages/Views/dlr/Dealers/DealersListView";
import DealersEditView from "../../../pages/Views/dlr/Dealers/DealersEditView";
import DealersAddView from "../../../pages/Views/dlr/Dealers/DealersAddView";
import DealersDeleteView from "../../../pages/Views/dlr/Dealers/DealersDeleteView";

const DealersRoute = {
  type: "collapse",
  name: "Dealers",
  sidenav: true,
  order: 2,
  key: "admin-dealers",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Dealer Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "dealers-list",
      route: "/dealers/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <DealersListView />,
    },
    {
      hide: true,
      name: "Edit Dealer",
      sidenav: true,
      key: "dealers-edit",
      route: "/dealers/edit/:dealerId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <DealersEditView />,
    },
    {
      hide: true,
      name: "View Dealer",
      sidenav: true,
      key: "dealers-view",
      route: "/dealers/view/:dealerId",
      icon: <FontAwesomeIcon icon={["fad", "search"]} />,
      component: <DealersEditView viewOnlyMode={true} />,
    },
    {
      hide: true,
      name: "Delete Dealer",
      sidenav: true,
      key: "dealers-delete",
      route: "/dealers/delete/:dealerId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <DealersDeleteView />,
    },
    {
      name: "Add New Dealer",
      sidenav: true,
      key: "dealers-add",
      route: "/dealers/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <DealersAddView />,
    },
  ],
};

export default DealersRoute;
