import ZevForm from "../ZevForm";
import Handler from "../Handler";
import Auth from "../../../Reusable/Responses/Auth";

class SelfServiceAccountHandler extends Handler {
  constructor(response: Auth, form: ZevForm, parentPageScope, callback) {
    super(response, form, parentPageScope, callback);
    this.response = response;
  }

  handleAnimation() {}

  async handle(state) {
    return await new Promise((res, rej) => {
      console.log(this.response);
      console.log(state);

      if (this.response.getIsSuccess()) {
        // this.doPause();
        this.markSuccess();

        setTimeout(() => {
          this.reset();
          res();
        }, 1);
      } else {
        switch (this.response.getStatus()) {
          case "ERR_VALIDATION":
            this.markValidationError();

            console.log(this.response.getValidationErrors());
            console.log(this.response.getErrors());

            this.form.displayErrors(this.response.getErrors(), this.response.getValidationErrors());

            this.doPause();
            setTimeout(rej, 500);
            break;
        }
      }
    })
      .then(() => {
        return this.response;
      })
      .catch((err) => {
        this.reset();
      });
  }
}

export default SelfServiceAccountHandler;
