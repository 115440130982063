class R {
  data = {};

  constructor(response) {
    this.data = response.data;
  }

  getSuppressNotice() {
    if (typeof this.data.suppressNotice !== "undefined") {
      return this.data.suppressNotice;
    }
    return false;
  }

  hasSettings() {
    return typeof this.data.settings !== "undefined";
  }

  getSettings() {
    return this.data.settings;
  }

  getInstance() {
    return this.data.instance;
  }

  getIsSuccess() {
    return this.data.success === true;
  }

  hasToast() {
    return this.data.toastr !== null && this.data.toastr !== {};
  }

  getToastStatus() {
    return this.data.toastr.status;
  }

  getToastSubject() {
    return this.data.toastr.subject;
  }

  getToastMessage() {
    return this.data.toastr.message;
  }

  getData(item) {
    if (item) {
      return this.data.data[item];
    }

    return this.data.data;
  }

  payload() {
    return this.data.jsondata;
  }

  isAdmin() {
    return this.data.userRole === 'admin';
  }

  isContractor() {
    return this.data.userRole === 'contractor';
  }

  getMessage() {
    return this.data.message;
  }

  getSubject() {
    return this.data.subject;
  }

  getHasRedirect() {
    return this.data.redirect !== null && this.data.redirect !== undefined && typeof this.data.redirect !== 'undefined';
  }

  getRedirect() {
    if (typeof this.data.redirect !== "undefined") {
      return this.data.redirect;
    }
    return false;
  }

  getStripeSetupComplete() {
    if (typeof this.data.stripeSetupComplete !== 'undefined') {
      return this.data.stripeSetupComplete;
    }
  }

  getStripeSetupUrl() {
    if (typeof this.data.stripeSetupUrl !== 'undefined') {
      return this.data.stripeSetupUrl;
    }
  }

  getExternalRedirectMessage() {
    if (typeof this.data.externalRedirectMessage !== 'undefined') {
      return this.data.externalRedirectMessage;
    }

    return false;
  }

  getHasExternalRedirect() {
    return this.data.externalRedirect !== null && this.data.externalRedirect !== undefined && typeof this.data.externalRedirect !== 'undefined';
  }

  getStripeSetupUrl() {
    return this.data.stripeSetupUrl;
  }

  getStripeSetupComplete() {
    return this.data.stripeSetupComplete;
  }

  getExternalRedirect() {
    if (typeof this.data.externalRedirect !== 'undefined') {
      return this.data.externalRedirect;
    }

    return false;
  }
}

export default R;
