import ZevForm from "../ZevForm";
import Handler from "../Handler";
import Auth from "../../../Reusable/Responses/Auth";

class AuthHandler extends Handler {
  constructor(response: Auth, form: ZevForm, parentPageScope, callback) {
    super(response, form, parentPageScope, callback);
    this.response = response;
  }

  handleAnimation() {}

  async handle(state) {
    return await new Promise((res, rej) => {

      this.setRedirect(this.response.getRedirect());


      if (this.response.getIsSuccess()) {
        // this.doPause();
        this.markSuccess();
        setTimeout(res, 1);
      } else {
        switch (this.response.getStatus()) {
          case "INVALID_CREDENTIALS":
          case "NOT_PERMITTED":
            this.doPause();
            this.markFailed();
            this.form.displayErrors(null, this.response.getErrors());

            setTimeout(rej, 500);

            break;
          case "ERR_VALIDATION":
            this.markValidationError();

            this.form.displayErrors(this.response.getErrors(), this.response.getValidationErrors());

            this.doPause();
            setTimeout(rej, 500);
            break;
        }
      }
    })
      .then(() => {
        return this.response;
      })
      .catch((err) => {
        this.reset();
      });
  }
}

export default AuthHandler;
