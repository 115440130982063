import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Toastr from "../Reusable/Toastr";
import { asValidateSession, isLoggedIn } from "../Reusable/Auth/Check";
import Heartbeat from "../Reusable/Heartbeat";
import brand from "../assets/img/logos/o.png";
import Sidenav from "../ZevModules/Sidenav";
import { setMiniSidenav } from "../context";
import Dashboard from "../ZevModules/Dashboard";
import RateLimit from "../Reusable/Responses/RateLimit";

var validateTimerTimeout = 30;
var validateTimerLastRun = null;

const timeToValidateAgain = () => {
  if (validateTimerLastRun === null) {
    validateTimerLastRun = new Date();
    return true;
  } else {
    if (validateTimerLastRun + validateTimerTimeout < new Date()) {
      validateTimerLastRun = new Date();
      return true;
    }
  }

  return false;
};

const PrivateRoute = (props: { children: React.ReactNode }) => {
  const { title, subtitle, children, controller, routes } = props;
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const navigate = useNavigate();

  const loggedIn = isLoggedIn();
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  if (loggedIn) {
    if (timeToValidateAgain()) {
      asValidateSession().then((r) => {
        if (r instanceof RateLimit) {
          console.log("ALERT");
        }
        if (r === false) {
          Toastr.warning("Authentication", "You are not currently logged in");
          navigate("/auth/login");
        }
      });
    }
  } else {
    Toastr.warning("Authentication", "You are not currently logged in");
    navigate("/auth/login");
  }

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const location = useLocation();

  return loggedIn ? (
    <>
      <Heartbeat />
      {props.sidenav === true ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="ZEV Society"
            routes={routes}
            // adminRoutes={adminRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      ) : (
        <></>
      )}
      <Dashboard title={title} subtitle={subtitle}>
        {children}
      </Dashboard>
    </>
  ) : (
    <Navigate
      replace={true}
      to="/auth/login"
      state={{ from: `${location.pathname}${location.search}` }}
    />
  );
};

export default PrivateRoute;
