import React from "react";
import ZevList from "../../../Forms/ZevList";
import { SiteLinkButton } from "../../../../Reusable/Misc";
import withRouter from "../../../../withRouter";
import moment from "moment";

class DealersList extends ZevList {
  constructor(props) {
    super(props);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(id) {
    this.props.navigate(this.state.service.endpoint + "/view/" + id, { replace: false });
  }

  componentDidMount() {
    this.init(this, {
      service: {
        entity: "dealers",
        endpoint: "/dealers",
      },
      sortFields: [
        { f: "id", l: "ID" },
        { f: "name", l: "Name" },
        { f: "city", l: "City" },
        { f: "state", l: "State" },

        { f: "modified", l: "Last Updated/By", cls: "text-right" },
        { f: false, l: "Actions", cls: "text-right" },
      ],
    });
  }

  renderRow(record) {
    const modified = (
      <>
        <div className="date-create-modified">
          <span className="text-color-dark">{moment.utc(record.modified).local().format("M/D/Y h:mm A")}</span>
          <br />
          <span className="small">{record.updatedBy}</span>
        </div>
      </>
    );

    const actions = (dealer) => {
      return (
        <>
          <SiteLinkButton
            perms={[]}
            short={true}
            className="mr2 list-button-action"
            label="View dealer"
            path={"/dealers/view/" + dealer.id}
            btnIcon="view"
            btnStyle="d"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className={"mr2 list-button-action"}
            label="Edit dealer"
            path={"/dealers/edit/" + dealer.id}
            btnIcon="edit"
            btnStyle="p"
          />
          <SiteLinkButton
            perms={[]}
            short={true}
            className="list-button-action"
            label="Delete dealer"
            path={"/dealers/delete/" + dealer.id}
            btnIcon="delete"
            btnStyle="del"
          />
        </>
      );
    };

    return {
      id: record.id,
      name: record.name,
      city: record.city,
      state: record.state,

      modified: modified,
      actions: actions(record),
    };
  }
}

export default withRouter(DealersList);
