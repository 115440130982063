import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {getBearerToken, getPermissions, isLoggedIn} from "../Auth/Check";
import { bakeCookie, deleteCookie } from "../../Utility/Cookie";
import Auth from "../Responses/Auth";
import Account from "../Responses/Account";
import RateLimit from "../Responses/RateLimit";
import Toastr from "../Toastr";
import Session from "../Responses/Session";
import Response from "../Responses/Response";
import Exception from "../Responses/Exception";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const AxiosInterceptor = ({ children, buildData }) => {
  const navigate = useNavigate();

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    setIsSet(true);

    const resInterceptor = (response) => {
      const cookie_auth = "authTokenCK";
      if (typeof response.headers !== "undefined") {
        if (
          typeof response.headers["x-build-date"] !== "undefined" &&
          typeof response.headers["x-build-no"] !== "undefined"
        ) {
          buildData(response.headers["x-build-date"], response.headers["x-build-no"]);
        }

        if (typeof response.headers["content-type"] !== "undefined") {
          if (response.headers["content-type"].indexOf("text/html") > -1) {
            setTimeout(() => {}, 500);
          }
        }

        if (typeof response.headers["set-authorization"] !== "undefined") {
          localStorage.removeItem("zev_cache");
          bakeCookie(cookie_auth, response.headers["set-authorization"]);
        }
      } else {
      }

      let responseObject;

      if (response.data.newAccount === true) {
        navigate("/complete_setup");
      } else {
        if (window.location === "/complete_setup") {
        }
      }

      if (response.data.instance === "AUTH") {
        // const perms = getPermissions();
        responseObject = new Auth(response);
      } else if (response.data.instance === "ACCOUNT") {
        responseObject = new Account(response);
      } else if (response.data.instance === "RATE_LIMIT") {
        responseObject = new RateLimit(response);
        Toastr.error("Error", "Rate limited!");
      } else if (response.data.instance === "SESSION") {
        responseObject = new Session(response);
      } else {
        responseObject = new Response(response);
      }

      if (responseObject.getStripeSetupComplete() === false) {

        return navigate(
            "/external",
            {
              state: {
                redirectUrl: responseObject.getStripeSetupUrl(),
                redirectMessage: "You will now be redirected to complete your Stripe Account"
              }
            }
        );
      }



      if (responseObject.hasToast()) {
        const toastSubject = responseObject.getToastSubject();
        const toastMessage = responseObject.getToastMessage();
        switch (responseObject.getToastStatus()) {
          case "ERROR":
            Toastr.error(toastSubject, toastMessage);
            break;
          case "INFO":
            Toastr.info(toastSubject, toastMessage);
            break;
          case "SUCCESS":
            Toastr.success(toastSubject, toastMessage);
            break;
          case "WARNING":
            Toastr.warning(toastSubject, toastMessage);
            break;
        }
      }

      if (responseObject.isAdmin()) {
        bakeCookie("role", "admin");
      }

      if (responseObject.isContractor()) {
        bakeCookie("role", "contractor");
      }

      if (responseObject.getHasRedirect()) {
        if (responseObject.getRedirect().indexOf('http') === 0) {
          return responseObject;
        }
        return navigate(responseObject.getRedirect());
      }

      return responseObject;
    };

    const reqInterceptor = (config) => {
      config.headers["content-type"] = "application/json";
      config.headers["Accept"] = "application/json";
      if (isLoggedIn()) {
        config.headers.Authorization = "Bearer " + getBearerToken();
      }

      return config;
    };

    const errInterceptor = (error) => {
      const cookie_auth = "authTokenCK";

      let responseObject;

      if (error.response.status === 404) {
        Toastr.error("Failed", "Server Error - Endpoint not found");
        responseObject = new Response({
          data: {
            status: false,
            suppressNotice: true,
            success: false,
          },
        });
        return responseObject;
      } else if (error.response.status === 404) {
        Toastr.error("Failed", "Server Error - Bad Request");
        responseObject = new Response({
          data: {
            status: false,
            suppressNotice: true,
            success: false,
          },
        });
        return responseObject;
      }

      if (error.response.status === 401) {
        deleteCookie(cookie_auth);
        localStorage.removeItem("zev_cache");
        if (error.config.url !== "/zev.hub/api/auth/login") {
          navigate("/auth/login");
        }
      }

      if (error.response.status === 403) {
        window.location.href = "/zev.hub/auth/status";
      }

      if (error.response.status === 429) {
        const errorText =
          "Maximum of " +
          error.response.data.data.max_requests +
          " requests in a " +
          error.response.data.data.time_period +
          " second period exceeded.";
        responseObject = new RateLimit(error.response);
        Toastr.error("Rate limit", errorText);
      } else if (error.message.indexOf("JWT") > -1) {
        deleteCookie("JSESSIONID");
        window.location.href = "/zev.hub/auth/status";
      } else if (error.toString().indexOf("status code 500") > -1) {
        Toastr.error("Failed", "Server Error - please try again");
      } else {
        responseObject = new Exception(error.response);
      }
      return responseObject;
    };

    const responseInterceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
    const requestInterceptor = instance.interceptors.request.use(reqInterceptor);

    return () => {
      instance.interceptors.response.eject(responseInterceptor, errInterceptor);
      instance.interceptors.response.eject(requestInterceptor);
    };
  }, []);

  return isSet && children;
};

export default instance;
export { AxiosInterceptor };
