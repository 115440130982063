import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomersListView from "../../../pages/Views/cst/Customers/CustomersListView";
import CustomersEditView from "../../../pages/Views/cst/Customers/CustomersEditView";
import CustomersAddView from "../../../pages/Views/cst/Customers/CustomersAddView";
import CustomersDeleteView from "../../../pages/Views/cst/Customers/CustomersDeleteView";

const CustomersRoute = {
  type: "collapse",
  name: "Customers",
  sidenav: true,
  order: 2,
  key: "admin-customers",
  icon: <FontAwesomeIcon icon={"fa-user fa-solid"} />,
  collapse: [
    {
      name: "Customer Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "customers-list",
      route: "/customers/list",
      icon: <FontAwesomeIcon icon={["fad", "list"]} />,
      component: <CustomersListView />,
    },
    {
      hide: true,
      name: "Edit Customer",
      sidenav: true,
      key: "customers-edit",
      route: "/customers/edit/:customerId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <CustomersEditView />,
    },
    {
      hide: true,
      name: "Edit Customer",
      sidenav: true,
      key: "customers-edit",
      route: "/customers/view/:customerId",
      icon: <FontAwesomeIcon icon={["fad", "file-pen"]} />,
      component: <CustomersEditView />,
    },
    {
      hide: true,
      name: "Delete Customer",
      sidenav: true,
      key: "customers-delete",
      route: "/customers/delete/:customerId",
      icon: <FontAwesomeIcon icon={["fad", "trash-list"]} />,
      component: <CustomersDeleteView />,
    },
    {
      name: "Add New Customer",
      sidenav: true,
      key: "customers-add",
      route: "/customers/add",
      icon: <FontAwesomeIcon icon={["fad", "file-circle-plus"]} />,
      component: <CustomersAddView />,
    },
  ],
};

export default CustomersRoute;
