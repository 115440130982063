import JobsListView from "../../../pages/Views/irq/Jobs/JobsListView";
import JobsEditView from "../../../pages/Views/irq/Jobs/JobsEditView";
import { FAStack } from "../../../Utility/FontAwesome";
import FinalPaymentPaymentCompleteView from "../../../pages/Checkout/FinalPaymentPaymentCompleteView";

const JobsRoute = {
  type: "collapse",
  name: "Jobs",
  sidenav: true,
  order: 2,
  key: "jobs-list",
  route: "/jobs/list",
  component: <JobsListView />,
  icon: (
    <>
      <FAStack main="list-check" sub="question-circle" />
      &nbsp;
    </>
  ),
  hideCollapse: true,
  collapse: [
    {
      name: "Job Listing",
      subtitle: "Dashboard",
      sidenav: true,
      key: "jobs-list",
      route: "/jobs/list",
      icon: <FAStack main="user" sub="plus-circle" />,
      component: <JobsListView />,
      hide: true,
    },
    {
      hide: true,
      name: "View Job",
      sidenav: true,
      key: "jobs-view",
      route: "/jobs/view/:jobId",
      component: <JobsEditView viewOnlyMode={true} />,
    },
  ],
};

export default JobsRoute;
